import { httpClient } from "../utils/HttpClient";

const getAll = () => {
  return httpClient.get("/admin/nft_group", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const get = (group_id) => {
  return httpClient.get(`/admin/nft_group/${group_id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};


const create = (data) => {
  return httpClient.post("/admin/nft_group", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const update = (group_id, data) => {
  return httpClient.patch(`/admin/nft_group/${group_id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const remove = (group_id) => {
  return httpClient.delete(`/admin/nft_group/${group_id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};


const getNftGroupMember = (filter_group_id, filter_nft_number,filter_nft_owner,filter_nft_wallet) => {
  return httpClient.get("/admin/nft_group_member", {
    params: {
      group_id: filter_group_id,
      nft_number: filter_nft_number,
      nft_owner: filter_nft_owner,
      wallet_id: filter_nft_wallet
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const addNftGroupMember = (data) => {
  return httpClient.post("/admin/nft_group_member", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const removeNftGroupMember = (id) => {
  return httpClient.delete(`/admin/nft_group_member/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getNFTWallet = (filterNFTWallet) => {
  return httpClient.get("/admin/member_nft", {
    params: {
      wallet_id: filterNFTWallet
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getNFTGroupSelect = () => {
  return httpClient.get(`/admin/nft_group_select`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const addVidioSpecifyNFT = (data) => {
  return httpClient.post("/admin/video_specify_nft", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getVdoSpecifyNFT = (filterVideoID) => {
  return httpClient.get("/admin/video_specify_nft", {
    params: {
      video_id: filterVideoID
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getVdoGroupNFT = (filterVideoID) => {
  return httpClient.get("/admin/video_group_nft", {
    params: {
      video_id: filterVideoID
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const addVideoGroupNFT = (data) => {
  return httpClient.post("/admin/video_group_nft", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const deleteVideoSpecifyNFT = (id, data) => {
  return httpClient.patch(`/admin/video_group_nft/status/${id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const deleteVideoGroupNFT = (group_id, data) => {
  console.log(data)
  return httpClient.patch(`/admin/video_group_nft/status_group/${group_id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const deleteWalletSpecifyVideo = (group_id, data) => {
  console.log(data)
  return httpClient.patch(`/admin/video_group_nft/group/${group_id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const deleteAllGroup = (group_id, data) => {
  console.log(data)
  return httpClient.patch(`/admin/video_group/${group_id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const updateNFTGroupMember = (data) => {
  return httpClient.patch(`/admin/nft_group_member`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const updateVideoSpecify = (data) => {
  return httpClient.patch(`/admin/video_specify_nft`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export default {
  getAll,
  get,
  create,
  update,
  remove,
  getNftGroupMember,
  addNftGroupMember,
  removeNftGroupMember,
  getNFTWallet,
  getNFTGroupSelect,
  addVidioSpecifyNFT,
  getVdoSpecifyNFT,
  getVdoGroupNFT,
  addVideoGroupNFT,
  deleteVideoSpecifyNFT,
  deleteVideoGroupNFT,
  deleteWalletSpecifyVideo,
  deleteAllGroup,
  updateNFTGroupMember,
  updateVideoSpecify
};
