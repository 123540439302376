import './Header.scss';
import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import useToken from '../../Token';
import { Menu, Dropdown } from 'antd';
import { CaretDownOutlined,UserOutlined,LogoutOutlined } from '@ant-design/icons';

export default function Header() {
  const { removeToken } = useToken();
  const history = useHistory();
  const [name, setName] = useState("");
  const [role, setRole] = useState("");

  useEffect(() => {
    let name = "";
    let role = "";

    var result = localStorage.getItem("user_profile");
    if (result) {
      name = JSON.parse(result).name;
      setName(name);

      role = JSON.parse(result).role_name;
      setRole(role);
    }
  }, [0]);

  const signout = async () => {
    await removeToken();
    await localStorage.removeItem('token');
    history.push("/login");
  };

  const userMenu = (
    <Menu>
      <Menu.Item key="1" onClick={signout} className="text-center">ออกจากระบบ</Menu.Item>
    </Menu>
  );

  return  (
    <div className="header-container">
      <header class="header d-flex align-items-center px-4" style={{ background:'#F0F2F5' }}>
        <div className="container d-flex">
        
          <img
            src={require("../../assets/img/logo_s2m_head.png").default}
            alt=""
          />
          <Dropdown className="ml-auto user-dropdown" overlay={userMenu} trigger={['click']} placement="bottomRight" arrow>
            <div className="d-flex align-items-center">
              <div className="ml-auto user-profile font-weight-bold"><UserOutlined /></div>
              <div className="ml-2">{name} <span style={{color:"#F16425"}}>({role})</span></div>
              <CaretDownOutlined className="ml-2" />
            </div>
          </Dropdown>
        </div>
      </header>
      {/* <div className="top-menu">
        <div className="container d-flex justify-content-between">
          <a href="#" className="active">บริหารจัดการโครงการวิจัย</a>
          <a href="#">บริหารจัดการอ้างอิง</a>
          <a href="#">รายงาน</a>
          <a href="#">ระบบ MIS</a>
          <a href="#">ระบบ IP</a>
          <a href="#">ระบบ ERP</a>
        </div>
      </div> */}
    </div>
  )
}