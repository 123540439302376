import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import NftService from "../services/NftService";

import {
  Input,
  Button,
  Tooltip,
  Tag,
  Modal,
  notification,
  Popconfirm,
  Switch,
  Table,
} from "antd";

import {
  EditOutlined,
  UserOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import ContentPagination from "../utils/Pagination";
import moment from "moment";
import Notification from "../utils/Notification";
const { Column } = Table;

export default function NftGroup() {
  const history = useHistory();

  const [data, setData] = useState([]);
  const nftGroupState = {
    group_id: null,
    group_name: "",
    updated_by: "",
    created_by: "",
    updated_datetime: null,
    status: null,
  };
  const [nftGroup, setNftGroup] = useState(nftGroupState);
  const [ModalOpen, setModalOpen] = useState(false);
  const [groupName, setGroupName] = useState();
  const [dataItem, setDataItem] = useState([]);
  const [action, setAction] = useState("");

  const { confirm } = Modal;

  useEffect(() => {
    getData();
  }, [0]);

  const getData = async () => {
    await NftService.getAll()
      .then((res) => {
        const response = res.data;
        setData(response.nft_group);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const ShowNotification = (type, header, message) =>
    notification[type]({
      message: header,
      description: message,
    });

  function SaveNFTGroup() {
    if (!groupName) {
      Notification.Show("error", "Validation", "กรุณาระบุชื่อ Group");
      return;
    }

    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;

    if (action === "add") {
      nftGroup.group_id = null;
    } else nftGroup.group_id = dataItem.group_id;

    nftGroup.group_name = groupName;

    if (action === "add") {
      nftGroup.created_by = action_by;
    }

    nftGroup.updated_by = action_by;
    nftGroup.updated_datetime = moment(new Date()).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    if (action === "add") nftGroup.status = "A";
    else nftGroup.status = dataItem.status;

    console.log(nftGroup);
    if (action === "add") {
      NftService.create(nftGroup)
        .then((response) => {
          ShowNotification("success", "Success", "บันทึกข้อมูลเรียบร้อยแล้ว");
          setModalOpen(false);
          getData();
        })
        .catch((e) => {
          console.log(e);
          Notification.Show("error", "Validation", e.response.data.message);
        });
    } else {
      NftService.update(nftGroup.group_id, nftGroup)
        .then((response) => {
          ShowNotification("success", "Success", "บันทึกข้อมูลเรียบร้อยแล้ว");
          setModalOpen(false);
          getData();
        })
        .catch((e) => {
          console.log(e);
          Notification.Show("error", "Validation", e.response.data.message);
        });
    }
  }

  async function manageNftGroup(action, data) {
    if (action === "add") {
      await setAction("add");
      await setGroupName("");
    } else {
      setAction("update");
      await setDataItem(data);
      await setGroupName(data.group_name);
    }
    setModalOpen(true);
  }

  const showDeleteConfirm = (id) => {
    confirm({
      title: "Confirm Delete?",
      icon: <ExclamationCircleOutlined />,
      content: "ต้องการลบ Group นี้ใช่หรือไม่",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        deleteNftGroup(id);
      },

      onCancel() {
        console.log("Cancel");
      },
    });
  };

  async function deleteNftGroup(id) {
    let response = await NftService.remove(id);
    if (response.status === 200 || response.status === 201) {
      Notification.Show("success", "Success", "ลบ Group นี้เรียบร้อยแล้ว");
      deleteAllGroupVideo(id);
      getData();
    } else {
      Notification.Show(
        "error",
        "Error",
        "เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง"
      );
    }
  }

  const deleteAllGroupVideo = (group_id) => {
    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;

    nftGroup.updated_by = action_by;
    nftGroup.updated_datetime = moment(new Date()).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    NftService.deleteAllGroup(group_id,nftGroup)
      .then((response) => {
        
      })
      .catch((e) => {
        console.log(e);
        Notification.Show("error", "Error", e.response.data.message);
      });
  };

  return (
    <div className="container">
      <div className="text-right container-fluid">
        <Button
          className="btn-primary px-3"
          onClick={() => {
            manageNftGroup("add", null);
          }}
        >
          เพิ่ม Group ใหม่
        </Button>
      </div>

      <h5 className="mt-2 font-weight-bold">NFT Group</h5>
      <div className="line-highlight">
        <hr />
      </div>

      <div className="flex-fill">
        <div class="table_wrapper">
          <Table dataSource={data} paging={true}>
            <Column
              title="ชื่อ Group"
              dataIndex="group_name"
              key="group_name"
              align="center"
            />
            <Column
              title="จัดการสมาชิก"
              render={(value) => {
                return (
                  <Tooltip title="จัดการสมาชิก" className="mr-2">
                    <Button
                      type="primary"
                      shape="circle"
                      size="small"
                      className="btn-secondary"
                      icon={<EditOutlined />}
                      onClick={() =>
                        history.push("/nft_group_member/" + value.group_id)
                      }
                    />
                  </Tooltip>
                );
              }}
              align="center"
            />
            <Column
              title="แก้ไขชื่อ Group"
              render={(value) => {
                return (
                  <Tooltip title="แก้ไขชื่อ Group" className="mr-2">
                    <Button
                      type="primary"
                      shape="circle"
                      size="small"
                      className="btn-secondary"
                      icon={<EditOutlined />}
                      onClick={() => manageNftGroup("update", value)}
                    />
                  </Tooltip>
                );
              }}
              align="center"
            />
            <Column
              title="ลบ"
              render={(value) => {
                return (
                  <Tooltip title="ลบ" className="mr-2">
                    <Button
                      type="primary"
                      shape="circle"
                      size="small"
                      className="btn-danger"
                      icon={<DeleteOutlined />}
                      onClick={() => showDeleteConfirm(value.group_id)}
                    />
                  </Tooltip>
                );
              }}
              align="center"
            />
          </Table>
        </div>

        <Modal
          title="NFT Group"
          footer={null}
          maskClosable={false}
          visible={ModalOpen}
          onCancel={() => setModalOpen(false)}
        >
          <div className="row">
            <div className="col-12">
              <label>
                ชื่อ Group
                <span className="text-danger">*</span>
              </label>
              <Input
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-6"></div>

            <div className="col-6">
              <div className="mt-2 text-right">
                <button
                  type="button"
                  className="btn btn-secondary mr-2"
                  onClick={() => {
                    setModalOpen(false);
                  }}
                >
                  ยกเลิก
                </button>
                <button
                  type="button"
                  onClick={() => {
                    SaveNFTGroup();
                  }}
                  className="btn btn-primary mr-2"
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
