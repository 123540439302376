import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Input, notification } from "antd";
import Notification from "../utils/Notification";
import UserService from "../services/UserService";
import "./Password.scss";

export default function ForgetPassword() {
  const [email, setEmail] = useState();

  const initialState = {
    email: "",
    reference_code: "",
  };
  const [sendEmail, setSendEmail] = useState(initialState);
  const history = useHistory();

  function ValidateEmail() {
    if (!email) {
      Notification.Show("error", "Validation", "กรุณาระบุ Email Address");
      return false;
    }
  }

  const getUser = async (email) => {
    if (email) {
      await UserService.get(email)
        .then((response) => {
          console.log(response.data.reference_code);
          return response.data.reference_code;
        })
        .catch((e) => {
          console.log(e);
          Notification.Show(
            "error",
            "From Web Service",
            e.response.data.message
          );
        });
    }
  };

  async function SendEmail() {
    if (ValidateEmail() === false) return;

    sendEmail.email = email;

    //Get RefereneceCode From Emai
    let referenceCode = "";
    await UserService.get(email)
      .then((response) => {
        referenceCode = response.data.reference_code;
      })
      .catch((e) => {
        console.log(e);
        Notification.Show("error", "From Web Service", e.response.data.message);
      });

    if (referenceCode) {
      await UserService.sendEmail(email,referenceCode)
        .then((response) => {
          Notification.Show("success", "Success", `ส่งข้อมูลสำหรับตั้งค่ารหัสผ่านไปทางอีเมล ${email} กรุณาตรวจสอบและดำเนินการต่อ`);
        })
        .catch((e) => {
          console.log(e);
          Notification.Show(
            "error",
            "From Web Service",
            e.response.data.message
          );
        });
    }
  }

  return (
    <div className="password-wrapper">
      <div className="card border-0">
        <div className="card-body p-5">
          <img
            className="img-fluid mx-auto d-block"
            src={require("../assets/img/logo_s2m_head.png").default}
            alt=""
            width="260"
          />

          <hr className="mx-auto" />

          <div className="form-group mt-4 pt-2">
            <b>กรุณาระบุอีเมลที่ลงทะเบียน</b>
          </div>

          <div className="form-group mt-0">
            ระบบจะส่งข้อมูลสำหรับตั้งค่ารหัสผ่านใหม่ให้คุณทางอีเมล
          </div>

          <div className="form-group mt-4">
            <label>อีเมล *</label>
            <Input
              placeholder="Email Address"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="mt-3 pt-2">
            <button
              className="btn btn-primary btn-block font-weight-bold py-2"
              onClick={SendEmail}
            >
              ส่งอีเมล
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
