import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Input,
  Button,
  Popconfirm,
  message,
  Switch,
  Modal,
  notification,
  Spin,
  Tooltip,
  Table,
} from "antd";
import moment from "moment";
import NftService from "../services/NftService";
import Notification from "../utils/Notification";
import {
  SearchOutlined,
  EditOutlined,
  UserOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

const { confirm } = Modal;
const { Column } = Table;

const ManageNftGroupMember = (props) => {
  const history = useHistory();
  const initialNftGroupMemberState = {
    id: null,
    group_id: "",
    nft_number: "",
    nft_owner: "",
    wallet_id: "",
    status: "",
    created_by: null,
    updated_datetime: null,
    updated_by: null,
  };

  const { group_id } = useParams();
  const [nftGroupMember, setNftGroupMember] = useState(
    initialNftGroupMemberState
  );

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataWallet, setDataWallet] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [ModalOpen, setModalOpen] = useState(false);
  const [editName, setEditName] = useState("");
  const [editWalletID, setEditWalletID] = useState("");

  const [searchWalletID, setSearchWalletID] = useState("");
  const [searchNftNumber, setSearchNftNumber] = useState("");
  const [searchNftOwner, setSearchNftOwner] = useState("");

  const [dataItem, setDataItem] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getData(group_id, searchNftNumber, searchNftOwner, searchWalletID);
    //getNFTWallet(searchWalletID);
  }, [group_id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNftGroupMember({ ...nftGroupMember, [name]: value });
  };

  const onChangeSearchNftNumber = (e) => {
    const searchNftNumber = e.target.value;
    setSearchNftNumber(searchNftNumber);
    getData(group_id, searchNftNumber, searchNftOwner, searchWalletID);
  };

  const onChangeSearchNftOwner = (e) => {
    const searchNftOwner = e.target.value;
    setSearchNftOwner(searchNftOwner);
    getData(group_id, searchNftNumber, searchNftOwner, searchWalletID);
  };

  const onChangeSearchNftWallet = (e) => {
    const searchNftWallet = e.target.value;
    setSearchWalletID(searchNftWallet);
    getData(group_id, searchNftNumber, searchNftOwner, searchNftWallet);
  };

  const getData = (group_id, nft_number, nft_owner, nft_wallet) => {
    if (group_id) {
      NftService.getNftGroupMember(group_id, nft_number, nft_owner, nft_wallet)
        .then((response) => {
          setData(response.data.nft_group_member);
        })
        .catch((e) => {
          console.log(e);
          Notification.Show(
            "error",
            "From Web Service",
            e.response.data.message
          );
        });
    }
  };

  // const onChangeSearchFirstName = (e) => {
  //   const searchFirstName = e.target.value;
  //   setSearchFirstName(searchFirstName);
  //   getNFTWallet(searchWalletID);
  // };

  // const onChangeSearchLastName = (e) => {
  //   const searchLastName = e.target.value;
  //   setSearchLastName(searchLastName);
  //   getNFTWallet(searchWalletID);
  // };

  // const getNFTWallet = (searchWalletID) => {
  //   NftService.getNFTWallet(searchWalletID)
  //     .then((response) => {
  //       setDataWallet(response.data.members);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       Notification.Show("error", "From Web Service", e.response.data.message);
  //     });
  // };

  const ShowNotification = (type, header, message) =>
    notification[type]({
      message: header,
      description: message,
    });

  async function ManageNftGroupMember() {
    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;

    if (!nftGroupMember.nft_number) {
      Notification.Show("error", "Validation", "กรุณาระบุ NFT Number");
      return;
    }

    if (!nftGroupMember.nft_owner) {
      Notification.Show("error", "Validation", "กรุณาระบุ Name");
      return;
    }

    nftGroupMember.group_id = group_id;
    nftGroupMember.nft_number = nftGroupMember.nft_number;
    nftGroupMember.nft_owner = nftGroupMember.nft_owner;
    nftGroupMember.wallet_id = nftGroupMember.wallet_id;

    nftGroupMember.created_by = action_by;
    nftGroupMember.updated_by = action_by;
    nftGroupMember.updated_datetime = moment(new Date()).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    nftGroupMember.status = "A";

    setLoading(true);

    NftService.addNftGroupMember(nftGroupMember)
      .then((response) => {
        ShowNotification("success", "Success", "บันทึกข้อมูลเรียบร้อยแล้ว");
        nftGroupMember.wallet_id = "";
        nftGroupMember.nft_number = "";
        nftGroupMember.nft_owner = "";
        setSubmitted(true);
        setLoading(false);
        getData(group_id, searchNftNumber, searchNftOwner, searchWalletID);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        Notification.Show("error", "Validation", e.response.data.message);
      });
  }

  const deleteNftGroupMember = (id, nft_number) => {
    NftService.removeNftGroupMember(id)
      .then((response) => {
        //console.log(response.data);
        deleteWalletSpecifyVideo(group_id, nft_number);

        Notification.Show("success", "Success", "ลบข้อมูล เรียบร้อยแล้ว");
        setNftGroupMember(initialNftGroupMemberState);
        getData(group_id, searchNftNumber, searchNftOwner, searchWalletID);
      })
      .catch((e) => {
        console.log(e);
        Notification.Show("error", "Error", e.response.data.message);
      });
  };

  const deleteWalletSpecifyVideo = (group_id, nft_number) => {
    nftGroupMember.nft_number = nft_number;

    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;

    nftGroupMember.updated_by = action_by;
    nftGroupMember.updated_datetime = moment(new Date()).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    NftService.deleteWalletSpecifyVideo(group_id, nftGroupMember)
      .then((response) => {
        nftGroupMember.wallet_id = "";
      })
      .catch((e) => {
        console.log(e);
        nftGroupMember.wallet_id = "";
        Notification.Show("error", "Error", e.response.data.message);
      });
  };

  const cancel = () => {
    history.push("/nft_group");
  };

  const showDeleteConfirm = (id, nft_number) => {
    confirm({
      title: "Confirm Delete?",
      icon: <ExclamationCircleOutlined />,
      content: "ต้องการลบ Member นี้ใช่หรือไม่",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        deleteNftGroupMember(id, nft_number);
      },

      onCancel() {
        console.log("Cancel");
      },
    });
  };

  function SaveEdit() {
    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;

    nftGroupMember.id = dataItem.id;
    nftGroupMember.group_id = dataItem.group_id;
    nftGroupMember.nft_number = dataItem.nft_number;
    nftGroupMember.nft_owner = editName;
    nftGroupMember.wallet_id = editWalletID;
    nftGroupMember.status = dataItem.status;
    nftGroupMember.created_by = dataItem.created_by;
    nftGroupMember.updated_by = action_by;
    nftGroupMember.updated_datetime = moment(new Date()).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    NftService.updateNFTGroupMember(nftGroupMember)
      .then((response) => {
        ShowNotification("success", "Success", "บันทึกข้อมูลเรียบร้อยแล้ว");
        setNftGroupMember(initialNftGroupMemberState);
        setModalOpen(false);
        getData(group_id, searchNftNumber, searchNftOwner, searchWalletID);
      })
      .catch((e) => {
        console.log(e);
        setNftGroupMember(initialNftGroupMemberState);
        Notification.Show("error", "Validation", e.response.data.message);
      });
  }

  const ManageEditNft = async (value) => {
    await setEditName(value.nft_owner);
    await setEditWalletID(value.wallet_id);
    await setDataItem(value);
    setModalOpen(true);
  };
  // const ManageEditNft = (value) => {
  //   setDataItem(data);
  //   setEditName(data.nft_owner);
  //   setEditWalletID(data.wallet_id);
  //   setModalOpen(true);
  // };

  return (
    <div className="container">
      <div className="d-flex mt-4 align-items-stretch">
        <div className="flex-fill">
          <div className="header-bar">Manage Group</div>

          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-4">
                <div>
                  <label>
                    NFT Number
                    <span className="text-danger">*</span>
                  </label>
                  <Input
                    id="nft_number"
                    required
                    value={nftGroupMember.nft_number}
                    onChange={handleInputChange}
                    name="nft_number"
                  />
                </div>
              </div>
              <div className="col-4">
                <div>
                  <label>
                    Name
                    <span className="text-danger">*</span>
                  </label>
                  <Input
                    id="nft_owner"
                    required
                    value={nftGroupMember.nft_owner}
                    onChange={handleInputChange}
                    name="nft_owner"
                  />
                </div>
              </div>
              <div className="col-4">
                <div>
                  <label>Wallet ID</label>
                  <Input
                    id="wallet_id"
                    value={nftGroupMember.wallet_id}
                    onChange={handleInputChange}
                    name="wallet_id"
                  />
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-12 text-right">
                <Button className="btn-secondary mr-2" onClick={cancel}>
                  ยกเลิก
                </Button>
                <Button className="btn-primary" onClick={ManageNftGroupMember}>
                  เพิ่ม Wallet
                </Button>
                <Spin style={{ marginLeft: 10 }} spinning={loading} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-fill">
        <div className="form-group mb-0 mt-4">
          <div className="row">
            <div className="col-4">
              <Input
                placeholder="ค้นหา Nft Number"
                prefix={<SearchOutlined />}
                value={searchNftNumber}
                onChange={onChangeSearchNftNumber}
              />
            </div>
            <div className="col-4">
              <Input
                placeholder="ค้นหา ชื่อ"
                prefix={<SearchOutlined />}
                value={searchNftOwner}
                onChange={onChangeSearchNftOwner}
              />
            </div>
            <div className="col-4">
              <Input
                placeholder="ค้นหา Wallet ID"
                prefix={<SearchOutlined />}
                value={searchWalletID}
                onChange={onChangeSearchNftWallet}
              />
            </div>
          </div>
        </div>

        <div class="table_wrapper mt-4">
          <Table dataSource={data} paging={true}>
            <Column
              title="Group Name"
              dataIndex="group_name"
              key="group_name"
              align="center"
            />
            <Column
              title="NFT Number"
              dataIndex="nft_number"
              key="nft_number"
              align="center"
            />
            <Column
              title="Name"
              dataIndex="nft_owner"
              key="nft_owner"
              align="left"
            />
            <Column
              title="Wallet ID"
              dataIndex="wallet_id"
              key="wallet_id"
              align="left"
            />
            <Column
              title="Edit"
              render={(value) => {
                return (
                  <Tooltip title="แก้ไข" className="mr-2">
                    <Button
                      type="primary"
                      shape="circle"
                      size="small"
                      className="btn-warning"
                      icon={<EditOutlined />}
                      onClick={() => ManageEditNft(value)}
                    />
                  </Tooltip>
                );
              }}
              align="center"
            />
            <Column
              title="Delete"
              render={(value) => {
                return (
                  <Tooltip title="ลบ" className="mr-2">
                    <Button
                      type="primary"
                      shape="circle"
                      size="small"
                      className="btn-danger"
                      icon={<DeleteOutlined />}
                      onClick={() =>
                        showDeleteConfirm(value.id, value.nft_number)
                      }
                    />
                  </Tooltip>
                );
              }}
              align="center"
            />
          </Table>

          <Modal
            title="Edit"
            footer={null}
            maskClosable={false}
            visible={ModalOpen}
            onCancel={() => setModalOpen(false)}
          >
            <div className="row">
              <div className="col-12">
                <label>NFT Number</label>
                <Input value={dataItem.nft_number} disabled />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-12">
                <label>Name</label>
                <Input
                  value={editName}
                  onChange={(e) => setEditName(e.target.value)}
                />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-12">
                <label>Wallet ID</label>
                <Input
                  value={editWalletID}
                  onChange={(e) => setEditWalletID(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6"></div>

              <div className="col-6">
                <div className="mt-2 text-right">
                  <button
                    type="button"
                    className="btn btn-secondary mr-2"
                    onClick={() => {
                      setModalOpen(false);
                    }}
                  >
                    ยกเลิก
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      SaveEdit();
                    }}
                    className="btn btn-primary mr-2"
                  >
                    ยืนยัน
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default ManageNftGroupMember;
