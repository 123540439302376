import { httpClient } from "../utils/HttpClient";

const getAll = (page_no,filter_order_number, filter_status, filter_deliver_status,filter_email,filter_first_name,filter_last_name,filter_product_type,filter_start_date,filter_end_date) => {
  return httpClient.get("/admin/report/orders", {
    params: {
      page: page_no,
      order_number: filter_order_number,
      status: filter_status,
      book_deliver_status: filter_deliver_status,
      order_email: filter_email,
      first_name: filter_first_name,
      last_name: filter_last_name,
      product_type: filter_product_type,
      start_date: filter_start_date,
      end_date: filter_end_date
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getOrderDetail = (page_no,filter_order_number) => {
  return httpClient.get(`/admin/report/orders/detail`, {
    params: {
      page: page_no,
      order_number: filter_order_number
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const cancelOrder = (filter_order_number) => {
  return httpClient.delete(`/admin/order/cancel`, {
    params: {
      order_number: filter_order_number
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const cancelEmail = (filter_order_number) => {
  return httpClient.get(`/admin/orders/email_cancel`, {
    params: {
      order_number: filter_order_number
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const sendTrackingNo = (data) => {
  return httpClient.post("/admin/email/tracking", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const updateOrder = (data) => {
  return httpClient.patch(`/admin/order`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};


export default {
  getAll,
  getOrderDetail,
  cancelOrder,
  sendTrackingNo,
  updateOrder,
  cancelEmail
};
