// Error Code
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK";

//////////////// Localization Begin ////////////////
export const NETWORK_CONNECTION_MESSAGE =
  "Cannot connect to server, Please try again.";
export const NETWORK_TIMEOUT_MESSAGE =
  "A network timeout has occurred, Please try again.";

export const YES = "YES";
export const NO = "NO";

export const server = {
  LOGIN_URL: `/login`,
  LOGIN_PASSED: `yes`,
  WRITER_URL: `/admin/writer`,
};
