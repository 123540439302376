import React from "react";
import "./App.scss";
import "antd/dist/antd.css";
import "./assets/css/bootstrap.min.css";
import "./assets/scss/styles.scss";
import useToken from "./Token";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Header from "./common/header/Header";
import Sidebar from "./common/sidebar/Sidebar";
import Login from "./login/Login";
import ForgetPassword from "./login/ForgetPassword";
import SetPassword from "./login/SetPassword";
import Writer from "./writer/Writer";
import ManageWriter from "./writer/ManageWriter";

import Lecturer from "./lecturer/Lecturer";
import ManageLecturer from "./lecturer/ManageLecturer";

import Article from "./article/Article";
import ManageArticle from "./article/ManageArticle";

import Course from "./course/Course";
import ManageCourse from "./course/ManageCourse";

import Book from "./book/Book";
import ManageBook from "./book/ManageBook";

import AudioBook from "./book_audio/BookAudio";
import ManageBookAudio from "./book_audio/ManageBookAudio";

import EBook from "./ebook/Ebook";
import ManageEBook from "./ebook/ManageEbook";

import User from "./user/User";
import ManageUser from "./user/ManageUser";
import UserRole from "./user_role/UserRole";
import ManageRole from "./user_role/ManageRole";

import Banner from "./banner/Banner";
import ManageBanner from "./banner/ManageBanner";

import Member from "./member/Member";
import ManageMember from "./member/ManageMember";
import ReportOrders from "./report/Order";
import ReportOrderDetail from "./report/OrderDetail";
import ReportPurchase from "./report/Purchase";
import ReportTaxInvoices from "./report/TaxInvoices";
import ReportCourse from "./report/Course";
import ManageOrder from "./report/ManageOrder";

import Topic from "./webboard/topic";
import Thread from "./webboard/thread";

import Video from "./video/Video";
import ManageVideo from "./video/ManageVideo";

import Influencer from "./Tracking/Influencer";
import Channel from "./Tracking/Channel";
import TrackingLink from "./Tracking/TrackingLink";
import ReportTracking from "./report/Tracking";

import PreorderBook from "./book/Preorder";
import NftGroup from "./nft/nft_group";
import ManageNftGroupMember from "./nft/nft_group_member";
import VideoSpecifyNFT from "./nft/vdo_specify_nft";

function App() {
  const { token, setToken } = useToken();

  const isLoggedIn = () => {
    const tokenString = localStorage.getItem("token");
    if (tokenString) return true;
    else return false;
  };

  const SecuredRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          isLoggedIn() ? <Component {...props} /> : <Redirect to="/login" />
        }
      />
    );
  };

  return (
    <BrowserRouter>
      <Switch>
        <Redirect exact from="/" to={token ? "/dashboard" : "/login"} />
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/forget_password">
          <ForgetPassword />
        </Route>
        <Route path="/set_password/:reference_code">
          <SetPassword />
        </Route>
        <>
          <div className="wrapper">
            <Header />
            <div className="container">
              <div className="d-flex mt-4 align-items-stretch">
                <Sidebar />
                <SecuredRoute path="/writer" component={() => <Writer />} />

                <SecuredRoute
                  path="/manage/writer/add"
                  component={() => <ManageWriter action="add" />}
                />
                <SecuredRoute
                  path="/manage/writer/update/:writer_id"
                  component={() => <ManageWriter action="update" />}
                />

                <SecuredRoute path="/lecturer" component={() => <Lecturer />} />
                <SecuredRoute
                  path="/manage/lecturer/add"
                  component={() => <ManageLecturer action="add" />}
                />
                <SecuredRoute
                  path="/manage/lecturer/update/:lecturer_id"
                  component={() => <ManageLecturer action="update" />}
                />

                <SecuredRoute path="/article" component={() => <Article />} />
                <SecuredRoute
                  path="/manage/article/add"
                  component={() => <ManageArticle action="add" />}
                />
                <SecuredRoute
                  path="/manage/article/update/:article_id"
                  component={() => <ManageArticle action="update" />}
                />

                <SecuredRoute path="/course" component={() => <Course />} />
                <SecuredRoute
                  path="/manage/course/add"
                  component={() => <ManageCourse action="add" />}
                />
                <SecuredRoute
                  path="/manage/course/update/:course_id"
                  component={() => <ManageCourse action="update" />}
                />

                <SecuredRoute path="/book" component={() => <Book />} />
                <SecuredRoute
                  path="/manage/book/add"
                  component={() => <ManageBook action="add" />}
                />
                <SecuredRoute
                  path="/manage/book/update/:book_id"
                  component={() => <ManageBook action="update" />}
                />

                <SecuredRoute
                  path="/audio_book"
                  component={() => <AudioBook />}
                />
                <SecuredRoute
                  path="/manage/audio_book/add"
                  component={() => <ManageBookAudio action="add" />}
                />
                <SecuredRoute
                  path="/manage/audio_book/update/:book_id"
                  component={() => <ManageBookAudio action="update" />}
                />

                <SecuredRoute path="/ebook" component={() => <EBook />} />
                <SecuredRoute
                  path="/manage/ebook/add"
                  component={() => <ManageEBook action="add" />}
                />
                <SecuredRoute
                  path="/manage/ebook/update/:book_id"
                  component={() => <ManageEBook action="update" />}
                />

                <SecuredRoute
                  path="/user"
                  component={() => <User />}
                  forceRefresh={true}
                />
                <SecuredRoute path="/member" component={() => <Member />} />
                <SecuredRoute
                  path="/manage/member/update/:id"
                  component={() => <ManageMember action="update" />}
                />

                <SecuredRoute
                  path="/manage/user/update/:email"
                  component={() => <ManageUser action="update" />}
                />
                <SecuredRoute
                  path="/manage/user/add"
                  component={() => <ManageUser action="add" />}
                />
   

                <SecuredRoute path="/role" component={() => <UserRole />} />
                <SecuredRoute
                  path="/manage/role/add"
                  component={() => <ManageRole action="add" />}
                />
                <SecuredRoute
                  path="/manage/role/update/:id"
                  component={() => <ManageRole action="update" />}
                />

                <SecuredRoute path="/banner" component={() => <Banner />} />
                <SecuredRoute
                  path="/manage/banner/add"
                  component={() => <ManageBanner action="add" />}
                />
                <SecuredRoute
                  path="/manage/banner/update/:id"
                  component={() => <ManageBanner action="update" />}
                />

                <SecuredRoute
                  path="/report/order"
                  component={() => <ReportOrders />}
                />
                <SecuredRoute
                  path="/report/order_detail/:order_number"
                  component={() => <ReportOrderDetail />}
                />
                <SecuredRoute
                  path="/manage_order/:order_number"
                  component={() => <ManageOrder />}
                />
                <SecuredRoute
                  path="/report/purchase"
                  component={() => <ReportPurchase />}
                />
                <SecuredRoute
                  path="/report/tax_invoices"
                  component={() => <ReportTaxInvoices />}
                />
                <SecuredRoute
                  path="/report/course/:course_id"
                  component={() => <ReportCourse />}
                />

                <SecuredRoute
                  path="/report/courses"
                  component={() => <ReportCourse />}
                />

                <SecuredRoute path="/webboard" component={() => <Topic />} />
                <SecuredRoute path="/thread/:topic_id" component={() => <Thread />} />

                <SecuredRoute path="/video" component={() => <Video />} />
                <SecuredRoute
                  path="/manage/video/add"
                  component={() => <ManageVideo action="add" />}
                />
                <SecuredRoute
                  path="/manage/video/update/:id"
                  component={() => <ManageVideo action="update" />}
                />

                <SecuredRoute path="/influencer" component={() => <Influencer />} />
                <SecuredRoute path="/channels" component={() => <Channel />} />
                <SecuredRoute path="/tracking_link" component={() => <TrackingLink />} />
                <SecuredRoute path="/tracking_report" component={() => <ReportTracking />} />

                <SecuredRoute path="/preorder_book" component={() => <PreorderBook />} />
                <SecuredRoute path="/nft_group" component={() => <NftGroup />} />
                <SecuredRoute
                  path="/nft_group_member/:group_id"
                  component={() => <ManageNftGroupMember />}
                />
                 <SecuredRoute path="/vdo_specify_nft/:id" component={() => <VideoSpecifyNFT />} />
              </div>
            </div>
          </div>
        </>
      </Switch>
    </BrowserRouter>
  );
}

export default App;