import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    localStorage.removeItem('phithanToken');
    const tokenString = localStorage.getItem('phithanToken');
    return tokenString
  };

  const [token, setToken] = useState(getToken());
  
  const saveToken = userToken => {
    localStorage.setItem('phithanToken', userToken);
    setToken(userToken);
  };

  const removeToken = async => {
    localStorage.removeItem('phithanToken');
    setToken(null);
  };

  return {
    setToken: saveToken,
    removeToken: removeToken,
    token
  }
}