import { httpClient } from "../utils/HttpClient";

const uploadImage = (data) => {
  console.log(data)
  return httpClient.post("/admin/upload/image", data, {
    headers: {
      enctype: "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const uploadAudio = (data) => {
  return httpClient.post("/admin/upload/audio", data, {
    headers: {
      enctype: "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const uploadVideo = (data) => {
  console.log(data)
  return httpClient.put("/admin/upload/vdo", data, {
    headers: {
      enctype: "multipart/form-data",
      "Content-Type" : "application/octet-stream",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Max-Age": "1800",
      "Access-Control-Allow-Headers": "content-type",
      "Access-Control-Allow-Methods": "PUT, POST, GET, DELETE, PATCH",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getAllWriter = () => {
  return httpClient.get("/admin/writer", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getAllLecturer = () => {
  return httpClient.get("/admin/lecturer", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getAllLecturerSelect = () => {
  return httpClient.get(`/admin/lecturer_select`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getAllWriterSelect = () => {
  return httpClient.get(`/admin/writer_select`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export default {
  uploadImage,
  uploadAudio,
  getAllWriter,
  getAllLecturer,
  getAllLecturerSelect,
  getAllWriterSelect,
  uploadVideo
};
