import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Input,
  Button,
  Popconfirm,
  Switch,
  Modal,
  notification,
  Card,
  DatePicker,
  Select,
  Upload,
  Spin,
} from "antd";
import BookService from "../services/BookService";
import UtilityService from "../services/UtilityService";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "2read-ckeditor5-custom-build";
import imageCompression from "browser-image-compression";
import MyUploadAdapter from "../utils/MyUploadAdapter";
import { PictureOutlined } from "@ant-design/icons";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Notification from "../utils/Notification";
import makeAnimated from "react-select/animated";
import ImgCrop from "antd-img-crop";
import NumberFormat from "react-number-format";

const { Option } = Select;
const animatedComponents = makeAnimated();

const ManageBook = (props) => {
  const history = useHistory();
  const initialBookState = {
    book_id: null,
    preorder_flag: false,
    last_preorder_datetime: null,
    book_name: "",
    writer_name: "",
    price: null,
    promotion_price: null,
    cover_image_url: "",
    introduction: "",
    book_detail: "",
    book_review: "",
    status: "",
    nft_only: false,
    created_datetime: null,
    created_by: null,
    updated_datetime: null,
    updated_by: null,
    temp_img_url: "",
  };

  const { book_id } = useParams();
  const [lastPreOrderDate, setlastPreOrderDate] = useState(new Date());
  const [book, setBook] = useState(initialBookState);
  const [submitted, setSubmitted] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [cropperRef, setCropperRef] = useState(React.createRef());
  const [coverImage, setCoverImage] = useState();
  const [status, setStatus] = useState(true);
  const [action, setAction] = useState();
  const [dateFormat, setDateFormat] = useState("DD-MM-YYYY HH:mm");
  const [fileList, setFileList] = useState([]);
  const [listImageURL, setlistImageURL] = useState("");
  const [preorder_flag, setPreOrderFlag] = useState(false);
  const [nft_only, setNftOnly] = useState(false);
  const [categorys, setCategorys] = useState("");
  const [categorySelect, setCategorySelect] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!localStorage.getItem("token")) history.push("/login");

    var result = localStorage.getItem("user_profile");
    if (result) {
      if (JSON.parse(result).book === false) {
        history.push("/login");
      }
    }

    setAction(props.action);
    //getAllWriter();
    getBookCategory();
    if (props.action === "update") getBook(book_id);
  }, [book_id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBook({ ...book, [name]: value });
  };

  // const getAllWriter = async () => {
  //   await UtilityService.getAllWriterSelect()
  //     .then((res) => {
  //       const reponse = res.data;
  //       console.log(res.data);
  //       setWriter(reponse.writers);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  const getBookCategory = async () => {
    await BookService.getBookCategory()
      .then((res) => {
        const response = res.data;
        setCategorySelect(response.category);
        console.log();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getBook = async (book_id) => {
    if (book_id) {
      BookService.get(book_id)
        .then((response) => {
          console.log(response);
          setlastPreOrderDate(response.data.last_preorder_datetime);

          if (response.data.last_preorder_datetime) {
            response.data.last_preorder_datetime = moment(
              response.data.last_preorder_datetime
            )
              .utc()
              .format("DD-MM-YYYY HH:mm");
          }

          setBook(response.data);
          const category_id = [];
          for (let i = 0; i < response.data.categorys.length; i++) {
            if (response.data.categorys[i].status === "A") {
              category_id.push(response.data.categorys[i].category_id);
            }
          }
          setCategorys(category_id);
          setPreOrderFlag(response.data.preorder_flag);
          setNftOnly(response.data.nft_only);

          let list_image = response.data.images.map((item) => {
            return {
              url: item.image_url,
              status: item.status,
            };
          });

          setFileList(list_image);

          setCoverImage(response.data.cover_image_url);
          if (response.data.status === "A") setStatus(true);
          else setStatus(false);
        })
        .catch((e) => {
          console.log(e);
          Notification.Show(
            "error",
            "From Web Service",
            e.response.data.message
          );
        });
    }
  };

  const ShowNotification = (type, header, message) =>
    notification[type]({
      message: header,
      description: message,
    });

  function ValidateManageBook() {
    if (!book.book_name) {
      Notification.Show("error", "Validation", "กรุณาระบุ ชื่อหนังสือ");
      return false;
    }

    if (!book.writer_name) {
      Notification.Show("error", "Validation", "กรุณาระบุ ชื่อผู้เขียน");
      return false;
    }

    if (categorys.length === 0) {
      Notification.Show("error", "Validation", "กรุณาระบุ หมวดหมู่");
      return;
    }

    if (preorder_flag) {
      if (!lastPreOrderDate) {
        Notification.Show(
          "error",
          "Validation",
          "กรุณาระบุ วันสุดท้ายที่ Pre-Order"
        );
        return false;
      }
    }

    if (!book.price) {
      Notification.Show(
        "error",
        "Validation",
        "กรุณาระบุ ราคาหนังสือ ก่อนลดราคา"
      );
      return false;
    }

    if (!book.book_detail) {
      Notification.Show("error", "Validation", "กรุณาระบุ เนื้อหาโดยสังเขป");
      return false;
    }

    if (!book.cover_image_url) {
      Notification.Show("error", "Validation", "กรุณาระบุ รูปปก(หนังสือ)");
      return false;
    }

    return true;
  }

  async function AddUpdateBook() {
    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;

    if (action === "add") {
      if (imageFile) {
        await uploadImage(imageFile, "cover");
      } else book.cover_image_url = "";
      book.created_by = action_by;
      book.created_datetime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    } else {
      if (imageFile) {
        await uploadImage(imageFile, "cover");
      } else book.cover_image_url = coverImage;
    }

    if (ValidateManageBook() === true) {
      setLoading(true);
      //Manage Image File List
      if (fileList) {
        for (let i = 0; i < fileList.length; i++) {
          if (!fileList[i].url) {
            await uploadImage(fileList[i].originFileObj, "list_image");

            fileList[i] = {
              url: book.temp_img_url,
              status: "A",
            };

            setFileList(fileList);
          }
        }

        book.images = fileList.map((item) => {
          return {
            image_url: item.url,
            status: item.status,
            created_by: action_by,
            created_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            updated_by: action_by,
            updated_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          };
        });
      }

      if (categorys) {
        book.categorys = categorys.map((item) => {
          return {
            category_id: item,
            status: "A",
            created_by: action_by,
            created_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            updated_by: action_by,
            updated_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          };
        });
      }

      if (status) book.status = "A";
      else book.status = "I";

      if (preorder_flag) book.preorder_flag = true;
      else book.preorder_flag = false;

      nft_only ? (book.nft_only = true) : (book.nft_only = false);

      if (book.preorder_flag && lastPreOrderDate) {
        book.last_preorder_datetime = new Date(lastPreOrderDate);

        const beginDate = book.last_preorder_datetime.getDate();
        const beginMonth = book.last_preorder_datetime.getMonth();
        const beginYear = book.last_preorder_datetime.getFullYear();
        book.last_preorder_datetime.setDate(beginDate);
        book.last_preorder_datetime.setMonth(beginMonth);
        book.last_preorder_datetime.setFullYear(beginYear);
        book.last_preorder_datetime = moment(
          book.last_preorder_datetime
        ).format("YYYY-MM-DD HH:mm");
      } else {
        book.last_preorder_datetime = null;
      }

      book.price = book.price
        ? Number(book.price.toString().replace(/,/g, ""))
        : 0;

      if(book.promotion_price)
      {
        book.promotion_price = book.promotion_price
          ? Number(book.promotion_price.toString().replace(/,/g, ""))
          : null;
      }
      else
        book.promotion_price = book.price;

      book.updated_by = action_by;
      book.updated_datetime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

      console.log(book);

      if (action === "add") {
        BookService.create(book)
          .then((response) => {
            ShowNotification(
              "success",
              "Success",
              "บันทึกข้อมูลหนังสือเรียบร้อยแล้ว"
            );
            setSubmitted(true);
            console.log(response.data);
            setLoading(false);
            history.push("/book");
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
            Notification.Show("error", "Validation", e.response.data.message);
          });
      } else {
        BookService.update(book.book_id, book)
          .then((response) => {
            ShowNotification(
              "success",
              "Success",
              "บันทึกข้อมูลหนังสือเรียบร้อยแล้ว"
            );
            setLoading(false);
            
            ReOrderPreOrderBook();
            
            history.push("/book");
          })
          .catch((e) => {
            setLoading(false);
            console.error(e.response.data.message);
            Notification.Show("error", "Error", e.response.data.message);
          });
      }
    } else return;
  }

  const ReOrderPreOrderBook = async () => {
    await BookService.getPreorderBook(0)
      .then((res) => {
        const response = res.data;
        let tempOrder;

        tempOrder = response.books.map((item, index) => {
          return {
            ...item,
            order: index + 1,
          };
        });

        ReOrderData(tempOrder);
      })
      .catch((e) => {});
  };

  const ReOrderData = async (data) => {
    setLoading(true);

    await BookService.reorderBook(data)
      .then((res) => {})
      .catch((e) => {});
  };

  const deleteBook = () => {
    setLoadingDelete(true);
    BookService.remove(book.book_id)
      .then((response) => {
        setLoadingDelete(false);
        console.log(response.data);
        Notification.Show("success", "Success", "ลบข้อมูลหนังสือเรียบร้อยแล้ว");
        history.push("/book");
      })
      .catch((e) => {
        setLoadingDelete(false);
        console.log(e);
        Notification.Show("error", "Error", e.response.data.message);
      });
  };

  const cancel = () => {
    history.push("/book");
  };

  function confirmDialog(e) {
    deleteBook();
  }

  function cancelDialog(e) {}

  function customUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      // Configure the URL to the upload script in your back-end here!
      return new MyUploadAdapter(loader);
    };
  }

  function onSelectFile(e, type) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImageUrl(reader.result));
      //reader.addEventListener("load", () => setCoverImage(reader.result));
      reader.readAsDataURL(e.target.files[0]);

      setCropModalOpen(true);
      setImageFile(e.target.files[0]);
    }
  }

  function cropImage() {
    const cropper = cropperRef.current.cropper;

    cropper.getCroppedCanvas().toBlob((blob) => {
      const fileName = imageFile.name;

      const file = new File([blob], fileName, {
        lastModifiedDate: new Date(),
        type: imageFile.type,
      });
      const cropppedPreview = cropper.getCroppedCanvas().toDataURL();
      setCoverImage(cropppedPreview);
      setImageFile(file);
      closeCropModal();
    });
  }

  function closeCropModal() {
    setCropModalOpen(false);
  }

  function removeImage() {
    setCoverImage("");
    setImageUrl("");
  }

  async function uploadImage(image, type) {
    const options = {
      maxSizeMB: 0.1,
      useWebWorker: true,
      initialQuality: 0.5,
    };
    try {
      const compressedFile = await imageCompression(image, options);
      const file = new File([compressedFile], image.name, { type: image.type });

      if (image) {
        let formData = new FormData();
        let imagefile = file;

        formData.append("upload", imagefile);
        await UtilityService.uploadImage(formData)
          .then((response) => {
            if (type === "cover") {
              book.cover_image_url = response.data.url;
            } else {
              book.temp_img_url = response.data.url;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } catch (error) {
      console.log(error);
    }
  }

  function onChangeStatus(checked) {
    setStatus(checked);
  }

  function onChangeCategory(value) {
    setCategorys(value);
  }

  function handleDatePickerChange(date, dateString, type) {
    setlastPreOrderDate(date);
  }

  const onChangeImgList = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  function onChangePreOrderFlag(checked) {
    setPreOrderFlag(checked);
  }

  function onChangeNftOnly(checked) {
    setNftOnly(checked);
  }

  return (
    <div className="container">
      <div className="d-flex mt-4 align-items-stretch">
        <div className="flex-fill">
          <div className="header-bar">ข้อมูลหนังสือ</div>

          <div className="row">
            <div className="col-sm-4"></div>
            <div className="col-sm-4">
              <div className="position-relative my-2 mt-4 text-center">
                {coverImage ? (
                  <img src={coverImage} className="img-fluid" width={200} />
                ) : (
                  <div
                    className="image-placeholder d-flex flex-column align-items-center justify-content-center mt-5"
                    style={{ height: 240 }}
                  >
                    <div>
                      <PictureOutlined
                        style={{ fontSize: 30, color: "#bbb" }}
                      />
                    </div>
                    <div className="font-12">อัพโหลดรูป</div>
                  </div>
                )}

                <input
                  type="file"
                  accept="image/*"
                  className="input-file-hidden"
                  onChange={(event) => {
                    onSelectFile(event, "writers");
                  }}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                />
              </div>
              {coverImage && (
                <div className="text-right mt-2">
                  <Button
                    className="btn btn-sm btn-danger"
                    onClick={() => removeImage()}
                  >
                    ลบ
                  </Button>
                </div>
              )}
              <div className="text-right text-danger">
                (ขนาดรูปที่แนะนำ 350 x 510 px)
              </div>
            </div>
            <div className="col-sm-4">
              <div className="text-right mt-3">
                <Switch
                  onChange={onChangeStatus}
                  checkedChildren="Active"
                  unCheckedChildren="InActive"
                  checked={status}
                />
              </div>
            </div>
          </div>

          <div className="header-bar mt-4 ml-2 mr-2">
            <Switch onChange={onChangeNftOnly} checked={nft_only} />
            <label className="ml-3 mt-1">
              กำหนดเป็นหนังสือ สำหรับผู้ถือ NFT เท่านั้น
            </label>
          </div>

          <div className="container-fluid mt-4">
            <div className="form-group mb-3">
              <label>
                ชื่อหนังสือ
                <span className="text-danger">*</span>
              </label>
              <Input
                id="book_name"
                required
                value={book.book_name}
                onChange={handleInputChange}
                name="book_name"
              />
            </div>
          </div>

          <div className="container-fluid mt-4">
            <div className="row">
              <div className="col-sm-6">
                <label>
                  ชื่อผู้เขียน
                  <span className="text-danger">*</span>
                </label>
                <Input
                  id="writer_name"
                  required
                  value={book.writer_name}
                  onChange={handleInputChange}
                  name="writer_name"
                />
              </div>
              <div className="col-sm-6">
                <label>
                  หมวดหมู่
                  <span className="text-danger">*</span>
                </label>
                {categorys && (
                  <Select
                    defaultValue={categorys}
                    className="w-100"
                    mode="multiple"
                    allowClear
                    onChange={onChangeCategory}
                    placeholder="เลือกหมวดหมู่"
                  >
                    {/* <Option value="">เลือกหมวดหมู่</Option> */}
                    {categorySelect &&
                      categorySelect.map((item, index) => {
                        return (
                          <Option value={item.category_id}>{item.name}</Option>
                        );
                      })}
                  </Select>
                )}

                {!categorys && (
                  <Select
                    className="w-100"
                    mode="multiple"
                    allowClear
                    onChange={onChangeCategory}
                    placeholder="เลือกหมวดหมู่"
                  >
                    {categorySelect &&
                      categorySelect.map((item, index) => {
                        return (
                          <Option value={item.category_id}>{item.name}</Option>
                        );
                      })}
                  </Select>
                )}
              </div>
            </div>
          </div>

          <div className="header-bar mt-4 ml-2 mr-2">
            <Switch onChange={onChangePreOrderFlag} checked={preorder_flag} />
            <label className="ml-3 mt-1">ตั้งค่าเป็น Pre-Order</label>
          </div>

          <div className="row mt-2">
            <div className="col-12">
              {preorder_flag && (
                <Card style={{ marginLeft: 10, marginRight: 5 }}>
                  <div className="row">
                    <div className="col-4">
                      <div className="form-group mb-4">
                        <label>
                          เลือกวันสุดท้ายที่ Pre-Order
                          <span className="text-danger">*</span>
                        </label>

                        {action === "update" && book.last_preorder_datetime ? (
                          <DatePicker
                            picker="date"
                            defaultValue={moment(
                              book.last_preorder_datetime,
                              dateFormat
                            )}
                            onChange={(date, dateString) =>
                              handleDatePickerChange(date, dateString)
                            }
                            className="w-100"
                            showTime
                            format="DD-MM-YYYY HH:mm"
                            disabledDate={(current) => {
                              return moment().add(-1, "days") >= current;
                            }}
                          />
                        ) : (
                          <DatePicker
                            picker="date"
                            className="w-100"
                            showTime
                            format="DD-MM-YYYY HH:mm"
                            disabledDate={(current) => {
                              return moment().add(-1, "days") >= current;
                            }}
                            onChange={(date, dateString) =>
                              handleDatePickerChange(date, dateString)
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Card>
              )}
            </div>
          </div>

          <div className="container-fluid mt-4">
            <div className="form-group mb-3 mt-3">
              <label>
                ราคาหนังสือ ก่อนลดราคา
                <span className="text-danger">*</span>
              </label>
              <NumberFormat
                customInput={Input}
                thousandSeparator={true}
                inputMode="numeric"
                className="some"
                name="price"
                id="price"
                placeholder=""
                onChange={handleInputChange}
                value={book.price}
              />
            </div>

            <div className="form-group mb-3 mt-3">
              <label>ราคาหนังสือ หลังลดราคา</label>
              <NumberFormat
                customInput={Input}
                thousandSeparator={true}
                inputMode="numeric"
                className="some"
                name="promotion_price"
                id="promotion_price"
                placeholder=""
                onChange={handleInputChange}
                value={book.promotion_price}
              />
            </div>

            <div className="form-group mb-3 mt-4">
              <label>รูปตัวอย่างหนังสือ</label>
              {/* <ImgCrop rotate zoom> */}
              <Upload
                listType="picture-card"
                fileList={fileList}
                onChange={onChangeImgList}
                onPreview={onPreview}
                beforeUpload={() => false}
              >
                {fileList.length < 20 && "+ Upload"}
              </Upload>
              {/* </ImgCrop> */}
            </div>

            <div className="form-group pb-3">
              <label>เกริ่นนำ</label>
              <div className="content-wrapper">
                <CKEditor
                  editor={ClassicEditor}
                  data={book.introduction}
                  config={{
                    extraPlugins: [customUploadAdapterPlugin],
                    indentBlock: {
                      offset: 1,
                      unit: "em",
                    },
                    image: {
                      resize: true,
                      toolbar: [
                        "linkImage",
                        "|",
                        "imageTextAlternative",
                        "|",
                        "imageStyle:full",
                        "imageStyle:alignLeft",
                        "imageStyle:alignCenter",
                        "imageStyle:alignRight",
                      ],
                      styles: [
                        "full",
                        "alignCenter",
                        "alignLeft",
                        "alignRight",
                      ],
                    },
                  }}
                  onInit={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    book.introduction = data;
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
              </div>
            </div>

            <div className="form-group pb-3">
              <label>
                เนื้อหาโดยสังเขป <span className="text-danger">*</span>
              </label>
              <div className="content-wrapper">
                <CKEditor
                  editor={ClassicEditor}
                  data={book.book_detail}
                  config={{
                    extraPlugins: [customUploadAdapterPlugin],
                    indentBlock: {
                      offset: 1,
                      unit: "em",
                    },
                    image: {
                      resize: true,
                      toolbar: [
                        "linkImage",
                        "|",
                        "imageTextAlternative",
                        "|",
                        "imageStyle:full",
                        "imageStyle:alignLeft",
                        "imageStyle:alignCenter",
                        "imageStyle:alignRight",
                      ],
                      styles: [
                        "full",
                        "alignCenter",
                        "alignLeft",
                        "alignRight",
                      ],
                    },
                  }}
                  onInit={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    book.book_detail = data;
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
              </div>
            </div>

            <div className="form-group pb-3">
              <label>คำนิยม</label>
              <div className="content-wrapper">
                <CKEditor
                  editor={ClassicEditor}
                  data={book.book_review}
                  config={{
                    extraPlugins: [customUploadAdapterPlugin],
                    indentBlock: {
                      offset: 1,
                      unit: "em",
                    },
                    image: {
                      resize: true,
                      toolbar: [
                        "linkImage",
                        "|",
                        "imageTextAlternative",
                        "|",
                        "imageStyle:full",
                        "imageStyle:alignLeft",
                        "imageStyle:alignCenter",
                        "imageStyle:alignRight",
                      ],
                      styles: [
                        "full",
                        "alignCenter",
                        "alignLeft",
                        "alignRight",
                      ],
                    },
                  }}
                  onInit={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    book.book_review = data;
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-2">
          <div>
            {action === "update" && (
              <Popconfirm
                title="ต้องการลบเนื้อหานี้ออกจากเว็บไซต์?"
                onConfirm={confirmDialog}
                onCancel={cancelDialog}
                okText="ยืนยันการลบ"
                cancelText="ยกเลิก"
              >
                <Button className="btn-danger px-3">ลบข้อมูล</Button>
                <Spin spinning={loadingDelete} />
              </Popconfirm>
            )}
          </div>
        </div>

        <div className="col-10 text-right">
          <Button className="btn-secondary mr-2" onClick={cancel}>
            ยกเลิก
          </Button>
          <Button className="btn-primary" onClick={AddUpdateBook}>
            บันทึกข้อมูล
          </Button>
          <Spin style={{ marginLeft: 10 }} spinning={loading} />
        </div>
      </div>

      <Modal
        title={null}
        footer={null}
        width={800}
        maskClosable={false}
        visible={cropModalOpen}
        onCancel={() => setCropModalOpen(false)}
      >
        <div className="d-flex">
          <div className="flex-fill thumbnail-crop-area">
            <Cropper
              src={imageUrl}
              aspectRatio={350 / 510}
              autoCropArea={1}
              preview=".book-preview"
              viewMode={1}
              ref={cropperRef}
            />
          </div>

          <div className="mt-4 book-preview-area ml-4">
            <div className="font-weight-bold text-center mb-2">ตัวอย่างรูป</div>

            <div className="book-preview" />
          </div>
        </div>

        <div className="mt-5 text-center">
          <button
            type="button"
            onClick={() => {
              cropImage();
            }}
            className="btn btn-primary mr-2"
          >
            ตกลง
          </button>
          <button
            type="button"
            className="btn btn-secondary mr-2"
            onClick={() => {
              setCropModalOpen(false);
            }}
          >
            ยกเลิก
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ManageBook;
