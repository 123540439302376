import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import UserService from "../services/UserService";
import { Button, Tooltip, Tag, Select } from "antd";
import { EditOutlined } from "@ant-design/icons";

const { Option } = Select;

export default function UserRole() {
  const history = useHistory();
  const [adminRole, setAdminRole] = useState("");

  useEffect(() => {
    if (!localStorage.getItem("token")) history.push("/login");

    var result = localStorage.getItem("user_profile");
    if (result) {
      if (JSON.parse(result).user_management === false) {
        history.push("/login");
      }
    }

    getAdminRole();
  }, [0]);

  const getAdminRole = async () => {
    await UserService.getAdminRole()
      .then((res) => {
        const reponse = res.data;
        console.log(res.data);
        setAdminRole(reponse.admin_role);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  function formatStatus(status) {
    if (status === true) {
      return <Tag color="green">/</Tag>;
    } else {
      return <Tag color="volcano">X</Tag>;
    }
  }

  return (
    <div className="container">
      <div className="text-right container-fluid">
        <Button
          className="btn-primary px-3"
          onClick={() => history.push("/manage/role/add")}
        >
          เพิ่ม Role
        </Button>
      </div>

      <h5 className="mt-2 font-weight-bold">Role &amp; Permission</h5>
      <div className="line-highlight">
        <hr />
      </div>

      <div className="flex-fill">
        <div class="table_wrapper">
          <table className="table border mb-0 mt-4">
            <thead>
              <tr>
                <th className="text-center">Role</th>
                <th className="text-center">แบนเนอร์เว็บไซต์</th>
                <th className="text-center">บทความ</th>
                <th className="text-center">คอร์สออนไลน์</th>
                <th className="text-center">หนังสือ</th>
                <th className="text-center">สมาชิก</th>
                <th className="text-center">Webboard</th>
                <th className="text-center">รายงานยอดสั่งซื้อ</th>
                <th className="text-center">จัดการคำสั่งซื้อ</th>
                <th className="text-center">User Management</th>
                <th className="text-center">Tracking</th>
                <th>แก้ไข</th>
              </tr>
            </thead>
            <tbody>
              {adminRole &&
                adminRole.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center text-primary">{item.role}</td>
                      <td className="text-center">
                        {formatStatus(item.banner)}
                      </td>
                      <td className="text-center">
                        {formatStatus(item.article)}
                      </td>
                      <td className="text-center">
                        {formatStatus(item.course)}
                      </td>
                      <td className="text-center">{formatStatus(item.book)}</td>
                      <td className="text-center">
                        {formatStatus(item.member)}
                      </td>
                      <td className="text-center">
                        {formatStatus(item.webboard)}
                      </td>
                      <td className="text-center">
                        {formatStatus(item.order_report)}
                      </td>
                      <td className="text-center">
                        {formatStatus(item.purchase_management)}
                      </td>
                      <td className="text-center">
                        {formatStatus(item.user_management)}
                      </td>
                      <td className="text-center">
                        {formatStatus(item.tracking)}
                      </td>
                      <td>
                        <Tooltip title="แก้ไข" className="mr-2">
                          <Button
                            type="primary"
                            shape="circle"
                            size="small"
                            className="btn-secondary"
                            icon={<EditOutlined />}
                            onClick={() =>
                              history.push("/manage/role/update/" + item.id)
                            }
                          />
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
