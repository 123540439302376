import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ReportService from "../services/ReportService";
import { Select, Tag, DatePicker, Button, Card, Spin } from "antd";
import ContentPagination from "../utils/Pagination";
import {
  LaptopOutlined,
  BookOutlined,
  AudioOutlined,
  ReadOutlined,
} from "@ant-design/icons";
import moment from "moment";
import XLSX from "xlsx";

const { Option } = Select;

export default function ReportOrders() {
  const [data, setData] = useState([]);
  const [dataExport, setDataExport] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [searchStartDate, setSearchStartDate] = useState(
    moment().add(-30, "days")
  );
  const [searchEndDate, setSearchEndDate] = useState(moment().format('YYYY-MM-DD 23:59:59'));
  const [searchProductType, setSearchProductType] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPage] = useState(1);
  const [dateFormat, setDateFormat] = useState("DD-MM-YYYY");
  const [courseTotal, setCourseTotal] = useState(0);
  const [bookTotal, setBookTotal] = useState(0);
  const [audioBookTotal, setAudioBookTotal] = useState(0);
  const [preOrderBookTotal, setPreOrderTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem("token")) history.push("/login");

    var result = localStorage.getItem("user_profile");
    if (result) {
      if (JSON.parse(result).order_report === false) {
        history.push("/login");
      }
    }

    getData(currentPage, searchStartDate, searchEndDate, searchProductType);
  }, [0]);

  const getData = async (
    page,
    filterStartDate,
    filterEndDate,
    filterProductType
  ) => {
    let response = null;
    
    setLoading(true);
    filterEndDate = moment(searchEndDate).format('YYYY-MM-DD 23:59:59');
    await ReportService.getReportPurchase(
      page,
      filterStartDate,
      filterEndDate,
      filterProductType
    )
      .then((res) => {
        response = res.data;
        //console.log(response);
        const totalPages = Math.ceil(response.total / perPage);
        setTotalPage(totalPages);
        setData(response.orders);
        setCourseTotal(response.sum_amount_course);
        setBookTotal(response.sum_amount_book);
        setAudioBookTotal(response.sum_audio_book);
        setPreOrderTotal(response.sum_preorder_book);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  async function changePage(page) {
    setCurrentPage(page);
    await getData(page, searchStartDate, searchEndDate, searchProductType);
  }

  function handleDatePickerChange(date, dateString, type) {
    if (type === "start_date") {
      setSearchStartDate(date);
    } else if (type === "end_date") {
      setSearchEndDate(date);
    }
  }

  const onChangeSearchProductType = (product_type, e) => {
    setSearchProductType(product_type);
  };

  function formatDate(date) {
    if (date) {
      return moment(date).utc().format("DD/MM/YYYY HH:mm");
    }
  }

  function formatStatus(status) {
    if (status === 2) {
      return "ชำระเงินแล้ว";
    } else if (status === 1) {
      return "รอการชำระเงิน";
    } else if (status === 3) {
      return "ชำระเงินล้มเหลว";
    } else if (status === 4) {
      return "ยกเลิกคำสั่งซื้อ";
    } else {
      return status;
    }
  }

  function formatProdutType(item) {
    if (item === "course") {
      return <Tag color="orange">คอร์สออนไลน์</Tag>;
    } else if (item === "book") {
      return <Tag color="blue">หนังสือ</Tag>;
    } else if (item === "audio") {
      return <Tag color="green">หนังสือเสียง</Tag>;
    } else if (item === "preorder_book") {
      return <Tag color="magenta">หนังสือ (pre-order)</Tag>;
    } else {
      return item.product_type;
    }
  }

  function formatExportProdutType(item) {
    if (item === "course") {
      return "คอร์สออนไลน";
    } else if (item === "book") {
      return "หนังสือ";
    } else if (item === "audio") {
      return "หนังสือเสียง";
    } else if (item === "preorder_book") {
      return "หนังสือ (pre-order)";
    } else {
      return item.product_type;
    }
  }

  function formatAmount(amount) {
    if (amount) {
      return new Intl.NumberFormat("en-GB", {
        style: "decimal",
      }).format(amount);
    } else return 0;
  }

  async function SearchData() {
    setCurrentPage(0);
    await getData(
      0,
      searchStartDate,
      searchEndDate,
      searchProductType
    );
  }

  function formatAddress(address, subDistrict, district, province, zipcode) {
    let output_address = "";
    if (address) {
      if (
        province === "กรุงเทพมหานคร" ||
        province === "กทม." ||
        province === "ก.ท.ม." ||
        province === "กทม" ||
        province === "กรุงเทพฯ"
      ) {
        output_address =
          address +
          " แขวง " +
          subDistrict +
          " เขต " +
          district +
          " " +
          province +
          " " +
          zipcode;
      } else
        output_address =
          address +
          " ต." +
          subDistrict +
          " อ." +
          district +
          " จ." +
          province +
          " " +
          zipcode;
    }
    return output_address;
  }



  function formatTaxAddress(item) {
    let output_address = "";
    if (item.tax_address_other_info) {
      if (
        item.tax_province === "กรุงเทพมหานคร" ||
        item.tax_province === "กทม." ||
        item.tax_province === "ก.ท.ม." ||
        item.tax_province === "กทม" ||
        item.tax_province === "กรุงเทพฯ"
      ) {
        output_address =
          item.tax_address_other_info +
          " แขวง " +
          item.tax_sub_district +
          " เขต " +
          item.tax_district +
          " " +
          item.tax_province +
          " " +
          item.tax_zip_code;
      } else
        output_address =
          item.tax_address_other_info +
          " ต." +
          item.tax_sub_district +
          " อ." +
          item.tax_district +
          " จ." +
          item.tax_province +
          " " +
          item.tax_zip_code;
    }
    return output_address;
  }

  function formatRequestTaxInvoice(item) {
    if (item) {
      if (item.tax_request === true) return "Yes";
      else return "No";
    }
  }

  function formatTaxInvoiceType(item) {
    if (item) {
      if (item === 1) return "บุคคลธรรมดา";
      else if (item === 2) {
        return "นิติบุคคล";
      }
    }
  }

  function formatRequestTaxInvoiceStatus(item) {
    if (item) {
      if (item.tax_request === true)
      {
        if(item.tax_status === 1)
          return "รอออกใบกำกับภาษี";
        else if(item.tax_status === 2)
        {
          return "ออกใบกำกับภาษีแล้ว";
        }
      }
      else return "";
    }
  }


  function formatTaxInvoiceName(item) {
    if (item) {
      if (item.tax_request) {
        if (item.tax_type === 1) {
          return item.tax_first_name + " " + item.tax_last_name;
        } else {
          return formatTaxInvoiceCompanyName(item);
        }
      } else return "";
    }
  }

  function formatTaxID(item) {
    if (item) {
      if (item.tax_request) {
        return item.tax_id_number;
      } else return "";
    }
  }

  function formatTaxInvoiceCompanyName(item) {
    let output_name = "";
    if (item.tax_company_name) {
      if (item.branch) {
        output_name = item.tax_company_name + " (" + item.branch + ")";
      } else output_name = item.tax_company_name;
    }
    return output_name;
  }

  function formatTaxInvoiceNo(item) {
    if (item) {
      if (item.tax_request) return item.tax_invoice_no;
      else return "";
    }
  }

  function formatDeliveryMethod(item) {
    if (item === "kerry") {
      return "Kerry";
    } else if (item === "postal") {
      return "ลงทะเบียน";
    } 
    else if (item === "ems") {
      return "EMS";
    }
    else if (item === "" || item === "other") {
      return "-";
    }
    else return item;
  }

  async function exportData() {
    let response = null;
    setLoading(true);
    let end_date = moment(searchEndDate).format('YYYY-MM-DD 23:59:59');
    await ReportService.getReportExportPurchase(
      "",
      searchStartDate,
      end_date,
      searchProductType
    )
      .then((res) => {
        response = res.data;
        //setDataExport(response.orders);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });

      
    for(let i=0;i<response.orders.length;i++){
      if(i>0)
      {
        if(response.orders[i].order_number === response.orders[i-1].order_number){
          if(Number(response.orders[i].delivery_cost) > 0)
            response.orders[i].delivery_cost = "0";
        }
      }
    }

    let report = response.orders.map((item, idx) => {
      return [
        item.order_number,
        formatDate(item.order_date),
        formatExportProdutType(item.product_type),
        item.name,
        Number(item.quantity),
        Number(item.price),
        Number(item.delivery_cost),
        formatDeliveryMethod(item.delivery_method),
        Number(item.total_amount),
        formatStatus(item.order_status),
        item.first_name + " " + item.last_name,
        item.phone_number,
        item.email,
        formatAddress(
          item.address_other_info,
          item.sub_district,
          item.district,
          item.province,
          item.zip_code
        ),
        formatRequestTaxInvoice(item),
        formatTaxInvoiceName(item),
        formatTaxID(item),
        formatTaxInvoiceType(item.tax_type),
        formatTaxAddress(item),
        formatRequestTaxInvoiceStatus(item),
        formatTaxInvoiceNo(item),
        formatDate(item.tax_issued_date)
      ];
    });

    report = [
      [
        "เลขที่คำสั่งซื้อ",
        "วันที่คำสั่งซื้อ",
        "ประเภทสินค้า",
        "ชื่อสินค้า",
        "จำนวนสินค้า",
        "ราคาต่อหน่วย",
        "ค่าจัดส่ง",
        "รูปแบบการจัดส่ง",
        "ราคารวม",
        "สถานะคำสั่งซื้อ",
        "ชื่อ-นามสกุล(ที่อยู่จัดส่ง)",
        "เบอร์โทรติดต่อ",
        "Email",
        "ที่อยู่ในการจัดส่ง",
        "ขอใบกำกับภาษี",
        "ชื่อ",
        "เลขประจำตัวผู้เสียภาษี",
        "ประเภท",
        "ที่อยู่ในการออกใบกำกับภาษี",
        "ข้อมูลใบกำกับภาษี",
        "เลขที่ใบกำกับภาษี",
        "วันที่ออกใบกำกับภาษี"
      ],
      ...report,
    ];

    if (report) {
      await downloadExportData(report);
      //await getData(0, searchStartDate, searchEndDate, searchProductType);
    }
  }

  async function downloadExportData(report) {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(report);
    XLSX.utils.book_append_sheet(workBook, workSheet, ["report-purchase"]);
    XLSX.writeFile(workBook, `report-purchase.xlsx`);

    //this.exportData(0);
  }

  return (
    <div className="container">
      <h5 className="mt-2 font-weight-bold">
        รายงานยอดสั่งซื้อ <Spin spinning={loading} />
      </h5>
      <div className="line-highlight">
        <hr />
      </div>

      <div className="flex-fill">
        <div className="row mt-4 pt-3">
          <div className="col-sm-3">
            <div className="form-group">
              <label>เลือกช่วงเวลา</label>
              <DatePicker
                picker="date"
                defaultValue={moment().add(-30, "days")}
                format={dateFormat}
                onChange={(date, dateString) =>
                  handleDatePickerChange(date, dateString, "start_date")
                }
                className="w-100"
                disabledDate={(current) => {
                  return current >= moment();
                }}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-group">
              <label>ถึง</label>
              <DatePicker
                picker="date"
                defaultValue={moment()}
                format={dateFormat}
                onChange={(date, dateString) =>
                  handleDatePickerChange(date, dateString, "end_date")
                }
                className="w-100"
                // disabledDate={(current) => {
                //   return moment().add(-1, "days") >= current;
                // }}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-group">
              <label>ประเภทสินค้า</label>
              <Select
                defaultValue=""
                className="w-100"
                onSelect={(value, event) =>
                  onChangeSearchProductType(value, event)
                }
              >
                <Option value="">ทั้งหมด</Option>
                <Option value="course">คอร์สออนไลน์</Option>
                <Option value="book">หนังสือ</Option>
                <Option value="audio">หนังสือเสียง</Option>
                <Option value="preorder_book">หนังสือ(pre-order)</Option>
              </Select>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group" style={{ marginTop: 25 }}>
              <Button
                className="btn-primary mr-4 px-3"
                size="large"
                onClick={SearchData}
              >
                ค้นหา
              </Button>
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-sm-3">
            <Card style={{ borderRadius: 5, background: "#f6f6f6" }}>
              <p className="text-primary font-weight-bold text-center font-weight-light">
                คอร์สออนไลน์
              </p>
              <div className="row text-center">
                <div className="col-sm-2"></div>
                <div className="col-sm-4">
                  <LaptopOutlined style={{ fontSize: 36 }} />
                </div>
                <div className="col-sm-4">
                  <span style={{ fontSize: 22 }}>
                    {formatAmount(courseTotal)}
                  </span>
                </div>
                <div className="col-sm-2"></div>
              </div>
            </Card>
          </div>
          <div className="col-sm-3">
            <Card style={{ borderRadius: 5, background: "#f6f6f6" }}>
              <p className="text-primary font-weight-bold text-center font-weight-light">
                หนังสือ
              </p>
              <div className="row text-center">
                <div className="col-sm-2"></div>
                <div className="col-sm-4">
                  <ReadOutlined style={{ fontSize: 36 }} />
                </div>
                <div className="col-sm-4">
                  <span style={{ fontSize: 22 }}>
                    {formatAmount(bookTotal)}
                  </span>
                </div>
                <div className="col-sm-2"></div>
              </div>
            </Card>
          </div>
          <div className="col-sm-3">
            <Card style={{ borderRadius: 5, background: "#f6f6f6" }}>
              <p className="text-primary font-weight-bold text-center font-weight-light">
                หนังสือเสียง
              </p>
              <div className="row text-center">
                <div className="col-sm-2"></div>
                <div className="col-sm-4">
                  <AudioOutlined style={{ fontSize: 36 }} />
                </div>
                <div className="col-sm-4">
                  <span style={{ fontSize: 22 }}>
                    {formatAmount(audioBookTotal)}
                  </span>
                </div>
                <div className="col-sm-2"></div>
              </div>
            </Card>
          </div>
          <div className="col-sm-3">
            <Card style={{ borderRadius: 5, background: "#f6f6f6" }}>
              <p className="text-primary font-weight-bold text-center font-weight-light">
                หนังสือ (pre-order)
              </p>
              <div className="row text-center">
                <div className="col-sm-2"></div>
                <div className="col-sm-4">
                  <BookOutlined style={{ fontSize: 36 }} />
                </div>
                <div className="col-sm-4">
                  <span style={{ fontSize: 22 }}>
                    {formatAmount(preOrderBookTotal)}
                  </span>
                </div>
                <div className="col-sm-2"></div>
              </div>
            </Card>
          </div>
        </div>

        <div className="row mt-4 ml-1">
          <Button className=" btn-info mr-4 px-3" onClick={exportData}>
            Export
          </Button>
        </div>

        <div class="table_wrapper">
          <table className="table border mb-0 mt-3">
            <thead>
              <tr>
                <th className="text-center">เลขที่คำสั่งซื้อ</th>
                <th className="text-center">วันที่คำสั่งซื้อ</th>
                <th className="text-center">ประเภทสินค้า</th>
                <th className="text-center">ชื่อสินค้า</th>
                <th className="text-center">จำนวนสินค้า</th>
                <th className="text-center">ราคาต่อหน่วย</th>
                <th className="text-center">ราคารวม</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">{item.order_number}</td>
                      <td className="text-center">
                        {formatDate(item.order_date)}
                      </td>
                      <td className="text-center">
                        {formatProdutType(item.product_type)}
                      </td>
                      <td className="text-center">{item.name}</td>
                      <td className="text-center">
                        {formatAmount(item.quantity)}
                      </td>
                      <td className="text-center">
                        {formatAmount(item.price)}
                      </td>
                      <td className="text-center">
                        {formatAmount(item.total_amount)}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <div className="text-right mt-4">
          {data && data.length > 0 && (
            <ContentPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={changePage.bind(this)}
              pageShow={5}
            />
          )}
        </div>
      </div>
    </div>
  );
}
