// src/firebase.js
import { initializeApp } from "firebase/app"
import { getStorage } from "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyD0qkHfo7P7UaVnLU0dfpCxPoGGxkMw3sY",
  authDomain: "stock2morrow-com.firebaseapp.com",
  databaseURL: "https://stock2morrow-com.firebaseio.com",
  projectId: "stock2morrow-com",
  storageBucket: "stock2morrow-com.appspot.com",
  messagingSenderId: "314196724887",
  appId: "1:314196724887:web:26c2ebe6b96355abe3add2",
  measurementId: "G-YQYHHJYEG9"
  };
  

// Initialize Firebase and cloud storage
export const app = initializeApp(firebaseConfig)
export const storage = getStorage(app)