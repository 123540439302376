import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Input,
  Button,
  Popconfirm,
  Switch,
  Modal,
  notification,
  Select,
  Radio
} from "antd";
import UtilityService from "../services/UtilityService";
import moment from "moment";
import ClassicEditor from "2read-ckeditor5-custom-build";
import imageCompression from "browser-image-compression";
import MyUploadAdapter from "../utils/MyUploadAdapter";
import { PictureOutlined } from "@ant-design/icons";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Notification from "../utils/Notification";
import makeAnimated from "react-select/animated";
import ImgCrop from "antd-img-crop";
import BannerService from "../services/BannerService";
import ArticleService from "../services/ArticleService";
import CourseService from "../services/CourseService";
import BookService from "../services/BookService";
import BookAudioService from "../services/BookAudioService";

const { Option } = Select;
const animatedComponents = makeAnimated();

const ManageBanner = (props) => {
  const history = useHistory();
  const initialBannerState = {
    id: null,
    image_url: "",
    type: "",
    status: "",
    internal_menu_id: 0,
    internal_content_id: 0,
    external_url: "",
    created_datetime: null,
    created_by: null,
    updated_datetime: null,
    updated_by: null,
  };

  const { id } = useParams();
  const [banner, setBanner] = useState(initialBannerState);
  const [submitted, setSubmitted] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [cropperRef, setCropperRef] = useState(React.createRef());
  const [coverImage, setCoverImage] = useState();
  const [status, setStatus] = useState(true);
  const [action, setAction] = useState();
  const [menuInternal, setMenuInternal] = useState("");
  const [bannerInternalMenuID, setBannerInternalMenuID] = useState("");
  const [internalContentID, setInternalContentID] = useState("");
  const [bannerType, setBannerType] = useState("");
  const [contentSelect, setContentSelect] = useState("");
  const [articleSelect, setArticleSelect] = useState("");
  const [loading, setLoading] = useState(false);

  const options = [
    { label: "ไม่ลิงค์ (none)", value: "none" },
    { label: "ภายในเว็บไซต์ (internal)", value: "internal" },
    { label: "ภายนอกเว็บไซต์ (external)", value: "external" },
  ];

  useEffect(() => {
    if (!localStorage.getItem("token")) history.push("/login");

    var result = localStorage.getItem("user_profile");
    if (result) {
      if (JSON.parse(result).banner === false) {
        history.push("/login");
      }
    }

    setAction(props.action);
    getInternalMenu();
    if (props.action === "update") getBanner(id);
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBanner({ ...banner, [name]: value });
  };

  const getInternalMenu = async () => {
    await BannerService.getInternalMenu()
      .then((res) => {
        const response = res.data;
        setMenuInternal(response.banner_menu);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getArticleSelect = async () => {
    await ArticleService.getArticleSelect()
    .then((res) => {
      const response = res.data;
      console.log(response)
      setArticleSelect(response.articles);
    })
    .catch((e) => {
      console.log(e);
    });
  };

  const getBanner = async (id) => {
    if (id) {
      BannerService.get(id)
        .then((response) => {
          console.log(response.data);
          setBanner(response.data);
          setBannerType(response.data.type);
          if (response.data.type === "internal") {
            setBannerInternalMenuID(response.data.internal_menu_id);
            console.log(response.data.internal_content_id);
            setInternalContentID(response.data.internal_content_id);
            getContentSelect(response.data.internal_menu_id);
          }
          setCoverImage(response.data.image_url);
          if (response.data.status === "A") setStatus(true);
          else setStatus(false);
        })
        .catch((e) => {
          console.log(e);
          Notification.Show(
            "error",
            "From Web Service",
            e.response.data.message
          );
        });
    }
  };

  const ShowNotification = (type, header, message) =>
    notification[type]({
      message: header,
      description: message,
    });

  function ValidateManageBanner() {
    if (!banner.image_url) {
      Notification.Show("error", "Validation", "กรุณาอัพโหลด รูปแบนเนอร์");
      return false;
    }

    if (!bannerType) {
      Notification.Show(
        "error",
        "Validation",
        "กรุณาระบุ ประเภทแบนเนอร์ (การลิงค์ของแบนเนอร์)"
      );
      return false;
    }

    if (bannerType === "internal") {
      if (!bannerInternalMenuID) {
        Notification.Show("error", "Validation", "กรุณาระบุ เมนู");
        return false;
      }

      if (!internalContentID) {
        Notification.Show("error", "Validation", "กรุณาระบุ เนื้อหา");
        return false;
      }
    }
    return true;
  }

  async function AddUpdateBanner() {
    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;

    if (action === "add") {
      if (imageFile) {
        await uploadImage(imageFile, "cover");
      } else banner.image_url = "";

      banner.created_by = action_by;
      banner.created_datetime = moment(new Date()).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    } else {
      if (imageFile) {
        await uploadImage(imageFile, "cover");
      } else banner.image_url = coverImage;
    }

    if (ValidateManageBanner() === true) {
      banner.type = bannerType;
      if (bannerType === "internal") {
        banner.internal_menu_id = bannerInternalMenuID;
        banner.internal_content_id = internalContentID;
      } else {
        banner.internal_menu_id = 0;
        banner.internal_content_id = 0;
      }

      if (status) banner.status = "A";
      else banner.status = "I";

      banner.updated_by = action_by;
      banner.updated_datetime = moment(new Date()).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      setLoading(true);

      if (action === "add") {
        BannerService.create(banner)
          .then((response) => {
            ShowNotification(
              "success",
              "Success",
              "บันทึกข้อมูล Banner เรียบร้อยแล้ว"
            );
            setSubmitted(true);
            setLoading(false);
            console.log(response.data);
            history.push("/banner");
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
            Notification.Show("error", "Validation", e.response.data.message);
          });
      } else {
        BannerService.update(banner.id, banner)
          .then((response) => {
            console.log(response.data);
            ShowNotification(
              "success",
              "Success",
              "บันทึกข้อมูล Banner เรียบร้อยแล้ว"
            );
            setLoading(false);
            history.push("/banner");
          })
          .catch((e) => {
            console.error(e.response.data.message);
            Notification.Show("error", "Error", e.response.data.message);
            setLoading(false);
          });
      }
    } else return;
  }

  const deleteBanner = () => {
    BannerService.remove(banner.id)
      .then((response) => {
        console.log(response.data);
        Notification.Show(
          "success",
          "Success",
          "ลบข้อมูล Banner เรียบร้อยแล้ว"
        );
        history.push("/banner");
      })
      .catch((e) => {
        console.log(e);
        Notification.Show("error", "Error", e.response.data.message);
      });
  };

  const cancel = () => {
    history.push("/banner");
  };

  function confirmDialog(e) {
    deleteBanner();
  }

  function cancelDialog(e) {}

  function onSelectFile(e, type) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImageUrl(reader.result));
      reader.readAsDataURL(e.target.files[0]);

      setCropModalOpen(true);
      setImageFile(e.target.files[0]);
    }
  }

  function cropImage() {
    const cropper = cropperRef.current.cropper;

    cropper.getCroppedCanvas().toBlob((blob) => {
      const fileName = imageFile.name;

      const file = new File([blob], fileName, {
        lastModifiedDate: new Date(),
        type: imageFile.type,
      });
      const cropppedPreview = cropper.getCroppedCanvas().toDataURL();
      setCoverImage(cropppedPreview);
      setImageFile(file);
      closeCropModal();
    });
  }

  function closeCropModal() {
    setCropModalOpen(false);
  }

  function removeImage() {
    setCoverImage("");
    setImageUrl("");
    setImageFile("");
  }

  async function uploadImage(image, type) {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1200,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(image, options);
      const file = new File([compressedFile], image.name, { type: image.type });

      if (image) {
        let formData = new FormData();
        let imagefile = file;

        formData.append("upload", imagefile);

        await UtilityService.uploadImage(formData)
          .then((response) => {
            banner.image_url = response.data.url;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } catch (error) {
      console.log(error);
    }
  }

  function onChangeStatus(checked) {
    setStatus(checked);
  }

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  function onChangeInternalMenuID(value) {
    setBannerInternalMenuID(value);
    getContentSelect(value);
  }

  function onChangeInternalContentID(value) {
    setInternalContentID(value);
  }

  const onChangeBannerType = (e) => {
    setBannerType(e.target.value);
  };

  const getContentSelect = async (value) => {
    setLoading(true);
    if (value === 1) {
      //course
      await CourseService.getCourseSelect()
        .then((res) => {
          const response = res.data;
          setContentSelect(response.courses);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (value === 2) {
      //book
      await BookService.getBookSelect()
        .then((res) => {
          const response = res.data;
          setContentSelect(response.books);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (value === 3) {
      //audio book
      await BookAudioService.getAudioBookSelect()
        .then((res) => {
          const response = res.data;
          setContentSelect(response.audioBooks);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (value === 4) {
     // บทความ
      await ArticleService.getArticleSelect()
        .then((res) => {
          const response = res.data;
          setContentSelect(response.articles);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
      //setContentSelect(articleSelect);
    }
  };

  return (
    <div className="container">
      <div className="d-flex mt-4 align-items-stretch">
        <div className="flex-fill">
          <div className="header-bar">ข้อมูล Banner</div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-2"></div>

              <div className="col-9">
                <div>
                  <div className="text-right mt-3">
                    <Switch
                      onChange={onChangeStatus}
                      checkedChildren="Active"
                      unCheckedChildren="InActive"
                      checked={status}
                    />
                  </div>

                  <div className="position-relative my-2 mt-4">
                    {coverImage ? (
                      <img src={coverImage} className="img-fluid text-danger" />
                    ) : (
                      <div className="image-placeholder d-flex flex-column align-items-center justify-content-center img-banner">
                        <div>
                          <PictureOutlined
                            style={{ fontSize: 30, color: "#bbb" }}
                          />
                        </div>
                        <div className="font-12">อัพโหลดรูป</div>
                      </div>
                    )}

                    <input
                      type="file"
                      accept="image/*"
                      className="input-file-hidden"
                      onChange={(event) => {
                        onSelectFile(event, "banner");
                      }}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                    />
                  </div>
                </div>
                <div className="text-danger text-right">
                  (ขนาดรูปที่แนะนำ 1440 x 372 px)
                </div>
                {coverImage && (
                  <div className="text-right mt-2">
                    <Button
                      className="btn btn-sm btn-danger"
                      onClick={() => removeImage()}
                    >
                      ลบ
                    </Button>
                  </div>
                )}
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-12">
                <div className="form-group">
                  <label>
                    ประเภทแบนเนอร์ (การลิงค์ของแบนเนอร์)
                    <span className="text-danger">*</span>
                  </label>
                </div>

                <div className="form-group">
                  <Radio.Group
                    options={options}
                    onChange={onChangeBannerType}
                    value={bannerType}
                  />
                </div>

                {bannerType === "internal" && (
                  <div>
                    <div className="form-group mb-3 mt-3">
                      <label>
                        เมนู
                        <span className="text-danger">*</span>
                      </label>
                      {bannerInternalMenuID && (
                        <Select
                          defaultValue={bannerInternalMenuID}
                          className="w-100"
                          allowClear
                          onChange={onChangeInternalMenuID}
                          placeholder="เลือกเมนู"
                        >
                          {menuInternal &&
                            menuInternal.map((item, index) => {
                              return (
                                <Option value={item.menu_id}>
                                  {item.name}
                                </Option>
                              );
                            })}
                        </Select>
                      )}

                      {!bannerInternalMenuID && (
                        <Select
                          className="w-100"
                          allowClear
                          onChange={onChangeInternalMenuID}
                          placeholder="เลือกเมนู"
                        >
                          {menuInternal &&
                            menuInternal.map((item, index) => {
                              return (
                                <Option value={item.menu_id}>
                                  {item.name}
                                </Option>
                              );
                            })}
                        </Select>
                      )}
                    </div>

                    <div className="form-group mb-3 mt-3">
                      {internalContentID && (
                        <Select
                          defaultValue={internalContentID}
                          className="w-100"
                          allowClear
                          onChange={onChangeInternalContentID}
                          placeholder="เลือกเนื้อหา"
                          showSearch
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {contentSelect &&
                            bannerInternalMenuID === 1 &&
                            contentSelect.map((item, index) => {
                              return (
                                <Option value={item.course_id}>
                                  {item.course_name}
                                </Option>
                              );
                            })}

                          {contentSelect &&
                            (bannerInternalMenuID === 2 ||
                              bannerInternalMenuID === 3) &&
                            contentSelect.map((item, index) => {
                              return (
                                <Option value={item.book_id}>
                                  {item.book_name}
                                </Option>
                              );
                            })}

                          {contentSelect &&
                            bannerInternalMenuID === 4 &&
                            contentSelect.map((item, index) => {
                              return (
                                <Option value={item.article_id}>
                                  {item.name}
                                </Option>
                              );
                            })}
                        </Select>
                      )}

                      {!internalContentID && (
                        <Select
                          className="w-100"
                          allowClear
                          onChange={onChangeInternalContentID}
                          placeholder="เลือกเนื้อหา"
                          showSearch
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {contentSelect &&
                            bannerInternalMenuID === 1 &&
                            contentSelect.map((item, index) => {
                              return (
                                <Option value={item.course_id}>
                                  {item.course_name}
                                </Option>
                              );
                            })}

                          {contentSelect &&
                            (bannerInternalMenuID === 2 ||
                              bannerInternalMenuID === 3) &&
                            contentSelect.map((item, index) => {
                              return (
                                <Option value={item.book_id}>
                                  {item.book_name}
                                </Option>
                              );
                            })}

                          {contentSelect &&
                            bannerInternalMenuID === 4 &&
                            contentSelect.map((item, index) => {
                              return (
                                <Option value={item.article_id}>
                                  {item.name}
                                </Option>
                              );
                            })}
                        </Select>
                      )}
                    </div>
                  </div>
                )}

                {bannerType === "external" && (
                  <div className="form-group mb-3 mt-4">
                    <label>
                      External URL
                      <span className="text-danger">*</span>
                    </label>
                    <Input
                      id="external_url"
                      value={banner.external_url}
                      onChange={handleInputChange}
                      name="external_url"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-2">
          <div>
            {action === "update" && (
              <Popconfirm
                title="ต้องการลบเนื้อหานี้ออกจากเว็บไซต์?"
                onConfirm={confirmDialog}
                onCancel={cancelDialog}
                okText="ยืนยันการลบ"
                cancelText="ยกเลิก"
              >
                <Button className="btn-danger px-3">ลบข้อมูล</Button>
              </Popconfirm>
            )}
          </div>
        </div>

        <div className="col-10 text-right">
          <Button className="btn-secondary mr-2" onClick={cancel}>
            ยกเลิก
          </Button>
          <Button className="btn-primary" onClick={AddUpdateBanner}>
            บันทึกข้อมูล
          </Button>
        </div>
      </div>

      <Modal
        title={null}
        footer={null}
        width={800}
        maskClosable={false}
        visible={cropModalOpen}
        onCancel={() => setCropModalOpen(false)}
      >
        <div className="d-flex">
          <div className="flex-fill thumbnail-crop-area">
            <Cropper
              src={imageUrl}
              aspectRatio={1440 / 372}
              autoCropArea={1}
              preview=".book-preview"
              viewMode={3}
              ref={cropperRef}
            />
          </div>

          <div className="mt-4 book-preview-area ml-4">
            <div className="font-weight-bold text-center mb-2">ตัวอย่างรูป</div>

            <div className="book-preview" />
          </div>
        </div>

        <div className="mt-5 text-center">
          <button
            type="button"
            onClick={() => {
              cropImage();
            }}
            className="btn btn-primary mr-2"
          >
            ตกลง
          </button>
          <button
            type="button"
            className="btn btn-secondary mr-2"
            onClick={() => {
              setCropModalOpen(false);
            }}
          >
            ยกเลิก
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ManageBanner;
