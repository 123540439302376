import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import TrackingService from "../services/TrackingService";
import InfluencerService from "../services/InfluencerService";
import ChannelService from "../services/ChannelService";
import BookService from "../services/BookService";
import BookAudioService from "../services/BookAudioService";
import CourseService from "../services/CourseService";

import {
  Input,
  Button,
  Tooltip,
  Tag,
  Modal,
  notification,
  Popconfirm,
  Switch,
  Select,
} from "antd";

import {
  EditOutlined,
  UserOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  CopyOutlined,
} from "@ant-design/icons";

import ContentPagination from "../utils/Pagination";
import moment from "moment";
import Notification from "../utils/Notification";
import { LinkOutlined } from "@material-ui/icons";

export default function TrackingLink() {
  const history = useHistory();
  const { Option } = Select;
  const [data, setData] = useState([]);
  const initialTrackingState = {
    id: null,
    ref_code: "",
    influencer_id: null,
    channel_id: null,
    product_type: "",
    product_id: null,
    tracking_link: "",
    updated_by: "",
    created_by: "",
    updated_datetime: null,
    status: null,
  };
  const [tracking, setTracking] = useState(initialTrackingState);
  const [perPage, setPerPage] = useState(10);
  const [searchInfluencer, setSearchInfluencer] = useState("");
  const [searchChannel, setSearchChannel] = useState("");
  const [searchProductType, setSearchProductType] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPage] = useState(1);
  const [ModalOpen, setModalOpen] = useState(false);
  const [influencerName, setInfluencerName] = useState();
  const [channelName, setChannelName] = useState();
  const [productID, setProductID] = useState();
  const [dataItem, setDataItem] = useState([]);
  const [action, setAction] = useState("");
  const [influencerSelect, setInfluencerSelect] = useState("");
  const [channelSelect, setChannelSelect] = useState("");

  const [influencerAdd, setInfluencerAdd] = useState("");
  const [channelAdd, setChannelAdd] = useState("");
  const [productTypeAdd, setProductTypeAdd] = useState("");
  const [productIDAdd, setProductIDAdd] = useState("");

  const [bookTitleSelect, setBookTitleSelect] = useState("");
  const [audioBookTitleSelect, setAudioBookTitleSelect] = useState("");
  const [courseTitleSelect, setCourseTitleSelect] = useState("");
  const [influencerAddSelect, setInfluenceAddrSelect] = useState("");
  const [channelAddSelect, setChannelAddSelect] = useState("");

  const { confirm } = Modal;

  useEffect(() => {
    getInfluencerSelect();
    getChannelNameSelect();
    getBookTitleSelect();
    getAudioBookTitleSelect();
    getCourseTitleSelect();
    getInfluencerAddSelect();
    getChannelNameAddSelect();
    getData(currentPage, searchInfluencer, searchChannel, searchProductType);
  }, [0]);

  const getData = async (
    page,
    influencer_name,
    influencer_channel,
    influencer_product
  ) => {
    await TrackingService.getAll(
      page,
      influencer_name,
      influencer_channel,
      influencer_product
    )
      .then((res) => {
        const response = res.data;

        const totalPages = Math.ceil(response.total / perPage);
        setTotalPage(totalPages);
        setData(response.trackings);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  function changePage(page) {
    setCurrentPage(page);
    getData(page, searchChannel);
  }

  const ShowNotification = (type, header, message) =>
    notification[type]({
      message: header,
      description: message,
    });

  function SaveTracking() {
    if (!influencerAdd) {
      Notification.Show("error", "Validation", "กรุณาระบุ Influencer");
      return;
    }
    if (!channelAdd) {
      Notification.Show("error", "Validation", "กรุณาระบุ Channel");
      return;
    }
    if (!productTypeAdd) {
      Notification.Show("error", "Validation", "กรุณาระบุ Product Type");
      return;
    }
    if (!productIDAdd) {
      Notification.Show("error", "Validation", "กรุณาระบุ Product Titile");
      return;
    }

    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;
    tracking.influencer_id = influencerAdd;
    tracking.channel_id = channelAdd;
    tracking.product_type = productTypeAdd;
    tracking.product_id = productIDAdd;
    tracking.created_by = action_by;
    tracking.status = "A";
    tracking.updated_by = action_by;
    tracking.updated_datetime = moment(new Date()).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    TrackingService.create(tracking)
      .then((response) => {
        ShowNotification("success", "Success", "บันทึกข้อมูลเรียบร้อยแล้ว");
        setModalOpen(false);
        getData(
          currentPage,
          searchInfluencer,
          searchChannel,
          searchProductType
        );
      })
      .catch((e) => {
        console.log(e);
        Notification.Show("error", "Validation", e.response.data.message);
      });
  }

  async function addTrackingLink(data) {
    setChannelAdd("");
    setInfluencerAdd("");
    setProductIDAdd("");
    setProductTypeAdd("");
    setModalOpen(true);
  }

  const showDeleteConfirm = (id) => {
    confirm({
      title: "Confirm Delete?",
      icon: <ExclamationCircleOutlined />,
      content: "ต้องการลบ Tracking นี้ใช่หรือไม่",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        deleteTracking(id);
        getData(
          currentPage,
          searchInfluencer,
          searchChannel,
          searchProductType
        );
      },

      onCancel() {
        console.log("Cancel");
      },
    });
  };

  async function deleteTracking(id) {
    let response = await TrackingService.remove(id);
    if (response.status === 200 || response.status === 201) {
      Notification.Show("success", "Success", "ลบ Tracking Link เรียบร้อยแล้ว");
      getData(
        currentPage,
        searchInfluencer,
        searchChannel,
        searchProductType
      );
    } else {
      Notification.Show(
        "error",
        "Error",
        "เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง"
      );
    }
  }


  const getInfluencerSelect = async (value) => {
    await InfluencerService.getInfluencerSelect()
      .then((res) => {
        const response = res.data;
        setInfluencerSelect(response.influencers);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getInfluencerAddSelect = async (value) => {
    await InfluencerService.getAddInfluencerSelect()
      .then((res) => {
        const response = res.data;
        setInfluenceAddrSelect(response.influencers);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getChannelNameSelect = async (value) => {
    await ChannelService.getChannelNameSelect()
      .then((res) => {
        const response = res.data;
        setChannelSelect(response.channels);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getChannelNameAddSelect = async (value) => {
    await ChannelService.getChannelNameAddSelect()
      .then((res) => {
        const response = res.data;
        setChannelAddSelect(response.channels);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getBookTitleSelect = async (value) => {
    await BookService.getBookSelect()
      .then((res) => {
        const response = res.data;
        setBookTitleSelect(response.books);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getAudioBookTitleSelect = async (value) => {
    await BookService.getAudioBookSelect()
      .then((res) => {
        const response = res.data;
        setAudioBookTitleSelect(response.audioBooks);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getCourseTitleSelect = async (value) => {
    await CourseService.getCourseSelect()
      .then((res) => {
        const response = res.data;
        setCourseTitleSelect(response.courses);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onChangeSearchInfluencer = (value) => {
    setSearchInfluencer(value);
    getData(currentPage, value, searchChannel, searchProductType);
  };

  function onChangeSearchChannel(value) {
    setSearchChannel(value);
    getData(currentPage, searchInfluencer, value, searchProductType);
  }

  function onChangeSearchProductType(value) {
    setSearchProductType(value);
    getData(currentPage, searchInfluencer, searchChannel, value);
  }

  function onChangeInfluencerAdd(value) {
    setInfluencerAdd(value);
  }

  function onChangeChannelAdd(value) {
    setChannelAdd(value);
  }

  async function onChangeProductTypeAdd(value) {
    await setProductIDAdd("");
    setProductTypeAdd(value);
  }

  function onChangeProductIDAdd(value) {
    setProductIDAdd(value);
  }

  function formatProductType(type) {
    if (type === "course") {
      return <Tag color="green">คอร์สสัมนา</Tag>;
    } else if (type === "book") {
      return <Tag color="blue">หนังสือ</Tag>;
    } else if (type === "audio") {
      return <Tag color="gold">หนังสือเสียง</Tag>;
    } else {
      return type;
    }
  }

  return (
    <div className="container">
      <div className="text-right container-fluid">
        <Button
          className="btn-primary px-3"
          onClick={() => {
            addTrackingLink(null);
          }}
        >
          เพิ่ม Tracking
        </Button>
      </div>

      <h5 className="mt-2 font-weight-bold">Tracking Link</h5>
      <div className="line-highlight">
        <hr />
      </div>

      <div className="flex-fill">
        <div className="form-group mb-0 mt-2">
          <div className="row">
            <div className="col-sm-4">
              <label>Influencer Name</label>
              <Select
                defaultValue={searchInfluencer}
                className="w-100"
                allowClear
                onChange={onChangeSearchInfluencer}
                placeholder="Influencer Name"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="">เลือก Influencer</Option>
                {influencerSelect &&
                  influencerSelect.map((item, index) => {
                    return (
                      <Option value={item.influencer_id}>{item.name}</Option>
                    );
                  })}
              </Select>
            </div>
            <div className="col-sm-4">
              <label>Channel Name</label>
              <Select
                defaultValue={searchChannel}
                className="w-100"
                allowClear
                onChange={onChangeSearchChannel}
                placeholder="Channel Name"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="">เลือก Channel</Option>
                {channelSelect &&
                  channelSelect.map((item, index) => {
                    return <Option value={item.channel_id}>{item.name}</Option>;
                  })}
              </Select>
            </div>
            <div className="col-sm-4">
              <label>Product Type</label>
              <Select
                defaultValue=""
                className="w-100"
                onSelect={(value, event) =>
                  onChangeSearchProductType(value, event)
                }
              >
                <Option value="">ทั้งหมด</Option>
                <Option value="course">คอร์สสัมนา</Option>
                <Option value="book">หนังสือ</Option>
                <Option value="audio">หนังสือเสียง</Option>
              </Select>
            </div>
          </div>
        </div>

        <div class="table_wrapper">
          <table className="table border mb-0 mt-4">
            <thead>
              <tr>
                <th className="text-center">ID</th>
                <th className="text-center">Ref Code</th>
                <th className="text-center">Influencer Name</th>
                <th className="text-center">Channel Name</th>
                <th className="text-center">Product Type</th>
                <th className="text-center">Product ID</th>
                <th className="text-center">Product Title</th>
                <th className="text-center">Link</th>
                <th>ลบ</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center font-weight-bold">
                        {item.id}
                      </td>
                      <td className="text-center font-weight-bold">
                        {item.ref_code}
                      </td>
                      <td className="text-center font-weight-bold">
                        {item.influencer_name}
                      </td>
                      <td className="text-center font-weight-bold">
                        {item.channel_name}
                      </td>
                      <td className="text-center font-weight-bold">
                        {formatProductType(item.product_type)}
                      </td>
                      <td className="text-center font-weight-bold">
                        {item.product_id}
                      </td>
                      <td className="text-center font-weight-bold">
                        {item.product_title}
                      </td>
                      <td className="text-center font-weight-bold">
                        {/* {item.tracking_link}{" "} */}
                        <Button
                          type="primary"
                          shape="circle"
                          size="small"
                          className="btn-primaty"
                          icon={<CopyOutlined />}
                          onClick={() => {
                            navigator.clipboard.writeText(item.tracking_link);
                          }}
                        />
                      </td>
                      <td>
                        <Button
                          type="primary"
                          shape="circle"
                          size="small"
                          className="btn-danger"
                          icon={<DeleteOutlined />}
                          onClick={() => showDeleteConfirm(item.id)}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <Modal
          title="Tracking Link"
          footer={null}
          maskClosable={false}
          visible={ModalOpen}
          onCancel={() => setModalOpen(false)}
        >
          <div className="row">
            <div className="col-12">
              <label>Influencer Name</label>
              <Select
                value={influencerAdd}
                className="w-100"
                allowClear
                onChange={onChangeInfluencerAdd}
                placeholder="Influencer Name"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {influencerAddSelect &&
                  influencerAddSelect.map((item, index) => {
                    return (
                      <Option value={item.influencer_id}>{item.name}</Option>
                    );
                  })}
              </Select>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-12">
              <label>Channel Name</label>
              <Select
                value={channelAdd}
                className="w-100"
                allowClear
                onChange={onChangeChannelAdd}
                placeholder="Channel Name"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {channelAddSelect &&
                  channelAddSelect.map((item, index) => {
                    return <Option value={item.channel_id}>{item.name}</Option>;
                  })}
              </Select>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-12">
              <label>Product Type</label>
              <Select
                value={productTypeAdd}
                className="w-100"
                allowClear
                onChange={onChangeProductTypeAdd}
                placeholder="Product Type"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="course">คอร์สออนไลน์</Option>
                <Option value="book">หนังสือ</Option>
                <Option value="audio">หนังสือเสียง</Option>
              </Select>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-12">
              <label>Product Title</label>
              <Select
                value={productIDAdd}
                className="w-100"
                allowClear
                onChange={onChangeProductIDAdd}
                placeholder="Product Title"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {productTypeAdd === "book" &&
                  bookTitleSelect &&
                  bookTitleSelect.map((item, index) => {
                    return (
                      <Option value={item.book_id}>{item.book_name}</Option>
                    );
                  })}
                {productTypeAdd === "audio" &&
                  audioBookTitleSelect &&
                  audioBookTitleSelect.map((item, index) => {
                    return (
                      <Option value={item.book_id}>{item.book_name}</Option>
                    );
                  })}
                {productTypeAdd === "course" &&
                  courseTitleSelect &&
                  courseTitleSelect.map((item, index) => {
                    return (
                      <Option value={item.course_id}>{item.course_name}</Option>
                    );
                  })}
              </Select>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-6"></div>

            <div className="col-6">
              <div className="mt-2 text-right">
                <button
                  type="button"
                  className="btn btn-secondary mr-2"
                  onClick={() => {
                    setModalOpen(false);
                  }}
                >
                  ยกเลิก
                </button>
                <button
                  type="button"
                  onClick={() => {
                    SaveTracking();
                  }}
                  className="btn btn-primary mr-2"
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <div className="text-right mt-4">
          {data && data.length > 0 && (
            <ContentPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={changePage.bind(this)}
              pageShow={10}
            />
          )}
        </div>
      </div>
    </div>
  );
}
