import { httpClient } from "../utils/HttpClient";

const login = (data) => {
  return httpClient.post("/admin/signin", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`
    },
  });
};

const getAll = (page_no, filter_email, filter_role) => {
  return httpClient.get("/admin/user", {
    params: {
      page: page_no,
      email: filter_email,
      role_id: filter_role,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`
    },
  });
};

const get = (email) => {
  return httpClient.get(`/admin/user/${email}`, {
    params: {
      email,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`
    },
  });
};

const create = (data) => {
  return httpClient.post("/admin/user", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`
    },
  });
};

const update = (email, data) => {
  return httpClient.patch(`/admin/user/${email}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`
    },
  });
};

const remove = (id) => {
  return httpClient.delete(`/admin/user/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`
    },
  });
};

const getAdminRole = () => {
  return httpClient.get("/admin/role", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`
    },
  });
};

const addRole = (data) => {
  return httpClient.post("/admin/role", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`
    },
  });
};

const getRoleById = (id) => {
  return httpClient.get(`/admin/role/${id}`, {
    params: {
      id,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`
    },
  });
};

const updateRole = (data) => {
  return httpClient.patch(`/admin/role`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`
    },
  });
};

const deleteRole = (id) => {
  return httpClient.delete(`/admin/role/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`
    },
  });
};

const setPassword = (email, data) => {
  return httpClient.patch(`/admin/user/set_password/${email}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`
    },
  });
};

const getEmailByReferenceCode = (reference_code) => {
  return httpClient.get(`/admin/user/reference/${reference_code}`, {
    params: {
      reference_code,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`
    },
  });
};

const sendEmail = (email, reference_code) => {
  return httpClient.post(`/admin/email/${email}/${reference_code}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`
    },
  });
};


export default {
  getAll,
  get,
  create,
  update,
  remove,
  getAdminRole,
  addRole,
  updateRole,
  deleteRole,
  getRoleById,
  login,
  setPassword,
  getEmailByReferenceCode,
  sendEmail
};
