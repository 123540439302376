import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Bannerservice from "../services/BannerService";
import moment from "moment";
import { Select, Button, Tag, Spin } from "antd";
import {
  PictureOutlined,
  UpCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { VisibilityOutlined, Mouse } from "@material-ui/icons";
import ContentPagination from "../utils/Pagination";
import ArticleService from "../services/ArticleService";
import CourseService from "../services/CourseService";
import BookService from "../services/BookService";
import BookAudioService from "../services/BookAudioService";

const { Option } = Select;

export default function Banner() {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(5);
  const [searchBannerType, setSearchBannerType] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPage] = useState(1);
  const [articleContent, setArticleContent] = useState("");
  const [bookContent, setBookContent] = useState("");
  const [courseContent, setCourseContent] = useState("");
  const [bookAudioContent, setBookAudioContent] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("token")) history.push("/login");

    var result = localStorage.getItem("user_profile");
    if (result) {
      if (JSON.parse(result).banner === false) {
        history.push("/login");
      }
    }

    getData(currentPage, searchBannerType);
    getArticleContent();
    getBookContent();
    getBookAudioContent();
    getCourseContent();
  }, [0]);

  const getData = async (page, filterBannerType) => {
    setLoading(true);
    await Bannerservice.getAll(page, filterBannerType)
      .then((res) => {
        setLoading(false);
        const response = res.data;

        const totalPages = Math.ceil(response.total / perPage);
        setTotalPage(totalPages);
        setData(response.banners);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const getArticleContent = async () => {
    await ArticleService.getArticleSelect()
      .then((res) => {
        const response = res.data;
        setArticleContent(response.articles);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getCourseContent = async () => {
    await CourseService.getCourseSelectReport()
      .then((res) => {
        const response = res.data;
        setCourseContent(response.courses);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getBookContent = async () => {
    await BookService.getBookSelect()
      .then((res) => {
        const response = res.data;
        setBookContent(response.books);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getBookAudioContent = async () => {
    await BookAudioService.getAudioBookSelect()
      .then((res) => {
        const response = res.data;
        setBookAudioContent(response.audioBooks);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  function changePage(page) {
    setCurrentPage(page);
    getData(page, searchBannerType);
  }

  const onChangeFilterBannerType = (banner_type, e) => {
    setSearchBannerType(banner_type);
    getData(currentPage, banner_type);
  };

  function formatDate(date) {
    if (date) {
      return moment(date).utc().format("DD/MM/YYYY HH:mm");
    }
  }

  function formatContentName(menu_name, content_id) {
    switch (menu_name) {
      case "บทความ":
        if (articleContent.length > 0) {
          for (let i = 0; i < articleContent.length; i++) {
            if (articleContent[i].article_id === content_id) {
              return articleContent[i].name;
            }
          }
        }
        break;
      case "คอร์สออนไลน์":
        console.log(courseContent);
        if (courseContent.length > 0) {
          for (let i = 0; i < courseContent.length; i++) {
            if (courseContent[i].course_id === content_id) {
              return courseContent[i].course_name;
            }
          }
        }
        break;
      case "หนังสือ":
        if (bookContent.length > 0) {
          for (let i = 0; i < bookContent.length; i++) {
            if (bookContent[i].book_id === content_id) {
              return bookContent[i].book_name;
            }
          }
        }
        break;
      case "หนังสือเสียง":
        if (bookAudioContent.length > 0) {
          for (let i = 0; i < bookAudioContent.length; i++) {
            if (bookAudioContent[i].book_id === content_id) {
              return bookAudioContent[i].book_name;
            }
          }
        }
        break;
      default:
        return "";
    }
  }

  function formatBannerType(type) {
    if (type === "internal") {
      return "ภายในเว็บไซต์ (internal)";
    } else if (type === "none") {
      return "ไม่ลิงค์ (none)";
    } else if (type === "external") {
      return "ภายนอกเว็บไซต์ (external)";
    }
  }

  function formatAmount(amount) {
    if (amount) {
      return new Intl.NumberFormat("en-GB", {
        style: "decimal",
      }).format(amount);
    } else return 0;
  }

  return (
    <div className="container">
      <div className="text-right container-fluid">
        <Button
          className="btn-primary px-3"
          onClick={() => history.push("/manage/banner/add")}
        >
          เพิ่มแบนเนอร์
        </Button>
      </div>

      <h5 className="mt-2 font-weight-bold">รายการแบนเนอร์</h5>
      <div className="line-highlight">
        <hr />
      </div>

      <div className="flex-fill">
        <div className="row mt-4 pt-3">
          <div className="col-sm-3 col-lg-4">
            <div className="form-group">
              <label>ประเภทแบนเนอร์ (การลิงค์ของแบนเนอร์)</label>
              <Spin style={{ marginLeft: 20 }} spinning={loading} />
              <Select
                defaultValue=""
                className="w-75"
                onSelect={(value, event) =>
                  onChangeFilterBannerType(value, event)
                }
              >
                <Option value="">เลือกประเภทแบนเนอร์</Option>
                <Option value="none">ไม่ลิงค์ (none)</Option>
                <Option value="internal">ภายในเว็บไซต์ (internal)</Option>
                <Option value="external">ภายนอกเว็บไซต์ (external)</Option>
              </Select>
            </div>
          </div>
        </div>

        {data &&
          data.map((item, index) => {
            return (
              <div className="card mt-2">
                <div className="card-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 text-right text-grey font-12 ml-0 mr-0 pl-0 pr-0">
                        {item.status === "A" ? (
                          <Tag
                            style={{ fontSize: "16px", padding: 7 }}
                            color="green"
                          >
                            Active
                          </Tag>
                        ) : (
                          <Tag
                            style={{ fontSize: "16px", padding: 7 }}
                            color="red"
                          >
                            InActive
                          </Tag>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-5 col-sm-3">
                        <div>
                          <div className="position-relative my-2 mt-4">
                            {item.image_url ? (
                              <div>
                                <img
                                  src={item.image_url}
                                  className="img-fluid"
                                />
                              </div>
                            ) : (
                              <div
                                className="image-placeholder d-flex flex-column align-items-center justify-content-center"
                                style={{ width: 250, height: 250 }}
                              >
                                <div>
                                  <PictureOutlined
                                    style={{ fontSize: 30, color: "#bbb" }}
                                  />
                                </div>
                                <div className="font-12">Banner Image</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-7 col-sm-9 mt-4">
                        <div className="d-flex ml-4">
                          <span className="align-title font-weight-bold">
                            ประเภทแบนเนอร์:
                          </span>
                          <span className="text-left ml-4">
                            {formatBannerType(item.type)}
                          </span>
                        </div>

                        <div className="d-flex mt-3 ml-4">
                          <span className="align-title font-weight-bold">
                            วันที่สร้าง :
                          </span>
                          <span className="text-left ml-4">
                            {formatDate(item.created_datetime)}
                          </span>
                        </div>

                        {item.type === "internal" && (
                          <>
                            <div className="d-flex mt-3 ml-4">
                              <span className="align-title font-weight-bold">
                                เมนู :
                              </span>
                              <span className="text-left ml-4">
                                {item.internal_menu_name}
                              </span>
                            </div>

                            <div className="d-flex mt-3 ml-4">
                              <span className="align-title font-weight-bold">
                                เนื้อหา :
                              </span>
                              <span className="text-left ml-4">
                                {formatContentName(
                                  item.internal_menu_name,
                                  item.internal_content_id
                                )}
                              </span>
                            </div>
                          </>
                        )}

                        {item.type === "external" && (
                          <div className="d-flex mt-3 ml-4">
                            <span className="align-title font-weight-bold">
                              External URL :
                            </span>
                            <span className="text-left ml-4">
                              <a target="_blank" href={item.external_url}>
                                {item.external_url}
                              </a>
                            </span>
                          </div>
                        )}

                        {item.type != "none" && (
                          <div className="d-flex mt-3 ml-4">
                            <span
                              className="font-weight-bold"
                              style={{ width: 20 }}
                            >
                              <VisibilityOutlined />
                            </span>
                            <div style={{ marginTop: 1, marginLeft: 10 }}>
                              {formatAmount(item.total_view)}
                            </div>

                            <span
                              className="font-weight-bold"
                              style={{ width: 20, marginLeft: 40  }}
                            >
                              <Mouse />
                            </span>
                            <div style={{ marginTop: 1, marginLeft: 10 }}>
                              {formatAmount(item.total_click)}
                            </div>
                          </div>
                        )}

                        <div className="text-right mt-5">
                          <Button
                            className="btn-secondary"
                            onClick={() =>
                              history.push("/manage/banner/update/" + item.id)
                            }
                          >
                            ดูรายละเอียด/แก้ไข
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

        <div className="text-right mt-4">
          {data && data.length > 0 && (
            <ContentPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={changePage.bind(this)}
              pageShow={5}
            />
          )}
        </div>
      </div>
    </div>
  );
}
