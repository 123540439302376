import { httpClient } from "../utils/HttpClient";

const getAll = (page_no, filter_name, filter_category, filter_writer,filter_nft_only,filter_preorder_flag) => {
  return httpClient.get("/admin/book", {
    params: {
      page: page_no,
      book_name: filter_name,
      category_id: filter_category,
      writer_name: filter_writer,
      nft_only: filter_nft_only,
      preorder_flag:filter_preorder_flag
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getPreorderBook = (page_no) => {
  return httpClient.get("/admin/preorder_book", {
    params: {
      page: page_no
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getBookSelect = () => {
  return httpClient.get("/admin/book/select", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getAudioBookSelect = () => {
  return httpClient.get("/admin/audio_book/select", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};


const get = (book_id) => {
  return httpClient.get(`/admin/book/${book_id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getBookCategory = () => {
  return httpClient.get(`/admin/category/book`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const create = (data) => {
  return httpClient.post("/admin/book", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const update = (book_id, data) => {
  return httpClient.patch(`/admin/book/${book_id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const remove = (book_id) => {
  return httpClient.delete(`/admin/book/${book_id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const updateStatus = (book_id, data) => {
  return httpClient.patch(`/admin/book/status/${book_id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const reorderBook = (data) => {
  return httpClient.put(`/admin/book/reorder`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export default {
  getAll,
  get,
  create,
  update,
  remove,
  updateStatus,
  getBookCategory,
  getBookSelect,
  getPreorderBook,
  reorderBook,
  getAudioBookSelect
};
