import { httpClient } from "../utils/HttpClient";

const getAll = (page_no, filter_banner_type) => {
  return httpClient.get("/admin/banner", {
    params: {
      page: page_no,
      type: filter_banner_type,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const get = (id) => {
  return httpClient.get(`/admin/banner/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getInternalMenu = () => {
  return httpClient.get(`/admin/banner_internal_menu`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const create = (data) => {
  return httpClient.post("/admin/banner", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const update = (id, data) => {
  return httpClient.patch(`/admin/banner/${id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const remove = (id) => {
  return httpClient.delete(`/admin/banner/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const updateStatus = (id, data) => {
  return httpClient.patch(`/admin/banner/status/${id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export default {
  getAll,
  get,
  create,
  update,
  remove,
  updateStatus,
  getInternalMenu,
};
