import { httpClient } from "../utils/HttpClient";

const getAll = (page_no, filter_influencer,filter_channel,filter_product) => {
  return httpClient.get("/admin/tracking_link", {
    params: {
      page: page_no,
      influencer_id: filter_influencer,
      channel_id: filter_channel,
      product_type: filter_product,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const get = (id) => {
  return httpClient.get(`/admin/tracking_link/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const create = (data) => {
  return httpClient.post("/admin/tracking_link", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const update = (id,data) => {
  return httpClient.patch(`/admin/tracking_link/${id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const remove = (id) => {
  return httpClient.delete(`/admin/tracking_link/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getReportTracking = (page_no,filterRefCode,filterStartDate,filterEndDate,filterInfluencer,filterChannel,filterProductType,filterProductTitle) => {
  return httpClient.get("/admin/report_tracking", {
    params: {
      page: page_no,
      ref_code: filterRefCode,
      start_date: filterStartDate,
      end_date: filterEndDate,
      influencer: filterInfluencer,
      channel: filterChannel,
      productType: filterProductType,
      productTitle: filterProductTitle
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getProductTitle = () => {
  return httpClient.get(`/admin/tracking/productTitle`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export default {
  getAll,
  get,
  create,
  update,
  remove,
  getReportTracking,
  getProductTitle
};
