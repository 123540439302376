import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Input,
  Button,
  Popconfirm,
  Switch,
  notification,
  Select,
  Spin,
} from "antd";
import UserService from "../services/UserService";
import moment from "moment";
import Notification from "../utils/Notification";

const { Option } = Select;

const ManageUser = (props) => {
  const history = useHistory();
  const initialUserState = {
    id: null,
    name: "",
    email: "",
    role_id: null,
    created_datetime: null,
    created_by: null,
    updated_datetime: null,
    updated_by: null,
  };

  const { email } = useParams();
  const [user, setUser] = useState(initialUserState);
  const [status, setStatus] = useState(true);
  const [action, setAction] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [role_id, setRoleID] = useState("");
  const [adminRole, setAdminRole] = useState("");
  const [oldEmail, setOldEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("token")) history.push("/login");

    var result = localStorage.getItem("user_profile");
    if (result) {
      if (JSON.parse(result).user_management === false) {
        history.push("/login");
      }
    }

    setAction(props.action);
    getUser(email);
    getAdminRole();
    if (props.action === "update") setOldEmail(email);
  }, [email]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  const getUser = (email) => {
    if (email) {
      setLoading(true);
      UserService.get(email)
        .then((response) => {
          setLoading(false);
          console.log(response.data);
          setRoleID(response.data.role_id);
          setUser(response.data);
          if (response.data.status === "A") setStatus(true);
          else setStatus(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
          let error = e.response ? e.response.data.message : e.message;
          Notification.Show("error", "Error", error);
        });
    }
  };

  const getAdminRole = async () => {
    await UserService.getAdminRole()
      .then((res) => {
        const reponse = res.data;
        console.log(res.data);
        setAdminRole(reponse.admin_role);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const ShowNotification = (type, header, message) =>
    notification[type]({
      message: header,
      description: message,
    });

  async function manageUser() {
    if (!user.email) {
      Notification.Show("error", "Validation", "กรุณาระบุ Email");
      return;
    }

    if (!user.name) {
      Notification.Show("error", "Validation", "กรุณาระบุ ชื่อ");
      return;
    }

    if (!role_id) {
      Notification.Show("error", "Validation", "กรุณาระบุ Role");
      return;
    }

    if (action === "update" && (user.status === "I" || user.status === "P")) {
      if (oldEmail != user.email) {
        Notification.Show(
          "error",
          "Validation",
          "User มี Status เป็น InActive ไม่สามารถแก้ไข Email ได้"
        );
        return;
      }
    }

    if (user.status != "P") {
      if (status) user.status = "A";
      else user.status = "I";
    }

    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;

    user.role_id = role_id;
    if (action === "add") user.created_by = action_by;

    user.updated_by = action_by;
    user.updated_datetime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

    setLoadingSave(true);
    if (action === "add") {
      await UserService.create(user)
        .then((response) => {
          ShowNotification(
            "success",
            "Success",
            "บันทึกข้อมูล User เรียบร้อยแล้ว กรุณาตั้งรหัสผ่านที่ Email ของท่าน"
          );
          setLoadingSave(false);
          history.push("/user");
        })
        .catch((e) => {
          console.log(e);
          setLoadingSave(false);
          Notification.Show("error", "Validation", e.response.data.message);
        });
    } else {
      await UserService.update(user.email, user)
        .then((response) => {
          setLoadingSave(false);
          ShowNotification(
            "success",
            "Success",
            "บันทึกข้อมูล User เรียบร้อยแล้ว"
          );
          //cancel();
        })
        .catch((e) => {
          setLoadingSave(false);
          console.error(e.response.data.message);
          Notification.Show("error", "Error", e.response.data.message);
        });
    }
  }

  const deleteUser = () => {
    setLoadingDelete(true);
    UserService.remove(user.id)
      .then((response) => {
        setLoadingDelete(false);
        console.log(response.data);
        Notification.Show("success", "Success", "Delete User Complete");
        history.push("/user");
      })
      .catch((e) => {
        setLoadingDelete(false);
        console.log(e);
        Notification.Show("error", "Error", e.response.data.message);
      });
  };

  const cancel = () => {
    history.push("/user");
  };

  function confirmDialog(e) {
    deleteUser();
  }

  function cancelDialog(e) {}

  function onChangeStatus(checked) {
    setStatus(checked);
  }

  const onChangeRoleID = (role_id, e) => {
    setRoleID(role_id);
  };

  return (
    <div className="container">
      <div className="d-flex mt-4 align-items-stretch">
        <div className="flex-fill">
          <div className="header-bar">
            ข้อมูล User <Spin style={{ marginLeft: 10 }} spinning={loading} />
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="text-right container-fluid mt-3">
                  <Switch
                    onChange={onChangeStatus}
                    checkedChildren="Active"
                    unCheckedChildren="InActive"
                    checked={status}
                  />
                </div>
                <div className="form-group mb-3">
                  <label>
                    ชื่อ
                    <span className="text-danger">*</span>
                  </label>
                  <Input
                    id="name"
                    required
                    value={user.name}
                    onChange={handleInputChange}
                    name="name"
                  />
                </div>

                <div className="form-group mb-3">
                  <label>
                    Email
                    <span className="text-danger">*</span>
                  </label>

                  {user.status === "A" || action === "add" ? (
                    <Input
                      id="email"
                      value={user.email}
                      onChange={handleInputChange}
                      name="email"
                    />
                  ) : (
                    <Input
                      id="email"
                      disabled={true}
                      value={user.email}
                      onChange={handleInputChange}
                      name="email"
                    />
                  )}
                </div>

                <div className="form-group mb-3">
                  <label>
                    Role <span className="text-danger">*</span>
                  </label>
                  <Select
                    defaultValue=""
                    value={role_id}
                    className="w-100"
                    onSelect={(value, event) => onChangeRoleID(value, event)}
                  >
                    {adminRole &&
                      adminRole.map((item, index) => {
                        return <Option value={item.id}>{item.role}</Option>;
                      })}
                  </Select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-2">
          <div>
            {action === "update" && (
              <Popconfirm
                title="ต้องการลบเนื้อหานี้ออกจากเว็บไซต์?"
                onConfirm={confirmDialog}
                onCancel={cancelDialog}
                okText="ยืนยันการลบ"
                cancelText="ยกเลิก"
              >
                <Button className="btn-danger px-3">ลบข้อมูล</Button>
                <Spin spinning={loadingDelete} />
              </Popconfirm>
            )}
          </div>
        </div>

        <div className="col-10 text-right">
          <Button className="btn-secondary mr-2" onClick={cancel}>
            ย้อนกลับ
          </Button>
          <Button className="btn-primary" onClick={manageUser}>
            บันทึกข้อมูล
          </Button>
          <Spin style={{ marginLeft: 10 }} spinning={loadingSave} />
        </div>
      </div>
    </div>
  );
};

export default ManageUser;
