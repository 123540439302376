import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Input,
  Button,
  Popconfirm,
  Switch,
  Modal,
  notification,
  Select,
  Spin,
} from "antd";
import UtilityService from "../services/UtilityService";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "2read-ckeditor5-custom-build";
import imageCompression from "browser-image-compression";
import MyUploadAdapter from "../utils/MyUploadAdapter";
import { PictureOutlined } from "@ant-design/icons";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Notification from "../utils/Notification";
import makeAnimated from "react-select/animated";
import ImgCrop from "antd-img-crop";
import EbookService from "../services/EbookService";

const { Option } = Select;
const animatedComponents = makeAnimated();

const ManageEBook = (props) => {
  const history = useHistory();
  const initialBookState = {
    book_id: null,
    book_name: "",
    writer_name: "",
    price: 0,
    promotion_price: 0,
    cover_image_url: "",
    ebook_url: "",
    status: "",
    created_datetime: null,
    created_by: null,
    updated_datetime: null,
    updated_by: null,
  };

  const { book_id } = useParams();
  const [book, setBook] = useState(initialBookState);
  const [submitted, setSubmitted] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [cropperRef, setCropperRef] = useState(React.createRef());
  const [coverImage, setCoverImage] = useState();
  const [status, setStatus] = useState(true);
  const [action, setAction] = useState();
  const [categorys, setCategorys] = useState("");
  const [categorySelect, setCategorySelect] = useState([]);
  const [loadingAddUpdate, setLoadingAddUpdate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("token")) history.push("/login");

    var result = localStorage.getItem("user_profile");
    if (result) {
      if (JSON.parse(result).book === false) {
        history.push("/login");
      }
    }

    setAction(props.action);
    getBookCategory();
    if (props.action === "update") getBook(book_id);
  }, [book_id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBook({ ...book, [name]: value });
  };

  // const getAllWriter = async () => {
  //   await UtilityService.getAllWriterSelect()
  //     .then((res) => {
  //       const reponse = res.data;
  //       console.log(res.data);
  //       setWriter(reponse.writers);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  const getBookCategory = async () => {
    await EbookService.getEBookCategory()
      .then((res) => {
        const response = res.data;
        setCategorySelect(response.category);
        console.log();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getBook = async (book_id) => {
    if (book_id) {
      EbookService.get(book_id)
        .then((response) => {
          console.log(response.data);
          setBook(response.data);

          const category_id = [];
          for (let i = 0; i < response.data.categorys.length; i++) {
            if (response.data.categorys[i].status === "A") {
              category_id.push(response.data.categorys[i].category_id);
            }
          }
          setCategorys(category_id);
          //setCategoryID(response.data.category_id);
          // setWriterID(response.data.writer_id);

          setCoverImage(response.data.cover_image_url);
          if (response.data.status === "A") setStatus(true);
          else setStatus(false);
        })
        .catch((e) => {
          console.log(e);
          Notification.Show(
            "error",
            "From Web Service",
            e.response.data.message
          );
        });
    }
  };

  const ShowNotification = (type, header, message) =>
    notification[type]({
      message: header,
      description: message,
    });

  function ValidateManageBook() {
    if (!book.book_name) {
      Notification.Show("error", "Validation", "กรุณาระบุ ชื่อหนังสือ");
      return false;
    }

    if (!book.writer_name) {
      Notification.Show("error", "Validation", "กรุณาระบุ ชื่อผู้เขียน");
      return false;
    }

    if (categorys.length === 0) {
      Notification.Show("error", "Validation", "กรุณาระบุ หมวดหมู่");
      return;
    }

    if (!book.ebook_url) {
      Notification.Show("error", "Validation", "กรุณาระบุ E-Book Link");
      return false;
    }

    if (!book.cover_image_url) {
      Notification.Show("error", "Validation", "กรุณาระบุ รูปปก E-book");
      return false;
    }
    return true;
  }

  async function AddUpdateEBook() {
    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;

    //cover
    if (action === "add") {
      if (imageFile) {
        //cover image
        await uploadImage(imageFile, "cover");
      } else book.cover_image_url = "";
      book.created_by = action_by;
      book.created_datetime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    } else {
      if (imageFile) {
        await uploadImage(imageFile, "cover");
      } else book.cover_image_url = coverImage;
    }

    if (ValidateManageBook() === true) {
      if (categorys) {
        book.categorys = categorys.map((item) => {
          return {
            category_id: item,
            status: "A",
            created_by: action_by,
            created_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            updated_by: action_by,
            updated_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          };
        });
      }

      if (status) book.status = "A";
      else book.status = "I";

      book.updated_by = action_by;
      book.updated_datetime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

      console.log(book);
      setLoadingAddUpdate(true);

      if (action === "add") {
        EbookService.create(book)
          .then((response) => {
            ShowNotification(
              "success",
              "Success",
              "บันทึกข้อมูล Ebook เรียบร้อยแล้ว"
            );
            setLoadingAddUpdate(false);
            setSubmitted(true);
            console.log(response.data);
            history.push("/ebook");
          })
          .catch((e) => {
            console.log(e);
            setLoadingAddUpdate(false);
            Notification.Show("error", "Validation", e.response.data.message);
          });
      } else {
        EbookService.update(book.book_id, book)
          .then((response) => {
            console.log(response.data);
            setLoadingAddUpdate(true);
            ShowNotification(
              "success",
              "Success",
              "บันทึกข้อมูล Ebook เรียบร้อยแล้ว"
            );
            history.push("/ebook");
          })
          .catch((e) => {
            console.error(e.response.data.message);
            setLoadingAddUpdate(false);
            Notification.Show("error", "Error", e.response.data.message);
          });
      }
    } else return;
  }

  const deleteBook = () => {
    setLoadingDelete(true);
    EbookService.remove(book.book_id)
      .then((response) => {
        setLoadingDelete(false);
        console.log(response.data);
        Notification.Show("success", "Success", "ลบข้อมูล EBook เรียบร้อยแล้ว");
        history.push("/ebook");
      })
      .catch((e) => {
        setLoadingDelete(false);
        console.log(e);
        Notification.Show("error", "Error", e.response.data.message);
      });
  };

  const cancel = () => {
    history.push("/ebook");
  };

  function confirmDialog(e) {
    deleteBook();
  }

  function cancelDialog(e) {}

  function customUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      // Configure the URL to the upload script in your back-end here!
      return new MyUploadAdapter(loader);
    };
  }

  function onSelectFile(e, type) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImageUrl(reader.result));
      //reader.addEventListener("load", () => setCoverImage(reader.result));
      reader.readAsDataURL(e.target.files[0]);

      setCropModalOpen(true);
      setImageFile(e.target.files[0]);
    }
  }

  function cropImage() {
    const cropper = cropperRef.current.cropper;

    cropper.getCroppedCanvas().toBlob((blob) => {
      const fileName = imageFile.name;

      const file = new File([blob], fileName, {
        lastModifiedDate: new Date(),
        type: imageFile.type,
      });
      const cropppedPreview = cropper.getCroppedCanvas().toDataURL();
      setCoverImage(cropppedPreview);
      setImageFile(file);
      closeCropModal();
    });
  }

  function closeCropModal() {
    setCropModalOpen(false);
  }

  function removeImage() {
    setCoverImage("");
    setImageUrl("");
  }

  async function uploadImage(image, type) {
    const options = {
      maxSizeMB: 0.1,
      useWebWorker: true,
      initialQuality: 0.5
    };
    try {
      const compressedFile = await imageCompression(image, options);
      const file = new File([compressedFile], image.name, { type: image.type });

      if (image) {
        let formData = new FormData();
        let imagefile = file;

        formData.append("upload", imagefile);

        await UtilityService.uploadImage(formData)
          .then((response) => {
            book.cover_image_url = response.data.url;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } catch (error) {
      console.log(error);
    }
  }

  function onChangeStatus(checked) {
    setStatus(checked);
  }

  function onChangeCategory(value) {
    setCategorys(value);
  }

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  // const onChangeWriterID = (writer_id, e) => {
  //   setWriterID(writer_id);
  // };

  return (
    <div className="container">
      <div className="d-flex mt-4 align-items-stretch">
        <div className="flex-fill">
          <div className="header-bar">ข้อมูล E-Book</div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4"></div>
              <div className="col-sm-4">
                <div className="position-relative my-2 mt-4 text-center">
                  {coverImage ? (
                    <img src={coverImage} className="img-fluid" width={200}  />
                  ) : (
                    <div
                      className="image-placeholder d-flex flex-column align-items-center justify-content-center mt-5"
                      style={{ height: 240 }}
                    >
                      <div>
                        <PictureOutlined
                          style={{ fontSize: 30, color: "#bbb" }}
                        />
                      </div>
                      <div className="font-12">อัพโหลดรูป</div>
                    </div>
                  )}

                  <input
                    type="file"
                    accept="image/*"
                    className="input-file-hidden"
                    onChange={(event) => {
                      onSelectFile(event, "writers");
                    }}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                  />
                </div>
                {coverImage && (
                  <div className="text-right mt-2">
                    <Button
                      className="btn btn-sm btn-danger"
                      onClick={() => removeImage()}
                    >
                      ลบ
                    </Button>
                  </div>
                )}
                <div className="text-right text-danger">
                  (ขนาดรูปที่แนะนำ 350 x 510 px)
                </div>
              </div>
              <div className="col-sm-4">
                <div className="text-right mt-3">
                  <Switch
                    onChange={onChangeStatus}
                    checkedChildren="Active"
                    unCheckedChildren="InActive"
                    checked={status}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group mb-3">
                  <label>
                    ชื่อหนังสือ
                    <span className="text-danger">*</span>
                  </label>
                  <Input
                    id="book_name"
                    required
                    value={book.book_name}
                    onChange={handleInputChange}
                    name="book_name"
                  />
                </div>

                <div className="form-group mb-3 mt-3">
                  <label>
                    ชื่อผู้เขียน
                    <span className="text-danger">*</span>
                  </label>
                  <Input
                    id="writer_name"
                    required
                    value={book.writer_name}
                    onChange={handleInputChange}
                    name="writer_name"
                  />
                </div>

                <div className="form-group mb-3 mt-3">
                  <label>
                    หมวดหมู่
                    <span className="text-danger">*</span>
                  </label>
                  {categorys && (
                    <Select
                      defaultValue={categorys}
                      className="w-100"
                      mode="multiple"
                      allowClear
                      onChange={onChangeCategory}
                      placeholder="เลือกหมวดหมู่"
                    >
                      {/* <Option value="">เลือกหมวดหมู่</Option> */}
                      {categorySelect &&
                        categorySelect.map((item, index) => {
                          return (
                            <Option value={item.category_id}>
                              {item.name}
                            </Option>
                          );
                        })}
                    </Select>
                  )}

                  {!categorys && (
                    <Select
                      className="w-100"
                      mode="multiple"
                      allowClear
                      onChange={onChangeCategory}
                      placeholder="เลือกหมวดหมู่"
                    >
                      {categorySelect &&
                        categorySelect.map((item, index) => {
                          return (
                            <Option value={item.category_id}>
                              {item.name}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </div>

                <div className="form-group mb-3">
                  <label>
                    E-Book Link
                    <span className="text-danger">*</span>
                  </label>
                  <Input
                    id="ebook_url"
                    required
                    value={book.ebook_url}
                    onChange={handleInputChange}
                    name="ebook_url"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-2">
          <div>
            {action === "update" && (
              <Popconfirm
                title="ต้องการลบเนื้อหานี้ออกจากเว็บไซต์?"
                onConfirm={confirmDialog}
                onCancel={cancelDialog}
                okText="ยืนยันการลบ"
                cancelText="ยกเลิก"
              >
                <Button className="btn-danger px-3">ลบข้อมูล</Button>
                <Spin spinning={loadingDelete} />
              </Popconfirm>
            )}
          </div>
        </div>

        <div className="col-10 text-right">
          <Button className="btn-secondary mr-2" onClick={cancel}>
            ยกเลิก
          </Button>
          <Button className="btn-primary" onClick={AddUpdateEBook}>
            บันทึกข้อมูล
          </Button>
          <Spin style={{ marginLeft: 10 }} spinning={loadingAddUpdate} />
        </div>
      </div>

      <Modal
        title={null}
        footer={null}
        width={800}
        maskClosable={false}
        visible={cropModalOpen}
        onCancel={() => setCropModalOpen(false)}
      >
        <div className="d-flex">
          <div className="flex-fill thumbnail-crop-area">
            <Cropper
              src={imageUrl}
              aspectRatio={350 / 510}
              autoCropArea={1}
              preview=".book-preview"
              viewMode={1}
              ref={cropperRef}
            />
          </div>

          <div className="mt-4 book-preview-area ml-4">
            <div className="font-weight-bold text-center mb-2">ตัวอย่างรูป</div>

            <div className="book-preview" />
          </div>
        </div>

        <div className="mt-5 text-center">
          <button
            type="button"
            onClick={() => {
              cropImage();
            }}
            className="btn btn-primary mr-2"
          >
            ตกลง
          </button>
          <button
            type="button"
            className="btn btn-secondary mr-2"
            onClick={() => {
              setCropModalOpen(false);
            }}
          >
            ยกเลิก
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ManageEBook;
