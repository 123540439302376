import { httpClient } from "../utils/HttpClient";

const getReportPurchase = (page_no,filter_start_date, filter_end_date, filter_product_type) => {
  return httpClient.get("/admin/report/purchase", {
    params: {
      page: page_no,
      start_date: filter_start_date,
      end_date: filter_end_date,
      product_type: filter_product_type,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getReportExportPurchase = (page_no,filter_start_date, filter_end_date, filter_product_type) => {
  return httpClient.get("/admin/report/export_purchase", {
    params: {
      page: page_no,
      start_date: filter_start_date,
      end_date: filter_end_date,
      product_type: filter_product_type,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const updateTaxInvoices = (data) => {
  return httpClient.patch(`/admin/tax_invoices`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const updateOrderRemark = (data) => {
  return httpClient.patch(`/admin/order/remark`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const updateOrderDetail = (data) => {
  return httpClient.patch(`/admin/order_detail`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};


const getReportTaxInvoices = (page_no,filter_order_number, filter_status) => {
  return httpClient.get("/admin/report/tax_invoices", {
    params: {
      page: page_no,
      order_number: filter_order_number,
      status: filter_status
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getReportCourse = (page_no,filter_course_id,filter_start_date,filter_end_date,filter_order_status,filter_name,filter_email) => {
  return httpClient.get("/admin/report/course", {
    params: {
      page: page_no,
      course_id: filter_course_id,
      start_date: filter_start_date,
      end_date: filter_end_date,
      is_status_success : filter_order_status,
      name: filter_name,
      email: filter_email
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export default {
    getReportPurchase,
    updateTaxInvoices,
    getReportTaxInvoices,
    updateOrderDetail,
    updateOrderRemark,
    getReportCourse,
    getReportExportPurchase
};
