import imageCompression from "browser-image-compression";

export default class MyUploadAdapter {
    constructor(loader) {
        // The file loader instance to use during the upload.
        this.loader = loader;
      }
    
      // Starts the upload process.
      upload() {
        return this.loader.file.then(
          (file) =>
            new Promise((resolve, reject) => {
              this._initRequest();
              this._initListeners(resolve, reject, file);
              this._sendRequest(file);
            })
        );
      }
    
      // Aborts the upload process.
      abort() {
        if (this.xhr) {
          this.xhr.abort();
        }
      }
    
      // Initializes the XMLHttpRequest object using the URL passed to the constructor.
      _initRequest() {
        const xhr = (this.xhr = new XMLHttpRequest());
        xhr.open("POST", "https://admin-api.stock2morrow.com/admin/upload/image", true);
        xhr.responseType = "json";
      }
    
      // Initializes XMLHttpRequest listeners.
      _initListeners(resolve, reject, file) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = `Couldn't upload file: ${file.name}.`;
    
        xhr.addEventListener("error", () => reject(genericErrorText));
        xhr.addEventListener("abort", () => reject());
        xhr.addEventListener("load", () => {
          const response = xhr.response;
    
          // This example assumes the XHR server's "response" object will come with
          // an "error" which has its own "message" that can be passed to reject()
          // in the upload promise.
          //
          // Your integration may handle upload errors in a different way so make sure
          // it is done properly. The reject() function must be called when the upload fails.
          if (!response || response.error) {
            return reject(
              response && response.error ? response.error.message : genericErrorText
            );
          }
    
          // If the upload is successful, resolve the upload promise with an object containing
          // at least the "default" URL, pointing to the image on the server.
          // This URL will be used to display the image in the content. Learn more in the
          // UploadAdapter#upload documentation.
          resolve({
            default: response.url,
          });
        });
    
        // Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
        // properties which are used e.g. to display the upload progress bar in the editor
        // user interface.
        if (xhr.upload) {
          xhr.upload.addEventListener("progress", (evt) => {
            if (evt.lengthComputable) {
              loader.uploadTotal = evt.total;
              loader.uploaded = evt.loaded;
            }
          });
        }
      }
    
      // Prepares the data and sends the request.
      async _sendRequest(file) {
        const options = {
          useWebWorker: true,
        };
    
        const compressedFile = await imageCompression(file, options);
        const newFile = new File([compressedFile], file.name, { type: file.type });
    
        const data = new FormData();
        data.append("upload", newFile);
        this.xhr.send(data);
      }
}