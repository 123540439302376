import { httpClient } from "../utils/HttpClient";
import axios from "axios";

const getAll = (page_no, filter_name) => {
  return httpClient.get("/admin/writer", {
    params: {
      page: page_no,
      name: filter_name,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const get = (writer_id) => {
  return httpClient.get(`/admin/writer/${writer_id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const create = (data) => {
  return httpClient.post("/admin/writer", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const update = (writer_id, data) => {
  return httpClient.patch(`/admin/writer/${writer_id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const remove = (writer_id) => {
  return httpClient.delete(`/admin/writer/${writer_id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const updateStatus = (id, data) => {
  return httpClient.patch(`/admin/writer/status/${id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export default {
  getAll,
  get,
  create,
  update,
  remove,
  updateStatus,
};
