import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Input,
  Button,
  Popconfirm,
  Switch,
  notification,
  Select,
  Spin,
  Modal,
  Radio,
  Table,
  Tooltip,
} from "antd";
import OrderService from "../services/OrderService";
import moment from "moment";
import Notification from "../utils/Notification";
import { SearchOutlined } from "@ant-design/icons";
import CourseService from "../services/CourseService";

const { Option } = Select;
const { TextArea } = Input;
const { Column } = Table;

const ManageOrder = (props) => {
  const history = useHistory();

  const initialEditState = {
    id: null,
    users_id: null,
    order_number: "",
    order_date: null,
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    address_other_info: "",
    sub_district: "",
    district: "",
    province: "",
    zip_code: "",
    payment_methods_id: null,
    tax_request: false,
    order_status: null,
    delivery_cost: 0,
    order_date: null,
    updated_by: "",
    updated_datetime: null,
    remark: "",
    is_nft: false,
  };

  const initialEditAttendee = {
    id: null,
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    updated_by: "",
    updated_datetime: null,
  };

  const { order_number } = useParams();
  const [order, setOrder] = useState([]);
  const [deliverDetail, setDeliverDetail] = useState([]);
  const [edit, setEdit] = useState(initialEditState);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [totalPages, setTotalPage] = useState(1);
  const [taxRequest, setTaxRequest] = useState(false);
  const [deliveryMethod, setDeliveryMethod] = useState(false);
  const [remark, setRemark] = useState("");
  const [orderStatus, setOrderStatus] = useState(0);
  const [attendee, setAttendee] = useState([]);
  const [modalEditAttendee, setModalEditAttendee] = useState(false);
  const [idAttendee, setIDAttendee] = useState("");
  const [firstNameAttendee, setFirstNameAttendee] = useState("");
  const [courseIDAttendee, setCourseIDAttendee] = useState("");
  const [lastNameAttendee, setLastNameAttendee] = useState("");
  const [emailAttendee, setEmailAttendee] = useState("");
  const [phoneAttendee, setPhoneAttendee] = useState("");
  const [editAttendee, setEditAttendee] = useState(initialEditAttendee);

  useEffect(() => {
    if (!localStorage.getItem("token")) history.push("/login");

    var result = localStorage.getItem("user_profile");
    if (result) {
      if (JSON.parse(result).purchase_management === false) {
        history.push("/login");
      }
    }
    console.log(order_number);
    getData(currentPage, order_number);
  }, [order_number]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDeliverDetail({ ...deliverDetail, [name]: value });
  };

  function formatName(item) {
    if (item.last_name) {
      return item.first_name + " " + item.last_name;
    } else {
      return item.first_name;
    }
  }

  const getData = async (currentPage, order_number) => {
    if (order_number) {
      setLoading(true);
      OrderService.getOrderDetail(currentPage, order_number)
        .then((response) => {
          setLoading(false);
          setOrder(response.data.order);
          setDeliverDetail(response.data.deliver_detail);
          setTaxRequest(response.data.order.tax_request);
          setDeliveryMethod(response.data.deliver_detail.delivery_method);
          setRemark(response.data.order.remark);
          setOrderStatus(response.data.order.status);
          setAttendee(response.data.attendee);

          const totalPages = Math.ceil(response.data.total / perPage);
          setTotalPage(totalPages);
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
          Notification.Show(
            "error",
            "From Web Service",
            e.response.data.message
          );
        });
    }
  };

  const ShowNotification = (type, header, message) =>
    notification[type]({
      message: header,
      description: message,
    });

  function ValidateManageOrder() {
    if (!deliverDetail.first_name) {
      Notification.Show("error", "Validation", "กรุณาระบุ ชื่อ");
      return false;
    }

    if (!deliverDetail.last_name) {
      Notification.Show("error", "Validation", "กรุณาระบุ นามสกุล");
      return false;
    }

    if (!deliverDetail.phone_number) {
      Notification.Show("error", "Validation", "กรุณาระบุ เบอร์โทรศัพท์");
      return false;
    }

    if (!deliverDetail.email) {
      Notification.Show("error", "Validation", "กรุณาระบุ Email");
      return false;
    }

    // if (!deliverDetail.address) {
    //     Notification.Show("error", "Validation", "กรุณาระบุ ที่อยู่");
    //     return false;
    // }

    // if (!deliverDetail.sub_district) {
    //     Notification.Show("error", "Validation", "กรุณาระบุ ตำบล/แขวง");
    //     return false;
    // }

    // if (!deliverDetail.district) {
    //     Notification.Show("error", "Validation", "กรุณาระบุ อำเภอ/เขต");
    //     return false;
    // }

    // if (!deliverDetail.province) {
    //     Notification.Show("error", "Validation", "กรุณาระบุ จังหวัด");
    //     return false;
    // }

    // if (!deliverDetail.zip_code) {
    //     Notification.Show("error", "Validation", "กรุณาระบุ รหัสไปรษณีย์");
    //     return false;
    // }

    return true;
  }

  async function manageOrder() {
    // if(ValidateManageOrder() === false)
    //     return;

    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;

    edit.id = order.id;
    edit.users_id = order.users_id;
    edit.order_date = order.order_date;
    edit.order_status = orderStatus;
    edit.order_number = order.order_number;
    edit.payment_methods_id = order.payment_methods_id;
    edit.first_name = deliverDetail.first_name;
    edit.last_name = deliverDetail.last_name;
    edit.phone_number = deliverDetail.phone_number;
    edit.email = deliverDetail.email;
    edit.address_other_info = deliverDetail.address;
    edit.sub_district = deliverDetail.sub_district;
    edit.district = deliverDetail.district;
    edit.province = deliverDetail.province;
    edit.zip_code = deliverDetail.zip_code;
    edit.delivery_cost = deliverDetail.delivery_cost;
    edit.delivery_method = deliveryMethod;
    edit.tax_request = taxRequest;
    edit.remark = remark;

    edit.updated_by = action_by;
    edit.updated_datetime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

    setLoading(true);

    await OrderService.updateOrder(edit)
      .then((response) => {
        setLoading(false);
        Modal.success({
          content: "บันทึกข้อมูล คำสั่งซื้อ เรียบร้อยแล้ว",
          onOk() {
            window.location.reload();
          },
        });

        if (edit.order_status === 4) {
          OrderService.cancelEmail(edit.order_number)
            .then((response) => {
              console.log(response.data);
            })
            .catch((e) => {
              console.log(e);
            });
        }
        getData(currentPage, order_number);
      })
      .catch((e) => {
        setLoading(false);
        console.error(e.response.data.message);
        Notification.Show("error", "Error", e.response.data.message);
      });
  }

  const cancel = () => {
    history.goBack();
  };

  function cancelDialog(e) {}

  const onChangeTaxRequest = (e) => {
    setTaxRequest(e.target.value);
  };

  const handleChangeDeliveryMethod = (e) => {
    setDeliveryMethod(e.target.value);
  };

  const handleChangeOrderStatus = (e) => {
    setOrderStatus(e.target.value);
  };

  function formatDate(date) {
    if (date) {
      return moment(date).utc().format("DD/MM/YYYY HH:mm");
    }
  }

  async function handleOk() {
    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;
    editAttendee.id = idAttendee;
    editAttendee.first_name = firstNameAttendee;
    editAttendee.last_name = lastNameAttendee;
    editAttendee.email = emailAttendee;
    editAttendee.phone_number = phoneAttendee;
    editAttendee.course_id = courseIDAttendee;

    editAttendee.updated_by = action_by;
    editAttendee.updated_datetime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    await CourseService.updateAttendee(editAttendee)
      .then((response) => {
        ShowNotification(
          "success",
          "Success",
          "บันทึกข้อมูลเรียบร้อยแล้ว"
        );
        getData(currentPage, order_number);
        setModalEditAttendee(false);
      })
      .catch((e) => {
        console.error(e.response.data.message);
        Notification.Show(
          "error",
          "Error",
          "เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง"
        );
      });
  }

  const handleCancel = () => {
    setModalEditAttendee(false);
  };

  const showEditAttendee = (item) => {
    console.log(item.course_id)
    setIDAttendee(item.id);
    setFirstNameAttendee(item.first_name);
    setLastNameAttendee(item.last_name);
    setEmailAttendee(item.email);
    setPhoneAttendee(item.phone_number);
    setCourseIDAttendee(item.course_id);
    setModalEditAttendee(true);
  };

  return (
    <div className="container">
      <div className="d-flex mt-4 align-items-stretch">
        <div className="flex-fill">
          <div className="header-bar">
            ข้อมูลรายการสั่งซื้อคอร์ส <span className="ml-1">({order_number})</span>
            <Spin style={{ marginLeft: 10 }} spinning={loading} />
          </div>

          <div className="container-fluid mt-3">
            <div className="row">
              <div className="col-12">
                <div className="form-group mb-3 mt-2">
                  <label>
                    สถานะคำสั่งซื้อ
                    <span className="text-danger">*</span>
                  </label>
                  <div className="mt-2">
                    <Radio.Group
                      onChange={handleChangeOrderStatus}
                      value={orderStatus}
                    >
                      <Radio value={1}>ยังไม่ชำระเงิน</Radio>
                      <Radio value={2}>ชำระเงินแล้ว</Radio>
                      <Radio value={3}>ชำระเงินล้มเหลว</Radio>
                      <Radio value={4}>ยกเลิกคำสั่งซื้อ</Radio>
                    </Radio.Group>
                  </div>
                </div>
              </div>
            </div>

            <>
              {attendee && attendee.length > 0 && (
                <div className="card mt-4" style={{ background: "#f6f6f6" }}>
                  <div className="card-body">
                    <div className="container-fluid">
                      <h6 className="font-weight-bold text-primary">
                        รายชื่อผู้เข้าร่วมสัมนา
                      </h6>

                      <div className="mt-4">
                        <Table dataSource={attendee}>
                        <Column
                            title="ชื่อคอร์ส"
                            dataIndex="course_name"
                            key="course_name"
                            align="center"
                          />
                          <Column
                            title="ชื่อ-นามสกุล"
                            align="center"
                            render={(value) => {
                              return formatName(value);
                            }}
                          />
                          <Column
                            title="เบอร์โทรศัพท์"
                            dataIndex="phone_number"
                            key="phone_number"
                            align="center"
                          />
                          <Column
                            title="Email"
                            dataIndex="email"
                            key="email"
                            align="center"
                          />
                          <Column
                            title="แก้ไขรายละเอียด"
                            render={(value) => {
                              return (
                                <Tooltip title="แก้ไข" className="mr-2">
                                  <Button
                                    type="primary"
                                    shape="circle"
                                    size="small"
                                    className="btn-secondary"
                                    icon={<SearchOutlined />}
                                    onClick={() => showEditAttendee(value)}
                                  />
                                </Tooltip>
                              );
                            }}
                            align="center"
                          />
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>

            {/* <div className="row">
              <div className="col-12">
                <div className="form-group mb-3 mt-2">
                  <label>
                    ชื่อ
                    <span className="text-danger">*</span>
                  </label>
                  <Input
                    id="first_name"
                    required
                    value={deliverDetail.first_name}
                    onChange={handleInputChange}
                    name="first_name"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group mb-3">
                  <label>
                    นามสกุล
                    <span className="text-danger">*</span>
                  </label>
                  <Input
                    id="last_name"
                    value={deliverDetail.last_name}
                    onChange={handleInputChange}
                    name="last_name"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group mb-3">
                  <label>
                    เบอร์โทรศัพท์
                    <span className="text-danger">*</span>
                  </label>
                  <Input
                    id="phone_number"
                    required
                    value={deliverDetail.phone_number}
                    onChange={handleInputChange}
                    name="phone_number"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group mb-3">
                  <label>
                    Email
                    <span className="text-danger">*</span>
                  </label>
                  <Input
                    id="email"
                    value={deliverDetail.email}
                    onChange={handleInputChange}
                    name="email"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group mb-3">
                  <label>
                    ที่อยู่
                    <span className="text-danger"></span>
                  </label>
                  <Input
                    id="address"
                    value={deliverDetail.address}
                    onChange={handleInputChange}
                    name="address"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group mb-3">
                  <label>
                    ตำบล/แขวง
                    <span className="text-danger"></span>
                  </label>
                  <Input
                    id="sub_district"
                    value={deliverDetail.sub_district}
                    onChange={handleInputChange}
                    name="sub_district"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group mb-3">
                  <label>
                    อำเภอ/เขต
                    <span className="text-danger"></span>
                  </label>
                  <Input
                    id="district"
                    value={deliverDetail.district}
                    onChange={handleInputChange}
                    name="district"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group mb-3">
                  <label>
                    จังหวัด
                    <span className="text-danger"></span>
                  </label>
                  <Input
                    id="province"
                    value={deliverDetail.province}
                    onChange={handleInputChange}
                    name="province"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group mb-3">
                  <label>
                    รหัสไปรษณีย์
                    <span className="text-danger"></span>
                  </label>
                  <Input
                    id="zip_code"
                    value={deliverDetail.zip_code}
                    onChange={handleInputChange}
                    name="zip_code"
                  />
                </div>
              </div>
            </div> */}

            <div className="row mt-4">
              <div className="col-12">
                <div className="form-group mb-2 mt-2">
                  <label>
                    ขอใบกำกับภาษี
                    <span className="text-danger"></span>
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group mb-3">
                  <Radio.Group onChange={onChangeTaxRequest} value={taxRequest}>
                    <Radio value={true}>ขอใบกำกับภาษี</Radio>
                    <Radio value={false}>ไม่ได้ขอใบกำกับภาษี</Radio>
                  </Radio.Group>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group mb-2 mt-2">
                  <label>วิธีการจัดส่ง</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group mb-3">
                  <Radio.Group
                    onChange={handleChangeDeliveryMethod}
                    value={deliveryMethod}
                  >
                    <Radio value="kerry">Kerry</Radio>
                    <Radio value="ems">Ems</Radio>
                    <Radio value="postal">ลงทะเบียน</Radio>
                    <Radio value="self pickup">รับด้วยตัวเอง</Radio>
                    <Radio value="">N/A</Radio>
                  </Radio.Group>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group mb-3 mt-2">
                  <label>
                    ค่าจัดส่ง
                    <span className="text-danger"></span>
                  </label>
                  <Input
                    id="delivery_cost"
                    value={deliverDetail.delivery_cost}
                    onChange={handleInputChange}
                    name="delivery_cost"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group mb-3">
                  <label>หมายเหตุ</label>
                  <TextArea
                    rows={4}
                    id="remark"
                    value={remark}
                    name="remark"
                    onChange={(e) => setRemark(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group mb-3">
                  <label>
                    Update By
                    <span className="text-danger"></span>
                  </label>
                  <Input
                    id="updated_by"
                    style={{ background: "#ececec" }}
                    value={order.updated_by}
                    readOnly
                    name="updated_by"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group mb-3">
                  <label>
                    Update Date
                    <span className="text-danger"></span>
                  </label>
                  <Input
                    id="updated_datetime"
                    style={{ background: "#ececec" }}
                    value={formatDate(order.updated_datetime)}
                    readOnly
                    name="updated_datetime"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="แก้ไขข้อมูลผู้เข้าสัมนา"
        visible={modalEditAttendee}
        width={550}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="d-flex">
          <div className="form-group mb-3 mt-2 ml-4">
            <div className="row">
              <div className="col-6">
                <label>
                  ชื่อ
                  <span className="text-danger">*</span>
                </label>
                <Input
                  value={firstNameAttendee}
                  onChange={(e) => setFirstNameAttendee(e.target.value)}
                />
              </div>
              <div className="col-6">
                <label>
                  นามสกุล
                  <span className="text-danger">*</span>
                </label>
                <Input
                  value={lastNameAttendee}
                  onChange={(e) => setLastNameAttendee(e.target.value)}
                />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-6">
                <label>
                  เบอร์โทรศัพท์
                  <span className="text-danger">*</span>
                </label>
                <Input
                  value={phoneAttendee}
                  onChange={(e) => setPhoneAttendee(e.target.value)}
                />
              </div>
              <div className="col-6">
                <label>
                  อีเมลล์
                  <span className="text-danger">*</span>
                </label>
                <Input
                  value={emailAttendee}
                  onChange={(e) => setEmailAttendee(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <div className="col-12 text-right">
        <Button className="btn-secondary mr-2" onClick={cancel}>
          ย้อนกลับ
        </Button>
        <Button className="btn-primary" onClick={manageOrder}>
          บันทึกข้อมูล
        </Button>
        <Spin style={{ marginLeft: 10 }} spinning={loading} />
      </div>
    </div>
  );
};

export default ManageOrder;