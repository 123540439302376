import React, { useCallback, useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Table, Button, Column, notification } from "antd";
import update from "immutability-helper";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import BookService from "../services/BookService";
import { Reorder } from "@material-ui/icons";
import Notification from "../utils/Notification";
import moment from "moment";

const type = "DraggableBodyRow";
const DraggableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}) => {
  const ref = useRef(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: {
      index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{
        cursor: "move",
        ...style,
      }}
      {...restProps}
    />
  );
};

const PreorderBook = () => {
  const [data, setData] = useState([]);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const { Column } = Table;

  useEffect(() => {
    if (!localStorage.getItem("token")) history.push("/login");

    var result = localStorage.getItem("user_profile");
    if (result) {
      if (JSON.parse(result).book === false) {
        history.push("/login");
      }
    }

    getData(currentPage);
  }, [0]);

  const getData = async (page) => {
    setLoading(true);

    await BookService.getPreorderBook(page)
      .then((res) => {
        setLoading(false);
        const response = res.data;
        // const totalPages = Math.ceil(reponse.total / perPage);
        // setTotalPage(totalPages);
        setData(response.books);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const ShowNotification = (type, header, message) =>
    notification[type]({
      message: header,
      description: message,
    });

  const ReOrderData = async (data) => {
    setLoading(true);

    await BookService.reorderBook(data)
      .then((res) => {
        ShowNotification(
          "success",
          "Success",
          "บันทึกข้อมูลการจัดเรียงหนังสือ Pre-order เรียบร้อยแล้ว"
        );
        setLoading(false);
        getData(currentPage);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
        Notification.Show("error", "Validation", e.response.data.message);
      });
  };

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };
  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex];
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [data]
  );

  async function saveReOrder() {
    let tempOrder;

    tempOrder = data.map((item, index) => {
      return {
        ...item,
        book_order: index + 1,
      };
    });

    setData(tempOrder);

    await ReOrderData(tempOrder);
  }

  function formatDate(date) {
    if (date) {
      return moment(date).utc().format("DD/MM/YYYY HH:mm");
    }
  }

  return (
    <div className="container">
      <h5 className="mt-2 font-weight-bold">จัดเรียงหนังสือ Pre-Order</h5>
      <div className="line-highlight">
        <hr />
      </div>
      <div>
        <span className="ml-2">
          สามารถตั้งค่าหนังสือเป็น Pre-Order ได้ที่เมนู "รายการหนังสือ"
        </span>
      </div>
      <div className="text-right container-fluid">
        <Button className="btn-primary px-3 mr-4" onClick={saveReOrder}>
          บันทึกการจัดเรียง
        </Button>
      </div>
      <div className="ml-5 mr-5 mt-3">
        <DndProvider backend={HTML5Backend}>
          <Table
            dataSource={data}
            pagination={false}
            components={components}
            onRow={(_, index) => {
              const attr = {
                index,
                moveRow,
              };
              return attr;
            }}
          >
            <Column
              title="ลำดับที่"
              dataIndex="book_order"
              key="book_order"
              align="center"
            />
            
            <Column
              title="ชื่อหนังสือ"
              dataIndex="cover_image_url"
              key="cover_image_url"
              align="left"
              render={(_, record) => {
                return (
                  <>
                    <img
                      width={40}
                      src={record.cover_image_url}
                      className="img-fluid"
                    />
                    <span className="ml-3">{record.book_name}</span>{" "}
                  </>
                );
              }}
            />
            <Column
              title="ชื่อผู้เขียน"
              dataIndex="writer_name"
              key="writer_name"
              align="left"
            />
            <Column
              title="วันสุดท้ายที่ Pre-Order"
              dataIndex="last_preorder_datetime"
              key="last_preorder_datetime"
              align="center"
              render={(value) => {
                return formatDate(value);
              }}
            />
          </Table>
        </DndProvider>
      </div>
    </div>
  );
};
export default PreorderBook;
