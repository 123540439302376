import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Input,
  Button,
  Popconfirm,
  Switch,
  Modal,
  notification,
  Select,
  Card,
  DatePicker,
  Spin,
} from "antd";
import ArticleService from "../services/ArticleService";
import UtilityService from "../services/UtilityService";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "2read-ckeditor5-custom-build";
import imageCompression from "browser-image-compression";
import MyUploadAdapter from "../utils/MyUploadAdapter";
import { PictureOutlined } from "@ant-design/icons";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Notification from "../utils/Notification";
import "moment/locale/th";

const { Option } = Select;

const ManageArticle = (props) => {
  const history = useHistory();
  const initialArticleState = {
    article_id: null,
    writer_id: "",
    name: "",
    categorys: [],
    cover_image_url: "",
    detail: "",
    is_line_today: false,
    publish_datetime: null,
    created_datetime: null,
    created_by: null,
    updated_datetime: null,
    updated_by: null,
  };

  const { article_id } = useParams();
  const [article, setArticle] = useState(initialArticleState);
  const [submitted, setSubmitted] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [cropperRef, setCropperRef] = useState(React.createRef());
  const [articleImage, setArticleImage] = useState();
  const [status, setStatus] = useState(true);
  const [action, setAction] = useState();
  const [writer, setWriter] = useState("");
  const [writer_id, setWriterID] = useState("");
  const [publish_flag, setPublishFlag] = useState(false);
  const [dateFormat, setDateFormat] = useState("DD-MM-YYYY HH:mm");
  const [publishDate, setPublishDate] = useState();
  const [categorys, setCategorys] = useState("");
  const [categorySelect, setCategorySelect] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [isLineToday, setIsLineToday] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!localStorage.getItem("token")) history.push("/login");

    var result = localStorage.getItem("user_profile");
    if (result) {
      if (JSON.parse(result).article === false) {
        history.push("/login");
      }
    }

    setAction(props.action);
    getArticleCategory();
    getAllWriter();
    if (props.action === "update") getArticle(article_id);
  }, [article_id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setArticle({ ...article, [name]: value });
  };

  const getArticle = (article_id) => {
    if (article_id) {
      ArticleService.get(article_id)
        .then((response) => {
          console.log(response.data);

          //  setPublishDate(response.data.publish_datetime);

          if (response.data.publish_datetime) {
            response.data.publish_datetime = moment(
              response.data.publish_datetime
            )
              .utc()
              .format("DD-MM-YYYY HH:mm");
          }

          setArticle(response.data);

          setIsLineToday(response.data.is_line_today);

          setWriterID(response.data.writer_id);

          const category_id = [];
          for (let i = 0; i < response.data.categorys.length; i++) {
            if (response.data.categorys[i].status === "A") {
              category_id.push(response.data.categorys[i].category_id);
            }
          }
          setCategorys(category_id);

          setArticleImage(response.data.cover_image_url);
          if (response.data.status === "A") setStatus(true);
          else setStatus(false);

          if (response.data.publish_datetime) {
            setPublishFlag(true);
          } else setPublishFlag(false);
        })
        .catch((e) => {
          console.log(e);
          Notification.Show(
            "error",
            "From Web Service",
            e.response.data.message
          );
        });
    }
  };

  const getArticleCategory = async () => {
    await ArticleService.getArticleCategory()
      .then((res) => {
        const reponse = res.data;
        setCategorySelect(reponse.category);
        console.log();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getAllWriter = async () => {
    await UtilityService.getAllWriterSelect()
      .then((res) => {
        const reponse = res.data;
        console.log(res.data);
        setWriter(reponse.writers);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const ShowNotification = (type, header, message) =>
    notification[type]({
      message: header,
      description: message,
    });

  async function AddUpdateArticle() {
    if (!writer_id) {
      Notification.Show("error", "Validation", "กรุณาระบุ บทความโดย(นักเขียน)");
      return;
    }

    if (categorys.length === 0) {
      Notification.Show("error", "Validation", "กรุณาระบุ หมวดหมู่");
      return;
    }

    if (!article.name) {
      Notification.Show("error", "Validation", "กรุณาระบุ ชื่อบทความ");
      return;
    }

    if (!article.detail) {
      Notification.Show("error", "Validation", "กรุณาระบุ รายละเอียดบทความ");
      return;
    }

    if (publish_flag) {
      if(action === "add"){
        if (!publishDate) {
          Notification.Show("error", "Validation", "กรุณาระบุ วันที่เผยแพร่");
          return;
        }
      }
      else
      {
        if (!article.publish_datetime) {
          Notification.Show("error", "Validation", "กรุณาระบุ วันที่เผยแพร่");
          return;
        }
      }
    }

    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;

    if (action === "add") {
      if (imageFile) {
        await uploadImage(imageFile);
      } else article.cover_image_url = "";

      article.created_by = action_by;
      article.created_datetime = moment(new Date()).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    } else {
      if (imageFile) {
        await uploadImage(imageFile);
      } else article.cover_image_url = articleImage;
    }

    if (!article.cover_image_url) {
      Notification.Show("error", "Validation", "กรุณาระบุ อัพโหลดรูป บทความ");
      return false;
    }

    article.writer_id = writer_id;

    if (categorys) {
      article.categorys = categorys.map((item) => {
        return {
          category_id: item,
          status: "A",
          created_by: action_by,
          created_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          updated_by: action_by,
          updated_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        };
      });
    }

    if (status) article.status = "A";
    else article.status = "I";

    if (publish_flag && publishDate) {
      article.publish_datetime = new Date(publishDate);

      const startDate = article.publish_datetime.getDate();
      const startMonth = article.publish_datetime.getMonth();
      const startYear = article.publish_datetime.getFullYear();

      article.publish_datetime.setDate(startDate);
      article.publish_datetime.setMonth(startMonth);
      article.publish_datetime.setFullYear(startYear);

      article.publish_datetime = moment(article.publish_datetime)
        .locale("th")
        .format("YYYY-MM-DD HH:mm:00");
    } else {
      if (action === "add") {
        article.publish_datetime = moment(new Date()).format(
          "YYYY-MM-DD HH:mm:ss"
        );
      } else
        article.publish_datetime = moment(article.publish_datetime, dateFormat).utc(+7);
    }

    isLineToday
      ? (article.is_line_today = true)
      : (article.is_line_today = false);

    article.updated_by = action_by;
    article.updated_datetime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

    console.log(article.writer);
    setLoading(true);
    if (action === "add") {
      ArticleService.create(article)
        .then((response) => {
          ShowNotification(
            "success",
            "Success",
            "บันทึกข้อมูลบทความเรียบร้อยแล้ว"
          );
          setLoading(false);
          setSubmitted(true);
          history.push("/article");
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
          Notification.Show("error", "Error", e.response.data.message);
        });
    } else {
      ArticleService.update(article.article_id, article)
        .then((response) => {
          ShowNotification(
            "success",
            "Success",
            "บันทึกข้อมูลบทความเรียบร้อยแล้ว"
          );
          setLoading(false);
          history.push("/article");
        })
        .catch((e) => {
          console.error(e.response.data.message);
          setLoading(false);
          Notification.Show("error", "Error", e.response.data.message);
        });
    }
  }

  const deleteArticle = () => {
    setLoadingDelete(true);
    ArticleService.remove(article.article_id)
      .then((response) => {
        setLoadingDelete(false);
        Notification.Show("success", "Success", "ลบข้อมูลบทความเรียบร้อยแล้ว");
        history.push("/article");
      })
      .catch((e) => {
        setLoadingDelete(false);
        Notification.Show("error", "Error", e.response.data.message);
      });
  };

  const cancel = () => {
    history.push("/article");
  };

  function confirmDialog(e) {
    deleteArticle();
  }

  function cancelDialog(e) {}

  function customUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      // Configure the URL to the upload script in your back-end here!
      return new MyUploadAdapter(loader);
    };
  }

  function onSelectFile(e, type) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      //reader.addEventListener("load", () => setArticleImage(reader.result));
      reader.addEventListener("load", () => setImageUrl(reader.result));
      reader.readAsDataURL(e.target.files[0]);

      setCropModalOpen(true);
      setImageFile(e.target.files[0]);
    }
  }

  function cropImage() {
    const cropper = cropperRef.current.cropper;

    cropper.getCroppedCanvas().toBlob((blob) => {
      const fileName = imageFile.name;

      const file = new File([blob], fileName, {
        lastModifiedDate: new Date(),
        type: imageFile.type,
      });
      const cropppedPreview = cropper.getCroppedCanvas().toDataURL();
      setArticleImage(cropppedPreview);
      setImageFile(file);
      closeCropModal();
    });
  }

  function closeCropModal() {
    setCropModalOpen(false);
  }

  function removeImage() {
    setArticleImage("");
    setImageUrl("");
  }

  async function uploadImage(image) {
    const options = {
      maxSizeMB: 2,
      useWebWorker: true,
      initialQuality: 1,
    };
    try {
      const compressedFile = await imageCompression(image, options);
      const file = new File([compressedFile], image.name, { type: image.type });

      if (image) {
        let formData = new FormData();
        let imagefile = file;

        formData.append("upload", imagefile);

        await UtilityService.uploadImage(formData)
          .then((response) => {
            article.cover_image_url = response.data.url;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } catch (error) {
      console.log(error);
    }
  }

  function onChangeStatus(checked) {
    setStatus(checked);
  }

  function onChangePublishFlag(checked) {
    setPublishFlag(checked);
  }

  const onChangeWriterID = (writer_id, e) => {
    setWriterID(writer_id);
  };

  function onChangeCategory(value) {
    setCategorys(value);
  }

  function handleDatePickerChange(date, dateString) {
    setPublishDate(date);
  }

  function onChangeLineToday(checked) {
    setIsLineToday(checked);
  }

  return (
    <div className="container">
      <div className="d-flex mt-4 align-items-stretch">
        <div className="flex-fill">
          <div className="header-bar">ข้อมูลบทความ</div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4"></div>

              <div className="col-sm-4">
                <div className="position-relative my-2 mt-4">
                  {articleImage ? (
                    <img src={articleImage} className="img-fluid" />
                  ) : (
                    <div
                      className="image-placeholder d-flex flex-column align-items-center justify-content-center mt-5"
                      style={{ height: 240 }}
                    >
                      <div>
                        <PictureOutlined
                          style={{ fontSize: 30, color: "#bbb" }}
                        />
                      </div>
                      <div className="font-12">อัพโหลดรูป</div>
                    </div>
                  )}

                  <input
                    type="file"
                    accept="image/*"
                    className="input-file-hidden"
                    onChange={(event) => {
                      onSelectFile(event, "article");
                    }}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                  />
                </div>
                {articleImage && (
                  <div className="text-right mt-2">
                    <Button
                      className="btn btn-sm btn-danger"
                      onClick={() => removeImage()}
                    >
                      ลบ
                    </Button>
                  </div>
                )}
                <div className="text-right text-danger">
                  (ขนาดรูปที่แนะนำ 750 x 418 px)
                </div>
              </div>

              <div className="col-sm-4">
                <div className="text-right mt-3">
                  <Switch
                    onChange={onChangeStatus}
                    checkedChildren="Active"
                    unCheckedChildren="InActive"
                    checked={status}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group mb-3 mt-2">
                  <label>
                    ชื่อบทความ
                    <span className="text-danger">*</span>
                  </label>
                  <Input
                    id="name"
                    required
                    value={article.name}
                    onChange={handleInputChange}
                    name="name"
                  />
                </div>

                <div className="form-group mb-3 mt-4">
                  <label>
                    บทความโดย(นักเขียน) <span className="text-danger">*</span>
                  </label>
                  <Select
                    defaultValue=""
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={writer_id}
                    className="w-100"
                    onSelect={(value, event) => onChangeWriterID(value, event)}
                  >
                    {writer &&
                      writer.map((item, index) => {
                        return (
                          <Option value={item.writer_id}>{item.name}</Option>
                        );
                      })}
                  </Select>
                </div>

                <div className="form-group mb-3 mt-4">
                  <label>
                    หมวดหมู่ <span className="text-danger">*</span>
                  </label>
                  {categorys && (
                    <Select
                      defaultValue={categorys}
                      className="w-100"
                      mode="multiple"
                      allowClear
                      onChange={onChangeCategory}
                      placeholder="เลือกหมวดหมู่"
                    >
                      {/* <Option value="">เลือกหมวดหมู่</Option> */}
                      {categorySelect &&
                        categorySelect.map((item, index) => {
                          return (
                            <Option value={item.category_id}>
                              {item.name}
                            </Option>
                          );
                        })}
                    </Select>
                  )}

                  {!categorys && (
                    <Select
                      className="w-100"
                      mode="multiple"
                      allowClear
                      onChange={onChangeCategory}
                      placeholder="เลือกหมวดหมู่"
                    >
                      {categorySelect &&
                        categorySelect.map((item, index) => {
                          return (
                            <Option value={item.category_id}>
                              {item.name}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label>
                    รายละเอียดบทความ<span className="text-danger">*</span>
                  </label>
                  <div className="content-wrapper">
                    <CKEditor
                      editor={ClassicEditor}
                      data={article.detail}
                      config={{
                        extraPlugins: [customUploadAdapterPlugin],
                        indentBlock: {
                          offset: 1,
                          unit: "em",
                        },
                        image: {
                          resize: true,
                          toolbar: [
                            "linkImage",
                            "|",
                            "imageTextAlternative",
                            "|",
                            "imageStyle:full",
                            "imageStyle:alignLeft",
                            "imageStyle:alignCenter",
                            "imageStyle:alignRight",
                          ],
                          styles: [
                            "full",
                            "alignCenter",
                            "alignLeft",
                            "alignRight",
                          ],
                        },
                      }}
                      onInit={(editor) => {}}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        article.detail = data;
                      }}
                      onBlur={(event, editor) => {}}
                      onFocus={(event, editor) => {}}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="header-bar-green mt-4 ml-2 mr-2">
              <Switch onChange={onChangeLineToday} checked={isLineToday} />
              <label className="ml-3 mt-1">เพิ่มบทความนี้ใน Line Today</label>
            </div>

            <div className="header-bar mt-4 ml-2 mr-2">
              <Switch onChange={onChangePublishFlag} checked={publish_flag} />
              <label className="ml-3 mt-1 font-smaller">
                ตั้งวันที่ / เวลา เผยแพร่
              </label>
            </div>
            <div className="row mt-2">
              <div className="col-12">
                {publish_flag && (
                  <Card style={{ marginLeft: 10, marginRight: 5 }}>
                    <div className="row">
                      <div className="form-group mb-4">
                        <label>
                          วันที่เผยแพร่
                          <span className="text-danger">*</span>
                        </label>

                        {action === "update" && article.publish_datetime ? (
                          <DatePicker
                            picker="date"
                            defaultValue={moment(
                              article.publish_datetime,
                              dateFormat
                            )}
                            onChange={(date, dateString) =>
                              handleDatePickerChange(date, dateString)
                            }
                            className="ml-2"
                            showTime
                            format="DD-MM-YYYY HH:mm"
                            disabledDate={(current) => {
                              return moment().add(-1, "days") >= current;
                            }}
                          />
                        ) : (
                          <DatePicker
                            picker="date"
                            showTime
                            format="DD-MM-YYYY HH:mm"
                            className="ml-2"
                            disabledDate={(current) => {
                              return moment().add(-1, "days") >= current;
                            }}
                            onChange={(date, dateString) =>
                              handleDatePickerChange(date, dateString)
                            }
                          />
                        )}
                      </div>
                    </div>
                  </Card>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-2">
          <div>
            {action === "update" && (
              <Popconfirm
                title="ต้องการลบเนื้อหานี้ออกจากเว็บไซต์?"
                onConfirm={confirmDialog}
                onCancel={cancelDialog}
                okText="ยืนยันการลบ"
                cancelText="ยกเลิก"
              >
                <Button className="btn-danger px-3">ลบข้อมูล</Button>
                <Spin spinning={loadingDelete} />
              </Popconfirm>
            )}
          </div>
        </div>

        <div className="col-10 text-right">
          <Button className="btn-secondary mr-2" onClick={cancel}>
            ยกเลิก
          </Button>
          <Button className="btn-primary" onClick={AddUpdateArticle}>
            บันทึกข้อมูล
          </Button>
          <Spin style={{ marginLeft: 10 }} spinning={loading} />
        </div>
      </div>

      <Modal
        title={null}
        footer={null}
        width={800}
        maskClosable={false}
        visible={cropModalOpen}
        onCancel={() => setCropModalOpen(false)}
      >
        <div className="d-flex">
          <div className="flex-fill thumbnail-crop-area">
            <Cropper
              src={imageUrl}
              //aspectRatio={750 / 418}
              autoCropArea={1}
              preview=".book-preview"
              viewMode={3}
              ref={cropperRef}
            />
          </div>

          <div className="mt-4 book-preview-area ml-4">
            <div className="font-weight-bold text-center mb-2">ตัวอย่างรูป</div>

            <div className="book-preview" />
          </div>
        </div>

        <div className="mt-5 text-center">
          <button
            type="button"
            onClick={() => {
              cropImage();
            }}
            className="btn btn-primary mr-2"
          >
            ตกลง
          </button>
          <button
            type="button"
            className="btn btn-secondary mr-2"
            onClick={() => {
              setCropModalOpen(false);
            }}
          >
            ยกเลิก
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ManageArticle;
