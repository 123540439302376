import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import CourseService from "../services/CourseService";
import { Input, Select, Button, Tag, Spin,Switch } from "antd";

import { PictureOutlined } from "@ant-design/icons";
import ContentPagination from "../utils/Pagination";

const { Option } = Select;

export default function Course() {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(5);
  const [searchCourseName, setSearchCourseName] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [searchLevel, setSearchLevel] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPage] = useState(1);
  const [courseCategory, setCourseCategory] = useState("");
  const [courseLevel, setCourseLevel] = useState("");
  const [loading, setLoading] = useState(false);
  const [nft_only, setNftOnly] = useState(false);
  const [courseSummary, setCourseSummary] =  useState([]);


  useEffect(() => {
    if (!localStorage.getItem("token")) history.push("/login");

    var result = localStorage.getItem("user_profile");
    if (result) {
      if (JSON.parse(result).course === false) {
        history.push("/login");
      }
    }

    getCourseSummaryRegister();

    getData(currentPage, searchCourseName, searchCategory, searchLevel,nft_only);
    getCourseCategory();
    getCourseLevel();
  }, [0]);

  const getCourseSummaryRegister = async () => {
    await CourseService.getCourseSummaryRegister()
      .then((res) => {
        const response = res.data;
        setCourseSummary(response);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getCourseCategory = async () => {
    await CourseService.getCourseCategory()
      .then((res) => {
        const response = res.data;
        setCourseCategory(response.course_category);
        console.log();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getCourseLevel = async () => {
    await CourseService.getCourseLevel()
      .then((res) => {
        const response = res.data;
        setCourseLevel(response.course_level);
        console.log();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getData = async (
    page,
    filterCourseName,
    filterCategory,
    filterLevel,
    filterNftOnly,
  ) => {
    setLoading(true);

    let filter_nft_only = "";

    if(filterNftOnly === true)
      filter_nft_only = "Y";

    await CourseService.getAll(
      page,
      filterCourseName,
      filterCategory,
      filterLevel,
      filter_nft_only
    )
      .then((res) => {
        setLoading(false);
        const response = res.data;

        const totalPages = Math.ceil(response.total / perPage);
        setTotalPage(totalPages);
        setData(response.courses);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });

      // for(let i=0;i<data.length;i++){
      //   let vijiko = await getCourseSummaryTotal(data[i].course_id);
      //   console.log(vijiko)
      //  }
  };


  function changePage(page) {
    setCurrentPage(page);
    getData(page, searchCourseName, searchCategory, searchLevel,nft_only);
  }

  const onChangeSearchCourseName = (e) => {
    const searchCourseName = e.target.value;
    setSearchCourseName(searchCourseName);
    getData(currentPage, searchCourseName, searchCategory, searchLevel,nft_only);
  };

  const onChangeSearchCategoryID = (category, e) => {
    setSearchCategory(category);
    getData(currentPage, searchCourseName, category, searchLevel,nft_only);
  };

  const onChangeSearchLevel = (level, e) => {
    setSearchLevel(level);
    getData(currentPage, searchCourseName, searchCategory, level,nft_only);
  };

  function onChangeNftOnly(checked) {
    setNftOnly(checked);

    if(checked)
      getData("", searchCourseName, searchCategory, searchLevel,true);
    else
      getData("", searchCourseName, searchCategory, searchLevel,false);
  }

  function formatCategory(category) {
    if (courseCategory.length > 0) {
      for (let i = 0; i < courseCategory.length; i++) {
        if (courseCategory[i].id === category) {
          return <Tag color="blue">{courseCategory[i].name}</Tag>;
          break;
        }
      }
    }
  }

  function formatCourseLevel(level) {
    if (courseLevel.length > 0) {
      for (let i = 0; i < courseLevel.length; i++) {
        if (courseLevel[i].id == level) {
          return <Tag color="gold">{courseLevel[i].name}</Tag>;
          break;
        }
      }
    }
  }

  function formatCountRegister(course_id) {
    let output = "0";
    if (courseSummary.length > 0) {
      for (let i = 0; i < courseSummary.length; i++) {
        if (courseSummary[i].course_id == course_id) {
          output = courseSummary[i].count_item;
          break;
        }
      }
    }
    return output;
  }

  function formatAmountRegister(course_id) {
    let output = "0";
    if (courseSummary.length > 0) {
      for (let i = 0; i < courseSummary.length; i++) {
        if (courseSummary[i].course_id == course_id) {
          output = new Intl.NumberFormat("en-GB", {
            style: "decimal",
          }).format(courseSummary[i].sum_amount) + " บาท";
          break;
        }
      }
    }
    return output;
  }

  return (
    <div className="container">
      <div className="text-right container-fluid">
        <Button
          className="btn-primary px-3"
          onClick={() => history.push("/manage/course/add")}
        >
          เพิ่มคอร์สออนไลน์
        </Button>
      </div>

      <h5 className="mt-2 font-weight-bold">
        รายการคอร์สออนไลน์
        <Spin style={{ marginLeft: 10 }} spinning={loading} />
      </h5>
      <div className="line-highlight">
        <hr />
      </div>

      <div className="flex-fill">
        <div className="row mt-4 pt-3">
          <div className="col-sm-4">
            <div className="form-group">
              <label>ชื่อคอร์ส</label>
              <Input
                placeholder="ค้นหาด้วย ชื่อคอร์สออนไลน์"
                value={searchCourseName}
                onChange={onChangeSearchCourseName}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>หมวดหมู่</label>
              <Select
                defaultValue=""
                className="w-100"
                onSelect={(value, event) =>
                  onChangeSearchCategoryID(value, event)
                }
              >
                <Option value="">เลือกหมวดหมู่</Option>
                {courseCategory &&
                  courseCategory.map((item, index) => {
                    return <Option value={item.id}>{item.name}</Option>;
                  })}
              </Select>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>ระดับผู้เรียน</label>
              <Select
                defaultValue=""
                className="w-100"
                onSelect={(value, event) => onChangeSearchLevel(value, event)}
              >
                <Option value="">เลือกระดับผู้เรียน</Option>
                {courseLevel &&
                  courseLevel.map((item, index) => {
                    return <Option value={item.id}>{item.name}</Option>;
                  })}
              </Select>
            </div>
          </div>
        </div>

        <div className="mt-2">
          <Switch onChange={onChangeNftOnly} checked={nft_only} />
          <label className="ml-3 mt-1">กรองเฉพาะคอร์สสำหรับผู้ถือ NFT เท่านั้น</label>
        </div>

        {data &&
          data.map((item, index) => {
            return (
              <div className="card mt-2">
                <div className="card-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 text-right text-grey font-12 ml-0 mr-0 pl-0 pr-0">
                        {item.nft_only === true &&
                          <Tag className="mr-2"
                          style={{ fontSize: "14px", padding: 7 }}
                          color="red"
                        >
                          สำหรับผู้ถือ NFT เท่านั้น
                          </Tag>
                        }
                        {item.status === "A" ? (
                          <Tag
                            style={{ fontSize: "16px", padding: 7 }}
                            color="green"
                          >
                            Active
                          </Tag>
                        ) : (
                          <Tag
                            style={{ fontSize: "16px", padding: 7 }}
                            color="red"
                          >
                            InActive
                          </Tag>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-5 col-sm-3">
                        <div>
                          <div className="position-relative my-2 pb-4">
                            {item.cover_image ? (
                              <div>
                                <img
                                  src={item.cover_image}
                                  className="img-fluid"
                                />
                              </div>
                            ) : (
                              <div className="image-placeholder d-flex flex-column align-items-center justify-content-center">
                                <div>
                                  <PictureOutlined
                                    style={{ fontSize: 30, color: "#bbb" }}
                                  />
                                </div>
                                <div className="font-12">Course Image</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-7 col-sm-9">
                        <div className="d-flex align-items-center ml-4">
                          <span className="align-title-small font-weight-bold">
                            ชื่อคอร์ส:
                          </span>
                          <span className="text-left ml-4">
                            {item.course_name}
                          </span>
                        </div>

                        <div className="d-flex mt-3 ml-4">
                          <span className="align-title-small font-weight-bold">
                            หมวดหมู่ :
                          </span>
                          <span className="text-left ml-4">
                            {formatCategory(item.category_id)}
                          </span>
                        </div>

                        <div className="d-flex mt-3 ml-4">
                          <span className="align-title-small font-weight-bold">
                            วันสัมนา :
                          </span>
                          <span className="text-left ml-4">
                            {item.seminar_day}
                          </span>
                        </div>

                        <div className="d-flex mt-3 ml-4">
                          <span className="align-title-small font-weight-bold">
                            เวลาเรียน :
                          </span>
                          <span className="text-left ml-4">
                            {item.study_time}
                          </span>
                        </div>

                        <div className="d-flex mt-3 ml-4">
                          <span className="align-title-small font-weight-bold">
                            ระดับผู้เรียน :
                          </span>
                          <span className="text-left ml-4">
                            {formatCourseLevel(item.level_id)}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="d-flex ml-4 mt-2">
                          <span className="font-weight-bold text-primary">
                            จำนวนที่ชำระเงินแล้ว :
                          </span>
                          <span className="text-left ml-3">
                            {formatCountRegister(item.course_id)}
                          </span>
                      </div>
                      <div className="d-flex ml-4 mt-2">
                          <span className="font-weight-bold text-primary">
                            จำนวนเงินรวม :
                          </span>
                          <span className="text-left ml-3">
                            {formatAmountRegister(item.course_id)}
                          </span>
                      </div>
                      <div className="d-flex ml-3">
                        <Button
                          className="btn-primary"
                          onClick={() => history.push("/report/course/" + item.course_id)}
                        >
                          ดูรายงานการสมัครคอร์ส
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className="text-right mt-2">
                    <Button
                      className="btn-secondary mr-2"
                      onClick={() =>
                        history.push("/manage/course/update/" + item.course_id)
                      }
                    >
                      ดูรายละเอียด/แก้ไข
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}

        <div className="text-right mt-4">
          {data && data.length > 0 && (
            <ContentPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={changePage.bind(this)}
              pageShow={5}
            />
          )}
        </div>
      </div>
    </div>
  );
}
