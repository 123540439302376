import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import WriterService from "../services/WriterService";
import { Input, Button, Tooltip, Tag, Spin } from "antd";
import { EditOutlined, UserOutlined } from "@ant-design/icons";
import ContentPagination from "../utils/Pagination";

export default function Writer() {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [searchWriter, setSearchWriter] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData(currentPage, searchWriter);
  }, [0]);

  const getData = async (page, filterName) => {
    setLoading(true);
    await WriterService.getAll(page, filterName)
      .then((res) => {
        setLoading(false);
        const reponse = res.data;

        const totalPages = Math.ceil(reponse.total / perPage);
        setTotalPage(totalPages);
        setData(reponse.writers);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  function changePage(page) {
    setCurrentPage(page);
    getData(page, searchWriter);
  }

  const onChangeSearchWriter = (e) => {
    const searchWriter = e.target.value;
    setSearchWriter(searchWriter);
    getData(currentPage, searchWriter);
  };

  return (
    <div className="container">
      <div className="text-right container-fluid">
        <Button
          className="btn-primary px-5"
          size=""
          onClick={() => history.push("/manage/writer/add")}
        >
          เพิ่มนักเขียน
        </Button>
      </div>

      <h5 className="mt-2 font-weight-bold">
        รายชื่อนักเขียน <Spin style={{ marginLeft: 10 }} spinning={loading} />
      </h5>
      <div className="line-highlight">
        <hr />
      </div>

      <div className="flex-fill">
        <div className="form-group mb-0 mt-2">
          <label>ชื่อ</label>
          <Input
            placeholder="ค้นหาด้วย ชื่อนักเขียน"
            prefix={<UserOutlined />}
            value={searchWriter}
            onChange={onChangeSearchWriter}
          />
        </div>

        <div class="table_wrapper">
          <table className="table border mb-0 mt-4">
            <thead>
              <tr>
                <th className="text-center">Status</th>
                <th className="text-left">ชื่อนักเขียน</th>
                <th className="text-left">ตำแหน่ง</th>
                <th>แก้ไข</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">
                        {item.status === "A" ? (
                          <Tag color="green">Active</Tag>
                        ) : (
                          <Tag color="volcano">InActive</Tag>
                        )}
                      </td>
                      <td className="text-left font-weight-bold">
                        {item.name}
                      </td>
                      <td className="text-left">{item.position}</td>
                      <td>
                        <Tooltip title="แก้ไข" className="mr-2">
                          <Button
                            type="primary"
                            shape="circle"
                            size="small"
                            className="btn-secondary"
                            icon={<EditOutlined />}
                            onClick={() =>
                              history.push(
                                "/manage/writer/update/" + item.writer_id
                              )
                            }
                          />
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <div className="text-right mt-4">
          {data && data.length > 0 && (
            <ContentPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={changePage.bind(this)}
              pageShow={10}
            />
          )}
        </div>
      </div>
    </div>
  );
}
