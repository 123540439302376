import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Input, notification } from "antd";
import Notification from "../utils/Notification";
import UserService from "../services/UserService";
import "./Password.scss";

export default function SetPassword() {
  const { reference_code } = useParams();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const initialState = {
    password: "",
  };
  const [changePassword, setChangePassword] = useState(initialState);
  const history = useHistory();

  useEffect(() => {
    getEmailByReferenceCode(reference_code);
  }, [email]);

  const getEmailByReferenceCode = (reference_code) => {
    if (reference_code) {
      UserService.getEmailByReferenceCode(reference_code)
        .then((response) => {
          setEmail(response.data.email);
        })
        .catch((e) => {
          console.log(e);
          Notification.Show(
            "error",
            "From Web Service",
            e.response.data.message
          );
        });
    }
  };

  function ValidateChangePassword() {
    if (!password) {
      Notification.Show("error", "Validation", "กรุณาระบุ รหัสผ่าน");
      return false;
    }

    if (!confirmPassword) {
      Notification.Show("error", "Validation", "กรุณาระบุ ยืนยันรหัสผ่าน");
      return false;
    }

    if (password != confirmPassword) {
      Notification.Show(
        "error",
        "Validation",
        "รหัสผ่านไม่ตรงกัน กรุณาตรวจสอบ"
      );
      return false;
    }
  }

  async function SetPassword() {
    if (ValidateChangePassword() == false) return;

    changePassword.password = password;

    UserService.setPassword(email, changePassword)
      .then((response) => {
        console.log(response.data);
        ShowNotification("success", "Success", "ตั้งรหัสผ่านเรียบร้อยแล้ว");
        history.push("/login");
      })
      .catch((e) => {
        console.error(e.response.data.message);
        Notification.Show("error", "Error", e.response.data.message);
      });
  }

  const ShowNotification = (type, header, message) =>
    notification[type]({
      message: header,
      description: message,
    });

  return (
    <div className="password-wrapper">
      <div className="card border-0">
        <div className="card-body p-5">
          <img
            className="img-fluid mx-auto d-block"
            src={require("../assets/img/logo_s2m_head.png").default}
            alt=""
            width="260"
          />

          <hr className="mx-auto" />

          <div className="form-group mt-4">
            <label>รหัสผ่าน *</label>
            <Input
              placeholder="Password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="form-group mt-4">
            <label>ยืนยันรหัสผ่าน *</label>
            <Input
              placeholder="Confirm Password"
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>

          <div className="mt-3 pt-2">
            <button
              className="btn btn-primary btn-block font-weight-bold py-2"
              onClick={SetPassword}
            >
              ตั้งรหัสผ่านใหม่
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
