import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import OrderService from "../services/OrderService";
import ReportService from "../services/ReportService";
import {
  Tag,
  Select,
  Modal,
  Button,
  Input,
  DatePicker,
  notification,
  Popconfirm,
  Spin,
  Table,
} from "antd";

import { PictureOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import Notification from "../utils/Notification";
import ContentPagination from "../utils/Pagination";
import { grey } from "@material-ui/core/colors";

const { Option } = Select;
const { confirm } = Modal;
const { TextArea } = Input;
const { Column } = Table;

export default function ReportOrderDetail() {
  const history = useHistory();
  const initialTaxInvoiceState = {
    id: null,
    tax_invoice_no: "",
    issued_date: "",
    updated_by: "",
    updated_datetime: null,
    status: null,
  };

  const initialOrderDetailState = {
    id: null,
    order_number: "",
    is_sent: false,
    tracking_number: "",
  };

  const initialTrackingState = {
    order_number: "",
    name: "",
    item_name: "",
    item_count: "",
    item_price: "",
    delivert_cost: "",
    total: "",
    address_line1: "",
    address_line2: "",
    address_line3: "",
    address_line4: "",
    tel: "",
  };

  const initialEmailTracking = {
    id: "",
    send_email: false,
  };

  const [perPage, setPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const { order_number } = useParams();
  const [order, setOrder] = useState([]);
  const [deliverDetail, setDeliverDetail] = useState([]);
  const [taxInvoice, setTaxInvoice] = useState([]);
  const [orderItem, setOrderItem] = useState([]);
  //const [deliverStatus, setDeliverStatus] = useState();
  const [ModalOpen, setModalOpen] = useState(false);
  const [taxInvoiceIssue, setTaxInvoiceIssue] = useState(
    initialTaxInvoiceState
  );
  const [taxInvoiceNo, setTaxInvoiceNo] = useState();
  const [taxInvoiceIssueDate, setTaxInvoiceIssueDate] = useState(new Date());
  const [dateFormat, setDateFormat] = useState("DD-MM-YYYY");
  const [OrderDetail, setOrderDetail] = useState(initialOrderDetailState);
  const [totalPages, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [saveloading, setSaveLoading] = useState(false);
  const [cancelloading, setCancelLoading] = useState(false);
  const [tracking, setTracking] = useState(initialTrackingState);
  const [attendee, setAttendee] = useState([]);
  const [emailTracking, setEmailTracking] = useState(initialEmailTracking);
  const [remark, setRemark] = useState("");

  useEffect(() => {
    if (!localStorage.getItem("token")) history.push("/login");

    var result = localStorage.getItem("user_profile");
    if (result) {
      if (JSON.parse(result).purchase_management === false) {
        history.push("/login");
      }
    }

    getData(currentPage, order_number);
  }, [order_number]);

  const getData = async (currentPage, order_number) => {
    if (order_number) {
      setLoading(true);
      OrderService.getOrderDetail(currentPage, order_number)
        .then((response) => {
          setLoading(false);
          console.log(response);
          setOrder(response.data.order);
          setDeliverDetail(response.data.deliver_detail);
          setTaxInvoice(response.data.tax_invoices);
          setOrderItem(response.data.order_item);
          setRemark(response.data.order.remark);
          setAttendee(response.data.attendee);

          const totalPages = Math.ceil(response.data.total / perPage);
          setTotalPage(totalPages);

          let { tax_status, tax_invoice_no, tax_issued_date } =
            response.data.tax_invoices;
          if (tax_issued_date) {
            tax_issued_date = new Date(tax_issued_date);
          }

          if (tax_status === 2) {
            //issued
            setTaxInvoiceNo(tax_invoice_no);
            setTaxInvoiceIssueDate(tax_issued_date);
          }
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
          Notification.Show(
            "error",
            "From Web Service",
            e.response.data.message
          );
        });
    }
  };

  function formatDate(date) {
    if (date) {
      return moment(date).utc().format("DD/MM/YYYY");
    }
  }

  function formatAmount(amount) {
    if (amount) {
      return new Intl.NumberFormat("en-GB", {
        style: "decimal",
      }).format(amount);
    }
  }

  function formatProdutType(item) {
    if (item === "course") {
      return <Tag color="orange">คอร์สออนไลน์</Tag>;
    } else if (item === "book") {
      return <Tag color="blue">หนังสือ</Tag>;
    } else if (item === "audio") {
      return <Tag color="green">หนังสือเสียง</Tag>;
    } else if (item === "preorder_book") {
      return <Tag color="magenta">หนังสือ (pre-order)</Tag>;
    } else {
      return item.product_type;
    }
  }

  function formatLecturerName(lecturer) {
    let lecturer_name = "";
    if (lecturer) {
      for (let i = 0; i < lecturer.length; i++) {
        if (i != 0 && lecturer_name != "") lecturer_name += " , ";

        if (lecturer[i].lecturer.name)
          lecturer_name += lecturer[i].lecturer.name;
      }
    }
    return lecturer_name;
  }

  function TagInvoiceStatus(status) {
    if (status === 2) {
      return <Tag color="green">ออกใบกำกับภาษีแล้ว</Tag>;
    } else if (status === 1) {
      return <Tag color="red">รอออกใบกำกับภาษี</Tag>;
    }
  }

  function formatDeliveryMethod(item) {
    if (item === "kerry") {
      return "Kerry";
    } else if (item === "postal") {
      return "ลงทะเบียน";
    } 
    else if (item === "ems") {
      return "EMS";
    }
    else return item;
  }

  function formatAddress(item) {
    let output_address = "";
    if (item.address) {
      if (
        item.province === "กรุงเทพมหานคร" ||
        item.province === "กทม." ||
        item.province === "ก.ท.ม." ||
        item.province === "กทม" ||
        item.province === "กรุงเทพฯ"
      ) {
        output_address =
          item.address +
          " แขวง " +
          item.sub_district +
          " เขต " +
          item.district +
          " " +
          item.province +
          " " +
          item.zip_code;
      } else
        output_address =
          item.address +
          " ต." +
          item.sub_district +
          " อ." +
          item.district +
          " จ." +
          item.province +
          " " +
          item.zip_code;
    }
    return output_address;
  }

  function formatTaxInvoiceType(item) {
    if (item) {
      if (item === 1) return "บุคคลธรรมดา";
      else if (item === 2) {
        return "นิติบุคคล";
      }
    }
  }

  function formatTaxInvoiceCompanyName(item) {
    let output_name = "";
    if (item.company_name) {
      if (item.branch) {
        output_name = item.company_name + " (" + item.branch + ")";
      } else output_name = item.company_name;
    }
    return output_name;
  }

  const onChangeDeliverStatus = (deliver_status, e, item) => {
    item.book_deliver_status = deliver_status;
  };

  const handleInputChange = (index) => (event) => {
    const { name, value } = event.target;
    orderItem[index].tracking_number = value;
    setOrderItem(orderItem);
  };

  function editTaxInvoiceIssue() {
    setModalOpen(true);
  }

  function handleDatePickerChange(date, dateString) {
    setTaxInvoiceIssueDate(date);
  }

  const ShowNotification = (type, header, message) =>
    notification[type]({
      message: header,
      description: message,
    });

  async function saveTaxIssue() {
    if (!taxInvoiceNo) {
      Notification.Show("error", "Validation", "กรุณาระบุ เลขที่ใบกำกับภาษี");
      return;
    }
    if (!taxInvoiceIssueDate) {
      Notification.Show(
        "error",
        "Validation",
        "กรุณาระบุ วันที่ออกใบกำกับภาษี"
      );
      return;
    }

    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;

    taxInvoiceIssue.id = taxInvoice.id;
    taxInvoiceIssue.tax_invoice_no = taxInvoiceNo;

    taxInvoiceIssue.issued_date = taxInvoiceIssueDate;
    console.log(taxInvoiceIssue.issued_date);
    taxInvoiceIssue.updated_by = action_by;
    taxInvoiceIssue.updated_datetime = moment(new Date()).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    taxInvoiceIssue.status = 2; //issued

    ReportService.updateTaxInvoices(taxInvoiceIssue)
      .then((response) => {
        ShowNotification(
          "success",
          "Success",
          "บันทึกข้อมูลใบกำกับภาษีเรียบร้อยแล้ว"
        );
        setModalOpen(false);
        getData(currentPage, order_number);
      })
      .catch((e) => {
        console.log(e);
        Notification.Show("error", "Validation", e.response.data.message);
      });
  }

  async function deleteTaxIssue() {
    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;

    taxInvoiceIssue.id = taxInvoice.id;
    taxInvoiceIssue.tax_invoice_no = "";
    taxInvoiceIssue.issued_date = null;
    taxInvoiceIssue.updated_by = action_by;
    taxInvoiceIssue.updated_datetime = moment(new Date()).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    taxInvoiceIssue.status = 1; //pending

    await ReportService.updateTaxInvoices(taxInvoiceIssue)
      .then((response) => {
        ShowNotification(
          "success",
          "Success",
          "ลบข้อมูลใบกำกับภาษีเรียบร้อยแล้ว"
        );
        setTaxInvoiceNo("");
        setTaxInvoiceIssueDate(null);
        getData(currentPage, order_number);
      })
      .catch((e) => {
        console.log(e);
        Notification.Show("error", "Validation", e.response.data.message);
      });
  }

  const cancel = () => {
    history.push("/report/order");
  };

  function confirmDialog(e) {
    if (ValidateCancelOrder() === false) return;
    setCancelLoading(true);
    OrderService.cancelOrder(order_number)
      .then((response) => {
        setCancelLoading(false);
        console.log(response.data);
        Notification.Show(
          "success",
          "Success",
          "ยกเลิกคำสั่งซื้อเรียบร้อยแล้ว"
        );

        //Send Email Cancel
        OrderService.cancelEmail(order_number)
          .then((response) => {
            console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          });

        history.push("/report/order");
      })
      .catch((e) => {
        setCancelLoading(false);
        console.log(e);
        Notification.Show("error", "Error", e.response.data.message);
      });
  }

  function ValidateCancelOrder() {
    for (let i = 0; i < orderItem.length; i++) {
      if (orderItem[i].product_type != "course") {
        if (orderItem[i].book_deliver_status === "Y") {
          Notification.Show(
            "error",
            "Error",
            "ไม่สามารถยกเลิกคำสั่งซื้อนี้ได้ เนื่องจากมีสินค้าที่จัดส่งไปแล้ว"
          );
          return false;
          break;
        }
      }
    }
    return true;
  }

  function cancelDialog(e) {}

  const showConfirm = (data) => {
    confirm({
      title: "Confirm Send Email",
      icon: <ExclamationCircleOutlined />,
      content: "ต้องการส่ง Email ซ้ำอีกครั้ง?",
      okText: "Yes",
      cancelText: "No",

      onOk() {
        SendTracking(data);
      },

      onCancel() {
        console.log("Cancel");
      },
    });
  };

  async function SendTracking(data) {
    if (data.book_deliver_status != "Y") {
      ShowNotification(
        "error",
        "Error",
        "กรุณาระบุ สถานะการจัดส่ง เป็นจัดส่งแล้ว"
      );
      return;
    }

    if (!data.tracking_number) {
      ShowNotification("error", "Error", "กรุณาระบุ Tracking No.");
      return;
    }

    let unit = "";
    if (
      data.payment_method === 1 ||
      data.payment_method === 2 ||
      data.payment_method === 3
    )
      unit = "บาท";
    else if (data.payment_method === 4) unit = "เหรียญ";

    tracking.email = data.email;
    tracking.id = data.id;
    tracking.order_number = data.order_number;
    tracking.name = deliverDetail.first_name + " " + deliverDetail.last_name;
    tracking.item_name = data.name;
    tracking.item_count = data.quantity;
    tracking.item_price = data.total_amount;
    tracking.delivery_cost = data.delivery_cost;
    tracking.total = data.total_amount;

    tracking.address_line1 = deliverDetail.address;
    if (deliverDetail.province === "กรุงเทพมหานคร") {
      tracking.address_line2 = "แขวง " + deliverDetail.sub_district;
      tracking.address_line3 = "เขต " + deliverDetail.district;
      tracking.address_line4 = "กรุงเทพมหานคร " + deliverDetail.zip_code;
    } else {
      tracking.address_line2 = "ต." + deliverDetail.sub_district;
      tracking.address_line3 = "อ." + deliverDetail.district;
      tracking.address_line4 =
        "จ." + deliverDetail.province + " " + deliverDetail.zip_code;
    }

    tracking.tel = deliverDetail.phone_number;

    let delivery = "";
    if (data.delivery_method === "kerry") delivery = " (Kerry)";
    else if (
      data.delivery_method === "postal" ||
      data.delivery_method === "ems"
    )
      delivery = " (ไปรษณีย์ไทย)";
    else delivery = " (" + data.delivery_method + ")";

    tracking.tracking_no = data.tracking_number + delivery;

    await OrderService.sendTrackingNo(tracking)
      .then((response) => {
        SaveIsSendEmail(data);
      })
      .catch((e) => {
        console.log(e);
        Notification.Show("error", "Send Tracking No", e.response.data.message);
      });

    //window.location.reload();
  }

  async function SaveIsSendEmail(item) {
    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;

    emailTracking.id = item.id;
    emailTracking.tracking_number = item.tracking_number;
    emailTracking.sent_email = true;
    emailTracking.is_sent = true;
    emailTracking.updated_by = action_by;
    emailTracking.updated_datetime = moment(new Date()).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    await ReportService.updateOrderDetail(emailTracking)
      .then((response) => {
        Modal.success({
          content: "ส่ง Email เรียบร้อยแล้ว",
          onOk() {
            window.location.reload();
          },
        });
      })
      .catch((e) => {
        console.log(e);
        setSaveLoading(false);
        //Notification.Show("error", "Validation", e.response.data.message);
        Modal.success({
          content: e.response.data.message,
          onOk() {
            window.location.reload();
          },
        });
      });
  }

  async function ManageOrderDetail() {
    let outputArray = [];
    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;

    console.log(orderItem);
    if (ValidateTrackingNumber(orderItem) === false) return;

    for (let i = 0; i < orderItem.length; i++) {
      if (orderItem[i].book_deliver_status === "Y") {
        //ส่งแล้ว
        outputArray.push({
          id: orderItem[i].id,
          order_number: orderItem[i].order_number,
          is_sent: true,
          tracking_number: orderItem[i].tracking_number,
          updated_by: action_by,
          updated_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        });
      } else {
        outputArray.push({
          id: orderItem[i].id,
          order_number: orderItem[i].order_number,
          is_sent: false,
          tracking_number: "",
          updated_by: action_by,
          updated_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        });
      }
    }

    setSaveLoading(true);

    order.remark = remark;
    await ReportService.updateOrderRemark(order)
      .then((response) => {})
      .catch((e) => {
        console.log(e);
      });

    if (outputArray.length > 0) {
      await ReportService.updateOrderDetail(outputArray)
        .then((response) => {
          ShowNotification(
            "success",
            "Success",
            "บันทึกข้อมูลคำสั่งซื้อเรียบร้อยแล้ว"
          );
          setSaveLoading(false);
          //history.push("/report/order");
        })
        .catch((e) => {
          console.log(e);
          setSaveLoading(false);
          Notification.Show("error", "Validation", e.response.data.message);
        });
    }
  }

  function ValidateTrackingNumber(orderItem) {
    if (orderItem.length > 0) {
      for (let i = 0; i < orderItem.length; i++) {
        if (orderItem[i].product_type != "course") {
          if (orderItem[i].book_deliver_status === "Y") {
            if (
              orderItem[i].tracking_number === null ||
              orderItem[i].tracking_number === ""
            ) {
              Notification.Show(
                "error",
                "Validation",
                "กรุณาระบุ Trancking Number"
              );
              return false;
            }
          } else {
            if (
              orderItem[i].tracking_number != null &&
              orderItem[i].tracking_number != ""
            ) {
              Notification.Show(
                "error",
                "Validation",
                "มีสถานะรอการจัดส่ง ไม่ต้องระบุ Trancking Number"
              );
              return false;
            }
          }
        }
      }
    }
  }

  function changePage(page) {
    setCurrentPage(page);
    getData(page, order_number);
  }

  function formatName(item) {
    if (item.last_name) {
      return item.first_name + " " + item.last_name;
    } else {
      return item.first_name;
    }
  }

  return (
    <div className="container">
      <h5 className="mt-2 font-weight-bold">
        รายละเอียดคำสั่งซื้อ <Spin spinning={loading} />
      </h5>
      <div className="line-highlight">
        <hr />
      </div>

      <div className="flex-fill">
        <div className="header-bar">
          <div className="row">
            <div className="col-sm-3 mt-2">
              <span className="">
                วันที่สั่งซื้อ : {formatDate(order.order_date)}
              </span>
            </div>
            <div className="col-sm-3 mt-2">
              หมายเลขคำสั่งซื้อ : {order.order_number}
            </div>
            <div className="col-sm-3 mt-2">
              ราคารวมทั้งหมด(บาท) : {formatAmount(order.total_amount)}
            </div>
            <div className="col-sm-3">
              <div className="text-right container-fluid">
                {order.status === 1 && (
                  <Tag style={{ fontSize: "16px", padding: 7 }} color="red">
                    ยังไม่ชำระเงิน
                  </Tag>
                )}

                {order.status === 2 && (
                  <Tag style={{ fontSize: "16px", padding: 7 }} color="green">
                    ชำระเงินแล้ว
                  </Tag>
                )}

                {order.status === 3 && (
                  <Tag style={{ fontSize: "16px", padding: 7 }} color="red">
                    ชำระเงินล้มเหลว
                  </Tag>
                )}

                {order.status === 4 && (
                  <Tag style={{ fontSize: "16px", padding: 7 }} color="red">
                    ยกเลิกคำสั่งซื้อ
                  </Tag>
                )}
              </div>
            </div>
          </div>
        </div>

        {orderItem &&
          orderItem.map((item, index) => {
            return (
              <div
                className="card mt-4"
                style={{ background: "#fffcf8", borderColor: "#ffecd0" }}
              >
                <div className="card-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-5 col-sm-3 text-center">
                        <div>
                          <div className="position-relative my-2 pb-4">
                            {item.image ? (
                              <div>
                                {item.product_type === "course" ? (
                                  <img
                                    src={item.image}
                                    className="img-fluid"
                                    width={300}
                                  />
                                ) : (
                                  <>
                                    <img
                                      src={item.image}
                                      className="img-fluid"
                                      width={200}
                                    />
                                  </>
                                )}
                              </div>
                            ) : (
                              <div
                                className="image-placeholder d-flex flex-column align-items-center justify-content-center"
                                style={{ width: 200, height: 200 }}
                              >
                                <div>
                                  <PictureOutlined
                                    style={{ fontSize: 30, color: "#bbb" }}
                                  />
                                </div>
                                <div className="font-12">Order Image</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-7 col-sm-9 mt-2">
                        <div className="d-flex ml-4">
                          <span
                            className="font-weight-bold"
                            style={{ width: 100 }}
                          >
                            ประเภทสินค้า :
                          </span>
                          {formatProdutType(item.product_type)}
                        </div>

                        <div className="d-flex mt-3 ml-4">
                          {item.product_type === "course" ? (
                            <>
                              <span
                                className="font-weight-bold"
                                style={{ width: 80 }}
                              >
                                ชื่อคอร์ส :
                              </span>
                              {item.name}
                            </>
                          ) : (
                            <>
                              <span
                                className="font-weight-bold"
                                style={{ width: 80 }}
                              >
                                ชื่อหนังสือ :
                              </span>
                              {item.name}
                            </>
                          )}
                        </div>

                        <div className="d-flex mt-3 ml-4">
                          {item.product_type === "course" ? (
                            <>
                              <span
                                className="font-weight-bold"
                                style={{ width: 85 }}
                              >
                                ชื่อวิทยากร :
                              </span>
                              {formatLecturerName(item.lecturer)}
                            </>
                          ) : (
                            <>
                              <span
                                className="font-weight-bold"
                                style={{ width: 80 }}
                              >
                                ผู้เขียน :
                              </span>
                              {item.writer_name}
                            </>
                          )}
                        </div>

                        <div className="d-flex mt-3 ml-4">
                          <span
                            className="font-weight-bold"
                            style={{ width: 60 }}
                          >
                            จำนวน :
                          </span>
                          {item.quantity}
                          <span
                            className="font-weight-bold ml-4"
                            style={{ width: 110 }}
                          >
                            ราคาต่อหน่วย :
                          </span>
                          {formatAmount(item.price)}
                          <span
                            className="font-weight-bold ml-4"
                            style={{ width: 80 }}
                          >
                            ราคารวม :
                          </span>
                          {formatAmount(item.total_amount)}
                        </div>

                        <div className="d-flex mt-3 ml-4">
                          {item.product_type === "course" ? (
                            "สถานะการจัดส่ง :  N/A"
                          ) : (
                            <>
                              {item.book_deliver_status === "Y" ? (
                                <span
                                  className="font-weight-bold"
                                  style={{
                                    width: 120,
                                    marginTop: 10,
                                    color: "#43af56",
                                  }}
                                >
                                  สถานะการจัดส่ง :
                                </span>
                              ) : (
                                <span
                                  className="font-weight-bold text-danger"
                                  style={{ width: 120, marginTop: 10 }}
                                >
                                  สถานะการจัดส่ง :
                                </span>
                              )}
                              <Select
                                defaultValue={item.book_deliver_status}
                                style={{ width: 140 }}
                                onSelect={(value, event) => {
                                  onChangeDeliverStatus(value, event, item);
                                }}
                              >
                                <Option value="Y">จัดส่งแล้ว</Option>
                                <Option value="N">รอการจัดส่ง</Option>
                              </Select>
                            </>
                          )}
                        </div>

                        <div className="d-flex mt-3 ml-4">
                          {item.product_type === "course" ? (
                            "Tracking Number :  N/A"
                          ) : (
                            <>
                              <span style={{ width: 120, marginTop: 10 }}>
                                Tracking Number :
                              </span>

                              <Input
                                id="tracking_number"
                                style={{ width: 140 }}
                                defaultValue={item.tracking_number}
                                name="tracking_number"
                                onChange={handleInputChange(index)}
                              />
                            </>
                          )}
                        </div>

                        <div className="d-flex mt-3 ml-4">
                          {item.product_type === "course" ? (
                            "วิธีการจัดส่ง :  N/A"
                          ) : (
                            <>
                              <span style={{ width: 120, marginTop: 10 }}>
                                วิธีการจัดส่ง :
                              </span>

                              <Input
                                id="delivery_method"
                                style={{ width: 140, background: "#ececec" }}
                                readOnly
                                defaultValue={formatDeliveryMethod(
                                  item.delivery_method
                                )}
                                name="delivery_method"
                              />

                              {order.status === 2 && (
                                <>
                                  {item.sent_email === false ? (
                                    <Button
                                      className="btn-info ml-2 btn-xs mt-1"
                                      onClick={() => SendTracking(item)}
                                    >
                                      ส่ง Email(Tracking)
                                    </Button>
                                  ) : (
                                    <>
                                      <Button
                                        className="btn-warning ml-2 btn-xs mt-1"
                                        onClick={() => showConfirm(item)}
                                      >
                                        ส่ง Email(Tracking) อีกครั้ง
                                      </Button>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </div>

                        <div className="d-flex mt-3 ml-4">
                          {item.product_type != "course" && (
                            <>
                              {item.sent_email === false ? (
                                <p className="text-right text-danger ml-1 font-weight-bold">
                                  *สินค้านี้ ยังไม่ส่ง Email แจ้ง Tracking No.
                                </p>
                              ) : (
                                <p
                                  className="text-right ml-1 font-weight-bold"
                                  style={{ color: "#35b449" }}
                                >
                                  *สินค้านี้ส่ง Email แจ้ง Tracking No.
                                  ไปยังลูกค้าเรียบร้อยแล้ว
                                </p>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

        <div className="text-right mt-4">
          {orderItem && orderItem.length > 0 && (
            <ContentPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={changePage.bind(this)}
              pageShow={5}
            />
          )}
        </div>

        {deliverDetail.first_name && (
          <div className="card mt-4" style={{ background: "#f6f6f6" }}>
            <div className="card-body">
              <div className="container-fluid">
                <h6 className="font-weight-bold text-primary">
                  ข้อมูลการติดต่อ
                </h6>
                <div className="row">
                  <div className="col-5">
                    <div className="d-flex mt-3 ml-4">
                      <span
                        className="font-weight-lighter"
                        style={{ width: 120 }}
                      >
                        ชื่อ / นามสกุล :
                      </span>
                      <div className="font-weight-lighter">
                        {deliverDetail.first_name} {deliverDetail.last_name}{" "}
                      </div>
                    </div>

                    <div className="d-flex mt-3 ml-4">
                      <span
                        className="font-weight-lighter"
                        style={{ width: 120 }}
                      >
                        เบอร์โทรติดต่อ :
                      </span>
                      <div className="font-weight-lighter">
                        {deliverDetail.phone_number}
                      </div>
                    </div>

                    <div className="d-flex mt-3 ml-4 mb-1">
                      <span
                        className="font-weight-lighter"
                        style={{ width: 120 }}
                      >
                        Email :
                      </span>
                      <div className="font-weight-lighter">
                        {deliverDetail.email}
                      </div>
                    </div>
                  </div>

                  <div className="col-7">
                    <div className="d-flex mt-3 ml-4">
                      <span
                        className="font-weight-lighter"
                        style={{ width: 120 }}
                      >
                        ที่อยู่ :
                      </span>
                      <div className="font-weight-lighter">
                        {formatAddress(deliverDetail)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <>
          {attendee && attendee.length > 0 && (
            <div className="card mt-4" style={{ background: "#f6f6f6" }}>
              <div className="card-body">
                <div className="container-fluid">
                  <h6 className="font-weight-bold text-primary">
                    รายชื่อผู้เข้าร่วมสัมนา
                  </h6>

                  <div className="mt-4">
                    <Table dataSource={attendee}>
                    <Column
                        title="ชื่อคอร์ส"
                        dataIndex="course_name"
                        key="course_name"
                        align="center"
                      />
                      <Column
                        title="ชื่อ-นามสกุล"
                        align="center"
                        render={(value) => {
                          return formatName(value);
                        }}
                      />
                      <Column
                        title="เบอร์โทรศัพท์"
                        dataIndex="phone_number"
                        key="phone_number"
                        align="center"
                      />
                      <Column
                        title="Email"
                        dataIndex="email"
                        key="email"
                        align="center"
                      />
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>

        <div className="card mt-4" style={{ background: "#f6f6f6" }}>
          <div className="card-body">
            <div className="container-fluid">
              <h6 className="font-weight-bold text-primary">
                ข้อมูลการขอใบกำกับภาษี{" "}
              </h6>
              {order.tax_request === true ? (
                <>
                  <div className="row">
                    <div className="col-5">
                      <div className="d-flex mt-3 ml-4">
                        {taxInvoice.tax_type === 1 ? (
                          <>
                            <span
                              className="font-weight-lighter"
                              style={{ width: 150 }}
                            >
                              ชื่อ / นามสกุล :
                            </span>
                            <div className="font-weight-lighter">
                              {taxInvoice.first_name +
                                " " +
                                taxInvoice.last_name}
                            </div>
                          </>
                        ) : (
                          <>
                            <span
                              className="font-weight-lighter"
                              style={{ width: 150 }}
                            >
                              ชื่อ :
                            </span>
                            <div className="font-weight-lighter">
                              {formatTaxInvoiceCompanyName(taxInvoice)}
                            </div>
                          </>
                        )}
                      </div>

                      <div className="d-flex mt-3 ml-4">
                        <span
                          className="font-weight-lighter"
                          style={{ width: 150 }}
                        >
                          เลขประจำตัวผู้เสียภาษี :
                        </span>
                        <div className="font-weight-lighter">
                          {taxInvoice.id_number}
                        </div>
                      </div>

                      <div className="d-flex mt-3 ml-4 mb-1">
                        <span
                          className="font-weight-lighter"
                          style={{ width: 150 }}
                        >
                          ประเภท :
                        </span>
                        <div className="font-weight-lighter">
                          {formatTaxInvoiceType(taxInvoice.tax_type)}
                        </div>
                      </div>
                    </div>

                    <div className="col-7">
                      <div className="d-flex mt-3 ml-4">
                        <span
                          className="font-weight-lighter"
                          style={{ width: 180 }}
                        >
                          ที่อยู่ในการออกใบกำกับภาษี :
                        </span>
                        <div className="font-weight-lighter">
                          {formatAddress(taxInvoice)}
                        </div>
                      </div>

                      <div className="d-flex mt-3 ml-4 mb-1">
                        <span
                          className="font-weight-lighter"
                          style={{ width: 90 }}
                        >
                          เบอร์โทรศัพท์ :
                        </span>
                        <div className="font-weight-lighter">
                          {taxInvoice.phone_number}
                        </div>
                      </div>
                    </div>
                  </div>

                  <h6 className="font-weight-bold text-primary mt-4">
                    ข้อมูลใบกำกับภาษี
                    {order.tax_request === true && (
                      <span className="ml-4">
                        {" "}
                        {TagInvoiceStatus(taxInvoice.tax_status)}
                      </span>
                    )}
                  </h6>

                  {order.status != 3 && order.status != 4 ? (
                    <>
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex mt-3 ml-4">
                            {taxInvoice.tax_status === 1 ? ( //1 = pending / 2 = issued
                              <>
                                <span className="font-weight-lighter ">
                                  <Button
                                    type="primary"
                                    ghost
                                    success
                                    onClick={() => {
                                      setModalOpen(true);
                                    }}
                                  >
                                    กรอกข้อมูลใบกำกับภาษี
                                  </Button>
                                </span>
                              </>
                            ) : (
                              <>
                                <span
                                  className="font-weight-lighter"
                                  style={{ width: 120 }}
                                >
                                  เลขที่ใบกำกับภาษี :
                                </span>
                                <div
                                  className="font-weight-lighter"
                                  style={{ width: 130 }}
                                >
                                  {taxInvoice.tax_invoice_no}
                                </div>

                                <span
                                  className="font-weight-lighter"
                                  style={{ width: 150 }}
                                >
                                  วันที่ออกใบกำกับภาษี :
                                </span>
                                <div
                                  className="font-weight-lighter"
                                  style={{ width: 120 }}
                                >
                                  {formatDate(taxInvoice.tax_issued_date)}
                                </div>

                                <Button
                                  type="link"
                                  style={{ marginTop: -6, color: "orange" }}
                                  onClick={() => {
                                    editTaxInvoiceIssue();
                                  }}
                                >
                                  แก้ไขข้อมูล
                                </Button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {order.status === 3 && (
                        <div className="mt-3 ml-4 text-danger">
                          ไม่สามารถออกใบกำกับภาษีได้เนื่องจากคำสั่งซื้อมีสถานะชำระเงินล้มเหลว
                        </div>
                      )}

                      {order.status === 4 && (
                        <div className="mt-3 ml-4 text-danger">
                          ไม่สามารถออกใบกำกับภาษีได้เนื่องจากคำสั่งซื้อมีสถานะยกเลิก
                        </div>
                      )}
                    </>
                  )}

                  <Modal
                    title="ข้อมูลใบกำกับภาษี"
                    footer={null}
                    width={650}
                    maskClosable={false}
                    visible={ModalOpen}
                    onCancel={() => setModalOpen(false)}
                  >
                    <div className="d-flex">
                      <div className="form-group mb-3 mt-2 ml-4">
                        <div className="row">
                          <div className="col-6">
                            <label>
                              เลขที่ใบกำกับภาษี
                              <span className="text-danger">*</span>
                            </label>
                            <Input
                              value={taxInvoiceNo}
                              onChange={(e) => setTaxInvoiceNo(e.target.value)}
                            />
                          </div>
                          <div className="col-6">
                            <label>
                              วันที่ออกใบกำกับภาษี
                              <span className="text-danger">*</span>
                            </label>

                            {taxInvoiceIssueDate && (
                              <DatePicker
                                picker="date"
                                defaultValue={moment(
                                  taxInvoiceIssueDate,
                                  dateFormat
                                )}
                                format={dateFormat}
                                onChange={(date, dateString) =>
                                  handleDatePickerChange(date, dateString)
                                }
                                className="w-100"
                                // disabledDate={(current) => {
                                //   return moment().add(-1, "days") >= current;
                                // }}
                              />
                            )}

                            {!taxInvoiceIssueDate && (
                              <DatePicker
                                picker="date"
                                format={dateFormat}
                                onChange={(date, dateString) =>
                                  handleDatePickerChange(date, dateString)
                                }
                                className="w-100"
                                // disabledDate={(current) => {
                                //   return moment().add(-1, "days") >= current;
                                // }}
                              />
                            )}
                          </div>
                        </div>

                        <h6 className="font-weight-bold mt-4">
                          ข้อมูลใบกำกับภาษี
                        </h6>

                        <div className="row">
                          <div className="col-12">
                            <div className="d-flex mt-3 ml-2">
                              {taxInvoice.tax_type === 1 ? (
                                <>
                                  <span
                                    className="font-weight-lighter text-primary"
                                    style={{ width: 170 }}
                                  >
                                    ชื่อ / นามสกุล :
                                  </span>
                                  <div className="font-weight-lighter">
                                    {taxInvoice.first_name +
                                      " " +
                                      taxInvoice.last_name}
                                  </div>
                                </>
                              ) : (
                                <>
                                  <span
                                    className="font-weight-lighter text-primary"
                                    style={{ width: 170 }}
                                  >
                                    ชื่อ :
                                  </span>
                                  <div className="font-weight-lighter">
                                    {formatTaxInvoiceCompanyName(taxInvoice)}
                                  </div>
                                </>
                              )}
                            </div>

                            <div className="d-flex mt-3 ml-2">
                              <span
                                className="font-weight-lighter text-primary"
                                style={{ width: 170 }}
                              >
                                เลขประจำตัวผู้เสียภาษี :
                              </span>
                              <div className="font-weight-lighter">
                                {taxInvoice.id_number}
                              </div>
                            </div>

                            <div className="d-flex mt-3 ml-2 mb-1">
                              <span
                                className="font-weight-lighter text-primary"
                                style={{ width: 170 }}
                              >
                                ประเภท :
                              </span>
                              <div className="font-weight-lighter">
                                {formatTaxInvoiceType(taxInvoice.tax_type)}
                              </div>
                            </div>

                            <div className="d-flex mt-3 ml-2">
                              <span
                                className="font-weight-lighter text-primary"
                                style={{ width: 170 }}
                              >
                                ที่อยู่ในการออกใบกำกับภาษี :
                              </span>
                              <div className="font-weight-lighter">
                                {formatAddress(taxInvoice)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <div className="mt-2 text-left">
                          <button
                            type="button"
                            className="btn btn-md btn-danger ml-4"
                            onClick={() => {
                              deleteTaxIssue();
                              setModalOpen(false);
                            }}
                          >
                            ลบข้อมูล
                          </button>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="mt-2 text-right">
                          <button
                            type="button"
                            className="btn btn-secondary mr-2"
                            onClick={() => {
                              setModalOpen(false);
                            }}
                          >
                            ยกเลิก
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              saveTaxIssue();
                            }}
                            className="btn btn-primary mr-2"
                          >
                            บันทึก
                          </button>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </>
              ) : (
                <Tag color="orange" className="ml-2 mt-2">
                  คำสั่งนี้ไม่ได้ข้อใบกำกับภาษี
                </Tag>
              )}
            </div>
          </div>
        </div>

        <div className="card mt-4" style={{ background: "#f6f6f6" }}>
          <div className="card-body">
            <div className="container-fluid">
              <h6 className="font-weight-bold text-primary">
                หมายเหตุ(บันทึกสำหรับ Admin)
              </h6>
              <TextArea
                rows={4}
                id="remark"
                value={remark}
                name="remark"
                onChange={(e) => setRemark(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-2">
            <div className="mt-2 text-left">
              {order.status != 3 && order.status != 4 && (
                <>
                  <Popconfirm
                    title="ต้องการยกเลิกคำสั่งซื้อนี้?"
                    onConfirm={confirmDialog}
                    onCancel={cancelDialog}
                    okText="ยืนยันการยกเลิกคำสั่งซื้อ"
                    cancelText="ยกเลิก"
                  >
                    <Button className="btn-danger">ยกเลิกคำสั่งซื้อ</Button>
                    <Spin spinning={cancelloading} />
                  </Popconfirm>
                </>
              )}
            </div>
          </div>
          <div className="col-10 text-right">
            <div className="mt-2 text-right">
              <Button className="btn-secondary mr-2" onClick={cancel}>
                ย้อนกลับ
              </Button>
              {order.status === 2 && (
                <>
                  <Button className="btn-primary" onClick={ManageOrderDetail}>
                    บันทึกข้อมูล
                  </Button>
                  <Spin style={{ marginLeft: 10 }} spinning={saveloading} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}