import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import WebboardService from "../services/WebboardService";
import {
  Input,
  Button,
  Tooltip,
  Tag,
  Select,
  Spin,
  Switch,
  Popconfirm,
  notification,
  Modal,
} from "antd";
import {
  SearchOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import ContentPagination from "../utils/Pagination";
import moment from "moment";
import Notification from "../utils/Notification";
import { SignalCellularConnectedNoInternet4BarTwoTone } from "@material-ui/icons";

const { Option } = Select;
const { confirm } = Modal;
const { TextArea } = Input;

export default function Topic() {
  const history = useHistory();

  const initialEditState = {
    id: null,
    is_pinned: false,
    is_show_comment: false,
    status: null,
    updated_by: "",
    updated_datetime: null,
  };

  const initialEditTopicName = {
    id: null,
    name: "",
    updated_by: "",
    updated_datetime: null,
  };

  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [searchTopicName, setSearchTopicName] = useState("");
  const [searchAction, setSearchAction] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState(false);
  const [edit, setEdit] = useState(initialEditState);
  const [modalTopicName, setModalTopicName] = useState(false);
  const [topicName, setTopicName] = useState("");
  const [topicIDEdit, setTopicIDEdit] = useState("");
  const [editTopicName, setEditTopicName] = useState(initialEditTopicName);

  useEffect(() => {
    if (!localStorage.getItem("token")) history.push("/login");

    var result = localStorage.getItem("user_profile");
    if (result) {
      if (JSON.parse(result).webboard === false) {
        history.push("/login");
      }
    }

    getData(currentPage, searchTopicName, searchAction);
  }, [0]);

  const getData = async (page, filterTopicName, filterAction) => {
    setLoading(true);
    await WebboardService.getAllTopic(page, filterTopicName, filterAction)
      .then((res) => {
        setLoading(false);
        const response = res.data;
        setData(response.topic);
        const totalPages = Math.ceil(response.total / perPage);
        setTotalPage(totalPages);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        let error = e.response ? e.response.data.message : e.message;
        Notification.Show("error", "Error", error);
      });
  };

  function changePage(page) {
    setCurrentPage(page);
    getData(page, searchTopicName, searchAction);
  }

  const ShowNotification = (type, header, message) =>
  notification[type]({
    message: header,
    description: message,
  });

  const onChangeSearchTopicName = (e) => {
    const topic = e.target.value;
    setSearchTopicName(topic);
    getData("", topic, searchAction);
  };

  const onChangeSearchFilter = (action, e) => {
    setSearchAction(action);
    getData(currentPage, searchTopicName, action);
  };

  function formatDate(date) {
    if (date) {
      return moment(date).utc().format("DD/MM/YYYY HH:mm");
    }
  }

  async function handleChange(item, action) {
    let newIsPin = null;
    let newStatus = null;
    let newShowComment = null;

    if (action === "pin") newIsPin = item.is_pinned === true ? false : true;
    else if (action === "hide") newStatus = item.status === 1 ? 2 : 1;
    //Status = 1 //Active     Status = 2 //In-Active     Status = 3 //Draft
    else if (action === "comment")
      newShowComment = item.is_show_comment === true ? false : true;

    let newData = data;

    newData.map((newData) => {
      if (newData.id === item.id) {
        if (action === "pin") {
          newData.is_pinned = newIsPin;
        } else if (action === "hide") {
          newData.status = newStatus;
        } else if (action === "comment") {
          newData.is_show_comment = newShowComment;
        }
      }
    });

   // setData([...data, newData]);

    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;
    
    edit.id = item.id;

    if (action === "pin") {
      edit.is_pinned = newIsPin;
      edit.status = item.status;
      edit.is_show_comment = item.is_show_comment;
    } else if (action === "hide") {
      edit.status = newStatus;
      edit.is_pinned = item.is_pinned;
      edit.is_show_comment = item.is_show_comment;
    } else if (action === "comment") {
      edit.is_show_comment = newShowComment;
      edit.status = item.status;
      edit.is_pinned = item.is_pinned;
    }

    edit.updated_by = action_by;
    edit.updated_datetime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

    await WebboardService.updateTopic(edit)
      .then((response) => {
        getData(currentPage, searchTopicName, searchAction);
      })
      .catch((e) => {
        console.error(e.response.data.message);
        Notification.Show(
          "error",
          "Error",
          "เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง"
        );
      });
  }

  const showDeleteConfirm = (id) => {
    confirm({
      title: "Confirm Delete?",
      icon: <ExclamationCircleOutlined />,
      content: "ต้องการลบกระทู้นี้ใช่หรือไม่",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        deleteTopic(id);
      },

      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const showEditTopicName = (item) => {
    setModalTopicName(true);
    setTopicName(item.name)
    setTopicIDEdit(item.id);
  };

  // const handleOk = () => {
  //   setModalTopicName(false);
  // };

  async function handleOk(){
    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;
  
    editTopicName.id = topicIDEdit;
    editTopicName.name = topicName;

    editTopicName.updated_by = action_by;
    editTopicName.updated_datetime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  
    await WebboardService.updateTopic(editTopicName)
      .then((response) => {
        ShowNotification(
          "success",
          "Success",
          "บันทึกข้อมูลเรียบร้อยแล้ว"
        );
        getData(currentPage, searchTopicName, searchAction);
        setModalTopicName(false);
      })
      .catch((e) => {
        console.error(e.response.data.message);
        Notification.Show(
          "error",
          "Error",
          "เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง"
        );
      });
  };

  const handleCancel = () => {
    setModalTopicName(false);
  };

  async function deleteTopic(id) {
    edit.id = id;

    let response = await WebboardService.deleteTopic(edit);
    console.log(response);
    if (response.status === 200 || response.status === 201) {
      Notification.Show("success", "Success", "ลบกระทู้เรียบร้อยแล้ว");
      getData(currentPage, searchTopicName, searchAction);
    } else {
      Notification.Show(
        "error",
        "Error",
        "เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง"
      );
    }
  }

  return (
    <div className="container">
      <h5 className="mt-2 font-weight-bold">
        Webboard
        <Spin style={{ marginLeft: 10 }} spinning={loading} />
      </h5>
      <div className="line-highlight">
        <hr />
      </div>

      <div className="flex-fill">
        <div className="row mt-4 pt-3">
          <div className="col-6">
            <div className="form-group">
              <label>ค้นหา</label>
              <Input
                placeholder="ค้นหาด้วย ชื่อกระทู้"
                value={searchTopicName}
                onChange={onChangeSearchTopicName}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>กรองกระทู้</label>
              <Select
                defaultValue=""
                className="w-100"
                onSelect={(value, event) => onChangeSearchFilter(value, event)}
              >
                <Option value="">ทั้งหมด</Option>
                <Option value="pin">กระทู้ที่ปักหมุด (Pin)</Option>
                <Option value="active">กระทู้ที่แสดงผลอยู่ (Active)</Option>
                <Option value="hide">กระทู้ที่ถูกซ่อน (Hide)</Option>
                <Option value="comment">
                  กระทู้ที่ถูกซ่อนความคิดเห็น/ปิดไม่ให้สร้าง Comment
                </Option>
              </Select>
            </div>
          </div>
        </div>

        <div class="table_wrapper">
          <table className="table border mb-0 mt-4">
            <thead>
              <tr>
                <th className="text-center">ชื่อกระทู้</th>
                <th className="text-center">วันที่สร้าง</th>
                <th className="text-center">ผู้สร้างกระทู้</th>
                <th className="text-center">Pin</th>
                <th className="text-center">แสดง/ซ่อนกระทู้</th>
                <th className="text-center">แสดง/ปิด Comment</th>
                <th className="text-center">ลบ</th>
                <th className="text-center">ความคิดเห็น</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-left">
                        {item.name}{" "}
                        <Button
                          type="link"
                          onClick={() => showEditTopicName(item)}
                        >
                          edit
                        </Button>
                      </td>
                      <td className="text-center">
                        {formatDate(item.created_datetime)}
                      </td>
                      <td className="text-center">
                        <Button
                          type="link"
                          onClick={() =>
                            history.push(
                              "/manage/member/update/" + item.user_id
                            )
                          }
                        >
                          {item.display_name}
                        </Button>
                      </td>
                      <td className="text-center">
                        <Switch
                          checkedChildren="Pin"
                          onChange={() => handleChange(item, "pin")}
                          checked={item.is_pinned}
                        />
                      </td>
                      <td className="text-center">
                        <Switch
                          onChange={() => handleChange(item, "hide")}
                          checkedChildren="Show"
                          unCheckedChildren="Hide"
                          checked={item.status === 1 ? true : false}
                        />
                      </td>
                      <td className="text-center">
                        <Switch
                          onChange={() => handleChange(item, "comment")}
                          checkedChildren="Show"
                          unCheckedChildren="Hide"
                          checked={item.is_show_comment}
                        />
                      </td>
                      <td className="text-center">
                        <Button
                          type="primary"
                          shape="circle"
                          size="small"
                          className="btn-danger"
                          icon={<DeleteOutlined />}
                          onClick={() => showDeleteConfirm(item.id)}
                        />
                      </td>
                      <td className="text-center">
                        <Tooltip title="ดูความคิดเห็น" className="mr-2">
                          <Button
                            type="primary"
                            shape="circle"
                            size="small"
                            className="btn-secondary"
                            icon={<SearchOutlined />}
                            onClick={() => history.push("/thread/" + item.id)}
                          />
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <div className="text-right mt-4">
          {data && data.length > 0 && (
            <ContentPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={changePage.bind(this)}
              pageShow={10}
            />
          )}
        </div>
      </div>

      <Modal
        title="แก้ไขชื่อกะทู้"
        visible={modalTopicName}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <TextArea
          rows={2}
          id="topicName"
          value={topicName}
          name="topicName"
          onChange={(e) => setTopicName(e.target.value)}
        />{" "}
      </Modal>
    </div>
  );
}
