import { httpClient } from "../utils/HttpClient";

const getAllTopic = (page_no,filter_topic_name,filter_action) => {
  return httpClient.get("/admin/topic", {
    params: {
      page: page_no,
      name: filter_topic_name,
      search: filter_action
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`
    },
  });
};

const updateTopic = (data) => {
    return httpClient.patch(`/admin/topic`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
};

const deleteTopic = (data) => {
    return httpClient.patch(`/admin/topic/delete`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
};

const getAllThread = (id,page_no,filter_search_comment) => {
  return httpClient.get(`/admin/thread/`, {
    params: {
      topic_id:id,
      page: page_no,
      comment: filter_search_comment
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`
    },
  });
};
  
const deleteThread = (data) => {
  return httpClient.patch(`/admin/thread/delete`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export default {
  getAllTopic,
  updateTopic,
  deleteTopic,
  getAllThread,
  deleteThread
};
