import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Input,
  Button,
  Popconfirm,
  Switch,
  notification,
  Select,
  Spin,
  Modal,
  Radio,
} from "antd";
import MemberService from "../services/MemberService";
import UtilityService from "../services/UtilityService";
import moment from "moment";
import Notification from "../utils/Notification";
import imageCompression from "browser-image-compression";
import MyUploadAdapter from "../utils/MyUploadAdapter";
import { PictureOutlined } from "@ant-design/icons";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const { Option } = Select;

const ManageMember = (props) => {
  const history = useHistory();
  const initialUserState = {
    id: null,
    display_name: "",
    email: "",
    password: "",
    confirm_password: "",
    type: "",
    bio: "",
    phone_number: "",
    user_status_id: null,
    is_ban_webboard: false,
    ban_webboard_reason: "",
    created_datetime: null,
    created_by: null,
    updated_datetime: null,
    updated_by: null,
  };

  const { id } = useParams();
  const [user, setUser] = useState(initialUserState);
  const [action, setAction] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userStatusID, setUserStatusID] = useState();
  const [statusSelect, setStatusSelect] = useState([]);
  const [coverImage, setCoverImage] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [cropperRef, setCropperRef] = useState(React.createRef());
  const [isBanWebboard, setIsBanWebboard] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("token")) history.push("/login");

    var result = localStorage.getItem("user_profile");
    if (result) {
      if (JSON.parse(result).member === false) {
        history.push("/login");
      }
    }

    setAction(props.action);
    getUserStatus();
    getUser(id);
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  const getUserStatus = async () => {
    await MemberService.getUserStatus()
      .then((res) => {
        const response = res.data;
        setStatusSelect(response.user_status);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  async function uploadImage(image, type) {
    const options = {
      maxSizeMB: 0.1,
      useWebWorker: true,
      initialQuality: 0.5,
    };
    try {
      const compressedFile = await imageCompression(image, options);
      const file = new File([compressedFile], image.name, { type: image.type });

      if (image) {
        let formData = new FormData();
        let imagefile = file;

        formData.append("upload", imagefile);
        await UtilityService.uploadImage(formData)
          .then((response) => {
            user.avatar = response.data.url;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getUser = (id) => {
    if (id) {
      setLoading(true);
      MemberService.getMemberByID(id)
        .then((response) => {
          setLoading(false);
          response.data.password = null;
          setUser(response.data);
          setUserStatusID(response.data.user_status_id);
          setCoverImage(response.data.avatar);
          setIsBanWebboard(response.data.is_ban_webboard);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
          let error = e.response ? e.response.data.message : e.message;
          Notification.Show("error", "Error", error);
        });
    }
  };

  const ShowNotification = (type, header, message) =>
    notification[type]({
      message: header,
      description: message,
    });

  async function manageUser() {
    if (!user.display_name) {
      Notification.Show("error", "Validation", "กรุณาระบุ Display Name");
      return;
    }

    if (!user.email) {
      Notification.Show("error", "Validation", "กรุณาระบุ Email");
      return;
    }

    if (user.password) {
      if (user.password != user.confirm_password) {
        Notification.Show(
          "error",
          "Validation",
          "กรุณาระบุ Password ให้ตรงกับ Confirm Password"
        );
        return;
      }
    }

    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;

    if (action === "add") user.created_by = action_by;

    if (imageFile) {
      await uploadImage(imageFile, "cover");
    } else user.avatar = coverImage;

    user.user_status_id = userStatusID;
    user.is_ban_webboard = isBanWebboard;
    user.updated_by = action_by;
    user.updated_datetime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

    setLoading(true);

    await MemberService.updateMember(user)
      .then((response) => {
        setLoading(false);
        ShowNotification(
          "success",
          "Success",
          "บันทึกข้อมูล สมาชิก เรียบร้อยแล้ว"
        );
        history.push("/member");
      })
      .catch((e) => {
        setLoading(false);
        console.error(e.response.data.message);
        Notification.Show("error", "Error", e.response.data.message);
      });
  }

  const cancel = () => {
    history.push("/member");
  };

  function cancelDialog(e) {}

  const onChangeUserStatusID = (user_status_id, e) => {
    setUserStatusID(user_status_id);
  };

  function customUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      // Configure the URL to the upload script in your back-end here!
      return new MyUploadAdapter(loader);
    };
  }

  function onSelectFile(e, type) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImageUrl(reader.result));
      //reader.addEventListener("load", () => setCoverImage(reader.result));
      reader.readAsDataURL(e.target.files[0]);

      setCropModalOpen(true);
      setImageFile(e.target.files[0]);
    }
  }

  function cropImage() {
    const cropper = cropperRef.current.cropper;

    cropper.getCroppedCanvas().toBlob((blob) => {
      const fileName = imageFile.name;

      const file = new File([blob], fileName, {
        lastModifiedDate: new Date(),
        type: imageFile.type,
      });
      const cropppedPreview = cropper.getCroppedCanvas().toDataURL();
      setCoverImage(cropppedPreview);
      setImageFile(file);
      closeCropModal();
    });
  }

  function closeCropModal() {
    setCropModalOpen(false);
  }

  function removeImage() {
    setCoverImage("");
    setImageUrl("");
  }

  const onChangeBanWebboard = (e) => {
    setIsBanWebboard(e.target.value);
  };

  return (
    <div className="container">
      <div className="d-flex mt-4 align-items-stretch">
        <div className="flex-fill">
          <div className="header-bar">
            ข้อมูลสมาชิก <Spin style={{ marginLeft: 10 }} spinning={loading} />
          </div>

          <div className="container-fluid mt-3">
            <div className="row">
              <div className="col-sm-4"></div>
              <div className="col-sm-4">
                <div className="position-relative my-2 mt-4 text-center">
                  {coverImage ? (
                    <img src={coverImage} className="img-fluid" width={200} />
                  ) : (
                    <div
                      className="image-placeholder d-flex flex-column align-items-center justify-content-center mt-5"
                      style={{ height: 240 }}
                    >
                      <div>
                        <PictureOutlined
                          style={{ fontSize: 30, color: "#bbb" }}
                        />
                      </div>
                      <div className="font-12">อัพโหลดรูป</div>
                    </div>
                  )}

                  <input
                    type="file"
                    accept="image/*"
                    className="input-file-hidden"
                    onChange={(event) => {
                      onSelectFile(event, "writers");
                    }}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                  />
                </div>
                {coverImage && (
                  <div className="text-right mt-2">
                    <Button
                      className="btn btn-sm btn-danger"
                      onClick={() => removeImage()}
                    >
                      ลบ
                    </Button>
                  </div>
                )}
              </div>
              <div className="col-sm-4"></div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group mb-3">
                  <label>
                    Display Name
                    <span className="text-danger">*</span>
                  </label>
                  <Input
                    id="display_name"
                    required
                    value={user.display_name}
                    onChange={handleInputChange}
                    name="display_name"
                  />
                </div>

                <div className="form-group mb-3">
                  <label>
                    Email
                    <span className="text-danger">*</span>
                  </label>

                  <Input
                    id="email"
                    value={user.email}
                    onChange={handleInputChange}
                    name="email"
                  />
                </div>

                <div className="form-group mb-3">
                  <label>Type</label>
                  <Input
                    id="type"
                    value={user.type}
                    style={{ background: "#ececec" }}
                    readOnly
                    onChange={handleInputChange}
                    name="type"
                  />
                </div>

                {user.type === "email" && (
                  <>
                    <div className="form-group mb-3">
                      <label>Password</label>

                      <Input
                        id="password"
                        type="password"
                        value={user.password}
                        onChange={handleInputChange}
                        name="password"
                      />
                    </div>

                    <div className="form-group mb-3">
                      <label>Confirm Password</label>

                      <Input
                        id="confirm_password"
                        type="password"
                        value={user.confirm_password}
                        onChange={handleInputChange}
                        name="confirm_password"
                      />
                    </div>
                  </>
                )}

                <div className="form-group mb-3">
                  <label>Phone Number</label>
                  <Input
                    id="phone_number"
                    value={user.phone_number}
                    onChange={handleInputChange}
                    name="phone_number"
                  />
                </div>

                <div className="form-group mb-3">
                  <label>Bio</label>
                  <Input
                    id="bio"
                    value={user.bio}
                    onChange={handleInputChange}
                    name="bio"
                  />
                </div>

                <div className="form-group mb-3">
                  <label>
                    Status <span className="text-danger">*</span>
                  </label>
                  <Select
                    defaultValue="2"
                    value={userStatusID}
                    className="w-100"
                    onSelect={(value, event) =>
                      onChangeUserStatusID(value, event)
                    }
                  >
                    {statusSelect &&
                      statusSelect.map((item, index) => {
                        return <Option value={item.id}>{item.status}</Option>;
                      })}
                  </Select>
                </div>

                <div className="form-group mt-4">
                  <label>Ban ไม่ให้ตั้งกระทู้ใหม่/แสดงความคิดเห็น</label>
                  <div className="form-group mt-2">
                    <Radio.Group
                      onChange={onChangeBanWebboard}
                      value={isBanWebboard}
                    >
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label>เหตุผลของการ Ban</label>
                  <Input
                    id="ban_webboard_reason"
                    value={user.ban_webboard_reason}
                    onChange={handleInputChange}
                    name="ban_webboard_reason"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 text-right">
        <Button className="btn-secondary mr-2" onClick={cancel}>
          ย้อนกลับ
        </Button>
        <Button className="btn-primary" onClick={manageUser}>
          บันทึกข้อมูล
        </Button>
        <Spin style={{ marginLeft: 10 }} spinning={loading} />
      </div>

      <Modal
        title={null}
        footer={null}
        width={800}
        maskClosable={false}
        visible={cropModalOpen}
        onCancel={() => setCropModalOpen(false)}
      >
        <div className="d-flex">
          <div className="flex-fill thumbnail-crop-area">
            <Cropper
              src={imageUrl}
              aspectRatio={250 / 250}
              autoCropArea={1}
              preview=".book-preview"
              viewMode={1}
              ref={cropperRef}
            />
          </div>

          <div className="mt-4 book-preview-area ml-4">
            <div className="font-weight-bold text-center mb-2">ตัวอย่างรูป</div>

            <div className="book-preview" />
          </div>
        </div>

        <div className="mt-5 text-center">
          <button
            type="button"
            onClick={() => {
              cropImage();
            }}
            className="btn btn-primary mr-2"
          >
            ตกลง
          </button>
          <button
            type="button"
            className="btn btn-secondary mr-2"
            onClick={() => {
              setCropModalOpen(false);
            }}
          >
            ยกเลิก
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ManageMember;
