import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ChannelService from "../services/ChannelService";

import { Input, Button, Tooltip, Tag, Modal, notification,  Popconfirm, Switch } from "antd";

import { EditOutlined, UserOutlined, DeleteOutlined,ExclamationCircleOutlined } from "@ant-design/icons";

import ContentPagination from "../utils/Pagination";
import moment from "moment";
import Notification from "../utils/Notification";

export default function Channel() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const initialChannelState = {
    channel_id: null,
    name: "",
    updated_by: "",
    created_by: "",
    updated_datetime: null,
    status: null,
  };
  const [channel, setChannel] = useState(initialChannelState);
  const [perPage, setPerPage] = useState(10);
  const [searchChannel, setSearchChannel] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPage] = useState(1);
  const [ModalOpen, setModalOpen] = useState(false);
  const [channelName, setChannelName] = useState();
  const [dataItem, setDataItem] = useState([]);
  const [action, setAction] = useState("");

  const { confirm } = Modal;

  useEffect(() => {
    getData(currentPage, searchChannel);
  }, [0]);

  const getData = async (page, filterName) => {
    await ChannelService.getAll(page, filterName)
      .then((res) => {
        const response = res.data;

        const totalPages = Math.ceil(response.total / perPage);
        setTotalPage(totalPages);
        setData(response.channels);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  function changePage(page) {
    setCurrentPage(page);
    getData(page, searchChannel);
  }

  const onChangeSearchChannel = (e) => {
    const searchChannel = e.target.value;
    setSearchChannel(searchChannel);
    getData(currentPage, searchChannel);
  };

  const ShowNotification = (type, header, message) =>
    notification[type]({
      message: header,
      description: message,
    });

  function SaveChannel() {
    if (!channelName) {
      Notification.Show("error", "Validation", "กรุณาระบุ Channel Name");
      return;
    }

    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;
    
    if(action === 'add')
    {
        channel.channel_id = null;
    }
    else 
        channel.channel_id = dataItem.channel_id;

        channel.name = channelName;

    if (action === 'add') channel.created_by = action_by;

    channel.updated_by = action_by;
    channel.updated_datetime = moment(new Date()).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    if (action === 'add') channel.status = "A";
    else channel.status = dataItem.status;

    console.log(channel);
    if (action === "add") {
      ChannelService.create(channel)
        .then((response) => {
          ShowNotification("success", "Success", "บันทึกข้อมูลเรียบร้อยแล้ว");
          setModalOpen(false);
          getData(currentPage, searchChannel);
        })
        .catch((e) => {
          console.log(e);
          Notification.Show("error", "Validation", e.response.data.message);
        });
    } else {
        ChannelService.update(channel.channel_id, channel)
        .then((response) => {
          ShowNotification("success", "Success", "บันทึกข้อมูลเรียบร้อยแล้ว");
          setModalOpen(false);
          getData(currentPage, searchChannel);
        })
        .catch((e) => {
          console.log(e);
          Notification.Show("error", "Validation", e.response.data.message);
        });
    }
  }

  async function manageChannel(action, data) {
    if (action === 'add') {
        await setAction('add');
        await setChannelName("");
    } else {
        setAction('update');
        await setDataItem(data);
        await setChannelName(data.name);
    }
    setModalOpen(true);
  }

  const showDeleteConfirm = (id) => {
    confirm({
      title: "Confirm Delete?",
      icon: <ExclamationCircleOutlined />,
      content: "ต้องการลบ Channel นี้ใช่หรือไม่",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        deleteChannel(id);
      },

      onCancel() {
        console.log("Cancel");
      },
    });
  };

  async function deleteChannel(id) {
    let response = await ChannelService.remove(id);
    if (response.status === 200 || response.status === 201) {
      Notification.Show("success", "Success", "ลบ Channel เรียบร้อยแล้ว");
      getData(currentPage,searchChannel);
    } else {
      Notification.Show(
        "error",
        "Error",
        "เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง"
      );
    }
  }


  async function handleChange(item) {
    let newStatus = null;

    newStatus = item.status === 'A' ? 'I' : 'A';

    let newData = data;

    newData.map((newData) => {
      if (newData.channel_id === item.channel_id) {
        newData.status = newStatus;
      }
    });

    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;
    
    channel.channel_id = item.channel_id;
    channel.status = newStatus;

    channel.updated_by = action_by;
    channel.updated_datetime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

    await ChannelService.updateStatus(channel.channel_id, channel)
      .then((response) => {
        getData(currentPage, searchChannel);
      })
      .catch((e) => {
        console.error(e.response.data.message);
        Notification.Show(
          "error",
          "Error",
          "เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง"
        );
      });
  }

  return (
    <div className="container">
      <div className="text-right container-fluid">
        <Button
          className="btn-primary px-3"
          onClick={() => {
            manageChannel('add', null);
          }}
        >
          เพิ่ม Channel
        </Button>
      </div>

      <h5 className="mt-2 font-weight-bold">Channels</h5>
      <div className="line-highlight">
        <hr />
      </div>

      <div className="flex-fill">
        <div className="form-group mb-0 mt-2">
          <Input
            placeholder="Channel Name"
            prefix={<UserOutlined />}
            value={searchChannel}
            onChange={onChangeSearchChannel}
          />
        </div>

        <div class="table_wrapper">
          <table className="table border mb-0 mt-4">
            <thead>
              <tr>
                <th className="text-center">ID</th>
                <th className="text-left">Channel Name</th>
                <th className="text-center">Status</th>
                <th>แก้ไข</th>
                <th>ลบ</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center font-weight-bold">
                        {item.channel_id}
                      </td>
                      <td className="text-left font-weight-bold">
                        {item.name}
                      </td>
                      <td className="text-center">
                        <Switch
                          onChange={() => handleChange(item)}
                          checkedChildren="Active"
                          unCheckedChildren="InActive"
                          checked={item.status === 'A' ? true : false}
                        />
                      </td>
                      <td>
                        <Tooltip title="แก้ไข" className="mr-2">
                          <Button
                            type="primary"
                            shape="circle"
                            size="small"
                            className="btn-secondary"
                            icon={<EditOutlined />}
                            onClick={() => {
                              manageChannel('update', item);
                            }}
                          />
                        </Tooltip>
                      </td>
                      <td>
                      <Button
                          type="primary"
                          shape="circle"
                          size="small"
                          className="btn-danger"
                          icon={<DeleteOutlined />}
                          onClick={() => showDeleteConfirm(item.channel_id)}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <Modal
          title="Channel"
          footer={null}
          maskClosable={false}
          visible={ModalOpen}
          onCancel={() => setModalOpen(false)}
        >
          <div className="row">
            <div className="col-12">
              <label>
                Channel Name
                <span className="text-danger">*</span>
              </label>
              <Input
                value={channelName}
                onChange={(e) => setChannelName(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-6"></div>

            <div className="col-6">
              <div className="mt-2 text-right">
                <button
                  type="button"
                  className="btn btn-secondary mr-2"
                  onClick={() => {
                    setModalOpen(false);
                  }}
                >
                  ยกเลิก
                </button>
                <button
                  type="button"
                  onClick={() => {
                    SaveChannel();
                  }}
                  className="btn btn-primary mr-2"
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <div className="text-right mt-4">
          {data && data.length > 0 && (
            <ContentPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={changePage.bind(this)}
              pageShow={10}
            />
          )}
        </div>
      </div>
    </div>
  );
}
