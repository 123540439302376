import { httpClient } from "../utils/HttpClient";

const getAll = (page_no, filter_name, filter_category, filter_writer) => {
  return httpClient.get("/admin/ebook", {
    params: {
      page: page_no,
      book_name: filter_name,
      category_id: filter_category,
      writer_name: filter_writer,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const get = (book_id) => {
  return httpClient.get(`/admin/ebook/${book_id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getEBookCategory = () => {
  return httpClient.get(`/admin/category/ebook`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const create = (data) => {
  return httpClient.post("/admin/ebook", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const update = (book_id, data) => {
  return httpClient.patch(`/admin/ebook/${book_id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const remove = (book_id) => {
  return httpClient.delete(`/admin/ebook/${book_id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const updateStatus = (book_id, data) => {
  return httpClient.patch(`/admin/ebook/status/${book_id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export default {
  getAll,
  get,
  create,
  update,
  remove,
  updateStatus,
  getEBookCategory,
};
