import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ArticleService from "../services/ArticleService";
import moment from "moment";
import { Input, Select, Button, Tag, Spin,AutoComplete } from "antd";

import { PictureOutlined } from "@ant-design/icons";
import ContentPagination from "../utils/Pagination";
import UtilityService from "../services/UtilityService";
import Notification from "../utils/Notification";

const { Option } = Select;

export default function Article() {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(5);
  const [searchArticleName, setSearchArticleName] = useState("");
  const [searchCategoryID, setSearchCategoryID] = useState("");
  const [searchWriterID, setSearchWriterID] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPage] = useState(1);
  const [articleCategory, setArticleCategory] = useState("");
  const [writer, setWriter] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("token")) history.push("/login");

    var result = localStorage.getItem("user_profile");
    if (result) {
      if (JSON.parse(result).article === false) {
        history.push("/login");
      }
    }

    getData(currentPage, searchArticleName, searchCategoryID, searchWriterID);
    getArticleCategory();
    getAllWriter();
  }, [0]);

  const getData = async (page, filterName, filterCategory, filterWriter) => {
    setLoading(true);
    await ArticleService.getAll(page, filterName, filterCategory, filterWriter)
      .then((res) => {
        setLoading(false);
        const reponse = res.data;

        const totalPages = Math.ceil(reponse.total / perPage);
        setTotalPage(totalPages);
        setData(reponse.articles);
      })
      .catch((e) => {
        setLoading(false);
        if (e.message) Notification.Show("error", "Error", e.message);
        else Notification.Show("error", "Error", e.response.data.message);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
        Notification.Show("error", "Error", e.message);
      });
  };

  const getArticleCategory = async () => {
    await ArticleService.getArticleCategory()
      .then((res) => {
        const reponse = res.data;
        setArticleCategory(reponse.category);
      })
      .catch((e) => {
        console.log(e);
        //Notification.Show("error", "Error", e.response.data.message);
      });
  };

  const getAllWriter = async () => {
    await UtilityService.getAllWriter()
      .then((res) => {
        const reponse = res.data;
        console.log(res.data);
        setWriter(reponse.writers);
      })
      .catch((e) => {
        console.log(e);
        //Notification.Show("error", "Error", e.response.data.message);
      });
  };

  function changePage(page) {
    setCurrentPage(page);
    getData(page, searchArticleName, searchCategoryID, searchWriterID);
  }

  const onChangeSearchArticleName = (e) => {
    const searchArticle = e.target.value;
    setSearchArticleName(searchArticle);
    getData(currentPage, searchArticle, searchCategoryID, searchWriterID);
  };

  const onChangeSearchCategoryID = (category_id, e) => {
    setSearchCategoryID(category_id);
    getData(currentPage, searchArticleName, category_id, searchWriterID);
  };

  const onChangeSearchWriterID = (writer_id, e) => {
    setSearchWriterID(writer_id);
    getData(currentPage, searchArticleName, searchCategoryID, writer_id);
  };

  function formatDate(date) {
    if (date) {
      return moment(date).utc().format("DD/MM/YYYY HH:mm");
    }
  }

  function formatCategory(value) {
    if (value) {
      let categories = [];
      let addedIdList = [];

      value.map((item) => {
        const isExist =
          addedIdList.filter((category) => {
            return category === item.category.category_id;
          }).length > 0;

        if (!isExist) {
          categories.push(<Tag color="gold">{item.category.name}</Tag>);

          addedIdList.push(item.category.category_id);
        }
      });

      return categories;
    }
  }

  return (
    <div className="container">
      <div className="text-right container-fluid">
        <Button
          className="btn-primary px-4"
          size=""
          onClick={() => history.push("/manage/article/add")}
        >
          เพิ่มบทความ
        </Button>
      </div>

      <h5 className="mt-2 font-weight-bold">
        รายการบทความ <Spin style={{ marginLeft: 10 }} spinning={loading} />
      </h5>
      <div className="line-highlight">
        <hr />
      </div>

      <div className="flex-fill">
        <div className="row mt-4 pt-3">
          <div className="col-sm-4">
            <div className="form-group">
              <label>ชื่อ</label>
              <Input
                placeholder="ค้นหาด้วย ชื่อบทความ"
                value={searchArticleName}
                onChange={onChangeSearchArticleName}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>หมวดหมู่</label>
              <Select
                defaultValue=""
                className="w-100"
                onSelect={(value, event) =>
                  onChangeSearchCategoryID(value, event)
                }
              >
                <Option value="">เลือกหมวดหมู่</Option>
                {articleCategory &&
                  articleCategory.map((item, index) => {
                    return (
                      <Option value={item.category_id}>{item.name}</Option>
                    );
                  })}
              </Select>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>นักเขียน</label>
              <Select
                defaultValue=""
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                className="w-100"
                onSelect={(value, event) =>
                  onChangeSearchWriterID(value, event)
                }
              >
                <Option value="">เลือกนักเขียน</Option>
                {writer &&
                  writer.map((item, index) => {
                    return <Option value={item.writer_id}>{item.name}</Option>;
                  })}
              </Select>
            </div>
          </div>
        </div>

        {data &&
          data.map((item, index) => {
            return (
              <div className="card mt-2">
                <div className="card-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 text-right text-grey font-12 ml-0 mr-0 pl-0 pr-0">
                        {item.status === "A" ? (
                          <Tag
                            style={{ fontSize: "16px", padding: 7 }}
                            color="green"
                          >
                            Active
                          </Tag>
                        ) : (
                          <Tag
                            style={{ fontSize: "16px", padding: 7 }}
                            color="red"
                          >
                            InActive
                          </Tag>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-5 col-sm-3">
                        <div>
                          <div className="position-relative my-2">
                            {item.cover_image_url ? (
                              <div>
                                <img
                                  src={item.cover_image_url}
                                  className="img-fluid"
                                />
                              </div>
                            ) : (
                              <div
                                className="image-placeholder d-flex flex-column align-items-center justify-content-center"
                                style={{ width: 250, height: 150 }}
                              >
                                <div>
                                  <PictureOutlined
                                    style={{ fontSize: 30, color: "#bbb" }}
                                  />
                                </div>
                                <div className="font-12">Article Image</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-7 col-sm-9">
                        <div className="d-flex ml-4">
                          <span className="align-title font-weight-bold">
                            ชื่อบทความ:
                          </span>
                          <span className="text-left ml-4">{item.name}</span>
                        </div>

                        <div className="d-flex mt-3 ml-4">
                          <span className="align-title font-weight-bold">
                            หมวดหมู่ :
                          </span>
                          <span className="text-left ml-4">
                            {formatCategory(item.categorys)}
                          </span>
                        </div>

                        <div className="d-flex mt-3 ml-4">
                          <span className="align-title font-weight-bold">
                            นักเขียน :
                          </span>
                          <span className="text-left ml-4">
                            {item.writer.name}
                          </span>
                        </div>

                        <div className="d-flex mt-3 ml-4">
                          <span className="align-title font-weight-bold">
                            วันที่เผยแพร่ :
                          </span>
                          <span className="text-left ml-4">
                            {formatDate(item.publish_datetime)}
                          </span>
                        </div>

                        <div className="text-right mt-4">
                          <Button
                            className="btn-secondary mr-2"
                            onClick={() =>
                              history.push(
                                "/manage/article/update/" + item.article_id
                              )
                            }
                          >
                            ดูรายละเอียด/แก้ไข
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

        <div className="text-right mt-4">
          {data && data.length > 0 && (
            <ContentPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={changePage.bind(this)}
              pageShow={5}
            />
          )}
        </div>
      </div>
    </div>
  );
}
