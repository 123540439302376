import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import WebboardService from "../services/WebboardService";
import {
  Input,
  Button,
  Tooltip,
  Tag,
  Select,
  Spin,
  Switch,
  Popconfirm,
  Modal,
  Comment,
  notification,
} from "antd";
import {
  SearchOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import ContentPagination from "../utils/Pagination";
import moment from "moment";
import Notification from "../utils/Notification";

const { Option } = Select;
const { confirm } = Modal;

export default function Thread() {
  const initialEditState = {
    id: null,
  };

  const history = useHistory();
  const { topic_id } = useParams();
  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchComment, setSearchComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPage] = useState(1);
  const [topicContent, setTopicContent] = useState("");
  const [topicName, setTopicName] = useState("");
  const [ModalOpen, setModalOpen] = useState(false);
  const [totalComment, setTotalComment] = useState(0);
  const [edit, setEdit] = useState(initialEditState);

  useEffect(() => {
    if (!localStorage.getItem("token")) history.push("/login");

    var result = localStorage.getItem("user_profile");
    if (result) {
      if (JSON.parse(result).webboard === false) {
        history.push("/login");
      }
    }

    getData(topic_id, currentPage, searchComment);
  }, [topic_id]);

  const getData = async (topic_id, page, searchComment) => {
    setLoading(true);
    await WebboardService.getAllThread(topic_id, page, searchComment)
      .then((res) => {
        setLoading(false);
        const response = res.data;
        setData(response.thread);
        setTopicContent(response.topic_content);
        setTopicName(response.topic_name);
        setTotalComment(response.total);
        const totalPages = Math.ceil(response.total / perPage);
        setTotalPage(totalPages);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        let error = e.response ? e.response.data.message : e.message;
        Notification.Show("error", "Error", error);
      });
  };

  function changePage(page) {
    setCurrentPage(page);
    getData(topic_id, page, searchComment);
  }

  const onChangeSearchContent = (e) => {
    const content = e.target.value;
    setSearchComment(content);
    getData(topic_id, currentPage, content);
  };

  function formatDate(date) {
    if (date) {
      return moment(date).utc().format("DD/MM/YYYY HH:mm");
    }
  }

  const showDeleteConfirm = (id) => {
    confirm({
      title: "Confirm Delete?",
      icon: <ExclamationCircleOutlined />,
      content: "ต้องการลบความคิดเห็นนี้ใช่หรือไม่",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        deleteThread(id);
      },

      onCancel() {
        console.log("Cancel");
      },
    });
  };

  async function deleteThread(id) {
    edit.id = id;

    let response = await WebboardService.deleteThread(edit);
    if (response.status === 200 || response.status === 201) {
      Notification.Show("success", "Success", "ลบความคิดเห็นนี้เรียบร้อยแล้ว");
      getData(topic_id, currentPage, searchComment);
    } else {
      Notification.Show(
        "error",
        "Error",
        "เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง"
      );
    }
  }
  function openModalComment() {
    setModalOpen(true);
  }

  return (
    <div className="container">
      <h5 className="mt-2 font-weight-bold text-primary">
        {topicName}
        <Spin style={{ marginLeft: 10 }} spinning={loading} />
      </h5>
      <div className="line-highlight">
        <hr />
      </div>

      <div className="text-right">
        <>
          {totalComment === 0 ? (
            <Button disabled className="mt-2">
              กระทู้นี้ไม่มีการแสดงความคิดเห็น
            </Button>
          ) : (
            <>
              <Button
                className="btn-primary mt-2"
                onClick={() => {
                  openModalComment();
                }}
              >
                ดูความคิดเห็น
              </Button>
            </>
          )}
        </>
      </div>

      <div className="text-center row mt-4">
        <div className="col-sm-12">
          <p dangerouslySetInnerHTML={{ __html: topicContent }} />
        </div>
      </div>

      {/* <div className="text-right">
        <Button
          className="btn-primary mt-2"
          onClick={() => {
            history.push("/webboard");
          }}
        >
          ย้อนกลับ
        </Button>
      </div> */}

      <Modal
        title="ความคิดเห็น"
        footer={null}
        width={850}
        maskClosable={false}
        visible={ModalOpen}
        onCancel={() => setModalOpen(false)}
        style={{ height: "calc(100vh - 200px)" }}
        bodyStyle={{ overflowY: "scroll" }}
      >
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label>ค้นหา</label>
              <Input
                placeholder="ค้นหา ความคิดเห็น"
                value={searchComment}
                onChange={onChangeSearchContent}
              />
            </div>
          </div>
        </div>

        <div class="table_wrapper">
          <table className="table border" width={200}>
            <thead>
              <tr>
                <th className="text-center">Date/Time</th>
                <th className="text-center">User</th>
                <th className="text-center">Comment</th>
                <th className="text-center">Delete</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">
                        {formatDate(item.created_datetime)}
                      </td>
                      <td className="text-center">
                        <Button
                          type="link"
                          onClick={() =>
                            history.push(
                              "/manage/member/update/" + item.user_id
                            )
                          }
                        >
                          {item.display_name}
                        </Button>
                      </td>
                      <td
                        className="text-center"
                        style={{ wordBreak: "break-word" }}
                      >
                        <p
                          className="mb-0"
                          dangerouslySetInnerHTML={{
                            __html: item.content,
                          }}
                        />
                      </td>

                      <td className="text-center">
                        <Button
                          type="primary"
                          shape="circle"
                          size="small"
                          className="btn-danger"
                          icon={<DeleteOutlined />}
                          onClick={() => showDeleteConfirm(item.id)}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="text-right">
          {data && data.length > 0 && (
            <ContentPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={changePage.bind(this)}
              pageShow={10}
            />
          )}
        </div>
      </Modal>
    </div>
  );
}
