import { httpClient } from "../utils/HttpClient";

const getAll = (page_no,filter_name, filter_email, filter_type) => {
  return httpClient.get("/admin/member", {
    params: {
      page: page_no,
      display_name: filter_name,
      email: filter_email,
      type: filter_type,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getMemberByID = (filter_member_id) => {
  return httpClient.get("/admin/member_detail", {
    params: {
      id:filter_member_id
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const updateMember = (data) => {
  return httpClient.patch("/admin/member", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getUserStatus = () => {
  return httpClient.get("/admin/member/user_status", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export default {
  getAll,
  getMemberByID,
  updateMember,
  getUserStatus
};
