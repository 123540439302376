import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Input,
  Button,
  Popconfirm,
  Switch,
  Modal,
  notification,
  Select,
  Card,
  DatePicker,
  Spin,
  Radio,
} from "antd";
import VideoService from "../services/VideoService";
import UtilityService from "../services/UtilityService";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "2read-ckeditor5-custom-build";
import imageCompression from "browser-image-compression";
import MyUploadAdapter from "../utils/MyUploadAdapter";
import { PictureOutlined } from "@ant-design/icons";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Notification from "../utils/Notification";
import "moment/locale/th";
import axios from "axios";
import { storage } from "../firebase";
import {
  ref,
  getDownloadURL,
  uploadBytes,
  uploadBytesResumable,
} from "../../node_modules/firebase/storage";
import { DefaultPlayer as Video } from 'react-html5video'
import 'react-html5video/dist/styles.css'

const { Option } = Select;

const ManageVideo = (props) => {
  const history = useHistory();
  const initialVideoState = {
    name: "",
    cover_image_url: "",
    category_id: "",
    is_youtube: false,
    is_specify_nft:false,
    video_url: "",
    description: "",
    status: "",
    created_datetime: null,
    created_by: null,
    updated_datetime: null,
    updated_by: null,
  };

  const { id } = useParams();
  const [video, setVideo] = useState(initialVideoState);
  const [submitted, setSubmitted] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [cropperRef, setCropperRef] = useState(React.createRef());
  const [vdoImage, setVdoImage] = useState();
  const [status, setStatus] = useState(true);
  const [action, setAction] = useState();
  const [dateFormat, setDateFormat] = useState("DD-MM-YYYY HH:mm");
  const [videoCategory, setVideoCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingVdo, setLoadingVdo] = useState(false);
  const [category_id, setCategoryID] = useState("");
  const [videoFile, setVideoFile] = useState();
  const [fileList, setFileList] = useState([]);
  const [progressPercent, setProgressPercent] = useState(0);
  const [videoUrl, setVideoUrl] = useState("");
  const [isSpecifyNFT, setIsSpecifyNFT] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("token")) history.push("/login");

    var result = localStorage.getItem("user_profile");
    if (result) {
      if (JSON.parse(result).course === false) {
        history.push("/login");
      }
    }

    setAction(props.action);
    getVideoCategory();
    if (props.action === "update") getVideo(id);
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setVideo({ ...video, [name]: value });
  };

  // const onChangeRadioVideoType = (e) => {
  //   video.video_url = "";
  //   setVideo(video);
  //   setVideoType(e.target.value);
  // };

  const getVideo = (id) => {
    if (id) {
      VideoService.get(id)
        .then((response) => {
          console.log(response.data);

          setVideo(response.data);
          setCategoryID(response.data.category_id);
          setVideoUrl(response.data.video_url);
          setVdoImage(response.data.cover_image_url);
          setIsSpecifyNFT(response.data.is_specify_nft);
          if (response.data.status === "A") setStatus(true);
          else setStatus(false);
        })
        .catch((e) => {
          console.log(e);
          Notification.Show(
            "error",
            "From Web Service",
            e.response.data.message
          );
        });
    }
  };

  function validateYouTubeUrl(urlToParse) {
    if (urlToParse) {
      var regExp =
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      if (urlToParse.match(regExp)) {
        return true;
      }
    }
    return false;
  }

  const getVideoCategory = async () => {
    await VideoService.getVideoCategory()
      .then((res) => {
        const response = res.data;
        setVideoCategory(response.vdo_category);
      })
      .catch((e) => {
        console.log(e);
        //Notification.Show("error", "Error", e.response.data.message);
      });
  };

  const ShowNotification = (type, header, message) =>
    notification[type]({
      message: header,
      description: message,
    });

  async function AddUpdateVideo() {
    if (!video.name) {
      Notification.Show("error", "Validation", "กรุณาระบุ ชื่อ Video");
      return;
    }

    if (!category_id) {
      Notification.Show("error", "Validation", "กรุณาระบุ หมวดหมู่");
      return;
    }

    if (!videoUrl) {
      Notification.Show("error", "Validation", "กรุณา Upload ไฟล์ Video");
      return;
    }

    if (!video.description) {
      Notification.Show("error", "Validation", "กรุณาระบุ รายละเอียด Video");
      return;
    }

    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;

    if (action === "add") {
      if (imageFile) {
        await uploadImage(imageFile);
      } else video.cover_image_url = "";

      video.created_by = action_by;
      video.created_datetime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    } else {
      video.id = id;

      if (imageFile) {
        await uploadImage(imageFile);
      } else video.cover_image_url = vdoImage;
    }

    if (!video.cover_image_url) {
      Notification.Show("error", "Validation", "กรุณาระบุ อัพโหลดรูปปก Video");
      return false;
    }

    video.category_id = category_id;

    if (status) video.status = "A";
    else video.status = "I";

    video.video_url = videoUrl;

    video.updated_by = action_by;
    video.updated_datetime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    video.is_specify_nft = isSpecifyNFT;

    setLoading(true);
    if (action === "add") {
      VideoService.create(video)
        .then((response) => {
          ShowNotification(
            "success",
            "Success",
            "บันทึกข้อมูล Video เรียบร้อยแล้ว"
          );
          setLoading(false);
          setSubmitted(true);
          history.push("/video");
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
          Notification.Show("error", "Error", e.response.data.message);
        });
    } else {
      VideoService.update(video)
        .then((response) => {
          ShowNotification(
            "success",
            "Success",
            "บันทึกข้อมูล Video เรียบร้อยแล้ว"
          );
          setLoading(false);
          if(!video.is_specify_nft){
            deleteAllVideoSpecifyNFT(video);
          }
          history.push("/video");
        })
        .catch((e) => {
          console.error(e.response.data.message);
          setLoading(false);
          Notification.Show("error", "Error", e.response.data.message);
        });
    }
  }

  const deleteAllVideoSpecifyNFT = (video) => {
    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;

    video.video_id = video.id;
    video.updated_by = action_by;
    video.updated_datetime = moment(new Date()).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    VideoService.deleteVideoSpecifyNFT(video)
      .then((response) => {
        
      })
      .catch((e) => {
        console.log(e);
        Notification.Show("error", "Error", e.response.data.message);
      });
  };


  const deleteVideo = () => {
    VideoService.deleteVideo(id)
      .then((response) => {
        Notification.Show("success", "Success", "ลบข้อมูล Video เรียบร้อยแล้ว");
        history.push("/video");
      })
      .catch((e) => {
        Notification.Show("error", "Error", e.response.data.message);
      });
  };

  const cancel = () => {
    history.push("/video");
  };

  function confirmDialog(e) {
    deleteVideo();
  }

  function cancelDialog(e) {}

  function customUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      // Configure the URL to the upload script in your back-end here!
      return new MyUploadAdapter(loader);
    };
  }

  function onSelectFile(e, type) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      //reader.addEventListener("load", () => setArticleImage(reader.result));
      reader.addEventListener("load", () => setImageUrl(reader.result));
      reader.readAsDataURL(e.target.files[0]);

      setCropModalOpen(true);
      setImageFile(e.target.files[0]);
    }
  }

  function cropImage() {
    const cropper = cropperRef.current.cropper;

    cropper.getCroppedCanvas().toBlob((blob) => {
      const fileName = imageFile.name;

      const file = new File([blob], fileName, {
        lastModifiedDate: new Date(),
        type: imageFile.type,
      });
      const cropppedPreview = cropper.getCroppedCanvas().toDataURL();
      setVdoImage(cropppedPreview);
      setImageFile(file);
      closeCropModal();
    });
  }

  function closeCropModal() {
    setCropModalOpen(false);
  }

  function removeImage() {
    setVdoImage("");
    setImageUrl("");
  }

  async function uploadImage(image) {
    const options = {
      maxSizeMB: 2,
      useWebWorker: true,
      initialQuality: 1,
    };
    try {
      const compressedFile = await imageCompression(image, options);
      const file = new File([compressedFile], image.name, { type: image.type });

      if (image) {
        let formData = new FormData();
        let imagefile = file;

        formData.append("upload", imagefile);

        await UtilityService.uploadImage(formData)
          .then((response) => {
            video.cover_image_url = response.data.url;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getVideoDuration = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(media.duration);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => reject(error);
    });

  async function uploadVideo(file) {
    if (file) {
      if (!file) return null;
      const storageRef = ref(storage, `files/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgressPercent(progress);
        },
        (error) => {
          alert(error);
        },
        () => {
          // e.target[0].value = ''
          getDownloadURL(storageRef).then((downloadURL) => {
            console.log(downloadURL);
            setVideoUrl(downloadURL);
          });
        }
      );
    }
  }

  function onChangeStatus(checked) {
    setStatus(checked);
  }

  function onChangeCategory(value) {
    setCategoryID(value);
  }

  function getYoutubeUrlEmbeded(url) {
    var regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length === 11) {
      return "//www.youtube.com/embed/" + match[2];
    }
  }

  async function ChunckFile(file) {
    if (!file) return;
    // Create a slice

    let size = 1024 * 50; //50KB Section size
    let fileChunks = [];
    let index = 0; //Section num
    for (let cur = 0; cur < file.size; cur += size) {
      fileChunks.push({
        hash: index++,
        chunk: file.slice(cur, cur + size),
      });
    }
    // Control concurrency and breakpoint continuation
    const uploadFileChunks = async function (file_list) {
      console.log(file_list);
      if (file_list.length === 0) {
        //All tasks complete, merge slices
        console.log("merge");
        console.log(file.name);
        await axios({
          method: "get",
          url: "http://localhost:3000/admin/upload/merge_vdo",
          params: {
            filename: file.name,
          },
        });
        return;
      }
      let pool = []; //Concurrent pool
      let max = 3; //Maximum concurrency
      let finish = 0; //Quantity completed
      let failList = []; //A list of failures
      for (let i = 0; i < file_list.length; i++) {
        let item = file_list[i];
        let formData = new FormData();
        formData.append("filename", file.name);
        formData.append("hash", item.hash);
        formData.append("chunk", item.chunk);
        console.log(item.chunk);
        // Upload section
        let task = axios({
          method: "post",
          url: "http://localhost:3000/admin/upload/slice_vdo",
          data: formData,
        });
        task
          .then((data) => {
            //Remove the Promise task from the concurrency pool when the request ends
            let index = pool.findIndex((t) => t === task);
            pool.splice(index);
          })
          .catch(() => {
            failList.push(item);
          })
          .finally(() => {
            finish++;
            //All requests are requested complete
            if (finish === file_list.length) {
              uploadFileChunks(failList);
            }
          });
        pool.push(task);
        if (pool.length === max) {
          //Each time the concurrent pool finishes running a task, another task is plugged in
          await Promise.race(pool);
        }
      }
    };

    uploadFileChunks(fileChunks);
  }

  const onChangeSpecifyNFT = (e) => {
    setIsSpecifyNFT(e.target.value);
  };

  return (
    <div className="container">
      <div className="d-flex mt-4 align-items-stretch">
        <div className="flex-fill">
          <div className="header-bar">ข้อมูล Video</div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4"></div>

              <div className="col-sm-4">
                <div className="position-relative my-2 mt-4">
                  {vdoImage ? (
                    <img src={vdoImage} className="img-fluid" />
                  ) : (
                    <div
                      className="image-placeholder d-flex flex-column align-items-center justify-content-center mt-5"
                      style={{ height: 240 }}
                    >
                      <div>
                        <PictureOutlined
                          style={{ fontSize: 30, color: "#bbb" }}
                        />
                      </div>
                      <div className="font-12">อัพโหลดรูป</div>
                    </div>
                  )}

                  <input
                    type="file"
                    accept="image/*"
                    className="input-file-hidden"
                    onChange={(event) => {
                      onSelectFile(event, "article");
                    }}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                  />
                </div>
                {vdoImage && (
                  <div className="text-right mt-2">
                    <Button
                      className="btn btn-sm btn-danger"
                      onClick={() => removeImage()}
                    >
                      ลบ
                    </Button>
                  </div>
                )}
                <div className="text-right text-danger">
                  (ขนาดรูปที่แนะนำ 726 x 409 px)
                </div>
              </div>

              <div className="col-sm-4">
                <div className="text-right mt-3">
                  <Switch
                    onChange={onChangeStatus}
                    checkedChildren="Active"
                    unCheckedChildren="InActive"
                    checked={status}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group mb-3 mt-2">
                  <label>
                    ชื่อ Video
                    <span className="text-danger">*</span>
                  </label>
                  <Input
                    id="name"
                    required
                    value={video.name}
                    onChange={handleInputChange}
                    name="name"
                  />
                </div>

                <div className="form-group mb-3 mt-4">
                  <label>
                    หมวดหมู่ <span className="text-danger">*</span>
                  </label>
                  <Select
                    defaultValue=""
                    className="w-100"
                    value={category_id}
                    onSelect={(value, event) => onChangeCategory(value, event)}
                  >
                    <Option value="">เลือกหมวดหมู่</Option>
                    {videoCategory &&
                      videoCategory.map((item, index) => {
                        return <Option value={item.id}>{item.name}</Option>;
                      })}
                  </Select>
                </div>

                <div className="form-group mt-4">
                  <label>จำกัด NFT ที่เข้าดูวีดีโอนี้ได้</label>
                  <div className="form-group mt-2">
                    <Radio.Group
                      onChange={onChangeSpecifyNFT}
                      value={isSpecifyNFT}
                    >
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </div>
                </div>

                <div className="form-group pb-3 mt-4">
                  <label>
                    อัพโหลดไฟล์ Video <span className="text-danger">*</span>
                    {/* <Spin style={{ marginLeft: 20 }} spinning={loadingVdo} /> */}
                    <progress value={progressPercent} max="100" style={{ marginLeft:20}} />
                    <label className="ml-2">{progressPercent}%</label>
                  </label>

                  <input
                    id="video_url"
                    name="video_url"
                    type="file"
                    accept="video/*"
                    onChange={(event) => {
                      video.video_url = null;
                      setVideoFile(null);
                      uploadVideo(event.currentTarget.files[0]);
                    }}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    className="form-control mt-2"
                    style={{ height: 45 }}
                  />
                </div>



                <div className="form-group pb-3">
                  <div className="row">
                    <div className="col-2"></div>
                    <div className="col-8">
                      {videoUrl ? (
                        <Video 
                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                        poster={vdoImage}
                      >
                        <source src={videoUrl} type="video/mp4" />
                      </Video>
                      ) : null}
                    </div>
                    <div className="col-2"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label>
                    รายละเอียด Video<span className="text-danger">*</span>
                  </label>
                  <div className="content-wrapper">
                    <CKEditor
                      editor={ClassicEditor}
                      data={video.description}
                      config={{
                        extraPlugins: [customUploadAdapterPlugin],
                        indentBlock: {
                          offset: 1,
                          unit: "em",
                        },
                        image: {
                          resize: true,
                          toolbar: [
                            "linkImage",
                            "|",
                            "imageTextAlternative",
                            "|",
                            "imageStyle:full",
                            "imageStyle:alignLeft",
                            "imageStyle:alignCenter",
                            "imageStyle:alignRight",
                          ],
                          styles: [
                            "full",
                            "alignCenter",
                            "alignLeft",
                            "alignRight",
                          ],
                        },
                      }}
                      onInit={(editor) => {}}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        video.description = data;
                      }}
                      onBlur={(event, editor) => {}}
                      onFocus={(event, editor) => {}}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-2">
          <div>
            {action === "update" && (
              <Popconfirm
                title="ต้องการลบเนื้อหานี้ออกจากเว็บไซต์?"
                onConfirm={confirmDialog}
                onCancel={cancelDialog}
                okText="ยืนยันการลบ"
                cancelText="ยกเลิก"
              >
                <Button className="btn-danger px-3">ลบข้อมูล</Button>
              </Popconfirm>
            )}
          </div>
        </div>

        <div className="col-10 text-right">
          <Button className="btn-secondary mr-2" onClick={cancel}>
            ยกเลิก
          </Button>
          <Button className="btn-primary" onClick={AddUpdateVideo}>
            บันทึกข้อมูล
          </Button>
          <Spin style={{ marginLeft: 10 }} spinning={loading} />
        </div>
      </div>

      <Modal
        title={null}
        footer={null}
        width={800}
        maskClosable={false}
        visible={cropModalOpen}
        onCancel={() => setCropModalOpen(false)}
      >
        <div className="d-flex">
          <div className="flex-fill thumbnail-crop-area">
            <Cropper
              src={imageUrl}
              aspectRatio={726 / 409}
              autoCropArea={1}
              preview=".book-preview"
              viewMode={3}
              ref={cropperRef}
            />
          </div>

          <div className="mt-4 book-preview-area ml-4">
            <div className="font-weight-bold text-center mb-2">ตัวอย่างรูป</div>

            <div className="book-preview" />
          </div>
        </div>

        <div className="mt-5 text-center">
          <button
            type="button"
            onClick={() => {
              cropImage();
            }}
            className="btn btn-primary mr-2"
          >
            ตกลง
          </button>
          <button
            type="button"
            className="btn btn-secondary mr-2"
            onClick={() => {
              setCropModalOpen(false);
            }}
          >
            ยกเลิก
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ManageVideo;
