import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ReportService from "../services/ReportService";
import {
  Input,
  Button,
  Tag,
  Select,
  Modal,
  DatePicker,
  notification,
  Spin,
} from "antd";
import ContentPagination from "../utils/Pagination";
import moment from "moment";
import Notification from "../utils/Notification";
import XLSX from "xlsx";

const { Option } = Select;

export default function ReportTaxInvoices() {
  const [data, setData] = useState([]);
  const initialTaxInvoiceState = {
    id: null,
    tax_invoice_no: "",
    issued_date: "",
    updated_by: "",
    updated_datetime: null,
    status: null,
  };
  const [perPage, setPerPage] = useState(10);
  const [searchOrderNumber, setSearchOrderNumber] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPage] = useState(1);
  const [ModalOpen, setModalOpen] = useState(false);
  const [taxInvoiceIssue, setTaxInvoiceIssue] = useState(
    initialTaxInvoiceState
  );
  const [taxInvoiceNo, setTaxInvoiceNo] = useState();
  const [taxInvoiceIssueDate, setTaxInvoiceIssueDate] = useState(new Date());
  const [dateFormat, setDateFormat] = useState("DD-MM-YYYY");
  const [dataItem, setDataItem] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem("token")) history.push("/login");

    var result = localStorage.getItem("user_profile");
    if (result) {
      if (JSON.parse(result).purchase_management === false) {
        history.push("/login");
      }
    }

    getData(currentPage, searchOrderNumber, searchStatus);
  }, [0]);

  const getData = async (page, filterOrderNumber, filterStatus) => {
    setLoading(true);
    await ReportService.getReportTaxInvoices(
      page,
      filterOrderNumber,
      filterStatus
    )
      .then((res) => {
        const response = res.data;
        const totalPages = Math.ceil(response.total / perPage);
        setTotalPage(totalPages);
        setData(response.tax_invoices);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  function changePage(page) {
    setCurrentPage(page);
    getData(page, searchOrderNumber, searchStatus);
  }

  async function SearchData() {
    setLoading(true);
    await getData(currentPage, searchOrderNumber, searchStatus);
  }

  const onChangeSearchOrderNumber = (e) => {
    const searchOrderNumber = e.target.value;
    setSearchOrderNumber(searchOrderNumber);
  };

  const onChangeSearchStatus = (status, e) => {
    setSearchStatus(status);
  };

  function formatDate(date) {
    if (date) {
      return moment(date).utc().format("DD/MM/YYYY");
    }
  }

  function formatName(item) {
    if (item.tax_type === 1) {
      return item.first_name + " " + item.last_name;
    } else if (item.tax_type === 2) {
      if (item.branch) return item.company_name + " (" + item.branch + ")";
      else return item.company_name;
    }
  }

  function formatTaxInvoiceCompanyName(item) {
    let output_name = "";
    if (item.company_name) {
      if (item.branch) {
        output_name = item.company_name + " (" + item.branch + ")";
      } else output_name = item.company_name;
    }
    return output_name;
  }

  function formatAddress(item) {
    let output_address = "";
    if (item.address_other_info) {
      if (
        item.province === "กรุงเทพมหานคร" ||
        item.province === "กทม." ||
        item.province === "ก.ท.ม." ||
        item.province === "กทม" ||
        item.province === "กรุงเทพฯ"
      ) {
        output_address =
          item.address_other_info +
          " แขวง " +
          item.sub_district +
          " เขต " +
          item.district +
          " " +
          item.province +
          " " +
          item.zip_code;
      } else
        output_address =
          item.address_other_info +
          " ต." +
          item.sub_district +
          " อ." +
          item.district +
          " จ." +
          item.province +
          " " +
          item.zip_code;
    }
    return output_address;
  }

  function formatStatus(status) {
    // 1 = pending / 2 = issued
    if (status === 2) {
      return (
        <Tag color="green" className="mt-1">
          ออกใบกำกับภาษีแล้ว
        </Tag>
      );
    } else if (status === 1) {
      return (
        <Tag color="volcano" className="mt-1">
          รอออกใบกำกับภาษี
        </Tag>
      );
    } else {
      return status;
    }
  }

  function formatStatusString(status) {
    if(status === 2)
      return "ออกใบกำกับภาษีแล้ว";
    else if(status === 1)
      return "รอออกใบกำกับภาษี";
  }

  async function formatEditData(item) {
    if (item) {
      if (item.issued_date) {
        item.issued_date = new Date(item.issued_date);
      }

      if (item.status === 2) {
        //issued
        setTaxInvoiceNo(item.tax_invoice_no);
        setTaxInvoiceIssueDate(item.issued_date);
      } else {
        setTaxInvoiceNo("");
        setTaxInvoiceIssueDate(null);
      }

      await setDataItem(item);
      // 1 = pending / 2 = issued
      if (item.status === 2) {
        return (
          <Button
            type="primary"
            ghost
            success
            onClick={() => {
              editTaxInvoiceIssue();
            }}
          >
            แก้ไขข้อมูล
          </Button>
        );
      } else if (item.status === 1) {
        return (
          <Button
            type="primary"
            ghost
            success
            onClick={() => {
              editTaxInvoiceIssue();
            }}
          >
            กรอกข้อมูลใบกำกับภาษี
          </Button>
        );
      }
    }
  }

  function editTaxInvoiceIssue(data) {
    if (data) {
      let issued_date;
      if (data.issued_date) {
        issued_date = new Date(data.issued_date);
      }

      if (data.status === 2) {
        //issued
        setTaxInvoiceNo(data.tax_invoice_no);
        setTaxInvoiceIssueDate(issued_date);
      } else {
        //ยังไม่ออกใบกำกับภาษี
        setTaxInvoiceNo("");
        setTaxInvoiceIssueDate(issued_date);
      }

      setDataItem(data);

      setModalOpen(true);
    }
  }

  function formatTaxInvoiceType(tax_type) {
    if (tax_type) {
      if (tax_type === 1) return "บุคคลธรรมดา";
      else if (tax_type === 2) {
        return "นิติบุคคล";
      }
    }
  }

  function handleDatePickerChange(date, dateString) {
    setTaxInvoiceIssueDate(date);
  }

  const ShowNotification = (type, header, message) =>
    notification[type]({
      message: header,
      description: message,
    });

  function saveTaxIssue() {
    if (!taxInvoiceNo) {
      Notification.Show("error", "Validation", "กรุณาระบุ เลขที่ใบกำกับภาษี");
      return;
    }
    if (!taxInvoiceIssueDate) {
      Notification.Show(
        "error",
        "Validation",
        "กรุณาระบุ วันที่ออกใบกำกับภาษี"
      );
      return;
    }

    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;

    taxInvoiceIssue.id = dataItem.id;
    taxInvoiceIssue.tax_invoice_no = taxInvoiceNo;

    console.log(taxInvoiceIssueDate);
    taxInvoiceIssue.issued_date = taxInvoiceIssueDate;
    taxInvoiceIssue.updated_by = action_by;
    taxInvoiceIssue.updated_datetime = moment(new Date()).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    taxInvoiceIssue.status = 2; //issued

    ReportService.updateTaxInvoices(taxInvoiceIssue)
      .then((response) => {
        ShowNotification(
          "success",
          "Success",
          "บันทึกข้อมูลใบกำกับภาษีเรียบร้อยแล้ว"
        );
        setModalOpen(false);
        getData(currentPage, searchOrderNumber, searchStatus);
      })
      .catch((e) => {
        console.log(e);
        Notification.Show("error", "Validation", e.response.data.message);
      });
  }

  async function deleteTaxIssue() {
    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;

    taxInvoiceIssue.id = dataItem.id;
    taxInvoiceIssue.tax_invoice_no = "";
    taxInvoiceIssue.issued_date = null;
    taxInvoiceIssue.updated_by = action_by;
    taxInvoiceIssue.updated_datetime = moment(new Date()).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    taxInvoiceIssue.status = 1; //pending

    await ReportService.updateTaxInvoices(taxInvoiceIssue)
      .then((response) => {
        ShowNotification(
          "success",
          "Success",
          "ลบข้อมูลใบกำกับภาษีเรียบร้อยแล้ว"
        );
        setTaxInvoiceNo("");
        setTaxInvoiceIssueDate(null);
        getData(currentPage, searchOrderNumber, searchStatus);
      })
      .catch((e) => {
        console.log(e);
        Notification.Show("error", "Validation", e.response.data.message);
      });
  }

  async function exportData() {
    let response = null;
    setLoading(true);

    await ReportService.getReportTaxInvoices(
      "",
      searchOrderNumber,
      searchStatus
    )
      .then((res) => {
        response = res.data;
        setData(response.tax_invoices);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });

    let report = response.tax_invoices.map((item, idx) => {
      return [
        item.order_number,
        formatName(item),
        item.id_number,
        formatTaxInvoiceType(item.tax_type),
        formatAddress(item),
        item.tax_invoice_no,
        formatDate(item.issued_date),
        formatStatusString(item.status),
      ];
    });

    report = [
      [
        "เลขที่คำสั่งซื้อ",
        "ชื่อ-นามสกุล",
        "เลขประจำตัวผู้เสียภาษี",
        "ประเภท",
        "ที่อยู่",
        "เลขที่ใบกำกับ",
        "วันที่ออกใบกำกับ",
        "สถานะการออกใบกำกับ"
      ],
      ...report,
    ];

    if (report) {
      await downloadExportData(report);
      getData(currentPage, searchOrderNumber, searchStatus);
    }
  }

  async function downloadExportData(report) {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(report);
    XLSX.utils.book_append_sheet(workBook, workSheet, ["report-taxinvoice"]);
    XLSX.writeFile(workBook, `report-taxinvoice.xlsx`);

    //this.exportData(0);
  }

  return (
    <div className="container">
      <h5 className="mt-2 font-weight-bold">
        รายการขอใบกำกับภาษี <Spin spinning={loading} />
      </h5>
      <div className="line-highlight">
        <hr />
      </div>

      <div className="flex-fill">
        <div className="row mt-4 pt-3">
          <div className="col-sm-4">
            <div className="form-group">
              <label>หมายเลขคำสั่งซื้อ</label>
              <Input
                placeholder="ค้นหาด้วย หมายเลขคำสั่งซื้อ"
                value={searchOrderNumber}
                onChange={onChangeSearchOrderNumber}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>สถานะการออกใบกำกับภาษี</label>
              <Select
                defaultValue=""
                className="w-100"
                onSelect={(value, event) => onChangeSearchStatus(value, event)}
              >
                <Option value="">ทั้งหมด</Option>
                <Option value="1">รอออกใบกำกับภาษี</Option>
                <Option value="2">ออกใบกำกับภาษีแล้ว</Option>
              </Select>
            </div>
          </div>

          <div className="col-sm-3">
            <div className="form-group" style={{ marginTop: 25 }}>
              <Button
                className="btn-primary mr-4 px-4"
                size="large"
                onClick={SearchData}
              >
                ค้นหา
              </Button>
            </div>
          </div>
        </div>

        <div className="row mt-1 ml-1">
          <Button className=" btn-info mr-4 px-3" onClick={exportData}>
            Export
          </Button>
        </div>

        <div class="table_wrapper">
          <table
            className="table border mb-0 mt-2"
            style={{ overflowX: "auto" }}
          >
            <thead>
              <tr>
                <th className="text-center">หมายเลขคำสั่งซื้อ</th>
                <th className="text-center">ชื่อ-นามสกุล</th>
                <th className="text-center">เลขประจำตัวผู้เสียภาษี</th>
                <th className="text-center">ประเภท</th>
                <th className="text-center">ที่อยู่</th>
                <th className="text-center">เลขที่ใบกำกับภาษี</th>
                <th className="text-center">วันที่ออกใบกำกับภาษี</th>
                <th className="text-center">สถานะการออกใบกำกับภาษี</th>
                <th className="text-center">แก้ไข</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">{item.order_number}</td>
                      <td className="text-center" width={150}>
                        {formatName(item)}
                      </td>
                      <td className="text-center">{item.id_number}</td>
                      <td className="text-center">
                        {formatTaxInvoiceType(item.tax_type)}
                      </td>
                      <td className="text-center">{formatAddress(item)}</td>
                      <td className="text-center">{item.tax_invoice_no}</td>
                      <td className="text-center">
                        {formatDate(item.issued_date)}
                      </td>
                      <td className="text-center">
                        {formatStatus(item.status)}
                      </td>
                      <td className="text-center">
                        {item.status === 2 ? (
                          <Button
                            type="link"
                            onClick={() => {
                              editTaxInvoiceIssue(item);
                            }}
                          >
                            แก้ไข
                          </Button>
                        ) : (
                          <Button
                            type="link"
                            onClick={() => {
                              editTaxInvoiceIssue(item);
                            }}
                          >
                            กรอกข้อมูลใบกำกับภาษี
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <Modal
          title="ข้อมูลใบกำกับภาษี"
          footer={null}
          width={650}
          maskClosable={false}
          visible={ModalOpen}
          onCancel={() => setModalOpen(false)}
        >
          <div className="d-flex">
            <div className="form-group mb-3 mt-2 ml-4">
              <div className="row">
                <div className="col-6">
                  <label>
                    เลขที่ใบกำกับภาษี
                    <span className="text-danger">*</span>
                  </label>
                  <Input
                    value={taxInvoiceNo}
                    onChange={(e) => setTaxInvoiceNo(e.target.value)}
                  />
                </div>
                <div className="col-6">
                  <label>
                    วันที่ออกใบกำกับภาษี
                    <span className="text-danger">*</span>
                  </label>

                  {taxInvoiceIssueDate && (
                    <DatePicker
                      picker="date"
                      defaultValue={moment(taxInvoiceIssueDate, dateFormat)}
                      format={dateFormat}
                      onChange={(date, dateString) =>
                        handleDatePickerChange(date, dateString)
                      }
                      className="w-100"
                    />
                  )}

                  {!taxInvoiceIssueDate && (
                    <DatePicker
                      picker="date"
                      format={dateFormat}
                      onChange={(date, dateString) =>
                        handleDatePickerChange(date, dateString)
                      }
                      className="w-100"
                    />
                  )}
                </div>
              </div>

              <h6 className="font-weight-bold mt-4">ข้อมูลใบกำกับภาษี</h6>

              <div className="row">
                <div className="col-12">
                  <div className="d-flex mt-3 ml-2">
                    {dataItem.tax_type === 1 ? (
                      <>
                        <span
                          className="font-weight-lighter text-primary"
                          style={{ width: 170 }}
                        >
                          ชื่อ / นามสกุล :
                        </span>
                        <div className="font-weight-lighter">
                          {dataItem.first_name + " " + dataItem.last_name}
                        </div>
                      </>
                    ) : (
                      <>
                        <span
                          className="font-weight-lighter text-primary"
                          style={{ width: 170 }}
                        >
                          ชื่อ :
                        </span>
                        <div className="font-weight-lighter">
                          {formatTaxInvoiceCompanyName(dataItem)}
                        </div>
                      </>
                    )}
                  </div>

                  <div className="d-flex mt-3 ml-2">
                    <span
                      className="font-weight-lighter text-primary"
                      style={{ width: 170 }}
                    >
                      เลขประจำตัวผู้เสียภาษี :
                    </span>
                    <div className="font-weight-lighter">
                      {dataItem.id_number}
                    </div>
                  </div>

                  <div className="d-flex mt-3 ml-2 mb-1">
                    <span
                      className="font-weight-lighter text-primary"
                      style={{ width: 170 }}
                    >
                      ประเภท :
                    </span>
                    <div className="font-weight-lighter">
                      {formatTaxInvoiceType(dataItem.tax_type)}
                    </div>
                  </div>
                  <div className="d-flex mt-3 ml-2">
                    <span
                      className="font-weight-lighter text-primary"
                      style={{ width: 170 }}
                    >
                      ที่อยู่ในการออกใบกำกับภาษี :
                    </span>
                    <div className="font-weight-lighter">
                      {formatAddress(dataItem)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="mt-2 text-left">
                <button
                  type="button"
                  className="btn btn-md btn-danger ml-4"
                  onClick={() => {
                    deleteTaxIssue();
                    setModalOpen(false);
                  }}
                >
                  ลบข้อมูล
                </button>
              </div>
            </div>

            <div className="col-6">
              <div className="mt-2 text-right">
                <button
                  type="button"
                  className="btn btn-secondary mr-2"
                  onClick={() => {
                    setModalOpen(false);
                  }}
                >
                  ยกเลิก
                </button>
                <button
                  type="button"
                  onClick={() => {
                    saveTaxIssue();
                  }}
                  className="btn btn-primary mr-2"
                >
                  บันทึก
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <div className="text-right mt-4">
          {data && data.length > 0 && (
            <ContentPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={changePage.bind(this)}
              pageShow={5}
            />
          )}
        </div>
      </div>
    </div>
  );
}
