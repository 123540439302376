import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import EbookService from "../services/EbookService";
import { Input, Select, Button, Tag, Spin } from "antd";

import { PictureOutlined } from "@ant-design/icons";
import ContentPagination from "../utils/Pagination";

const { Option } = Select;

export default function EBook() {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [searchBookName, setSearchBookName] = useState("");
  const [searchWriterName, setSearchWriterName] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPage] = useState(1);
  const [bookCategory, setBookCategory] = useState("");
  const [writer, setWriter] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("token")) history.push("/login");

    var result = localStorage.getItem("user_profile");
    if (result) {
      if (JSON.parse(result).book === false) {
        history.push("/login");
      }
    }

    getData(currentPage, searchBookName, searchCategory, searchWriterName);
    getEBookCategory();
    //getAllWriter();
  }, [0]);

  const getEBookCategory = async () => {
    await EbookService.getEBookCategory()
      .then((res) => {
        const response = res.data;
        setBookCategory(response.category);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // const getAllWriter = async () => {
  //   await UtilityService.getAllWriterSelect()
  //     .then((res) => {
  //       const reponse = res.data;
  //       console.log(res.data);
  //       setWriter(reponse.writers);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  const getData = async (
    page,
    filterBookName,
    filterCategory,
    filterWriterName
  ) => {
    setLoading(true);
    await EbookService.getAll(
      page,
      filterBookName,
      filterCategory,
      filterWriterName
    )
      .then((res) => {
        setLoading(false);
        const reponse = res.data;
        const totalPages = Math.ceil(reponse.total / perPage);
        setTotalPage(totalPages);
        setData(reponse.eBooks);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  function changePage(page) {
    setCurrentPage(page);
    getData(page, searchBookName, searchCategory, searchWriterName);
  }

  const onChangeSearchBookName = (e) => {
    const searchBookName = e.target.value;
    setSearchBookName(searchBookName);
    getData(currentPage, searchBookName, searchCategory, searchWriterName);
  };

  const onChangeSearchWriterName = (e) => {
    const searchWriterName = e.target.value;
    setSearchWriterName(searchWriterName);
    getData(currentPage, searchBookName, searchCategory, searchWriterName);
  };

  const onChangeSearchCategoryID = (category, e) => {
    setSearchCategory(category);
    getData(currentPage, searchBookName, category, searchWriterName);
  };

  function formatCategory(value) {
    if (value) {
      let categories = [];
      let addedIdList = [];

      value.map((item) => {
        const isExist =
          addedIdList.filter((category) => {
            return category === item.category.category_id;
          }).length > 0;

        if (!isExist) {
          categories.push(<Tag color="gold">{item.category.name}</Tag>);

          addedIdList.push(item.category.category_id);
        }
      });

      return categories;
    }
  }

  // function formatWriterName(writer_id) {
  //   if (writer.length > 0) {
  //     for (let i = 0; i < writer.length; i++) {
  //       if (writer[i].writer_id === writer_id) {
  //         return writer[i].name;
  //         break;
  //       }
  //     }
  //   }
  // }

  return (
    <div className="container">
      <div className="text-right container-fluid">
        <Button
          className="btn-primary px-3"
          size=""
          onClick={() => history.push("/manage/ebook/add")}
        >
          เพิ่ม E-Book
        </Button>
      </div>

      <h5 className="mt-2 font-weight-bold">
        รายการ E-Book <Spin style={{ marginLeft: 10 }} spinning={loading} />
      </h5>
      <div className="line-highlight">
        <hr />
      </div>

      <div className="flex-fill">
        <div className="row mt-4 pt-3">
          <div className="col-sm-4">
            <div className="form-group">
              <label>ชื่อหนังสือ</label>
              <Input
                placeholder="ค้นหาด้วย ชื่อหนังสือ"
                value={searchBookName}
                onChange={onChangeSearchBookName}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>ชื่อผู้เขียน</label>
              <Input
                placeholder="ค้นหาด้วย ชื่อผู้เขียน"
                value={searchWriterName}
                onChange={onChangeSearchWriterName}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>หมวดหมู่</label>
              <Select
                defaultValue=""
                className="w-100"
                onSelect={(value, event) =>
                  onChangeSearchCategoryID(value, event)
                }
              >
                <Option value="">เลือกหมวดหมู่</Option>
                {bookCategory &&
                  bookCategory.map((item, index) => {
                    return (
                      <Option value={item.category_id}>{item.name}</Option>
                    );
                  })}
              </Select>
            </div>
          </div>
        </div>

        {data &&
          data.map((item, index) => {
            return (
              <div className="card mt-2">
                <div className="card-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 text-right text-grey font-12 ml-0 mr-0 pl-0 pr-0">
                        {item.status === "A" ? (
                          <Tag
                            style={{ fontSize: "16px", padding: 7 }}
                            color="green"
                          >
                            Active
                          </Tag>
                        ) : (
                          <Tag
                            style={{ fontSize: "16px", padding: 7 }}
                            color="red"
                          >
                            InActive
                          </Tag>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-sm-2 text-center">
                        <div>
                          <div className="position-relative my-2 pb-4 ml-5">
                            {item.cover_image_url ? (
                              <div>
                                <img width={150}
                                  src={item.cover_image_url}
                                  className="img-fluid"
                                />
                              </div>
                            ) : (
                              <div className="image-placeholder d-flex flex-column align-items-center justify-content-center">
                                <div>
                                  <PictureOutlined
                                    style={{ fontSize: 30, color: "#bbb" }}
                                  />
                                </div>
                                <div className="font-12">EBook Image</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-9 col-sm-10 mt-2">
                        <div className="d-flex align-items-center ml-4">
                          <span className="align-title-xs font-weight-bold">
                            ชื่อหนังสือ:
                          </span>
                          <span className="text-left ml-4">
                            {item.book_name}
                          </span>
                        </div>

                        <div className="d-flex mt-3 ml-4">
                          <span className="align-title-xs font-weight-bold">
                            ชื่อผู้เขียน :
                          </span>
                          <span className="text-left ml-4">
                            {item.writer_name}
                          </span>
                        </div>

                        <div className="d-flex mt-3 ml-4">
                          <span className="align-title-xs font-weight-bold">
                            หมวดหมู่ :
                          </span>
                          <span className="text-left ml-4">
                            {formatCategory(item.categorys)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="text-right m-2 mt-1">
                    <Button
                      className="btn-secondary mr-2"
                      onClick={() =>
                        history.push("/manage/ebook/update/" + item.book_id)
                      }
                    >
                      ดูรายละเอียด/แก้ไข
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}

        <div className="text-right mt-4">
          {data && data.length > 0 && (
            <ContentPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={changePage.bind(this)}
              pageShow={5}
            />
          )}
        </div>
      </div>
    </div>
  );
}
