import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import TrackingService from "../services/TrackingService";
import InfluencerService from "../services/InfluencerService";
import ChannelService from "../services/ChannelService";
import {
  Select,
  Tag,
  DatePicker,
  Button,
  Card,
  Spin,
  Tooltip,
  Table,
  Input,
} from "antd";
import ContentPagination from "../utils/Pagination";
import {
  LaptopOutlined,
  BookOutlined,
  AudioOutlined,
  ReadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import moment from "moment";
import XLSX from "xlsx";

const { Option } = Select;
const { Column } = Table;
const Excel = require("exceljs");
var FileSaver = require("file-saver");

export default function ReportTracking() {
  const { course_id } = useParams();
  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [dateFormat, setDateFormat] = useState("DD-MM-YYYY");
  const [searchStartDate, setSearchStartDate] = useState(
    moment().add(-20000, "days")
  );
  const [searchEndDate, setSearchEndDate] = useState(
    moment().format("YYYY-MM-DD 23:59:59")
  );
  const [searchRefCode, setSearchRefCode] = useState("");
  const [searchInfluencer, setSearchInfluencer] = useState("");
  const [searchChannel, setSearchChannel] = useState("");
  const [searchProductType, setSearchProductType] = useState("");
  const [searchProductTitle, setSearchProductTitle] = useState("");
  const [influencerSelect, setInfluencerSelect] = useState("");
  const [channelSelect, setChannelSelect] = useState("");
  const [productTitleSelect, setProductTitleSelect] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: false,
  });
  const [historyLoading, setHistoryLoading] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("token")) history.push("/login");

    var result = localStorage.getItem("user_profile");
    if (result) {
      if (JSON.parse(result).order_report === false) {
        history.push("/login");
      }
    }

    getInfluencerSelect();
    getChannelNameSelect();
    getProductTitleSelect();

    getData(
      currentPage,
      searchRefCode,
      searchStartDate,
      searchEndDate,
      searchInfluencer,
      searchChannel,
      searchProductType,
      searchProductTitle
    );
  }, [0]);

  const getInfluencerSelect = async (value) => {
    await InfluencerService.getInfluencerSelect()
      .then((res) => {
        const response = res.data;
        setInfluencerSelect(response.influencers);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getChannelNameSelect = async (value) => {
    await ChannelService.getChannelNameSelect()
      .then((res) => {
        const response = res.data;
        setChannelSelect(response.channels);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getProductTitleSelect = async (value) => {
    await TrackingService.getProductTitle()
      .then((res) => {
        const response = res.data;
        setProductTitleSelect(response.productTitle);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // async function handleTableChange(pagination, filters, sorter) {
  //   console.log(pagination)
  //   setPagination(pagination);

  //   await getData(
  //     pagination,
  //     searchRefCode,
  //     searchStartDate,
  //     searchEndDate,
  //     searchInfluencer,
  //     searchChannel,
  //     searchProductType,
  //     searchProductTitle
  //   );

  // }

  const getData = async (
    page,
    filterRefCode,
    filterStartDate,
    filterEndDate,
    filterInfluencer,
    filterChannel,
    filterProductType,
    filterProductTitle
  ) => {
    let response = null;
    setLoading(true);
    setHistoryLoading(true);
    filterStartDate = moment(filterStartDate).format("YYYY-MM-DD 00:00:00");
    filterEndDate = moment(filterEndDate).format("YYYY-MM-DD 23:59:59");

    await TrackingService.getReportTracking(
      page,
      filterRefCode,
      filterStartDate,
      filterEndDate,
      filterInfluencer,
      filterChannel,
      filterProductType,
      filterProductTitle
    )
      .then((res) => {
        response = res.data;
        const totalPages = Math.ceil(response.total / perPage);
        setTotalPage(totalPages);

        setPagination({
          ...pagination,
          current: page + 1,
          total: response.total,
        });

        for (let i = 0; i < response.trackings.length; i++) {
          response.trackings[i].key = i;
        }

        setData(response.trackings);

        setLoading(false);
        setHistoryLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setHistoryLoading(false);
        console.log(e);
      });
  };

  async function changePage(page) {
    console.log(page.current);
    setCurrentPage(page.current - 1);
    await getData(
      page.current - 1,
      searchRefCode,
      searchStartDate,
      searchEndDate,
      searchInfluencer,
      searchChannel,
      searchProductType,
      searchProductTitle
    );
  }

  async function handleDatePickerChange(date, dateString, type) {
    if (type === "start_date") {
      setSearchStartDate(date);
      await getData(
        currentPage,
        searchRefCode,
        date,
        searchEndDate,
        searchInfluencer,
        searchChannel,
        searchProductType,
        searchProductTitle
      );
    } else if (type === "end_date") {
      setSearchEndDate(date);
      await getData(
        currentPage,
        searchRefCode,
        searchStartDate,
        date,
        searchInfluencer,
        searchChannel,
        searchProductType,
        searchProductTitle
      );
    }
  }

  function formatDate(date) {
    if (date) {
      return moment(date).utc().format("DD/MM/YYYY");
    }
  }

  function formatAmount(amount) {
    if (amount) {
      return new Intl.NumberFormat("en-GB", {
        style: "decimal",
      }).format(amount);
    } else return 0;
  }

  function formatProductType(product_type) {
    if (product_type === "book") {
      return "หนังสือ";
    } else if (product_type === "audio") {
      return "หนังสือเสียง";
    } else if (product_type === "course") {
      return "คอร์สสัมนา";
    } else {
      return product_type;
    }
  }

  async function SearchData() {
    await getData(
      currentPage,
      searchRefCode,
      searchStartDate,
      searchEndDate,
      searchInfluencer,
      searchChannel,
      searchProductType,
      searchProductTitle
    );
  }

  async function downloadExportData(report) {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(report);
    XLSX.utils.book_append_sheet(workBook, workSheet, ["report-course"]);
    XLSX.writeFile(workBook, `report-course.xlsx`);
  }

  async function refreshData(){
    window.location.reload();
  }

  async function exportData() {
    let response = null;
    setLoading(true);
    let end_date = moment(searchEndDate).format("YYYY-MM-DD 23:59:59");
    await TrackingService.getReportTracking(
      "",
      searchRefCode,
      searchStartDate,
      searchEndDate,
      searchInfluencer,
      searchChannel,
      searchProductType,
      searchProductTitle
    )
      .then((res) => {
        response = res.data;
        setData(response.trackings);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
    const wb = new Excel.Workbook();
    const courseWorkSheet = wb.addWorksheet("Tracking Report");
    courseWorkSheet.columns = [
      { header: "Product Type", key: "product_type", width: 20 },
      { header: "Product ID", key: "product_id" },
      { header: "Product Title", key: "product_title", width: 30 },
      { header: "Influencer Name", key: "influencer_name", width: 30 },
      { header: "Channels Name", key: "channel_name", width: 30 },
      {
        header: "จำนวนเข้าชม",
        key: "total_view",
        alignment: "right",
        width: 15,
      },
      {
        header: "จำนวนจอง",
        key: "total_order",
        alignment: "right",
        width: 15,
      },
      {
        header: "จำนวนชำระ",
        key: "total_purchase",
        alignment: "right",
        width: 15,
      },
      {
        header: "ยอดเงิน",
        key: "total_amount",
        alignment: "right",
        width: 15,
      },
    ];
    for (const item of data) {
      courseWorkSheet.addRow({
        product_type: formatProductType(item.product_type),
        product_id: item.product_id,
        product_title: item.product_title,
        ref_code: item.ref_code,
        influencer_name: item.influencer_name,
        channel_name: item.channel_name,
        total_view: Number(item.total_view),
        total_order: Number(item.total_order),
        total_purchase: Number(item.total_purchase),
        total_amount: Number(item.total_amount),
      });
    }

    const buffer = await wb.xlsx.writeBuffer();
    await wb.xlsx
      .writeBuffer()
      .then((buffer) =>
        FileSaver.saveAs(new Blob([buffer]), `Tracking_Report.xlsx`)
      )
      .catch((err) => {
        console.log(err.message);
      });
    getData(
      0,
      searchRefCode,
      searchStartDate,
      searchEndDate,
      searchInfluencer,
      searchChannel,
      searchProductType,
      searchProductTitle
    );
  }

  const onChangeSearchRefCode = (e) => {
    const searchRefCode = e.target.value;
    setSearchRefCode(searchRefCode);
    getData(
      currentPage,
      searchRefCode,
      searchStartDate,
      searchEndDate,
      searchInfluencer,
      searchChannel,
      searchProductType,
      searchProductTitle
    );
  };

  const onChangeSearchInfluencer = (value) => {
    setSearchInfluencer(value);
    getData(
      currentPage,
      searchRefCode,
      searchStartDate,
      searchEndDate,
      value,
      searchChannel,
      searchProductType,
      searchProductTitle
    );
  };

  function onChangeSearchProductType(value) {
    setSearchProductType(value);
    getData(
      currentPage,
      searchRefCode,
      searchStartDate,
      searchEndDate,
      searchInfluencer,
      searchChannel,
      value,
      searchProductTitle
    );
  }

  function onChangeSearchProductTitle(value) {
    setSearchProductTitle(value);
    getData(
      currentPage,
      searchRefCode,
      searchStartDate,
      searchEndDate,
      searchInfluencer,
      searchChannel,
      searchProductType,
      value
    );
  }

  function onChangeSearchChannel(value) {
    setSearchChannel(value);
    getData(
      currentPage,
      searchRefCode,
      searchStartDate,
      searchEndDate,
      searchInfluencer,
      value,
      searchProductType,
      searchProductTitle
    );
  }

  return (
    <div className="container">
      <h5 className="mt-2 font-weight-bold">
        Tracking Report <Spin spinning={loading} />
      </h5>
      <div className="line-highlight">
        <hr />
      </div>

      <div className="flex-fill">
        <div className="row mt-2">
          <div className="col-sm-3">
            <div className="form-group">
              <label>Ref Code</label>
              <Input
                placeholder="ค้นหาด้วย Ref Code"
                value={searchRefCode}
                onChange={onChangeSearchRefCode}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-group">
              <label>Start Date</label>
              <DatePicker
                picker="date"
                // defaultValue={moment().add(-30, "days")}
                format={dateFormat}
                onChange={(date, dateString) =>
                  handleDatePickerChange(date, dateString, "start_date")
                }
                className="w-100"
                disabledDate={(current) => {
                  return current >= moment();
                }}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-group">
              <label>End Date</label>
              <DatePicker
                picker="date"
                //defaultValue={moment()}
                format={dateFormat}
                onChange={(date, dateString) =>
                  handleDatePickerChange(date, dateString, "end_date")
                }
                className="w-100"
                // disabledDate={(current) => {
                //   return moment().add(-1, "days") >= current;
                // }}
              />
            </div>
          </div>
          <div className="col-sm-3 mt-4">
            <div className="form-group">
              <Button className=" btn-info mt-2" onClick={refreshData}>
                Refresh
              </Button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-3">
            <div className="form-group">
              <label>Influencer Name</label>
              <Select
                defaultValue={searchInfluencer}
                className="w-100"
                allowClear
                onChange={onChangeSearchInfluencer}
                placeholder="Searuch Influencer"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="">ทั้งหมด</Option>
                {influencerSelect &&
                  influencerSelect.map((item, index) => {
                    return (
                      <Option value={item.influencer_id}>{item.name}</Option>
                    );
                  })}
              </Select>
            </div>
          </div>
          <div className="col-sm-3">
            <label>Channel Name</label>
            <Select
              defaultValue={searchChannel}
              className="w-100"
              allowClear
              onChange={onChangeSearchChannel}
              placeholder="Channel Name"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="">ทั้งหมด</Option>
              {channelSelect &&
                channelSelect.map((item, index) => {
                  return <Option value={item.channel_id}>{item.name}</Option>;
                })}
            </Select>
          </div>
          <div className="col-sm-3">
            <label>Product Type</label>
            <Select
              defaultValue=""
              className="w-100"
              onSelect={(value, event) =>
                onChangeSearchProductType(value, event)
              }
            >
              <Option value="">ทั้งหมด</Option>
              <Option value="course">คอร์สสัมนา</Option>
              <Option value="book">หนังสือ</Option>
              <Option value="audio">หนังสือเสียง</Option>
            </Select>
          </div>
          <div className="col-sm-3">
            <label>Product Title</label>
            <Select
              defaultValue={searchProductTitle}
              className="w-100"
              allowClear
              onChange={onChangeSearchProductTitle}
              placeholder="Product Title"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="">ทั้งหมด</Option>
              {productTitleSelect &&
                productTitleSelect.map((item, index) => {
                  return <Option value={item}>{item}</Option>;
                })}
            </Select>
          </div>
        </div>

        <div className="row"></div>

        <div className="row mt-3 ml-1">
          <Button className=" btn-info mr-4 px-3" onClick={exportData}>
            Export
          </Button>
        </div>

        <div class="table_wrapper mt-2">
          <Table
            dataSource={data}
            loading={historyLoading}
            // pagination={pagination}
            // onChange={changePage}
          >
            <Column
              title="Product Type"
              render={(value) => {
                return formatProductType(value.product_type);
              }}
              align="center"
            />
            <Column
              title="Product ID"
              dataIndex="product_id"
              key="product_id"
              align="center"
            />
            <Column
              title="Product Title"
              dataIndex="product_title"
              key="product_title"
              align="center"
            />
            <Column
              title="Ref Code"
              dataIndex="ref_code"
              key="ref_code"
              align="center"
            />
            <Column
              title="Influencer Name"
              dataIndex="influencer_name"
              key="influencer_name"
              align="center"
            />
            <Column
              title="Channels Name"
              dataIndex="channel_name"
              key="channel_name"
              align="center"
            />
            <Column
              title="จำนวนเข้าชม"
              render={(value) => {
                return formatAmount(value.total_view);
              }}
              align="center"
            />
            <Column
              title="จำนวนจอง"
              render={(value) => {
                return formatAmount(value.total_order);
              }}
              align="center"
            />
            <Column
              title="จำนวนชำระ"
              render={(value) => {
                return formatAmount(value.total_purchase);
              }}
              align="center"
            />
            <Column
              title="ยอดเงิน"
              render={(value) => {
                return formatAmount(value.total_amount);
              }}
              align="center"
            />
          </Table>

          {/* <div className="text-right mt-4">
            {data && data.length > 0 && (
              <ContentPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={changePage.bind(this)}
                pageShow={10}
              />
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
}

