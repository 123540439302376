import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import MemberService from "../services/MemberService";
import { Input, Select, Spin,Tooltip,Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import ContentPagination from "../utils/Pagination";
import moment from "moment";

const { Option } = Select;

export default function Member() {
  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [searchName, setSearchName] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [searchType, setSearchType] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPage] = useState(1);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("token")) history.push("/login");

    var result = localStorage.getItem("user_profile");
    if (result) {
      if (JSON.parse(result).member === false) {
        history.push("/login");
      }
    }

    getData(currentPage, searchName, searchEmail, searchType);
  }, [0]);

  const getData = async (page, filterName, filterEmail, filterType) => {
    setLoading(true);
    await MemberService.getAll(page, filterName, filterEmail, filterType)
      .then((res) => {
        setLoading(false);
        const reponse = res.data;

        const totalPages = Math.ceil(reponse.total / perPage);
        setTotalPage(totalPages);
        setData(reponse.members);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  function changePage(page) {
    setCurrentPage(page);
    getData(page, searchName, searchEmail, searchType);
  }

  const onChangeSearchName = (e) => {
    const searchName = e.target.value;
    setSearchName(searchName);
    getData(currentPage, searchName, searchEmail, searchType);
  };

  const onChangeSearchEmail = (e) => {
    const searchEmail = e.target.value;
    setSearchEmail(searchEmail);
    getData(currentPage, searchName, searchEmail, searchType);
  };

  const onChangeSearchType = (type, e) => {
    setSearchType(type);
    getData(currentPage, searchName, searchEmail, type);
  };

  function formatDate(date) {
    if (date) {
      return moment(date).utc().format("DD/MM/YYYY HH:mm:ss");
    }
  }

  return (
    <div className="container">
      <h5 className="mt-2 font-weight-bold">
        รายการสมาชิก
        <Spin style={{ marginLeft: 10 }} spinning={loading} />
      </h5>
      <div className="line-highlight">
        <hr />
      </div>

      <div className="flex-fill">
        <div className="row mt-4 pt-3">
          <div className="col-sm-4">
            <div className="form-group">
              <label>ชื่อ</label>
              <Input
                placeholder="ค้นหาด้วย ชื่อผู้ใช้"
                value={searchName}
                onChange={onChangeSearchName}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>อีเมล</label>
              <Input
                placeholder="ค้นหาด้วย อีเมล"
                value={searchEmail}
                onChange={onChangeSearchEmail}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>ประเภท</label>
              <Select
                defaultValue=""
                className="w-100"
                onSelect={(value, event) => onChangeSearchType(value, event)}
              >
                <Option value="">ทั้งหมด</Option>
                <Option value="email">email</Option>
                <Option value="facebook">facebook</Option>
                <Option value="google">google</Option>
              </Select>
            </div>
          </div>
        </div>

        <div class="table_wrapper">
          <table className="table border mb-0 mt-4">
            <thead>
              <tr>
                <th className="text-left">ชื่อผู้ใช้</th>
                <th className="text-left">อีเมล</th>
                <th className="text-center">ประเภท</th>
                <th className="text-center">Status</th>
                <th className="text-center">วันที่เป็นสมาชิก</th>
                <th className="text-center">แก้ไข</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-left font-weight-bold">
                        {item.display_name}
                      </td>
                      <td className="text-left">{item.email}</td>
                      <td className="text-center">{item.type}</td>
                      <td className="text-center">{item.status}</td>
                      <td className="text-center">
                        {formatDate(item.created_datetime)}
                      </td>
                      <td className="text-center">
                        <Tooltip title="แก้ไข" className="mr-2">
                          <Button
                            type="primary"
                            shape="circle"
                            size="small"
                            className="btn-secondary"
                            icon={<EditOutlined />}
                            onClick={() =>
                              history.push("/manage/member/update/" + item.id)
                            }
                          />
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <div className="text-right mt-4">
          {data && data.length > 0 && (
            <ContentPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={changePage.bind(this)}
              pageShow={10}
            />
          )}
        </div>
      </div>
    </div>
  );
}
