import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Input, notification, Spin } from "antd";
import Notification from "../utils/Notification";
import UserService from "../services/UserService";
import "./Login.scss";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const initialState = {
    email: "",
    password: "",
  };
  const [login, setLogIn] = useState(initialState);
  const [token, setToken] = useState();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  function ValidateLogIn() {
    if (!email) {
      Notification.Show("error", "Validation", "กรุณาระบุ Email Address");
      return false;
    }

    if (!password) {
      Notification.Show("error", "Validation", "กรุณาระบุ Password");
      return false;
    }
  }

  async function Login() {
    if (ValidateLogIn() === false) return;

    login.email = email;
    login.password = password;

    setLoading(true);
    UserService.login(login)
      .then((response) => {
        setLoading(false);
        const { data } = response.data;

        setToken(data.token);
        localStorage.setItem("token", data.token);
        localStorage.setItem("user_profile", JSON.stringify(data.user_profile));

        var result = localStorage.getItem("user_profile");

        if (result) {
          if (JSON.parse(result).banner === true) history.push("/banner");
          else if (JSON.parse(result).article === true)
            history.push("/article");
          else if (JSON.parse(result).course === true) history.push("/course");
          else if (JSON.parse(result).book === true) history.push("/book");
          else if (JSON.parse(result).member === true) history.push("/member");
          else if (JSON.parse(result).order_report === true)
            history.push("/report/purchase");
          else if (JSON.parse(result).purchase_management === true)
            history.push("/report/order");
          else if (JSON.parse(result).user_management === true)
            history.push("/user");
        }
      })
      .catch((e) => {
        setLoading(false);
        let error = e.response ? e.response.data.message : e.message;
        Notification.Show("error", "Error", error);
      });
  }

  return (
    <div className="login-wrapper">
      <div className="card border-0">
        <div className="card-body p-5">
          <img
            className="img-fluid mx-auto d-block"
            src={require("../assets/img/logo_s2m_head.png").default}
            alt=""
            width="260"
          />

          <hr className="mx-auto" />

          <div className="form-group mt-4 pt-2">
            <Input
              placeholder="Email Address"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group mt-4">
            <Input
              placeholder="Password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="mt-4 pt-2">
            <button
              className="btn btn-primary btn-block font-weight-bold py-2"
              onClick={Login}
            >
              SIGN IN <Spin className="ml-2" spinning={loading} />
            </button>
            <div className="mt-1 pt-2">
              <div className="text-left">
                <a href="/forget_password">ลืมรหัสผ่าน/เปลี่ยนรหัสผ่าน</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
