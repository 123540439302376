import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import ReportService from "../services/ReportService";
import CourseService from "../services/CourseService";
import {
  Select,
  Tag,
  DatePicker,
  Button,
  Card,
  Spin,
  Tooltip,
  Table,
  Input,
} from "antd";
import ContentPagination from "../utils/Pagination";
import {
  LaptopOutlined,
  BookOutlined,
  AudioOutlined,
  ReadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import moment from "moment";
import XLSX from "xlsx";

const { Option } = Select;
const { Column } = Table;
const Excel = require("exceljs");
var FileSaver = require("file-saver");

export default function ReportCourse() {
  const { course_id } = useParams();
  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(50);
  const [searchCourse, setSearchCourse] = useState(course_id);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [courseSelect, setCourseSelect] = useState("");
  const [sumOrderPending, setSumOrderPending] = useState(0);
  const [sumOrderPaid, setSumOrderPaid] = useState(0);
  const [sumOrderFail, setSumOrderFail] = useState(0);
  const [sumOrderCancel, setSumOrderCancel] = useState(0);
  const [searchStartDate, setSearchStartDate] = useState(
    moment().add(-30, "days")
  );
  const [searchEndDate, setSearchEndDate] = useState(
    moment().format("YYYY-MM-DD 23:59:59")
  );
  const [dateFormat, setDateFormat] = useState("DD-MM-YYYY");
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [expended, setExpended] = useState();
  const [searchName, setSearchName] = useState("");
  const [searchEmail, setSearchEmail] = useState("");

  useEffect(() => {
    if (!localStorage.getItem("token")) history.push("/login");

    var result = localStorage.getItem("user_profile");
    if (result) {
      if (JSON.parse(result).order_report === false) {
        history.push("/login");
      }
    }

    getCourseSelect();

    if (course_id) {
      setSearchCourse(course_id);
      getData(
        currentPage,
        course_id,
        searchStartDate,
        searchEndDate,
        searchName,
        searchEmail
      );
    } else
      getData(
        currentPage,
        "",
        searchStartDate,
        searchEndDate,
        searchName,
        searchEmail
      );
  }, [course_id]);

  const getData = async (
    page,
    filterCourse,
    filterStartDate,
    filterEndDate,
    filterName,
    filterEmail
  ) => {
    let response = null;
    setLoading(true);
    setExpandedRowKeys([]);
    filterEndDate = moment(filterEndDate).format("YYYY-MM-DD 23:59:59");

    await ReportService.getReportCourse(
      "",
      filterCourse,
      filterStartDate,
      filterEndDate,
      "",
      filterName,
      filterEmail
    )
      .then((res) => {
        response = res.data;
        const totalPages = Math.ceil(response.total / perPage);
        setTotalPage(totalPages);
        countOrderStatus(response.courses);

        for (let i = 0; i < response.courses.length; i++) {
          response.courses[i].key = i;
        }

        console.log(response.courses);

        setData(response.courses);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  async function changePage(page) {
    setCurrentPage(page);
    await getData(
      page,
      searchCourse,
      searchStartDate,
      searchEndDate,
      searchName,
      searchEmail
    );
  }

  function handleDatePickerChange(date, dateString, type) {
    if (type === "start_date") {
      setSearchStartDate(date);
    } else if (type === "end_date") {
      setSearchEndDate(date);
    }
  }

  function countOrderStatus(courses) {
    let countPending = 0;
    let countPaid = 0;
    let countFail = 0;
    let countCancel = 0;
    if (courses) {
      for (let i = 0; i < courses.length; i++) {
        if (courses[i].order_status === 1) {
          countPending += courses[i].quantity;
        } else if (courses[i].order_status === 2) {
          countPaid += courses[i].quantity;
        } else if (courses[i].order_status === 3) {
          countFail += courses[i].quantity;
        } else if (courses[i].order_status === 4) {
          countCancel += courses[i].quantity;
        }
      }
      setSumOrderPending(countPending);
      setSumOrderPaid(countPaid);
      setSumOrderFail(countFail);
      setSumOrderCancel(countCancel);
    }
  }

  function formatDate(date) {
    if (date) {
      return moment(date).utc().format("DD/MM/YYYY");
    }
  }

  function formatAmount(amount) {
    if (amount) {
      return new Intl.NumberFormat("en-GB", {
        style: "decimal",
      }).format(amount);
    } else return 0;
  }

  function formatStatus(status) {
    if (status === 2) {
      return <Tag color="green">ชำระเงินแล้ว</Tag>;
    } else if (status === 1) {
      return <Tag color="gold">รอการชำระเงิน</Tag>;
    } else if (status === 3) {
      return <Tag color="red">ชำระเงินล้มเหลว</Tag>;
    } else if (status === 4) {
      return <Tag color="red">ยกเลิกคำสั่งซื้อ</Tag>;
    } else {
      return status;
    }
  }

  function formatName(item) {
    if (item.last_name) {
      return item.first_name + " " + item.last_name;
    } else {
      return item.first_name;
    }
  }

  function formatPaymentDate(date, order_status, total_amount) {
    if (date) {
      const d = new Date(date);
      let year = d.getFullYear();
      if (order_status === 2) {
        if (total_amount == 0 || year === 1) return "-";
        else return moment(date).utc().format("DD/MM/YYYY HH:mm:ss");
      } else return "-";
    } else return "-";
  }

  function formatOrderDate(date, order_status, total_amount) {
    if (date) {
      return moment(date).utc().format("DD/MM/YYYY HH:mm:ss");
    }
  }

  const getCourseSelect = async (value) => {
    await CourseService.getCourseSelectReport()
      .then((res) => {
        const response = res.data;
        setCourseSelect(response.courses);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  function onChangeCourseSelect(value) {
    setSearchCourse(value);
  }

  const onChangeSearchName = (e) => {
    const searchName = e.target.value;
    setSearchName(searchName);
  };

  const onChangeSearchEmail = (e) => {
    const searchEmail = e.target.value;
    setSearchEmail(searchEmail);
  };

  function formatStatusExport(status) {
    if (status === 2) {
      return "ชำระเงินแล้ว";
    } else if (status === 1) {
      return "รอการชำระเงิน";
    } else if (status === 3) {
      return "ชำระเงินล้มเหลว";
    } else if (status === 4) {
      return "ยกเลิกคำสั่งซื้อ";
    } else {
      return status;
    }
  }

  function formatPaymentMethod(data) {
    if (data.payment_methods_id === 1) {
      return "Credit Card";
    } else if (data.payment_methods_id === 2) {
      return "Cash";
    } else if (data.payment_methods_id === 3) {
      return "Prompt Pay";
    } else if (data.payment_methods_id === 4) {
      return "S2M-Token";
    } else if (data.payment_methods_id === 5) {
      return "Free";
    } else if (data.payment_methods_id === 6) {
      return "installment" + " (" + data.installment + " months)";
    } else {
      return data;
    }
  }

  function formatIsNFT(is_nft) {
    if (is_nft === true) {
      return "NFT";
    } else return "-";
  }

  async function SearchData() {
    await getData(
      currentPage,
      searchCourse,
      searchStartDate,
      searchEndDate,
      searchName,
      searchEmail
    );
  }

  async function downloadExportData(report) {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(report);
    XLSX.utils.book_append_sheet(workBook, workSheet, ["report-course"]);
    XLSX.writeFile(workBook, `report-course.xlsx`);
  }

  async function exportData2() {
    let response = null;
    setLoading(true);

    let end_date = moment(searchEndDate).format("YYYY-MM-DD 23:59:59");

    await ReportService.getReportCourse(
      "",
      searchCourse,
      searchStartDate,
      end_date,
      2,
      searchName,
      searchEmail
    )
      .then((res) => {
        response = res.data;
        setData(response.courses);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });

    console.log(data);
    const wb = new Excel.Workbook();
    const courseWorkSheet = wb.addWorksheet("รายงานคอร์ส");
    const attendeeWorkSheet = wb.addWorksheet("รายชื่อผู้เข้าร่วมสัมนา");

    courseWorkSheet.columns = [
      { header: "รหัสสั่งจอง", key: "order_number" },
      { header: "วันที่สั่งซื้อ", key: "order_date" },
      { header: "ชื่อคอร์ส", key: "course_name", width: 30 },
      { header: "Username", key: "display_name", width: 20 },
      { header: "ชื่อ(ผู้เข้าสัมนา)", key: "name", width: 25 },
      { header: "เบอร์โทรศัพท์", key: "phone_number", width: 15 },
      { header: "อีเมลล์", key: "email", width: 25 },
      { header: "สถานะการชำระเงิน", key: "order_status", width: 20 },
      { header: "จำนวน", key: "quantity", alignment: "right" },
      {
        header: "รวมจำนวนเงิน",
        key: "total_amount",
        alignment: "right",
        width: 15,
      },
      { header: "ช่องทางชำระเงิน", key: "payment_methods_id", width: 20 },
      { header: "Influencer", key: "influencer_name", width: 25 },
      { header: "Channel", key: "channel_name", width: 25 },
      { header: "NFT", key: "is_nft" },
      { header: "วันที่ชำระเงิน", key: "payment_datetime", width: 20 },
      { header: "หมายเหตุ", key: "remark", width: 30 },
    ];

    attendeeWorkSheet.columns = [
      { header: "รหัสสั่งจอง", key: "order_number" },
      { header: "ชื่อ-นามสกุล", key: "name", width: 25 },
      { header: "เบอร์โทรศัพท์", key: "phone_number", width: 25 },
      { header: "อีเมลล์", key: "email", width: 25 },
    ];

    //course
    let count = 0;
    let sum_amount = 0;
    for (const item of data) {
      if (item.order_status === 2) {
        count += item.quantity;
        sum_amount += Number(item.total_amount);
      }

      let name1 = "";
      let phone1 = "";
      let email1 = "";

      if (item.attendee.length > 0) {
        name1 = formatName(item.attendee[0]);
        phone1 = item.attendee[0].phone_number;
        email1 = item.attendee[0].email;
      }

      courseWorkSheet.addRow({
        order_number: item.order_number,
        order_date: formatDate(item.order_date),
        course_name: item.course_name,
        display_name: item.display_name,
        name: name1,
        phone_number: phone1,
        email: email1,
        order_status: formatStatusExport(item.order_status),
        quantity: Number(item.quantity),
        total_amount: Number(item.total_amount),
        payment_methods_id: formatPaymentMethod(item),
        influencer_name: item.influencer_name,
        channel_name: item.channel_name,
        is_nft: formatIsNFT(item.is_nft),
        payment_datetime: formatPaymentDate(
          item.payment_datetime,
          item.order_status,
          item.total_amount
        ),
        remark: item.remark,
      });

      if (item.attendee.length > 1) {
        for (let i = 1; i < item.attendee.length; i++) {
          courseWorkSheet.addRow({
            order_number: "",
            order_date: "",
            course_name: "",
            display_name: "",
            name: formatName(item.attendee[i]),
            phone_number: item.attendee[i].phone_number,
            email: item.attendee[i].email,
            order_status: "",
            total_amount: "",
            payment_methods_id: "",
            is_nft: "",
            payment_datetime: "",
            remark: "",
          });
        }
      }
    }

    //attendee
    for (const item of data) {
      if (item.order_status === 2) {
        if (item.attendee.length > 0) {
          for (let i = 0; i < item.attendee.length; i++) {
            attendeeWorkSheet.addRow({
              order_number: item.attendee[i].order_number,
              name: formatName(item.attendee[i]),
              phone_number: item.attendee[i].phone_number,
              email: item.attendee[i].email,
            });
          }
        }
      }
    }

    courseWorkSheet.addRow({});
    courseWorkSheet.addRow({
      order_status: "รวม(ชำระเงินแล้ว)",
      quantity: count,
      total_amount: sum_amount,
    });

    const buffer = await wb.xlsx.writeBuffer();

    await wb.xlsx
      .writeBuffer()
      .then((buffer) =>
        FileSaver.saveAs(new Blob([buffer]), `report-course.xlsx`)
      )
      .catch((err) => {
        console.log(err.message);
      });
    await getData(
      0,
      searchCourse,
      searchStartDate,
      searchEndDate,
      searchName,
      searchEmail
    );
  }

  const handleRowExpand = (expanded, record) => {
    const currentExpandedRows = expandedRowKeys;

    if (currentExpandedRows.includes(record.key)) {
      const index = currentExpandedRows.indexOf(record.key);
      currentExpandedRows.splice(index, 1);
    } else {
      currentExpandedRows.push(record.key);
    }

    setExpandedRowKeys(currentExpandedRows);
  };

  const expend = (index) => {
    if (expended === index) setExpended(undefined);
    else setExpended(index);
  };

  const expandedRowRender = (e) => {
    return (
      <Table dataSource={e.attendee} pagination={false} className="mt-2 mb-4">
        <Column
          title="ชื่อ-สกุล (ผู้เข้าร่วมสัมนา)"
          color="blue"
          key="phone_number"
          render={(value) => {
            return formatName(value);
          }}
          align="center"
        />
        <Column
          title="เบอร์โทรศัพท์"
          dataIndex="phone_number"
          key="phone_number"
          align="center"
        />
        <Column title="อีเมล์" dataIndex="email" key="email" align="center" />
      </Table>
    );
  };

  return (
    <div className="container">
      <h5 className="mt-2 font-weight-bold">
        รายละเอียดการจองคอร์ส <Spin spinning={loading} />
      </h5>
      <div className="line-highlight">
        <hr />
      </div>

      <div className="row mt-4 pt-2">
        <div className="col-sm-5">
          <div className="form-group">
            <label>ชื่อคอร์ส</label>
            <Select
              defaultValue={searchCourse}
              className="w-100"
              allowClear
              onChange={onChangeCourseSelect}
              placeholder="เลือกคอร์ส"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {courseSelect &&
                courseSelect.map((item, index) => {
                  return (
                    <Option value={item.course_id}>{item.course_name}</Option>
                  );
                })}
            </Select>
          </div>
        </div>
        <div className="col-sm-2">
          <div className="form-group">
            <label>วันที่สั่งซื้อ</label>
            <DatePicker
              picker="date"
              defaultValue={moment().add(-30, "days")}
              format={dateFormat}
              onChange={(date, dateString) =>
                handleDatePickerChange(date, dateString, "start_date")
              }
              className="w-100"
              disabledDate={(current) => {
                return current >= moment();
              }}
            />
          </div>
        </div>
        <div className="col-sm-2">
          <div className="form-group">
            <label>ถึง</label>
            <DatePicker
              picker="date"
              defaultValue={moment()}
              format={dateFormat}
              onChange={(date, dateString) =>
                handleDatePickerChange(date, dateString, "end_date")
              }
              className="w-100"
              // disabledDate={(current) => {
              //   return moment().add(-1, "days") >= current;
              // }}
            />
          </div>
        </div>
        <div className="col-3">
          <div className="form-group" style={{ marginTop: 30 }}>
            <Button
              className="btn-primary mr-4 px-3"
              size="large"
              onClick={SearchData}
            >
              ค้นหา
            </Button>
          </div>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-sm-5">
          <div className="form-group">
            <label>ชื่อ สกุล</label>
            <Input
              placeholder="ค้นหาด้วย ชื่อ สกุล"
              value={searchName}
              onChange={onChangeSearchName}
            />
          </div>
        </div>
        <div className="col-sm-5">
          <div className="form-group">
            <label>อีเมล</label>
            <Input
              placeholder="ค้นหาด้วย อีเมล"
              value={searchEmail}
              onChange={onChangeSearchEmail}
            />
          </div>
        </div>
      </div>

      <div className="flex-fill">
        <div className="card mt-2" style={{ background: "#f6f6f6" }}>
          <div className="card-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex ml-2">
                    <span className="font-weight text-primary col-2">
                      ชำระเงินแล้ว :{" "}
                      <span className="ml-2" style={{ color: "black" }}>
                        {sumOrderPaid}
                      </span>
                    </span>

                    <span className="font-weight text-primary col-2">
                      รอการชำระเงิน :{" "}
                      <span className="ml-2" style={{ color: "black" }}>
                        {sumOrderPending}
                      </span>
                    </span>

                    <span className="font-weight text-primary col-2">
                      ชำระเงินล้มเหลว :{" "}
                      <span className="ml-2" style={{ color: "black" }}>
                        {sumOrderFail}
                      </span>
                    </span>

                    <span className="font-weight-lighter text-primary col-2">
                      ยกเลิกคำสั่งซื้อ :{" "}
                      <span className="ml-2" style={{ color: "black" }}>
                        {sumOrderCancel}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3 ml-1">
          <Button className=" btn-info mr-4 px-3" onClick={exportData2}>
            Export
          </Button>
        </div>

        <div class="table_wrapper mt-2">
          <Table
            dataSource={data}
            key={data.key}
            expandable={{
              expandedRowRender,
              expandedRowKeys: expandedRowKeys,
              onExpand: handleRowExpand,
            }}
          >
            <Column
              title="รหัสการสั่งจอง"
              dataIndex="order_number"
              key="order_number"
              align="center"
            />
            <Column
              title="วันที่สั่งซื้อ"
              render={(value) => {
                return formatDate(value.order_date);
              }}
              align="center"
            />
            <Column
              title="ชื่อคอร์ส"
              dataIndex="course_name"
              key="course_name"
              align="center"
            />
            <Column
              title="Username"
              dataIndex="display_name"
              key="display_name"
              align="center"
            />
            <Column
              title="สถานะการชำระเงิน"
              render={(value) => {
                return formatStatus(value.order_status);
              }}
              align="center"
            />
            <Column
              title="Influencer"
              dataIndex="influencer_name"
              key="influencer_name"
              align="center"
            />
            <Column
              title="Channel"
              dataIndex="channel_name"
              key="channel_name"
              align="center"
            />
            <Column
              title="จำนวน"
              dataIndex="quantity"
              key="quantity"
              align="center"
            />
            <Column
              title="รวมจำนวนเงิน"
              render={(value) => {
                return formatAmount(value.total_amount);
              }}
              align="center"
            />
            <Column
              title="วันเวลาที่ชำระ"
              render={(value) => {
                return formatPaymentDate(
                  value.payment_datetime,
                  value.order_status,
                  value.total_amount
                );
              }}
              align="center"
            />
            <Column
              title="แก้ไขรายละเอียด"
              render={(value) => {
                return (
                  <Tooltip title="แก้ไข" className="mr-2">
                    <Button
                      type="primary"
                      shape="circle"
                      size="small"
                      className="btn-secondary"
                      icon={<SearchOutlined />}
                      onClick={() =>
                        history.push("/manage_order/" + value.order_number)
                      }
                    />
                  </Tooltip>
                );
              }}
              align="center"
            />
          </Table>
        </div>

        {/* <div className="text-right mt-4">
          {data && data.length > 0 && (
            <ContentPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={changePage.bind(this)}
              pageShow={5}
            />
          )}
        </div> */}
      </div>
    </div>
  );
}
