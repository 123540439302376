import { httpClient } from "../utils/HttpClient";

const getAll = (page_no, filter_name, filter_category) => {
  return httpClient.get("/admin/video", {
    params: {
      page: page_no,
      name: filter_name,
      category_id: filter_category
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getVideoSelect = () => {
  return httpClient.get("/admin/video/select", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getVideoCategory = () => {
  return httpClient.get("/admin/video_category", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const get = (id) => {
  return httpClient.get("/admin/video_id", {
    params: {
      id
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

// const getArticleName = (article_id) => {
//   return httpClient.get(`/admin/article/name/${article_id}`, {
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem("token")}`,
//     },
//   });
// };

const create = (data) => {
  return httpClient.post("/admin/video", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const update = (data) => {
    return httpClient.patch(`/admin/video`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
};

const deleteVideo = (id) => {
  return httpClient.delete("/admin/video", {
    params: {
      id
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const deleteVideoSpecifyNFT = (data) => {
  return httpClient.patch(`/admin/video_id_specify_nft`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

// const updateStatus = (article_id, data) => {
//   return httpClient.patch(`/admin/article/status/${article_id}`, data, {
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem("token")}`,
//     },
//   });
// };

export default {
  getAll,
  get,
  getVideoSelect,
  getVideoCategory,
  create,
  update,
  deleteVideo,
  deleteVideoSpecifyNFT
};
