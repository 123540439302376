import "./Sidebar.scss";
import React, { useState, useEffect } from "react";
import { Button, Menu } from "antd";
import {
  UserOutlined,
  NotificationOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  ReadOutlined,
  FileWordOutlined,
  DesktopOutlined,
  FundProjectionScreenOutlined,
  ShoppingCartOutlined,
  TeamOutlined,
  CommentOutlined,
  LinkOutlined
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
const { SubMenu } = Menu;
const rootSubmenuKeys = [
  "sub1",
  "sub2",
  "sub3",
  "sub4",
  "sub5",
  "sub6",
  "sub7",
  "sub8",
];

export default function Sidebar() {
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);
  const [profile, setProfile] = useState();
  const [bannerRole, setBannerRole] = useState(false);
  const [articleRole, setArticleRole] = useState(false);
  const [courseRole, setCourseRole] = useState(false);
  const [BookRole, setBookRole] = useState(false);
  const [memberRole, setMemberRole] = useState(false);
  const [webboardRole, setWebboardRole] = useState(false);
  const [orderReportRole, setOrderReportRole] = useState(false);
  const [purchaseManageRole, setPurchaseManageRole] = useState(false);
  const [userManageRole, setUserManageRole] = useState(false);
  const [trackingRole, setTrackingRole] = useState(false); 
  const [openKeys, setOpenKeys] = useState(["sub1"]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    var result = localStorage.getItem("user_profile");
    if (result) {
      setBannerRole(JSON.parse(result).banner);
      setArticleRole(JSON.parse(result).article);
      setCourseRole(JSON.parse(result).course);
      setBookRole(JSON.parse(result).book);
      setMemberRole(JSON.parse(result).member);
      setWebboardRole(JSON.parse(result).webboard);
      setOrderReportRole(JSON.parse(result).order_report);
      setPurchaseManageRole(JSON.parse(result).purchase_management);
      setUserManageRole(JSON.parse(result).user_management);
      setTrackingRole(JSON.parse(result).tracking);
    }

    const fetchData = async () => {
      if (JSON.parse(result).banner === true) await setOpenKeys(["sub1"]);
      else if (JSON.parse(result).article === true) await setOpenKeys(["sub2"]);
      else if (JSON.parse(result).course === true) await setOpenKeys(["sub3"]);
      else if (JSON.parse(result).book === true) await setOpenKeys(["sub4"]);
      else if (JSON.parse(result).member === true) await setOpenKeys(["sub5"]);
      else if (JSON.parse(result).order_report === true)
        await setOpenKeys(["sub6"]);
      else if (JSON.parse(result).purchase_management === true)
        await setOpenKeys(["sub7"]);
      else if (JSON.parse(result).user_management === true)
        await setOpenKeys(["sub8"]);
      else if (JSON.parse(result).tracking === true)
        await setOpenKeys(["sub9"]);
      else if (JSON.parse(result).webboard === true)
        await setOpenKeys(["sub10"]);
    };
    fetchData();
  }, [profile]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div>
      <Button
        type="primary"
        onClick={toggleCollapsed}
        style={{ marginBottom: 15 }}
      >
        <div style={{ marginTop: -7 }}>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined
          )}
        </div>
      </Button>

      <Menu
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        style={{ height: "100%", borderRight: 0 }}
        inlineCollapsed={collapsed}
      >
        {bannerRole === true && (
          <SubMenu
            key="sub1"
            icon={<NotificationOutlined />}
            title="แบนเนอร์เว็บไซต์"
          >
            <Menu.Item key="1" onClick={() => history.push("/banner")}>
              รายการแบนเนอร์
            </Menu.Item>
          </SubMenu>
        )}

        {articleRole === true && (
          <SubMenu key="sub2" icon={<FileWordOutlined />} title="บทความ">
            <Menu.Item key="2" onClick={() => history.push("/article")}>
              รายการบทความ
            </Menu.Item>
            <Menu.Item key="3" onClick={() => history.push("/writer")}>
              รายชื่อนักเขียน
            </Menu.Item>
          </SubMenu>
        )}

        {courseRole === true && (
          <SubMenu key="sub3" icon={<DesktopOutlined />} title="คอร์สออนไลน์">
            <Menu.Item key="4" onClick={() => history.push("/course")}>
              รายการคอร์สออนไลน์
            </Menu.Item>
            <Menu.Item key="5" onClick={() => history.push("/nft_group")}>
              NFT Group
            </Menu.Item>
            <Menu.Item key="6" onClick={() => history.push("/video")}>
              รายการ Video
            </Menu.Item>
            <Menu.Item key="7" onClick={() => history.push("/lecturer")}>
              รายชื่อวิทยากร
            </Menu.Item>
          </SubMenu>
        )}

        {BookRole === true && (
          <SubMenu key="sub4" icon={<ReadOutlined />} title="หนังสือ">
            <Menu.Item key="8" onClick={() => history.push("/book")}>
              รายการหนังสือ
            </Menu.Item>
            <Menu.Item key="9" onClick={() => history.push("/preorder_book")}>
              จัดเรียงหนังสือ Pre-Order
            </Menu.Item>
            <Menu.Item key="10" onClick={() => history.push("/audio_book")}>
              รายการหนังสือเสียง
            </Menu.Item>
            <Menu.Item key="11" onClick={() => history.push("/ebook")}>
              รายการ E-Book
            </Menu.Item>
          </SubMenu>
        )}

        {trackingRole === true && (
          <SubMenu key="sub9" icon={<LinkOutlined />} title="Tracking">
            <Menu.Item key="19" onClick={() => history.push("/influencer")}>
              Influencer
            </Menu.Item>
            <Menu.Item key="20" onClick={() => history.push("/channels")}>
              Channels
            </Menu.Item>
            <Menu.Item key="21" onClick={() => history.push("/tracking_link")}>
              Tracking Link
            </Menu.Item>
            <Menu.Item key="22" onClick={() => history.push("/tracking_report")}>
              Tracking Report
            </Menu.Item>
          </SubMenu>
        )}

        {memberRole === true && (
          <SubMenu key="sub5" icon={<TeamOutlined />} title="สมาชิก">
            <Menu.Item key="12" onClick={() => history.push("/member")}>
              รายการสมาชิก
            </Menu.Item>
          </SubMenu>
        )}

        {webboardRole === true && (
            <Menu.Item key="sub10" icon={<CommentOutlined  />} onClick={() => history.push("/webboard")}>
              Webboard
            </Menu.Item>
        )}

        {orderReportRole === true && (
          <SubMenu
            key="sub6"
            icon={<FundProjectionScreenOutlined />}
            title="รายงาน"
          >
            <Menu.Item
              key="13"
              onClick={() => history.push("/report/purchase")}
            >
              รายงานยอดสั่งซื้อ
            </Menu.Item>
            <Menu.Item key="14" onClick={() => history.push("/report/courses")}>
              รายงานสั่งซื้อคอร์ส
            </Menu.Item>
          </SubMenu>
        )}

        {purchaseManageRole === true && (
          <SubMenu
            key="sub7"
            icon={<ShoppingCartOutlined />}
            title="จัดการคำสั่งซื้อ"
          >
            <Menu.Item key="15" onClick={() => history.push("/report/order")}>
              รายการคำสั่งซื้อ
            </Menu.Item>
            <Menu.Item
              key="16"
              onClick={() => history.push("/report/tax_invoices")}
            >
              รายการขอใบกำกับภาษี
            </Menu.Item>
          </SubMenu>
        )}

        {userManageRole === true && (
          <SubMenu key="sub8" icon={<UserOutlined />} title="User Management">
            <Menu.Item key="17" onClick={() => history.push("/user")}>
              User
            </Menu.Item>
            <Menu.Item key="18" onClick={() => history.push("/role")}>
              Role &amp; Permission
            </Menu.Item>
          </SubMenu>
        )}
      </Menu>
    </div>
  );
}