import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import LecturerService from "../services/LecturerService";

import { Input, Button, Tooltip, Tag } from "antd";

import { EditOutlined, UserOutlined } from "@ant-design/icons";

import ContentPagination from "../utils/Pagination";

export default function Lecturer() {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [searchLecturer, setSearchLecturer] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPage] = useState(1);

  useEffect(() => {
    getData(currentPage, searchLecturer);
  }, [0]);

  const getData = async (page, filterName) => {
    await LecturerService.getAll(page, filterName)
      .then((res) => {
        const reponse = res.data;

        const totalPages = Math.ceil(reponse.total / perPage);
        setTotalPage(totalPages);
        setData(reponse.lecturers);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  function changePage(page) {
    setCurrentPage(page);
    getData(page, searchLecturer);
  }

  const onChangeSearchLecturer = (e) => {
    const searchLecturer = e.target.value;
    setSearchLecturer(searchLecturer);
    getData(currentPage, searchLecturer);
  };

  return (
    <div className="container">
      <div className="text-right container-fluid">
        <Button
          className="btn-primary px-3"
          onClick={() => history.push("/manage/lecturer/add")}
        >
          เพิ่มวิทยากร
        </Button>
      </div>

      <h5 className="mt-2 font-weight-bold">รายชื่อวิทยากร</h5>
      <div className="line-highlight">
        <hr />
      </div>

      <div className="flex-fill">
        <div className="form-group mb-0 mt-2">
          <label>ชื่อ</label>
          <Input
            placeholder="ค้นหาด้วย ชื่อวิทยาการ"
            prefix={<UserOutlined />}
            value={searchLecturer}
            onChange={onChangeSearchLecturer}
          />
        </div>

        <div class="table_wrapper">
          <table className="table border mb-0 mt-4">
            <thead>
              <tr>
                <th className="text-center">Status</th>
                <th className="text-left">ชื่อวิทยากร</th>
                <th className="text-left">ตำแหน่ง</th>
                <th>แก้ไข</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">
                        {item.status === "A" ? (
                          <Tag color="green">Active</Tag>
                        ) : (
                          <Tag color="volcano">InActive</Tag>
                        )}
                      </td>
                      <td className="text-left font-weight-bold">
                        {item.name}
                      </td>
                      <td className="text-left">{item.position}</td>
                      <td>
                        <Tooltip title="แก้ไข" className="mr-2">
                          <Button
                            type="primary"
                            shape="circle"
                            size="small"
                            className="btn-secondary"
                            icon={<EditOutlined />}
                            onClick={() =>
                              history.push(
                                "/manage/lecturer/update/" + item.lecturer_id
                              )
                            }
                          />
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <div className="text-right mt-4">
          {data && data.length > 0 && (
            <ContentPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={changePage.bind(this)}
              pageShow={10}
            />
          )}
        </div>
      </div>
    </div>
  );
}
