import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Input, Button, Popconfirm, Switch, notification } from "antd";
import UserService from "../services/UserService";
import moment from "moment";
import Notification from "../utils/Notification";

const ManageRole = (props) => {
  const history = useHistory();
  const initialRoleState = {
    id: null,
    role: "",
    banner: false,
    article: false,
    course: false,
    book: false,
    member: false,
    webboard: false,
    order_report: false,
    purchase_management: false,
    user_management: false,
    tracking: false,
    created_datetime: null,
    created_by: null,
    updated_datetime: null,
    updated_by: null,
  };

  const { id } = useParams();
  const [role, setRole] = useState(initialRoleState);
  const [submitted, setSubmitted] = useState(false);
  const [action, setAction] = useState();
  const [banner, setBanner] = useState(false);
  const [article, setArticle] = useState(false);
  const [course, setCourse] = useState(false);
  const [book, setBook] = useState(false);
  const [member, setMember] = useState(false);
  const [webboard, setWebboard] = useState(false);
  const [orderReport, setOrderReport] = useState(false);
  const [purchaseManagement, setPurchaseManagement] = useState(false);
  const [userManagement, setUserManagement] = useState(false);
  const [tracking, setTracking] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("token")) history.push("/login");

    var result = localStorage.getItem("user_profile");
    if (result) {
      if (JSON.parse(result).user_management === false) {
        history.push("/login");
      }
    }

    setAction(props.action);
    getRole(id);
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setRole({ ...role, [name]: value });
  };

  const getRole = (id) => {
    if (id) {
      UserService.getRoleById(id)
        .then((response) => {
          setRole(response.data);

          setBanner(response.data.banner);
          setArticle(response.data.article);
          setCourse(response.data.course);
          setBook(response.data.book);
          setMember(response.data.member);
          setWebboard(response.data.webboard);
          setOrderReport(response.data.order_report);
          setPurchaseManagement(response.data.purchase_management);
          setUserManagement(response.data.user_management);
          setTracking(response.data.tracking);
        })
        .catch((e) => {
          console.log(e);
          Notification.Show(
            "error",
            "From Web Service",
            e.response.data.message
          );
        });
    }
  };

  const ShowNotification = (type, header, message) =>
    notification[type]({
      message: header,
      description: message,
    });

  async function manageRole() {
    if (!role.role) {
      Notification.Show("error", "Validation", "กรุณาระบุ ชื่อ Role");
      return false;
    }

    if (action === "add") {
      role.created_by = "";
      role.created_datetime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    }

    role.status = "A";
    role.banner = banner;

    role.article = article;
    role.course = course;
    role.book = book;
    role.member = member;
    role.webboard = webboard;
    role.order_report = orderReport;
    role.purchase_management = purchaseManagement;
    role.user_management = userManagement;
    role.tracking = tracking;

    role.updated_by = "";
    role.updated_datetime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

    if (action === "add") {
      UserService.addRole(role)
        .then((response) => {
          ShowNotification(
            "success",
            "Success",
            "บันทึกข้อมูล Role เรียบร้อยแล้ว"
          );
          setSubmitted(true);
          history.push("/role");
        })
        .catch((e) => {
          console.log(e);
          Notification.Show("error", "Validation", e.response.data.message);
        });
    } else {
      UserService.updateRole(role)
        .then((response) => {
          console.log(response.data);
          ShowNotification(
            "success",
            "Success",
            "บันทึกข้อมูล Role เรียบร้อยแล้ว"
          );
          history.push("/role");
        })
        .catch((e) => {
          console.error(e.response.data.message);
          Notification.Show("error", "Error", e.response.data.message);
        });
    }
  }

  const deleteRole = () => {
    UserService.deleteRole(role.id)
      .then((response) => {
        console.log(response.data);
        Notification.Show("success", "Success", "Delete Role Complete");
        history.push("/role");
      })
      .catch((e) => {
        console.log(e);
        Notification.Show("error", "Error", e.response.data.message);
      });
  };

  const cancel = () => {
    history.push("/role");
  };

  function confirmDialog(e) {
    deleteRole();
  }

  function cancelDialog(e) {}

  function onChangeRoleBanner(checked) {
    setBanner(checked);
  }

  function onChangeRoleArticle(checked) {
    setArticle(checked);
  }

  function onChangeRoleCourse(checked) {
    setCourse(checked);
  }

  function onChangeRoleBook(checked) {
    setBook(checked);
  }

  function onChangeRoleMember(checked) {
    setMember(checked);
  }

  function onChangeRoleWebboard(checked) {
    setWebboard(checked);
  }

  function onChangeRoleOrderReport(checked) {
    setOrderReport(checked);
  }

  function onChangeRolePurchaseManage(checked) {
    setPurchaseManagement(checked);
  }

  function onChangeRoleUserManage(checked) {
    setUserManagement(checked);
  }

  function onChangeRoleTracking(checked) {
    setTracking(checked);
  }

  return (
    <div className="container">
      <div className="d-flex mt-4 align-items-stretch">
        <div className="flex-fill">
          <div className="header-bar">ข้อมูล Role</div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="form-group mb-3 mt-4">
                  <label>
                    ชื่อ Role
                    <span className="text-danger">*</span>
                  </label>
                  <Input
                    id="role"
                    required
                    value={role.role}
                    onChange={handleInputChange}
                    name="role"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="header-bar mt-4">ข้อมูล Role</div>

          <div className="container-fluid mt-4">
            <div className="form-group pb-3">
              <div className="row">
                <div className="col-sm-2">
                  <div className="text-left container-fluid mt-1">
                    แบนเนอร์เว็บไซต์
                  </div>
                </div>
                <div className="col-sm-2">
                  <div className="text-right container-fluid mt-1">
                    <Switch
                      onChange={onChangeRoleBanner}
                      checkedChildren="Active"
                      unCheckedChildren="InActive"
                      checked={banner}
                    />
                  </div>
                </div>

                <div className="col-sm-2">
                  <div className="text-left container-fluid mt-1">บทความ</div>
                </div>
                <div className="col-sm-2">
                  <div className="text-right container-fluid mt-1">
                    <Switch
                      onChange={onChangeRoleArticle}
                      checkedChildren="Active"
                      unCheckedChildren="InActive"
                      checked={article}
                    />
                  </div>
                </div>

                <div className="col-sm-2">
                  <div className="text-left container-fluid mt-1">
                    คอร์สออนไลน์
                  </div>
                </div>
                <div className="col-sm-2">
                  <div className="text-right container-fluid mt-1">
                    <Switch
                      onChange={onChangeRoleCourse}
                      checkedChildren="Active"
                      unCheckedChildren="InActive"
                      checked={course}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid mt-4">
            <div className="form-group pb-3">
              <div className="row">
                <div className="col-sm-2">
                  <div className="text-left container-fluid mt-1">หนังสือ</div>
                </div>
                <div className="col-sm-2">
                  <div className="text-right container-fluid mt-1">
                    <Switch
                      onChange={onChangeRoleBook}
                      checkedChildren="Active"
                      unCheckedChildren="InActive"
                      checked={book}
                    />
                  </div>
                </div>

                <div className="col-sm-2">
                  <div className="text-left container-fluid mt-1">สมาชิก</div>
                </div>
                <div className="col-sm-2">
                  <div className="text-right container-fluid mt-1">
                    <Switch
                      onChange={onChangeRoleMember}
                      checkedChildren="Active"
                      unCheckedChildren="InActive"
                      checked={member}
                    />
                  </div>
                </div>

                <div className="col-sm-2">
                  <div className="text-left container-fluid mt-1">
                    รายงานยอดสั่งซื้อ
                  </div>
                </div>
                <div className="col-sm-2">
                  <div className="text-right container-fluid mt-1">
                    <Switch
                      onChange={onChangeRoleOrderReport}
                      checkedChildren="Active"
                      unCheckedChildren="InActive"
                      checked={orderReport}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid mt-4">
            <div className="form-group pb-3">
              <div className="row">
                <div className="col-sm-2">
                  <div className="text-left container-fluid mt-1">
                    จัดการคำสั่งซื้อ
                  </div>
                </div>
                <div className="col-sm-2">
                  <div className="text-right container-fluid mt-1">
                    <Switch
                      onChange={onChangeRolePurchaseManage}
                      checkedChildren="Active"
                      unCheckedChildren="InActive"
                      checked={purchaseManagement}
                    />
                  </div>
                </div>

                <div className="col-sm-2">
                  <div className="text-left container-fluid mt-1">
                    User Management
                  </div>
                </div>
                <div className="col-sm-2">
                  <div className="text-right container-fluid mt-1">
                    <Switch
                      onChange={onChangeRoleUserManage}
                      checkedChildren="Active"
                      unCheckedChildren="InActive"
                      checked={userManagement}
                    />
                  </div>
                </div>

                <div className="col-sm-2">
                  <div className="text-left container-fluid mt-1">
                    จัดการ Webboard
                  </div>
                </div>
                <div className="col-sm-2">
                  <div className="text-right container-fluid mt-1">
                    <Switch
                      onChange={onChangeRoleWebboard}
                      checkedChildren="Active"
                      unCheckedChildren="InActive"
                      checked={webboard}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid mt-4">
            <div className="form-group pb-3">
              <div className="row">
                <div className="col-sm-2">
                  <div className="text-left container-fluid mt-1">Tracking</div>
                </div>
                <div className="col-sm-2">
                  <div className="text-right container-fluid mt-1">
                    <Switch
                      onChange={onChangeRoleTracking}
                      checkedChildren="Active"
                      unCheckedChildren="InActive"
                      checked={tracking}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-2">
          <div>
            {action === "update" && (
              <Popconfirm
                title="ต้องการลบเนื้อหานี้ออกจากเว็บไซต์?"
                onConfirm={confirmDialog}
                onCancel={cancelDialog}
                okText="ยืนยันการลบ"
                cancelText="ยกเลิก"
              >
                <Button className="btn-danger px-3">ลบข้อมูล</Button>
              </Popconfirm>
            )}
          </div>
        </div>

        <div className="col-10 text-right">
          <Button className="btn-secondary mr-2" onClick={cancel}>
            ย้อนกลับ
          </Button>
          <Button className="btn-primary" onClick={manageRole}>
            บันทึกข้อมูล
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ManageRole;
