import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import VideoService from "../services/VideoService";
import moment from "moment";
import { Input, Select, Button, Tag, Spin, AutoComplete } from "antd";

import { PictureOutlined } from "@ant-design/icons";
import ContentPagination from "../utils/Pagination";
import Notification from "../utils/Notification";
import { DefaultPlayer as Videos } from "react-html5video";
import "react-html5video/dist/styles.css";

const { Option } = Select;

export default function Video() {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(5);
  const [searchVideoName, setSearchVideoName] = useState("");
  const [searchCategoryID, setSearchCategoryID] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPage] = useState(1);
  const [videoCategory, setVideoCategory] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("token")) history.push("/login");

    var result = localStorage.getItem("user_profile");
    if (result) {
      if (JSON.parse(result).course === false) {
        history.push("/login");
      }
    }

    getData(currentPage, searchVideoName, searchCategoryID);
    getVideoCategory();
  }, [0]);

  const getData = async (page, filterName, filterCategory) => {
    setLoading(true);
    await VideoService.getAll(page, filterName, filterCategory)
      .then((res) => {
        setLoading(false);
        const response = res.data;

        const totalPages = Math.ceil(response.total / perPage);
        setTotalPage(totalPages);
        setData(response.video);
      })
      .catch((e) => {
        setLoading(false);
        if (e.message) Notification.Show("error", "Error", e.message);
        else Notification.Show("error", "Error", e.response.data.message);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
        Notification.Show("error", "Error", e.message);
      });
  };

  const getVideoCategory = async () => {
    await VideoService.getVideoCategory()
      .then((res) => {
        const response = res.data;
        setVideoCategory(response.vdo_category);
      })
      .catch((e) => {
        console.log(e);
        //Notification.Show("error", "Error", e.response.data.message);
      });
  };

  function changePage(page) {
    setCurrentPage(page);
    getData(page, searchVideoName, searchCategoryID);
  }

  const onChangeSearchVideoName = (e) => {
    const searchVideoName = e.target.value;
    setSearchVideoName(searchVideoName);
    getData("", searchVideoName, searchCategoryID);
  };

  const onChangeSearchCategoryID = (category_id, e) => {
    setSearchCategoryID(category_id);
    getData("", searchVideoName, category_id);
  };

  function formatDate(date) {
    if (date) {
      return moment(date).utc().format("DD/MM/YYYY HH:mm");
    }
  }

  function formatCategory(category) {
    if (videoCategory.length > 0) {
      for (let i = 0; i < videoCategory.length; i++) {
        if (videoCategory[i].id === category) {
          return <Tag color="blue">{videoCategory[i].name}</Tag>;
          break;
        }
      }
    }
  }

  function formatDate(date) {
    if (date) {
      return moment(date).utc().format("DD/MM/YYYY HH:mm:ss");
    }
  }

  function getYoutubeUrlEmbeded(url) {
    var regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length === 11) {
      return "//www.youtube.com/embed/" + match[2];
    }
  }

  return (
    <div className="container">
      <div className="text-right container-fluid">
        <Button
          className="btn-primary px-4"
          size=""
          onClick={() => history.push("/manage/video/add")}
        >
          เพิ่ม Video
        </Button>
      </div>

      <h5 className="mt-2 font-weight-bold">
        รายการ Video <Spin style={{ marginLeft: 10 }} spinning={loading} />
      </h5>
      <div className="line-highlight">
        <hr />
      </div>

      <div className="flex-fill">
        <div className="row mt-4 pt-4">
          <div className="col-sm-4">
            <div className="form-group">
              <label>ชื่อ Video</label>
              <Input
                placeholder="ค้นหาด้วย ชื่อ Video"
                value={searchVideoName}
                onChange={onChangeSearchVideoName}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>หมวดหมู่</label>
              <Select
                defaultValue=""
                className="w-100"
                onSelect={(value, event) =>
                  onChangeSearchCategoryID(value, event)
                }
              >
                <Option value="">เลือกหมวดหมู่</Option>
                {videoCategory &&
                  videoCategory.map((item, index) => {
                    return <Option value={item.id}>{item.name}</Option>;
                  })}
              </Select>
            </div>
          </div>
        </div>

        {data &&
          data.map((item, index) => {
            return (
              <div className="card mt-2">
                <div className="card-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 text-right text-grey font-12 ml-0 mr-0 pl-0 pr-0">
                        {item.is_specify_nft && (
                          <Tag
                            style={{ fontSize: "16px", padding: 7 }}
                            color="gold"
                          >
                            Specify NFT
                          </Tag>
                        )}
                        {item.status === "A" ? (
                          <Tag
                            style={{ fontSize: "16px", padding: 7 }}
                            color="green"
                          >
                            Active
                          </Tag>
                        ) : (
                          <Tag
                            style={{ fontSize: "16px", padding: 7 }}
                            color="red"
                          >
                            InActive
                          </Tag>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-5 col-sm-3">
                        <div>
                          <div className="position-relative my-2">
                            {item.video_url ? (
                              <Videos
                                controls={[
                                  "PlayPause",
                                  "Seek",
                                  "Time",
                                  "Volume",
                                  "Fullscreen",
                                ]}
                                poster={item.cover_image_url}
                              >
                                <source src={item.video_url} type="video/mp4" />
                              </Videos>
                            ) : null}
                            {/* {item.is_youtube === true && (
                              <iframe
                                style={{
                                  display: "block",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                                src={getYoutubeUrlEmbeded(item.video_url)}
                              ></iframe>
                            )} */}

                            {/* {item.is_youtube === false && (
                              <>
                                {item.video_url ? (
                                  <Player
                                    playsInline
                                    poster={item.cover_image_url}
                                    src={item.video_url}
                                  />
                                ) : null}
                              </>
                            )} */}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-7 col-sm-9 mt-4">
                        <div className="d-flex ml-4">
                          <span className="align-title font-weight-bold">
                            ชื่อ Video:
                          </span>
                          <span className="text-left">{item.name}</span>
                        </div>

                        <div className="d-flex mt-3 ml-4">
                          <span className="align-title font-weight-bold">
                            หมวดหมู่ :
                          </span>
                          <span className="text-left">
                            {formatCategory(item.category_id)}
                          </span>
                        </div>

                        <div className="d-flex mt-3 ml-4">
                          <span className="align-title font-weight-bold">
                            วันที่สร้าง :
                          </span>
                          <span className="text-left">
                            {formatDate(item.created_datetime)}
                          </span>
                        </div>

                        <div className="text-right mt-4">
                          {item.is_specify_nft && (
                            <Button
                              className="btn-warning mr-2"
                              onClick={() =>
                                history.push("/vdo_specify_nft/" + item.id)
                              }
                            >
                             Specify NFT
                            </Button>
                          )}

                          <Button
                            className="btn-secondary mr-2"
                            onClick={() =>
                              history.push("/manage/video/update/" + item.id)
                            }
                          >
                            ดูรายละเอียด/แก้ไข
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

        <div className="text-right mt-4">
          {data && data.length > 0 && (
            <ContentPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={changePage.bind(this)}
              pageShow={5}
            />
          )}
        </div>
      </div>
    </div>
  );
}
