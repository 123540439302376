import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Input,
  Button,
  Popconfirm,
  Switch,
  Modal,
  notification,
  Spin,
} from "antd";
import WriterService from "../services/WriterService";
import UtilityService from "../services/UtilityService";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "2read-ckeditor5-custom-build";
import imageCompression from "browser-image-compression";
import MyUploadAdapter from "../utils/MyUploadAdapter";
import { PictureOutlined } from "@ant-design/icons";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Notification from "../utils/Notification";

const ManageWriter = (props) => {
  const history = useHistory();
  const initialWriterState = {
    writer_id: null,
    name: "",
    position: "",
    detail: "",
    facebook: "",
    youtube: "",
    twitter: "",
    line: "",
    instagram: "",
    image_url: "",
    created_datetime: null,
    created_by: null,
    updated_datetime: null,
    updated_by: null,
  };

  const { writer_id } = useParams();
  const [writer, setWriter] = useState(initialWriterState);
  const [submitted, setSubmitted] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [imageType, setImageType] = useState();
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [cropperRef, setCropperRef] = useState(React.createRef());
  const [writerImage, setWriterImage] = useState();
  const [status, setStatus] = useState(true);
  const [action, setAction] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  useEffect(() => {
    setAction(props.action);
    getWriter(writer_id);
  }, [writer_id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setWriter({ ...writer, [name]: value });
  };

  const getWriter = (writer_id) => {
    if (writer_id) {
      WriterService.get(writer_id)
        .then((response) => {
          console.log(response.data);
          setWriter(response.data);
          setWriterImage(response.data.image_url);
          if (response.data.status === "A") setStatus(true);
          else setStatus(false);
        })
        .catch((e) => {
          console.log(e);
          Notification.Show(
            "error",
            "From Web Service",
            e.response.data.message
          );
        });
    }
  };

  const ShowNotification = (type, header, message) =>
    notification[type]({
      message: header,
      description: message,
    });

  async function manageWriter() {
    if (!writer.name) {
      Notification.Show("error", "Validation", "กรุณาระบุ ชื่อนักเขียน");
      return;
    }

    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;

    if (action === "add") {
      if (imageFile) {
        await uploadImage(imageFile);
      } else writer.image_url = "";

      writer.created_by = action_by;
      writer.created_datetime = moment(new Date()).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    } else {
      if (imageFile) {
        await uploadImage(imageFile);
      } else writer.image_url = writerImage;
    }

    if (status) writer.status = "A";
    else writer.status = "I";

    writer.updated_by = action_by;
    writer.updated_datetime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

    console.log(writer);
    setLoading(true);

    if (action === "add") {
      WriterService.create(writer)
        .then((response) => {
          ShowNotification(
            "success",
            "Success",
            "บันทึกข้อมูลนักเขียนเรียบร้อยแล้ว"
          );
          setSubmitted(true);
          console.log(response.data);
          history.push("/writer");
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
          Notification.Show("error", "Validation", e.response.data.message);
        });
    } else {
      WriterService.update(writer.writer_id, writer)
        .then((response) => {
          console.log(response.data);
          ShowNotification(
            "success",
            "Success",
            "บันทึกข้อมูลนักเขียนเรียบร้อยแล้ว"
          );
          history.push("/writer");
          setLoading(false);
        })
        .catch((e) => {
          console.error(e.response.data.message);
          setLoading(false);
          Notification.Show("error", "Error", e.response.data.message);
        });
    }
  }

  const deleteWriter = () => {
    setLoadingDelete(true);
    WriterService.remove(writer.writer_id)
      .then((response) => {
        setLoadingDelete(false);
        console.log(response.data);
        Notification.Show("success", "Success", "Delete Writer Complete");
        history.push("/writer");
      })
      .catch((e) => {
        setLoadingDelete(false);
        console.log(e);
        Notification.Show("error", "Error", e.response.data.message);
      });
  };

  const cancel = () => {
    history.push("/writer");
  };

  function confirmDialog(e) {
    deleteWriter();
  }

  function cancelDialog(e) {
    //message.error("Click on No");
  }

  function customUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      // Configure the URL to the upload script in your back-end here!
      return new MyUploadAdapter(loader);
    };
  }

  function onSelectFile(e, type) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener("load", () => setImageUrl(reader.result));
      //reader.addEventListener("load", () => setWriterImage(reader.result));
      reader.readAsDataURL(e.target.files[0]);

      setImageType(type);
      setCropModalOpen(true);
      setImageFile(e.target.files[0]);
    }
  }

  function cropImage() {
    const cropper = cropperRef.current.cropper;

    cropper.getCroppedCanvas().toBlob((blob) => {
      const fileName = imageFile.name;

      const file = new File([blob], fileName, {
        lastModifiedDate: new Date(),
        type: imageFile.type,
      });
      const cropppedPreview = cropper.getCroppedCanvas().toDataURL();
      //this.props.onUpdateThumbnail(file, this.state.imageType);
      setWriterImage(cropppedPreview);
      setImageType("");
      setImageFile(file);
      closeCropModal();
    });
  }

  function closeCropModal() {
    setCropModalOpen(false);
  }

  function removeImage() {
    //this.props.onRemoveThumbnail(type);
    setWriterImage("");
    setImageUrl("");
  }

  async function uploadImage(image) {
    const options = {
      maxSizeMB: 0.1,
      useWebWorker: true,
      initialQuality: 0.5
    };
    try {
      const compressedFile = await imageCompression(image, options);
      const file = new File([compressedFile], image.name, { type: image.type });

      if (image) {
        let formData = new FormData();
        let imagefile = file;

        formData.append("upload", imagefile);

        await UtilityService.uploadImage(formData)
          .then((response) => {
            console.log(response);
            writer.image_url = response.data.url;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } catch (error) {
      console.log(error);
    }
  }

  function onChangeStatus(checked) {
    setStatus(checked);
  }

  return (
    <div className="container">
      <div className="d-flex mt-4 align-items-stretch">
        <div className="flex-fill">
          <div className="header-bar">ข้อมูลนักเขียน</div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4"></div>
              <div className="col-sm-4">
                <div className="position-relative my-2 mt-4">
                  {writerImage ? (
                    <img src={writerImage} className="img-fluid" />
                  ) : (
                    <div
                      className="image-placeholder d-flex flex-column align-items-center justify-content-center mt-5"
                      style={{  height: 240 }}
                    >
                      <div>
                        <PictureOutlined
                          style={{ fontSize: 30, color: "#bbb" }}
                        />
                      </div>
                      <div className="font-12">อัพโหลดรูป</div>
                    </div>
                  )}

                  <input
                    type="file"
                    accept="image/*"
                    className="input-file-hidden"
                    onChange={(event) => {
                      onSelectFile(event, "writers");
                    }}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                  />
                </div>
                {writerImage && (
                  <div className="text-right mt-2">
                    <Button
                      className="btn btn-sm btn-danger"
                      onClick={() => removeImage()}
                    >
                      ลบ
                    </Button>
                  </div>
                )}
                <div className="text-right text-danger">
                  (ขนาดรูปที่แนะนำ 240 x 240 px)
                </div>
              </div>
              <div className="col-sm-4">
                <div className="text-right mt-3">
                  <Switch
                    onChange={onChangeStatus}
                    checkedChildren="Active"
                    unCheckedChildren="InActive"
                    checked={status}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group mb-3 mt-4">
                  <label>
                    ชื่อนักเขียน (รวมคำนำหน้า/ชื่อเรียกในวงการ)
                    <span className="text-danger">*</span>
                  </label>
                  <Input
                    id="name"
                    required
                    value={writer.name}
                    onChange={handleInputChange}
                    name="name"
                  />
                </div>

                <div className="form-group mb-3">
                  <label
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    ตำแหน่ง
                  </label>
                  <Input
                    id="position"
                    value={writer.position}
                    onChange={handleInputChange}
                    name="position"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="header-bar mt-4">ประวัตินักเขียน</div>

          <div className="container-fluid mt-4">
            <div className="form-group pb-3">
              <label>ประวัติโดยสังเขป</label>
              <div className="content-wrapper">
                <CKEditor
                  editor={ClassicEditor}
                  data={writer.detail}
                  config={{
                    extraPlugins: [customUploadAdapterPlugin],
                    indentBlock: {
                      offset: 1,
                      unit: "em",
                    },
                    image: {
                      resize: true,
                      toolbar: [
                        "linkImage",
                        "|",
                        "imageTextAlternative",
                        "|",
                        "imageStyle:full",
                        "imageStyle:alignLeft",
                        "imageStyle:alignCenter",
                        "imageStyle:alignRight",
                      ],
                      styles: [
                        "full",
                        "alignCenter",
                        "alignLeft",
                        "alignRight",
                      ],
                    },
                  }}
                  onInit={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    writer.detail = data;
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
              </div>
            </div>
          </div>

          <div className="header-bar mt-4">ช่องทางการติดตาม (Social)</div>

          <div className="container-fluid mt-4">
            <div className="form-group mb-3">
              <label>Facebook</label>
              <Input
                id="facebook"
                value={writer.facebook}
                onChange={handleInputChange}
                name="facebook"
              />
            </div>

            <div className="form-group mb-3">
              <label>Youtube</label>
              <Input
                id="facebook"
                value={writer.youtube}
                onChange={handleInputChange}
                name="youtube"
              />
            </div>

            <div className="form-group mb-3">
              <label>Twitter</label>
              <Input
                id="twitter"
                value={writer.twitter}
                onChange={handleInputChange}
                name="twitter"
              />
            </div>

            <div className="form-group mb-3">
              <label>Line@</label>
              <Input
                id="line"
                value={writer.line}
                onChange={handleInputChange}
                name="line"
              />
            </div>
            <div className="form-group mb-3">
              <label>instagram</label>
              <Input
                id="instagram"
                value={writer.instagram}
                onChange={handleInputChange}
                name="instagram"
              />
            </div>
          </div>
        </div>
      </div>

      
      <div className="row mt-4">
        <div className="col-2">
          <div>
            {action === "update" && (
              <Popconfirm
                title="ต้องการลบเนื้อหานี้ออกจากเว็บไซต์?"
                onConfirm={confirmDialog}
                onCancel={cancelDialog}
                okText="ยืนยันการลบ"
                cancelText="ยกเลิก"
              >
                <Button className="btn-danger px-3">ลบข้อมูล</Button>
                <Spin spinning={loadingDelete} />
              </Popconfirm>
            )}
          </div>
        </div>

        <div className="col-10 text-right">
          <Button className="btn-secondary mr-2" onClick={cancel}>
            ยกเลิก
          </Button>
          <Button className="btn-primary" onClick={manageWriter}>
            บันทึกข้อมูล
          </Button>
          <Spin style={{ marginLeft: 10 }} spinning={loading} />
        </div>
      </div>

      <Modal
        title={null}
        footer={null}
        width={500}
        maskClosable={false}
        visible={cropModalOpen}
        onCancel={() => setCropModalOpen(false)}
      >
        <div className="d-flex">
          <div className="flex-fill thumbnail-crop-area mt-5">
            <Cropper
              src={imageUrl}
              aspectRatio={240 / 240}
              autoCropArea={1}
              preview=".book-preview"
              viewMode={1}
              ref={cropperRef}
            />
          </div>

          <div className="mt-4 book-preview-area ml-4">
            <div className="font-weight-bold text-center mb-2">ตัวอย่างรูป</div>

            <div className="book-preview" />
          </div>
        </div>

        <div className="mt-5 text-center">
          <button
            type="button"
            onClick={() => {
              cropImage();
            }}
            className="btn btn-primary mr-2"
          >
            ตกลง
          </button>
          <button
            type="button"
            className="btn btn-secondary mr-2"
            onClick={() => {
              setCropModalOpen(false);
            }}
          >
            ยกเลิก
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ManageWriter;
