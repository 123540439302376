import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import UserService from "../services/UserService";
import { Input, Button, Tooltip, Tag, Select, Spin } from "antd";
import { EditOutlined } from "@ant-design/icons";
import ContentPagination from "../utils/Pagination";

const { Option } = Select;

export default function User() {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [searchEmail, setSearchEmail] = useState("");
  const [searchRole, setSearchRole] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPage] = useState(1);
  const [adminRole, setAdminRole] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("token")) history.push("/login");

    var result = localStorage.getItem("user_profile");
    if (result) {
      if (JSON.parse(result).user_management === false) {
        history.push("/login");
      }
    }

    getData(currentPage, searchEmail, searchRole);
    getAdminRole();
  }, [0]);

  const getData = async (page, filterEmail, filterRole) => {
    setLoading(true);
    await UserService.getAll(page, filterEmail, filterRole)
      .then((res) => {
        setLoading(false);
        const response = res.data;
        console.log(response.users);
        setData(response.users);
        const totalPages = Math.ceil(response.total / perPage);
        setTotalPage(totalPages);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        let error = e.response ? e.response.data.message : e.message;
        Notification.Show("error", "Error", error);
      });
  };

  const getAdminRole = async () => {
    await UserService.getAdminRole()
      .then((res) => {
        const reponse = res.data;
        setAdminRole(reponse.admin_role);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  function changePage(page) {
    setCurrentPage(page);
    getData(page, searchEmail, searchRole);
  }

  const onChangeSearchEmail = (e) => {
    const searchEmail = e.target.value;
    setSearchEmail(searchEmail);
    getData(currentPage, searchEmail, searchRole);
  };

  const onChangeSearchRole = (role_id, e) => {
    console.log(role_id);
    setSearchRole(role_id);
    getData(currentPage, searchEmail, role_id);
  };

  function formatStatus(status) {
    if (status === "A") {
      return <Tag color="green">Active</Tag>;
    } else if (status === "I") {
      return <Tag color="red">InActive</Tag>;
    } else if (status === "P") {
      return <Tag color="red">Reset Password</Tag>;
    } else {
      return "";
    }
  }

  return (
    <div className="container">
      <div className="text-right container-fluid">
        <Button
          className="btn-primary px-3"
          size=""
          onClick={() => history.push("/manage/user/add")}
        >
          เพิ่ม User
        </Button>
      </div>

      <h5 className="mt-2 font-weight-bold">
        รายชื่อ User
        <Spin style={{ marginLeft: 10 }} spinning={loading} />
      </h5>
      <div className="line-highlight">
        <hr />
      </div>

      <div className="flex-fill">
        <div className="row mt-4 pt-3">
          <div className="col-6">
            <div className="form-group">
              <label>Email</label>
              <Input
                placeholder="ค้นหาด้วย Email"
                value={searchEmail}
                onChange={onChangeSearchEmail}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>Role</label>
              <Select
                defaultValue=""
                className="w-100"
                onSelect={(value, event) => onChangeSearchRole(value, event)}
              >
                <Option value="">Select Role</Option>
                {adminRole &&
                  adminRole.map((item, index) => {
                    return <Option value={item.id}>{item.role}</Option>;
                  })}
              </Select>
            </div>
          </div>
        </div>

        <div class="table_wrapper">
          <table className="table border mb-0 mt-4">
            <thead>
              <tr>
                <th className="text-center">Status</th>
                <th className="text-left">Email</th>
                <th className="text-left">Name</th>
                <th className="text-left">Role</th>
                <th>แก้ไข</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">
                        {formatStatus(item.status)}
                      </td>
                      <td className="text-left font-weight-bold">
                        {item.email}
                      </td>
                      <td className="text-left">{item.name}</td>
                      <td className="text-left">{item.role_name}</td>
                      <td>
                        <Tooltip title="แก้ไข" className="mr-2">
                          <Button
                            type="primary"
                            shape="circle"
                            size="small"
                            className="btn-secondary"
                            icon={<EditOutlined />}
                            onClick={() =>
                              history.push("/manage/user/update/" + item.email)
                            }
                          />
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <div className="text-right mt-4">
          {data && data.length > 0 && (
            <ContentPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={changePage.bind(this)}
              pageShow={10}
            />
          )}
        </div>
      </div>
    </div>
  );
}
