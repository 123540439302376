import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import OrderService from "../services/OrderService";
import { Input, Button, Tooltip, Tag, Select, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import ContentPagination from "../utils/Pagination";
import moment from "moment";
import XLSX from "xlsx";

const { Option } = Select;

export default function ReportOrders() {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [searchOrderNumber, setSearchOrderNumber] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [searchDeliverStatus, setSearchDeliverStatus] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [searchName, setSearchName] = useState("");
  const [searchProductType, setSearchProductType] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchStartDate, setSearchStartDate] = useState(
    moment().add(-30, "days")
  );

  useEffect(() => {
    if (!localStorage.getItem("token")) history.push("/login");

    var result = localStorage.getItem("user_profile");
    if (result) {
      if (JSON.parse(result).purchase_management === false) {
        history.push("/login");
      }
    }

    getData(
      currentPage,
      searchOrderNumber,
      searchStatus,
      searchDeliverStatus,
      searchEmail,
      searchName,
      searchProductType
    );
  }, [0]);

  const getData = async (
    page,
    filterOrderNumber,
    filterStatus,
    filterDeliverStatus,
    filterEmail,
    filterName,
    filterProductType
  ) => {
    setLoading(true);
    var filterFirstName = "";
    var filterLastName = "";

    if (filterName) {
      if (filterName.includes(" ")) {
        filterFirstName = filterName.split(" ").slice(0, -1).join(" ");
        filterLastName = filterName.split(" ").slice(-1).join(" ");
      } else {
        filterFirstName = filterName;
        filterLastName = "";
      }
    }

    await OrderService.getAll(
      page,
      filterOrderNumber,
      filterStatus,
      filterDeliverStatus,
      filterEmail,
      filterFirstName,
      filterLastName,
      filterProductType
    )
      .then((res) => {
        setLoading(false);
        const reponse = res.data;
        console.log(reponse);
        const totalPages = Math.ceil(reponse.total / perPage);
        setTotalPage(totalPages);
        setData(reponse.orders);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  function changePage(page) {
    setCurrentPage(page);
    // getData(page, searchOrderNumber, searchStatus, searchDeliverStatus,searchEmail,se);

    getData(
      page,
      searchOrderNumber,
      searchStatus,
      searchDeliverStatus,
      searchEmail,
      searchName,
      searchProductType
    );
  }

  const onChangeSearchOrderNumber = (e) => {
    const searchOrderNumber = e.target.value;
    setSearchOrderNumber(searchOrderNumber);
    getData(
      currentPage,
      searchOrderNumber,
      searchStatus,
      searchDeliverStatus,
      searchEmail,
      searchName,
      searchProductType
    );
  };

  const onChangeSearchName = (e) => {
    const searchName = e.target.value;
    setSearchName(searchName);
    getData(
      currentPage,
      searchOrderNumber,
      searchStatus,
      searchDeliverStatus,
      searchEmail,
      searchName,
      searchProductType
    );
  };

  const onChangeSearchEmail = (e) => {
    const searchEmail = e.target.value;
    setSearchEmail(searchEmail);
    getData(
      currentPage,
      searchOrderNumber,
      searchStatus,
      searchDeliverStatus,
      searchEmail,
      searchName,
      searchProductType
    );
  };

  const onChangeSearchProductType = (product_type, e) => {
    setSearchProductType(product_type);

    getData(
      currentPage,
      searchOrderNumber,
      searchStatus,
      searchDeliverStatus,
      searchEmail,
      searchName,
      product_type
    );
  };

  const onChangeSearchStatus = (status, e) => {
    setSearchStatus(status);
    getData(
      currentPage,
      searchOrderNumber,
      status,
      searchDeliverStatus,
      searchEmail,
      searchName,
      searchProductType
    );
  };

  const onChangeSearchDeliverStatus = (deliver_status, e) => {
    setSearchDeliverStatus(deliver_status);
    if (deliver_status === "" || deliver_status === "N/A")
      getData(
        currentPage,
        searchOrderNumber,
        searchStatus,
        deliver_status,
        searchEmail,
        searchName,
        searchProductType
      );
    else
      getData(
        "",
        searchOrderNumber,
        searchStatus,
        deliver_status,
        searchEmail,
        searchName,
        searchProductType
      );
  };

  function formatDate(date) {
    if (date) {
      return moment(date).utc().format("DD/MM/YYYY HH:mm");
    }
  }

  function formatBookDeliveryStatus(item) {
    if (item.book_deliver_status === "Y") {
      return <Tag color="green">จัดส่งแล้ว</Tag>;
    } else if (item.book_deliver_status === "N") {
      return <Tag color="volcano">รอการจัดส่ง</Tag>;
    } else {
      return item.book_deliver_status;
    }
  }

  function formatPaymentMethod(item) {
    if (item.payment_method === "installment") {
      let output = "";
      output = item.payment_method + " (" + item.installment + " months)";
      return output;
    } else return item.payment_method;
  }

  function formatDeliveryName(name) {
    if (name.trim() === "" || name === null) {
      return "N/A";
    } else {
      return name;
    }
  }

  function formatStatus(status) {
    if (status === 2) {
      return <Tag color="green">ชำระเงินแล้ว</Tag>;
    } else if (status === 1) {
      return <Tag color="gold">รอการชำระเงิน</Tag>;
    } else if (status === 3) {
      return <Tag color="red">ชำระเงินล้มเหลว</Tag>;
    } else if (status === 4) {
      return <Tag color="red">ยกเลิกคำสั่งซื้อ</Tag>;
    } else {
      return status;
    }
  }

  function formatStatusString(status) {
    if (status === 2) {
      return "ชำระเงินแล้ว";
    } else if (status === 1) {
      return "รอการชำระเงิน";
    } else if (status === 3) {
      return "ชำระเงินล้มเหลว";
    } else if (status === 4) {
      return "ยกเลิกคำสั่งซื้อ";
    } else {
      return status;
    }
  }

  function formatAmount(amount) {
    if (amount) {
      return new Intl.NumberFormat("en-GB", {
        style: "decimal",
      }).format(amount);
    }
  }

  async function exportData() {
    let response = null;
    setLoading(true);
    let end_date = moment().format("YYYY-MM-DD 23:59:59");

    var filterFirstName = "";
    var filterLastName = "";

    if (searchName) {
      if (searchName.includes(" ")) {
        filterFirstName = searchName.split(" ").slice(0, -1).join(" ");
        filterLastName = searchName.split(" ").slice(-1).join(" ");
      } else {
        filterFirstName = searchName;
        filterLastName = "";
      }
    }
      
    await OrderService.getAll(
      "",
      searchOrderNumber,
      searchStatus,
      searchDeliverStatus,
      searchEmail,
      filterFirstName,
      filterLastName,
      searchProductType,
      searchStartDate,
      end_date
    )
      .then((res) => {
        response = res.data;
        setData(response.orders);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });

    let report = response.orders.map((item, idx) => {
      return [
        formatStatusString(item.status),
        item.order_number,
        formatDate(item.order_date),
        item.total_quantity,
        formatAmount(item.total_amount),
        formatPaymentMethod(item),
        item.display_name,
        formatDeliveryName(item.name),
        formatBookDeliveryStatus(item),
        item.delivery_cost
      ];
    });

    report = [
      [
        "สถานะคำสั่งซื้อ",
        "หมายเลขคำสั่งซื้อ",
        "วันที่สั่งซื้อ",
        "จำนวนสินค้า",
        "ยอดรวม(บาท)",
        "รูปแบบการชำระเงิน",
        "ชื่อผู้ใช้",
        "ชื่อ-สกุล(ที่ติดต่อได้)",
        "สถานะการจัดส่ง",
        "ค่าจัดส่ง",
      ],
      ...report,
    ];

    if (report) {
      await downloadExportData(report);
      await getData(
        0,
        searchOrderNumber,
        searchStatus,
        searchDeliverStatus,
        searchEmail,
        searchName,
        searchProductType
      );
    }
  }

  async function downloadExportData(report) {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(report);
    XLSX.utils.book_append_sheet(workBook, workSheet, ["report-order"]);
    XLSX.writeFile(workBook, `report-order.xlsx`);

    //this.exportData(0);
  }

  return (
    <div className="container">
      <h5 className="mt-2 font-weight-bold">
        รายการคำสั่งซื้อ <Spin spinning={loading} />
      </h5>
      <div className="line-highlight">
        <hr />
      </div>

      <div className="flex-fill">
        <div className="row mt-4 pt-3">
          <div className="col-sm-4">
            <div className="form-group">
              <label>หมายเลขคำสั่งซื้อ</label>
              <Input
                placeholder="ค้นหาด้วย หมายเลขคำสั่งซื้อ"
                value={searchOrderNumber}
                onChange={onChangeSearchOrderNumber}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>สถานะคำสั่งซื้อ</label>
              <Select
                defaultValue=""
                className="w-100"
                onSelect={(value, event) => onChangeSearchStatus(value, event)}
              >
                <Option value="">ทั้งหมด</Option>
                <Option value="1">รอการชำระเงิน</Option>
                <Option value="2">ชำระเงินแล้ว</Option>
              </Select>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>สถานะการจัดส่ง</label>
              <Select
                defaultValue=""
                className="w-100"
                onSelect={(value, event) =>
                  onChangeSearchDeliverStatus(value, event)
                }
              >
                <Option value="">ทั้งหมด</Option>
                <Option value="N/A">N/A</Option>
                <Option value="Y">จัดส่งแล้ว</Option>
                <Option value="N">รอการจัดส่ง</Option>
              </Select>
            </div>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-sm-4">
            <div className="form-group">
              <label>ชื่อ</label>
              <Input
                placeholder="ค้นหาด้วย ชื่อ"
                value={searchName}
                onChange={onChangeSearchName}
              />
            </div>
          </div>

          <div className="col-sm-4">
            <div className="form-group">
              <label>อีเมล</label>
              <Input
                placeholder="ค้นหาด้วย อีเมลล์"
                value={searchEmail}
                onChange={onChangeSearchEmail}
              />
            </div>
          </div>

          <div className="col-sm-4">
            <div className="form-group">
              <label>ประเภทสินค้า</label>
              <Select
                defaultValue=""
                className="w-100"
                onSelect={(value, event) =>
                  onChangeSearchProductType(value, event)
                }
              >
                <Option value="">ทั้งหมด</Option>
                <Option value="course">คอร์สออนไลน์</Option>
                <Option value="book">หนังสือ</Option>
              </Select>
            </div>
          </div>
        </div>

        {/* <div className="row mt-4 ml-1">
          <Button className=" btn-info mr-4 px-3" onClick={exportData}>
            Export
          </Button>
        </div> */}

        <div class="table_wrapper">
          <table className="table border mb-0 mt-2">
            <thead>
              <tr>
                <th className="text-center">สถานะคำสั่งซื้อ</th>
                <th className="text-center">หมายเลขคำสั่งซื้อ</th>
                <th className="text-center">วันที่สั่งซื้อ</th>
                <th className="text-center">จำนวนสินค้า</th>
                <th className="text-center">ยอดรวม(บาท)</th>
                <th className="text-center">รูปแบบการชำระเงิน</th>
                <th className="text-center">ชื่อผู้ใช้</th>
                <th className="text-center">ชื่อ-สกุล(ที่ติดต่อได้)</th>
                <th className="text-center">สถานะการจัดส่ง</th>
                <th className="text-center">ค่าจัดส่ง</th>
                <th className="text-center">รายละเอียด</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center font-weight-bold">
                        {formatStatus(item.status)}
                      </td>
                      <td className="text-center" width={80}>
                        {item.order_number}
                      </td>
                      <td className="text-center">
                        {formatDate(item.order_date)}
                      </td>
                      <td className="text-center">{item.total_quantity}</td>
                      <td className="text-center">
                        {formatAmount(item.total_amount)}
                      </td>
                      <td className="text-center" width={100}>
                        {formatPaymentMethod(item)}
                      </td>
                      <td className="text-center">{item.display_name}</td>
                      <td className="text-center">
                        {formatDeliveryName(item.name)}
                      </td>
                      <td className="text-center">
                        {formatBookDeliveryStatus(item)}
                      </td>
                      <td className="text-center">{item.delivery_cost}</td>
                      <td className="text-center">
                        <Tooltip title="รายละเอียด" className="mr-2">
                          <Button
                            type="primary"
                            shape="circle"
                            size="small"
                            className="btn-secondary"
                            icon={<SearchOutlined />}
                            onClick={() =>
                              history.push(
                                "/report/order_detail/" + item.order_number
                              )
                            }
                          />
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <div className="text-right mt-4">
          {data && data.length > 0 && (
            <ContentPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={changePage.bind(this)}
              pageShow={10}
            />
          )}
        </div>
      </div>
    </div>
  );
}
