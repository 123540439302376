import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Input,
  Button,
  Popconfirm,
  message,
  Switch,
  Modal,
  notification,
  Spin,
  Select,
  Table,
  Tooltip,
} from "antd";
import moment from "moment";
import NftService from "../services/NftService";
import VideoService from "../services/VideoService";
import Notification from "../utils/Notification";
import {
  EditOutlined,
  UserOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

const { confirm } = Modal;
const { Column } = Table;

const VideoSpecifyNFT = (props) => {
  const history = useHistory();
  const { Option } = Select;
  const initialVdoSpecifyNFT = {
    id: null,
    video_id: null,
    nft_number: "",
    nft_owner: "",
    wallet_id: "",
    group_id: "",
    status: "",
    created_by: null,
    updated_datetime: null,
    updated_by: null,
  };

  const { id } = useParams();
  const [vdoSpecifyNFT, setVdoSpecifyNFT] = useState(initialVdoSpecifyNFT);
  const [vdoGroupNFT, setVdoNFT] = useState(initialVdoSpecifyNFT);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataNFTGroup, setDataNftGroup] = useState([]);
  const [dataWallet, setDataWallet] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [ModalOpen, setModalOpen] = useState(false);
  const [ModalNFTGroupOpen, setModalNFTGroupOpen] = useState(false);

  const [nftGroupSelect, setNftGroupSelect] = useState("");
  const [searchGroup, setSearchGroup] = useState("");
  const [walletIDSpecify, setWalletIDSpecity] = useState("");
  const [nftNumberSpecify, setNftNumberSpecity] = useState("");
  const [nftOwnerSpecify, setNftOwnerSpecity] = useState("");
  const [dataVideoSpecifyNFT, setDataVideoSpecifyNFT] = useState([]);
  const [dataVideoGroupNFT, setDataVideoGroupNFT] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [vdoName, setVdoName] = useState("");
  const [searchWalletID, setSearchWalletID] = useState("");

  const [editName, setEditName] = useState("");
  const [editWalletID, setEditWalletID] = useState("");
  const [dataItem, setDataItem] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getNFTGroupSelect();
    getVideo(id);
    getDataVideoGroupNFT(id);
    getDataVideoSpecifyNFT(id);
    //getNFTWallet(searchWalletID);
  }, [id]);

  const getVideo = (id) => {
    if (id) {
      VideoService.get(id)
        .then((response) => {
          setVdoName(response.data.name);
        })
        .catch((e) => {
          console.log(e);
          Notification.Show(
            "error",
            "From Web Service",
            e.response.data.message
          );
        });
    }
  };

  const getNFTGroupSelect = async () => {
    await NftService.getNFTGroupSelect()
      .then((res) => {
        const response = res.data;
        setNftGroupSelect(response.nft_group);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getDataNFTGroup = (group_id) => {
    if (group_id) {
      NftService.getNftGroupMember(group_id)
        .then((response) => {
          setDataNftGroup(response.data.nft_group_member);
        })
        .catch((e) => {
          console.log(e);
          Notification.Show(
            "error",
            "From Web Service",
            e.response.data.message
          );
        });
    }
  };

  const getDataVideoSpecifyNFT = (video_id) => {
    if (video_id) {
      NftService.getVdoSpecifyNFT(video_id)
        .then((response) => {
          setDataVideoSpecifyNFT(response.data.video_specify_nft);
        })
        .catch((e) => {
          console.log(e);
          Notification.Show(
            "error",
            "From Web Service",
            e.response.data.message
          );
        });
    }
  };

  const getDataVideoGroupNFT = (video_id) => {
    if (video_id) {
      setExpandedRowKeys([]);

      NftService.getVdoGroupNFT(video_id)
        .then((response) => {
          for (let i = 0; i < response.data.video_group_nft.length; i++) {
            response.data.video_group_nft[i].key = i;
          }

          setDataVideoGroupNFT(response.data.video_group_nft);
        })
        .catch((e) => {
          console.log(e);
          Notification.Show(
            "error",
            "From Web Service",
            e.response.data.message
          );
        });
    }
  };

  const ShowNotification = (type, header, message) =>
    notification[type]({
      message: header,
      description: message,
    });

  async function AddVdoSpecify() {
    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;

    if (!nftNumberSpecify) {
      Notification.Show("error", "Validation", "กรุณาระบุ NFT Number");
      return;
    }

    if (!nftOwnerSpecify) {
      Notification.Show("error", "Validation", "กรุณาระบุ Name");
      return;
    }

    vdoSpecifyNFT.video_id = id;
    vdoSpecifyNFT.nft_number = nftNumberSpecify;
    vdoSpecifyNFT.nft_owner = nftOwnerSpecify;
    vdoSpecifyNFT.wallet_id = walletIDSpecify;
    vdoSpecifyNFT.group_id = 0;

    vdoSpecifyNFT.created_by = action_by;
    vdoSpecifyNFT.updated_by = action_by;
    vdoSpecifyNFT.updated_datetime = moment(new Date()).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    vdoSpecifyNFT.status = "A";

    setLoading(true);

    NftService.addVidioSpecifyNFT(vdoSpecifyNFT)
      .then((response) => {
        ShowNotification("success", "Success", "บันทึกข้อมูลเรียบร้อยแล้ว");
        setWalletIDSpecity("");
        setNftNumberSpecity("");
        setNftOwnerSpecity("");

        setSubmitted(true);
        setLoading(false);
        getDataVideoGroupNFT(id);
        getDataVideoSpecifyNFT(id);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        Notification.Show("error", "Validation", e.response.data.message);
      });
  }

  async function AddVdoGroupNFT(isGetDataNFTGroup) {
    if (isGetDataNFTGroup) {
      if (!searchGroup) {
        Notification.Show("error", "Validation", "กรุณาเลือกกลุ่ม NFT");
        return;
      }
    }

    let wallet = [];

    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;

    if (dataNFTGroup.length === 0) {
      ShowNotification(
        "error",
        "Error",
        "กรุณาเพิ่มสมาชิกในกลุ่มที่ Menu NFT Group ก่อน"
      );
      return;
    } else {
      for (let i = 0; i < dataNFTGroup.length; i++) {
        wallet.push({
          video_id: id,
          nft_number: dataNFTGroup[i].nft_number,
          nft_owner: dataNFTGroup[i].nft_owner,
          wallet_id: dataNFTGroup[i].wallet_id,
          group_id: searchGroup,
          created_by: action_by,
          updated_by: action_by,
          status: "A",
          updated_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        });
      }

      setLoading(true);

      await NftService.addVideoGroupNFT(wallet)
        .then((response) => {
          setSearchGroup("");
          ShowNotification("success", "Success", "บันทึกข้อมูลเรียบร้อยแล้ว");
          setSubmitted(true);
          setLoading(false);
          getDataVideoGroupNFT(id);
          getDataVideoSpecifyNFT(id);
        })
        .catch((e) => {
          setSearchGroup("");
          console.log(e);
          setLoading(false);
          Notification.Show("error", "Validation", e.response.data.message);
        });
    }
  }

  const deleteVideoNFTSpeicify = (params) => {
    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;

    vdoSpecifyNFT.updated_by = action_by;
    vdoSpecifyNFT.updated_datetime = moment(new Date()).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    NftService.deleteVideoSpecifyNFT(params.id, vdoSpecifyNFT)
      .then((response) => {
        console.log(response.data);
        getDataVideoSpecifyNFT(id);
        Notification.Show("success", "Success", "Delete Wallet Complete");
      })
      .catch((e) => {
        console.log(e);
        Notification.Show("error", "Error", e.response.data.message);
      });
  };

  const deleteVideoGroupNFTSpeicify = (params) => {
    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;

    vdoSpecifyNFT.video_id = id;
    vdoSpecifyNFT.updated_by = action_by;
    vdoSpecifyNFT.updated_datetime = moment(new Date()).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    NftService.deleteVideoGroupNFT(params.group_id, vdoSpecifyNFT)
      .then((response) => {
        console.log(response.data);
        getDataVideoGroupNFT(id);
        Notification.Show("success", "Success", "Delete Group Wallet Complete");
      })
      .catch((e) => {
        console.log(e);
        Notification.Show("error", "Error", e.response.data.message);
      });
  };

  const cancel = () => {
    history.push("/video");
  };

  const showDeleteConfirmSpecify = (params) => {
    confirm({
      title: "Confirm Delete?",
      icon: <ExclamationCircleOutlined />,
      content: "ต้องการลบ NFT Number นี้ใช่หรือไม่",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        deleteVideoNFTSpeicify(params);
      },

      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const showDeleteGroupConfirmSpecify = (params) => {
    confirm({
      title: "Confirm Delete?",
      icon: <ExclamationCircleOutlined />,
      content: "ต้องการลบ NFT Number กลุ่มนี้ใช่หรือไม่",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        deleteVideoGroupNFTSpeicify(params);
      },

      onCancel() {
        console.log("Cancel");
      },
    });
  };

  function cancelDialog(e) {}

  async function openModalGetWallet() {
    setModalOpen(true);
  }

  async function openModalViewGroup() {
    if (!searchGroup) {
      Notification.Show("error", "Validation", "กรุณาเลือกกลุ่ม NFT");
      return;
    }

    await getDataNFTGroup(searchGroup);
    setModalNFTGroupOpen(true);
  }

  const onChangeSearchGroup = (value) => {
    setSearchGroup(value);

    getDataNFTGroup(value);
  };

  const walletIDChange = (event) => {
    const { name, value } = event.target;
    setWalletIDSpecity(value);
  };

  const nftNumberChange = (event) => {
    const { name, value } = event.target;
    setNftNumberSpecity(value);
  };

  const nftOwnerChange = (event) => {
    const { name, value } = event.target;
    setNftOwnerSpecity(value);
  };

  const handleRowExpand = (expanded, record) => {
    const currentExpandedRows = expandedRowKeys;

    if (currentExpandedRows.includes(record.key)) {
      const index = currentExpandedRows.indexOf(record.key);
      currentExpandedRows.splice(index, 1);
    } else {
      currentExpandedRows.push(record.key);
    }

    setExpandedRowKeys(currentExpandedRows);

    if (expanded) {
      getDataNFTGroup(record.group_id);
    }
  };

  const expandedRowRender = (e) => {
    return (
      <>
        <div className="mt-1 d-flex align-items-center">
          <div className="flex-fill"></div>
          {/* <Spin spinning={loadingExport} /> */}
        </div>
        <Table dataSource={dataNFTGroup} paging={true}>
          <Column
            title="NFT Number"
            dataIndex="nft_number"
            key="nft_number"
            align="center"
          />
          <Column
            title="Name"
            dataIndex="nft_owner"
            key="nft_owner"
            align="center"
          />
          <Column
            title="Wallet ID"
            dataIndex="wallet_id"
            key="wallet_id"
            align="center"
          />
        </Table>
      </>
    );
  };

  const ManageEditNft = async (value) => {
    await setEditName(value.nft_owner);
    await setEditWalletID(value.wallet_id);
    await setDataItem(value);
    setModalOpen(true);
  };

  function SaveEdit() {
    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;

    vdoSpecifyNFT.id = dataItem.id;
    vdoSpecifyNFT.group_id = 0;
    vdoSpecifyNFT.video_id = id;
    vdoSpecifyNFT.nft_number = dataItem.nft_number;
    vdoSpecifyNFT.nft_owner = editName;
    vdoSpecifyNFT.wallet_id = editWalletID;
    vdoSpecifyNFT.status = dataItem.status;
    vdoSpecifyNFT.created_by = dataItem.created_by;
    vdoSpecifyNFT.updated_by = action_by;
    vdoSpecifyNFT.updated_datetime = moment(new Date()).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    NftService.updateVideoSpecify(vdoSpecifyNFT)
      .then((response) => {
        ShowNotification("success", "Success", "บันทึกข้อมูลเรียบร้อยแล้ว");
        setVdoSpecifyNFT(initialVdoSpecifyNFT);
        setModalOpen(false);
        getDataVideoSpecifyNFT(id);
      })
      .catch((e) => {
        console.log(e);
        setVdoSpecifyNFT(initialVdoSpecifyNFT);
        getDataVideoSpecifyNFT(id);
        Notification.Show("error", "Validation", e.response.data.message);
      });
  }

  return (
    <div className="container">
      <div className="d-flex mt-4 align-items-stretch">
        <div className="flex-fill">
          <div className="header-bar">
            <div className="row">
              <div className="col-12 text-primary">
                <span style={{ fontSize: 16 }}>Manage NFT Video</span> &nbsp;{" "}
                <span className="text-dark">({vdoName})</span>
              </div>
            </div>
          </div>

          <table className="table table-bordered mt-3">
            <thead>
              <tr>
                <th className="text-primary">เพิ่ม NFT Number</th>
              </tr>
            </thead>
            <tbody>
              <div className="container-fluid mt-3 mb-4 ml-3">
                <div className="row">
                  <div className="col-6">
                    <label>เพิ่มกลุ่ม NFT ที่สามารถดูวีดีโอนี้</label>
                    <span className="text-danger">*</span>
                    <Select
                      //defaultValue={searchGroup}
                      value={searchGroup}
                      className="w-100"
                      allowClear
                      onChange={onChangeSearchGroup}
                      placeholder="เลือก Group"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="">เลือก Group</Option>
                      {nftGroupSelect &&
                        nftGroupSelect.map((item, index) => {
                          return (
                            <Option value={item.group_id}>
                              {item.group_name}
                            </Option>
                          );
                        })}
                    </Select>
                  </div>
                  <div className="col-4 form-group mt-4">
                    <Button
                      className="btn-primary ml-3 mt-2"
                      onClick={() => {
                        AddVdoGroupNFT(true);
                      }}
                      style={{ height: 35 }}
                    >
                      เพิ่ม NFT Number ทั้งกลุ่ม
                    </Button>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <div>
                      <label>
                        NFT Number
                        <span className="text-danger">*</span>
                      </label>
                      <Input
                        id="nftNumberSpecify"
                        required
                        value={nftNumberSpecify}
                        onChange={nftNumberChange}
                        name="nftNumberSpecify"
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div>
                      <label>
                        Name
                        <span className="text-danger">*</span>
                      </label>
                      <Input
                        id="nftOwnerSpecify"
                        required
                        value={nftOwnerSpecify}
                        onChange={nftOwnerChange}
                        name="nftOwnerSpecify"
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div>
                      <label>Wallet ID</label>
                      <Input
                        id="walletIDSpecify"
                        required
                        value={walletIDSpecify}
                        onChange={walletIDChange}
                        name="walletIDSpecify"
                      />
                    </div>
                  </div>
                  <div className="col-3 form-group mt-4 mb-2">
                    <Button
                      className="btn-primary ml-3 mt-2"
                      onClick={AddVdoSpecify}
                      style={{ height: 35 }}
                    >
                      เพิ่ม NFT Number
                    </Button>
                    <Spin style={{ marginLeft: 10 }} spinning={loading} />
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-12 text-right">
                    <Button className="btn-secondary mr-2" onClick={cancel}>
                      ย้อนกลับ
                    </Button>
                  </div>
                </div>
              </div>
            </tbody>
          </table>

          <table className="table table-bordered mt-4">
            <thead>
              <tr>
                <th>กลุ่ม NFT ที่สามารถดูวีดีโอนี้</th>
              </tr>
            </thead>
            <tbody>
              <div class="table_wrapper mt-4 ml-5 mr-5">
                <Table
                  dataSource={dataVideoGroupNFT}
                  paging={true}
                  expandable={{
                    expandedRowRender,
                    expandedRowKeys: expandedRowKeys,
                    onExpand: handleRowExpand,
                  }}
                >
                  <Column
                    title="ชื่อ กลุ่ม"
                    dataIndex="group_name"
                    key="group_name"
                    align="center"
                  />
                  <Column
                    title="ลบกลุ่มนี้ไม่ให้ดูวีดีโอนี้"
                    render={(value) => {
                      return (
                        <Tooltip
                          title="ลบกลุ่มนี้ไม่ให้ดูวีดีโอนี้"
                          className="mr-2"
                        >
                          <Button
                            className="btn-danger btn-xs"
                            onClick={() => showDeleteGroupConfirmSpecify(value)}
                          >
                            Delete
                          </Button>
                        </Tooltip>
                      );
                    }}
                    align="center"
                  />
                </Table>
              </div>
            </tbody>
          </table>

          <table className="table table-bordered mt-4">
            <thead>
              <tr>
                <th>Specify NFT</th>
              </tr>
            </thead>
            <tbody>
              <div class="table_wrapper mt-4 ml-4 mr-4">
                <Table dataSource={dataVideoSpecifyNFT} paging={true}>
                  <Column
                    title="NFT Number"
                    dataIndex="nft_number"
                    key="nft_number"
                    align="center"
                  />
                  <Column
                    title="Name"
                    dataIndex="nft_owner"
                    key="nft_owner"
                    align="center"
                  />
                  <Column
                    title="Wallet ID"
                    dataIndex="wallet_id"
                    key="wallet_id"
                    align="center"
                  />
                  <Column
                    title="Edit"
                    render={(value) => {
                      return (
                        <Tooltip title="Edit" className="mr-2">
                          <Button
                            className="btn-warning btn-xs"
                            onClick={() => ManageEditNft(value)}
                          >
                            Edit
                          </Button>
                        </Tooltip>
                      );
                    }}
                    align="center"
                  />
                  <Column
                    title="Delete"
                    render={(value) => {
                      return (
                        <Tooltip title="Delete" className="mr-2">
                          <Button
                            className="btn-danger btn-xs"
                            onClick={() => showDeleteConfirmSpecify(value)}
                          >
                            Delete
                          </Button>
                        </Tooltip>
                      );
                    }}
                    align="center"
                  />
                </Table>

                <Modal
                  title="Edit"
                  footer={null}
                  maskClosable={false}
                  visible={ModalOpen}
                  onCancel={() => setModalOpen(false)}
                >
                  <div className="row">
                    <div className="col-12">
                      <label>NFT Number</label>
                      <Input value={dataItem.nft_number} disabled />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-12">
                      <label>Name</label>
                      <Input
                        value={editName}
                        onChange={(e) => setEditName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-12">
                      <label>Wallet ID</label>
                      <Input
                        value={editWalletID}
                        onChange={(e) => setEditWalletID(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-6"></div>

                    <div className="col-6">
                      <div className="mt-2 text-right">
                        <button
                          type="button"
                          className="btn btn-secondary mr-2"
                          onClick={() => {
                            setModalOpen(false);
                          }}
                        >
                          ยกเลิก
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            SaveEdit();
                          }}
                          className="btn btn-primary mr-2"
                        >
                          ยืนยัน
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default VideoSpecifyNFT;
