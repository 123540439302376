import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Input,
  Button,
  Popconfirm,
  Switch,
  Modal,
  notification,
  DatePicker,
  Select,
  Upload,
  Spin,
  Checkbox,
} from "antd";
import CourseService from "../services/CourseService";
import UtilityService from "../services/UtilityService";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "2read-ckeditor5-custom-build";
import imageCompression from "browser-image-compression";
import MyUploadAdapter from "../utils/MyUploadAdapter";
import { PictureOutlined } from "@ant-design/icons";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Notification from "../utils/Notification";
import makeAnimated from "react-select/animated";
import ImgCrop from "antd-img-crop";
import NumberFormat from "react-number-format";
import { Fastfood, StayCurrentLandscapeOutlined } from "@material-ui/icons";

const { Option } = Select;
const animatedComponents = makeAnimated();

const ManageCourse = (props) => {
  const history = useHistory();
  const initialCourseState = {
    course_id: null,
    course_name: "",
    duration: "",
    start_datetime: null,
    end_datetime: null,
    last_register_datetime: null,
    seminar_day: "",
    study_time: "",
    study_location: "",
    price: null,
    promotion_price: null,
    quota: null,
    cover_image: "",
    category_id: "",
    level_id: "",
    vdo_link: "",
    course_detail: "",
    expected_result: "",
    how_to_study: "",
    close_sale: "",
    status: "",
    nft_only: false,
    free_nft: false,
    is_nft_discount:false,
    is_common: false,
    is_rare: false,
    is_super_rare: false,
    lecturers: [],
    images: [],
    max_reserve: null,
    external_url: null,
    created_datetime: null,
    created_by: null,
    updated_datetime: null,
    updated_by: null,
    temp_img_url: "",
  };

  const { course_id } = useParams();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [lastRegisterDate, setLastRegisterDate] = useState(null);
  const [course, setCourse] = useState(initialCourseState);
  const [submitted, setSubmitted] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [cropperRef, setCropperRef] = useState(React.createRef());
  const [coverImage, setCoverImage] = useState();
  const [status, setStatus] = useState(true);
  const [action, setAction] = useState();
  const [category_id, setCategoryID] = useState("");
  const [level_id, setLevelID] = useState("");
  const [lecturer_id, setLecturerID] = useState("");
  const [lecturers, setLecturers] = useState("");
  const [dateFormat, setDateFormat] = useState("DD-MM-YYYY");
  const [lecturerSelect, setLecturerSelect] = useState([]);
  const [categorySelect, setCategorySelect] = useState([]);
  const [levelSelect, setLevelSelect] = useState([]);
  const [vdoLink, setVdoLink] = useState("");
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [nft_only, setNftOnly] = useState(false);
  const [free_nft, setFreeNFT] = useState(false);
  const [isCommon, setIsCommon] = useState(false);
  const [isNftDiscount, setIsNftDiscount] = useState(false);
  const [isRare, setIsRare] = useState(false);
  const [isSuperRare, setIsSuperRare] = useState(false);
  const plainOptions = ["Apple", "Pear", "Orange"];

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!localStorage.getItem("token")) history.push("/login");

    var result = localStorage.getItem("user_profile");
    if (result) {
      if (JSON.parse(result).course === false) {
        history.push("/login");
      }
    }

    setAction(props.action);
    getCourseLevel();
    getAllLecturer();
    getCourseCategory();
    if (props.action === "update") getCourse(course_id);
  }, [course_id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCourse({ ...course, [name]: value });
  };

  const getAllLecturer = async () => {
    await UtilityService.getAllLecturerSelect()
      .then((res) => {
        const response = res.data;
        console.log(res.data);
        setLecturerSelect(response.lecturers);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getCourseCategory = async () => {
    await CourseService.getCourseCategory()
      .then((res) => {
        const response = res.data;
        if (response.course_category) {
          const categorys = response.course_category.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          });
          setCategorySelect(categorys);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getCourseLevel = async () => {
    await CourseService.getCourseLevel()
      .then((res) => {
        const response = res.data;
        if (response.course_level) {
          const levels = response.course_level.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          });
          setLevelSelect(levels);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getCourse = async (course_id) => {
    if (course_id) {
      CourseService.get(course_id)
        .then((response) => {
          setStartDate(response.data.start_datetime);
          setEndDate(response.data.end_datetime);
          setLastRegisterDate(response.data.last_register_datetime);

          if (response.data.start_datetime) {
            response.data.start_datetime = moment(response.data.start_datetime)
              .utc()
              .format("DD-MM-YYYY");
          }

          if (response.data.end_datetime) {
            response.data.end_datetime = moment(response.data.end_datetime)
              .utc()
              .format("DD-MM-YYYY");
          }

          if (response.data.last_register_datetime) {
            response.data.last_register_datetime = moment(
              response.data.last_register_datetime
            )
              .utc()
              .format("DD-MM-YYYY");
          }

          setVdoLink(response.data.vdo_link);

          setCourse(response.data);

          setLevelID(response.data.level_id);
          setCategoryID(response.data.category_id);

          setNftOnly(response.data.nft_only);
          setFreeNFT(response.data.free_nft);
          
          if(response.data.is_nft_discount === true) 
          {
            setIsNftDiscount(false);
          }
          else
          {
            setIsNftDiscount(true);
          }

          setIsCommon(response.data.is_common);
          setIsRare(response.data.is_rare);
          setIsSuperRare(response.data.is_super_rare);

          const lecturer_id = [];
          for (let i = 0; i < response.data.lecturers.length; i++) {
            if (response.data.lecturers[i].status === "A") {
              lecturer_id.push(response.data.lecturers[i].lecturer_id);
            }
          }
          setLecturers(lecturer_id);

          let list_image = response.data.images.map((item) => {
            return {
              url: item.image_url,
              status: item.status,
            };
          });

          setFileList(list_image);

          setCoverImage(response.data.cover_image);
          if (response.data.status === "A") setStatus(true);
          else setStatus(false);
        })
        .catch((e) => {
          console.log(e);
          Notification.Show(
            "error",
            "From Web Service",
            e.response.data.message
          );
        });
    }
  };

  const ShowNotification = (type, header, message) =>
    notification[type]({
      message: header,
      description: message,
    });

  function ValidateManageCourse() {
    if (!course.course_name) {
      Notification.Show("error", "Validation", "กรุณาระบุ ชื่อคอร์ส");
      return false;
    }

    if (!category_id) {
      Notification.Show("error", "Validation", "กรุณาระบุ หมวดหมู่");
      return false;
    }

    if (category_id != 2) {
      //ไม่ใช่เรียนออนไลน์
      if (!startDate) {
        Notification.Show("error", "Validation", "กรุณาระบุ วันเริ่มต้นคอร์ส");
        return false;
      }

      if (!endDate) {
        Notification.Show("error", "Validation", "กรุณาระบุ วันสิ้นสุดคอร์ส");
        return false;
      }
    }

    if (!course.duration) {
      Notification.Show("error", "Validation", "กรุณาระบุ ระยะเวลา");
      return false;
    }

    if (!course.seminar_day) {
      Notification.Show("error", "Validation", "กรุณาระบุ วันสัมนา");
      return false;
    }

    if (!course.study_time) {
      Notification.Show("error", "Validation", "กรุณาระบุ เวลาเรียน");
      return false;
    }

    if (!course.study_location) {
      Notification.Show("error", "Validation", "กรุณาระบุ สถานที่เรียน");
      return false;
    }

    if (nft_only === true) {
      if (free_nft == true) {
        if (Number(course.price) > 0 || Number(course.promotion_price) > 0) {
          Notification.Show(
            "error",
            "Validation",
            "คอร์สนี้เป็นคอร์สฟรีสำหรับผู้ถือ NFT เท่านั้น กรุณาระบุ ราคาคอร์ส เป็น 0"
          );
          return false;
        }
      } else {
        if (Number(course.price) > 0 || Number(course.promotion_price) > 0) {
          Notification.Show(
            "error",
            "Validation",
            "คอร์สนี้เป็นคอร์สสำหรับผู้ถือ NFT เท่านั้น กรุณาระบุ ราคาคอร์ส เป็น 0"
          );
          return false;
        }
      }
    } else {
      if (Number(course.price) === 0) {
        Notification.Show(
          "error",
          "Validation",
          "กรุณาระบุ ราคาคอร์ส มากกว่า 0"
        );
        return false;
      }
    }
    // if (free_nft) {
    //   if (nft_only === false) {
    //     Notification.Show(
    //       "error",
    //       "Validation",
    //       "กรุณาระบุว่าเป็น คอร์สฟรี(สำหรับผู้ถือ NFT) เท่านั้น"
    //     );
    //     return false;
    //   }
    //   if (course.price > 0) {
    //     Notification.Show("error", "Validation", "ราคาเต็ม ต้องเป็น 0");
    //     return false;
    //   }
    // }

    if (!course.price) {
      Notification.Show("error", "Validation", "กรุณาระบุ ราคาคอร์ส");
      return false;
    }

    if (!level_id) {
      Notification.Show("error", "Validation", "กรุณาระบุ ระดับผู้เรียน");
      return false;
    }

    if (!course.max_reserve) {
      Notification.Show(
        "error",
        "Validation",
        "กรุณาระบุ จำนวนที่นั่งสูงสุดต่อการจอง"
      );
      return false;
    }

    if (course.max_reserve <= 0) {
      Notification.Show(
        "error",
        "Validation",
        "จำนวนที่นั่งสูงสุดต่อการจอง ต้องมากกว่า 0"
      );
      return false;
    }

    if (nft_only || free_nft) {
      if (
        (isCommon === false && isRare === false && isSuperRare === false) ||
        (isCommon === null && isRare === null && isSuperRare === null)
      ) {
        Notification.Show(
          "error",
          "Validation",
          "กรุณาระบุ สิทธิ์ของการจองคอร์ส(NFT) อย่างน้อย 1 class"
        );
        return false;
      }
    }

    if (course.vdo_link) {
      if (validateYouTubeUrl(course.vdo_link) === false) {
        Notification.Show(
          "error",
          "Validation",
          "VDO Link (youtube) ไม่ถูกต้อง"
        );
        return false;
      }
    }

    if (course.external_url) {
      if (isValidHttpUrl(course.external_url) === false) {
        Notification.Show("error", "Validation", "External URL ไม่ถูกต้อง");
        return false;
      }
    }

    return true;
  }

  function validateYouTubeUrl(urlToParse) {
    if (urlToParse) {
      var regExp =
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      if (urlToParse.match(regExp)) {
        return true;
      }
    }
    return false;
  }

  function isValidHttpUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  const onChangeCommon = (e) => {
    setIsCommon(e.target.checked);
  };

  const onChangeRare = (e) => {
    setIsRare(e.target.checked);
  };

  const onChangeSuperRare = (e) => {
    setIsSuperRare(e.target.checked);
  };

  async function AddUpdateCourse() {
    if (ValidateManageCourse() === false) return;

    let action_by = "";
    var result = localStorage.getItem("user_profile");
    if (result) action_by = JSON.parse(result).name;

    //cover
    if (action === "add") {
      if (imageFile) {
        //cover image
        await uploadImage(imageFile, "cover");
      } else course.cover_image = "";
      course.created_by = action_by;
      course.created_datetime = moment(new Date()).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    } else {
      if (imageFile) {
        await uploadImage(imageFile, "cover");
      } else course.cover_image = coverImage;
    }

    if (!course.cover_image) {
      Notification.Show("error", "Validation", "กรุณาระบุ รูปปกคอร์ส");
      return false;
    }

    if (lecturers === null || lecturers.length === 0) {
      Notification.Show("error", "Validation", "กรุณาระบุ วิทยากร");
      return false;
    }

    //Manage Image File List
    if (fileList) {
      for (let i = 0; i < fileList.length; i++) {
        if (!fileList[i].url) {
          await uploadImage(fileList[i].originFileObj, "list_image");

          fileList[i] = {
            url: course.temp_img_url,
            status: "A",
          };

          setFileList(fileList);
        }
      }

      course.images = fileList.map((item) => {
        return {
          image_url: item.url,
          status: item.status,
          created_by: action_by,
          created_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          updated_by: action_by,
          updated_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        };
      });
    }

    if (lecturers) {
      course.lecturers = lecturers.map((item) => {
        return {
          lecturer_id: item,
          status: "A",
          created_by: action_by,
          created_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          updated_by: action_by,
          updated_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        };
      });
    }

    course.category_id = category_id;
    course.level_id = level_id;

    if (status) course.status = "A";
    else course.status = "I";

    if (course.category_id === 2) {
      //online course
      course.start_datetime = null;

      course.end_datetime = moment(new Date()).format("9999-12-31 00:00:00");
    } else {
      if (startDate) {
        course.start_datetime = new Date(startDate);

        const beginDate = course.start_datetime.getDate();
        const beginMonth = course.start_datetime.getMonth();
        const beginYear = course.start_datetime.getFullYear();
        course.start_datetime.setDate(beginDate);
        course.start_datetime.setMonth(beginMonth);
        course.start_datetime.setFullYear(beginYear);
        course.start_datetime = moment(course.start_datetime).format(
          "YYYY-MM-DD 00:00:00"
        );
      } else {
        course.start_datetime = moment(new Date()).format(
          "YYYY-MM-DD HH:mm:ss"
        );
      }

      if (endDate) {
        course.end_datetime = new Date(endDate);
        const beginDate = course.end_datetime.getDate();
        const beginMonth = course.end_datetime.getMonth();
        const beginYear = course.end_datetime.getFullYear();
        course.end_datetime.setDate(beginDate);
        course.end_datetime.setMonth(beginMonth);
        course.end_datetime.setFullYear(beginYear);
        course.end_datetime = moment(course.end_datetime)
          .utc()
          .format("YYYY-MM-DD 23:59:59");
      } else {
        course.end_datetime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      }
    }

    if (lastRegisterDate) {
      course.last_register_datetime = new Date(lastRegisterDate);
      const beginDate = course.last_register_datetime.getDate();
      const beginMonth = course.last_register_datetime.getMonth();
      const beginYear = course.last_register_datetime.getFullYear();
      course.last_register_datetime.setDate(beginDate);
      course.last_register_datetime.setMonth(beginMonth);
      course.last_register_datetime.setFullYear(beginYear);

      course.last_register_datetime = moment(course.last_register_datetime)
        .utc()
        .format("YYYY-MM-DD 23:59:59");
    } else {
      course.last_register_datetime = null;
    }

    course.price = course.price
      ? Number(course.price.toString().replace(/,/g, ""))
      : 0;

    if (course.promotion_price) {
      if (Number(course.promotion_price) === 0)
        course.promotion_price = course.price;
      else {
        course.promotion_price = course.promotion_price
          ? Number(course.promotion_price.toString().replace(/,/g, ""))
          : null;
      }
    } else course.promotion_price = course.price;

    course.quota = course.quota
      ? Number(course.quota.toString().replace(/,/g, ""))
      : null;

    nft_only ? (course.nft_only = true) : (course.nft_only = false);
    free_nft ? (course.free_nft = true) : (course.free_nft = false);
    console.log(isNftDiscount)
    isNftDiscount? (course.is_nft_discount = false) : (course.is_nft_discount = true);

    // if (nft_only) {
    //   isCommon ? (course.is_common = true) : (course.is_common = false);
    //   isRare ? (course.is_rare = true) : (course.is_rare = false);
    //   isSuperRare
    //     ? (course.is_super_rare = true)
    //     : (course.is_super_rare = false);
    // } else {
    //   course.is_common = null;
    //   course.is_rare = null;
    //   course.is_super_rare = null;
    // }

    isCommon ? (course.is_common = true) : (course.is_common = false);
    isRare ? (course.is_rare = true) : (course.is_rare = false);
    isSuperRare
      ? (course.is_super_rare = true)
      : (course.is_super_rare = false);

    course.updated_by = action_by;
    course.updated_datetime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

    console.log(course);
    setLoading(true);

    if (action === "add") {
      CourseService.create(course)
        .then((response) => {
          ShowNotification(
            "success",
            "Success",
            "บันทึกข้อมูลคอร์สเรียบร้อยแล้ว"
          );
          setSubmitted(true);
          setLoading(false);
          console.log(response.data);
          history.push("/course");
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
          Notification.Show("error", "Validation", e.response.data.message);
        });
    } else {
      CourseService.update(course.course_id, course)
        .then((response) => {
          console.log(response.data);
          ShowNotification(
            "success",
            "Success",
            "บันทึกข้อมูลคอร์สเรียบร้อยแล้ว"
          );
          setLoading(false);
          history.push("/course");
        })
        .catch((e) => {
          console.error(e.response.data.message);
          setLoading(false);
          Notification.Show("error", "Error", e.response.data.message);
        });
    }
  }

  const deleteCourse = () => {
    setLoadingDelete(true);
    CourseService.remove(course.course_id)
      .then((response) => {
        setLoadingDelete(false);
        console.log(response.data);
        Notification.Show("success", "Success", "ลบข้อมูลคอร์สเรียบร้อยแล้ว");
        history.push("/course");
      })
      .catch((e) => {
        setLoadingDelete(false);
        console.log(e);
        Notification.Show("error", "Error", e.response.data.message);
      });
  };

  const cancel = () => {
    history.push("/course");
  };

  function confirmDialog(e) {
    deleteCourse();
  }

  function cancelDialog(e) {}

  function customUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      // Configure the URL to the upload script in your back-end here!
      return new MyUploadAdapter(loader);
    };
  }

  function onSelectFile(e, type) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImageUrl(reader.result));
      //reader.addEventListener("load", () => setCoverImage(reader.result));
      reader.readAsDataURL(e.target.files[0]);

      setCropModalOpen(true);
      setImageFile(e.target.files[0]);
    }
  }

  function cropImage() {
    const cropper = cropperRef.current.cropper;

    cropper.getCroppedCanvas().toBlob((blob) => {
      const fileName = imageFile.name;

      const file = new File([blob], fileName, {
        lastModifiedDate: new Date(),
        type: imageFile.type,
      });
      const cropppedPreview = cropper.getCroppedCanvas().toDataURL();
      setCoverImage(cropppedPreview);
      setImageFile(file);
      closeCropModal();
    });
  }

  function closeCropModal() {
    setCropModalOpen(false);
  }

  function removeImage() {
    setCoverImage("");
    setImageUrl("");
  }

  async function uploadImage(image, type) {
    const options = {
      maxSizeMB: 2,
      useWebWorker: true,
      initialQuality: 1,
    };
    try {
      const compressedFile = await imageCompression(image, options);
      const file = new File([compressedFile], image.name, { type: image.type });

      if (image) {
        let formData = new FormData();
        let imagefile = file;

        formData.append("upload", imagefile);

        await UtilityService.uploadImage(formData)
          .then((response) => {
            if (type === "cover") {
              course.cover_image = response.data.url;
            } else {
              course.temp_img_url = response.data.url;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } catch (error) {
      console.log(error);
    }
  }

  function onChangeStatus(checked) {
    setStatus(checked);
  }

  const onChangeLevelID = (level_id, e) => {
    setLevelID(level_id);
  };

  const onChangeCategory = (category_id, e) => {
    setCategoryID(category_id);
  };

  function onChangeLecturer(value) {
    setLecturers(value);
  }

  function onChangeNftOnly(checked) {
    setNftOnly(checked);
  }

  function onChangeIsNFTDiscount(checked) {
    if(checked)
      setIsNftDiscount(true);
    else
      setIsNftDiscount(false)
  }

  function onChangeFreeNFT(checked) {
    setFreeNFT(checked);
  }

  function handleDatePickerChange(date, dateString, type) {
    if (type === "start_datetime") {
      setStartDate(date);
    } else if (type === "end_datetime") {
      if(date)
      {
        date = moment(date).utc(-7).format("YYYY-MM-DD 23:59:59");
        setEndDate(date);
      }
      else
        setLastRegisterDate(null);
    } else if (type === "last_register_datetime") {
      if (date) {
        date = moment(date).utc(-7).format("YYYY-MM-DD 23:59:59");
        setLastRegisterDate(date);
      }
      else
        setLastRegisterDate(null);
    }
  }

  const onChangeImgList = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  function formatAmount(amount) {
    if (amount) {
      return new Intl.NumberFormat("en-GB", {
        style: "decimal",
      }).format(amount);
    }
  }

  function getYoutubeUrlEmbeded(url) {
    var regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length === 11) {
      return "//www.youtube.com/embed/" + match[2];
    }
  }

  return (
    <div className="container">
      <div className="d-flex mt-4 align-items-stretch">
        <div className="flex-fill">
          <div className="header-bar">ข้อมูลคอร์สออนไลน์</div>

          <div className="row">
            <div className="col-sm-4"></div>
            <div className="col-sm-4">
              <div className="position-relative my-2 mt-4">
                {coverImage ? (
                  <img src={coverImage} className="img-fluid" />
                ) : (
                  <div
                    className="image-placeholder d-flex flex-column align-items-center justify-content-center mt-5"
                    style={{ height: 240 }}
                  >
                    <div>
                      <PictureOutlined
                        style={{ fontSize: 30, color: "#bbb" }}
                      />
                    </div>
                    <div className="font-12">อัพโหลดรูป</div>
                  </div>
                )}

                <input
                  type="file"
                  accept="image/*"
                  className="input-file-hidden"
                  onChange={(event) => {
                    onSelectFile(event, "writers");
                  }}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                />
              </div>
              {coverImage && (
                <div className="text-right mt-2">
                  <Button
                    className="btn btn-sm btn-danger"
                    onClick={() => removeImage()}
                  >
                    ลบ
                  </Button>
                </div>
              )}
              <div className="text-right text-danger">
                (ขนาดรูปที่แนะนำ 726 x 409 px)
              </div>
            </div>
            <div className="col-sm-4">
              <div className="text-right mt-3">
                <Switch
                  onChange={onChangeStatus}
                  checkedChildren="Active"
                  unCheckedChildren="InActive"
                  checked={status}
                />
              </div>
            </div>
          </div>

          <div className="header-bar mt-4 ml-2 mr-2">
            <Switch onChange={onChangeIsNFTDiscount} checked={isNftDiscount} />
            <label className="ml-3 mt-1">
              คอร์สนี้ไม่เข้าร่วมส่วนลด 10% สำหรับ BullmoonClub NFT
            </label>
          </div>

          <div className="header-bar mt-4 ml-2 mr-2">
            <Switch onChange={onChangeNftOnly} checked={nft_only} />
            <label className="ml-3 mt-1">
              กำหนดเป็นคอร์ส สำหรับผู้ถือ NFT เท่านั้น
            </label>
          </div>

          <div className="header-bar mt-4 ml-2 mr-2">
            <Switch onChange={onChangeFreeNFT} checked={free_nft} />
            <label className="ml-3 mt-1">
              กำหนดเป็นคอร์สฟรี (สำหรับผู้ถือ NFT)
            </label>
          </div>

          <>
            <div className="form-group ml-4 mb-2 mt-3">
              <label>
                สิทธิ์ของการจองคอร์ส (NFT){" "}
                <span className="text-danger">
                  *กรุณาระบุถ้าคอร์สนี้ ผู้ถือ NFT สามารถจองได้
                </span>
              </label>
            </div>
            <div className="form-group ml-4 mb-3 mt-2">
              <Checkbox onChange={onChangeCommon} checked={isCommon}>
                Common
              </Checkbox>
              <Checkbox onChange={onChangeRare} checked={isRare}>
                Rare
              </Checkbox>
              <Checkbox onChange={onChangeSuperRare} checked={isSuperRare}>
                Super Rare
              </Checkbox>
            </div>
          </>

          <div className="container-fluid mt-4">
            <div className="form-group mb-3">
              <label>
                ชื่อคอร์ส
                <span className="text-danger">*</span>
              </label>
              <Input
                id="course_name"
                required
                value={course.course_name}
                onChange={handleInputChange}
                name="course_name"
              />
            </div>
          </div>

          <div className="container-fluid mt-4">
            <div className="form-group mb-3">
              <label>
                หมวดหมู่ <span className="text-danger">*</span>
              </label>
              <Select
                defaultValue=""
                value={category_id}
                className="w-100"
                onSelect={(value, event) => onChangeCategory(value, event)}
              >
                {categorySelect &&
                  categorySelect.map((item, index) => {
                    return <Option value={item.value}>{item.label}</Option>;
                  })}
              </Select>
            </div>
          </div>

          {category_id != 2 && (
            <div className="container-fluid mt-4">
              <div className="row">
                <div className="col-sm-6">
                  <label>
                    วันเริ่มต้นคอร์ส (เลือกวันที่)
                    <span className="text-danger">*</span>
                  </label>

                  {action === "update" && course.start_datetime && (
                    <DatePicker
                      picker="date"
                      defaultValue={moment(course.start_datetime, dateFormat)}
                      format={dateFormat}
                      onChange={(date, dateString) =>
                        handleDatePickerChange(
                          date,
                          dateString,
                          "start_datetime"
                        )
                      }
                      className="w-100"
                    />
                  )}

                  {(action === "add" || course.start_datetime == null) && (
                    <DatePicker
                      picker="date"
                      format={dateFormat}
                      onChange={(date, dateString) =>
                        handleDatePickerChange(
                          date,
                          dateString,
                          "start_datetime"
                        )
                      }
                      className="w-100"
                    />
                  )}
                </div>
                <div className="col-sm-6">
                  <label>
                    วันสิ้นสุดคอร์ส (เลือกวันที่)
                    <span className="text-danger">*</span>
                  </label>
                  {action === "update" && course.end_datetime && (
                    <DatePicker
                      picker="date"
                      defaultValue={moment(course.end_datetime, dateFormat)}
                      format={dateFormat}
                      onChange={(date, dateString) =>
                        handleDatePickerChange(date, dateString, "end_datetime")
                      }
                      className="w-100"
                      disabledDate={(current) => {
                        return moment().add(-1, "days") >= current;
                      }}
                    />
                  )}

                  {action === "add" && (
                    <DatePicker
                      picker="date"
                      format={dateFormat}
                      onChange={(date, dateString) =>
                        handleDatePickerChange(date, dateString, "end_datetime")
                      }
                      className="w-100"
                      disabledDate={(current) => {
                        return moment().add(-1, "days") >= current;
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="container-fluid mt-4">
            <div className="row">
              <div className="col-sm-6">
                <label>สมัครได้ถึงวันที่ (เลือกวันที่)</label>

                {action === "update" &&
                  course.last_register_datetime != null && (
                    <DatePicker
                      picker="date"
                      defaultValue={moment(
                        course.last_register_datetime,
                        dateFormat
                      )}
                      format={dateFormat}
                      className="w-100"
                      onChange={(date, dateString) =>
                        handleDatePickerChange(
                          date,
                          dateString,
                          "last_register_datetime"
                        )
                      }
                    />
                  )}

                {(action === "add" ||
                  course.last_register_datetime == null) && (
                  <DatePicker
                    picker="date"
                    format={dateFormat}
                    onChange={(date, dateString) =>
                      handleDatePickerChange(
                        date,
                        dateString,
                        "last_register_datetime"
                      )
                    }
                    className="w-100"
                    disabledDate={(current) => {
                      return moment().add(-1, "days") >= current;
                    }}
                  />
                )}
              </div>
              <div className="col-sm-6">
                <label>
                  ระยะเวลา (ข้อความ)
                  <span className="text-danger">*</span>
                </label>
                <Input
                  id="duration"
                  required
                  value={course.duration}
                  onChange={handleInputChange}
                  name="duration"
                />
              </div>
            </div>
          </div>

          <div className="container-fluid mt-4">
            <div className="form-group mb-3 mt-3">
              <label>
                วันสัมนา (ข้อความ)
                <span className="text-danger">*</span>
              </label>
              <Input
                id="seminar_day"
                required
                value={course.seminar_day}
                onChange={handleInputChange}
                name="seminar_day"
              />
            </div>

            <div className="form-group mb-3 mt-3">
              <label>
                เวลาเรียน (ข้อความ)
                <span className="text-danger">*</span>
              </label>
              <Input
                id="study_time"
                required
                value={course.study_time}
                onChange={handleInputChange}
                name="study_time"
              />
            </div>

            <div className="form-group mb-3 mt-3">
              <label>
                สถานที่เรียน (ข้อความ)
                <span className="text-danger">*</span>
              </label>
              <Input
                id="study_location"
                required
                value={course.study_location}
                onChange={handleInputChange}
                name="study_location"
              />
            </div>

            <div className="form-group mb-3 mt-3">
              <label>
                ราคาเต็ม
                <span className="text-danger">*</span>
              </label>
              <NumberFormat
                customInput={Input}
                thousandSeparator={true}
                className="some"
                inputMode="numeric"
                name="price"
                id="price"
                placeholder=""
                onChange={handleInputChange}
                value={course.price}
              />
            </div>

            <div className="form-group mb-3 mt-3">
              <label>ราคา Promotion</label>
              <NumberFormat
                customInput={Input}
                allowEmptyFormatting={true}
                thousandSeparator={true}
                className="some"
                inputMode="numeric"
                name="promotion_price"
                id="promotion_price"
                placeholder=""
                onChange={handleInputChange}
                value={course.promotion_price}
              />
            </div>

            <div className="form-group mb-3 mt-3">
              <label>จำนวนโควต้า</label>
              <NumberFormat
                customInput={Input}
                thousandSeparator={true}
                className="some"
                inputMode="numeric"
                name="quota"
                id="quota"
                placeholder=""
                onChange={handleInputChange}
                value={course.quota}
              />
            </div>

            <div className="form-group mb-3 mt-3">
              <label>
                ระดับผู้เรียน <span className="text-danger">*</span>
              </label>
              <Select
                defaultValue=""
                value={level_id}
                className="w-100"
                onSelect={(value, event) => onChangeLevelID(value, event)}
              >
                {levelSelect &&
                  levelSelect.map((item, index) => {
                    return <Option value={item.value}>{item.label}</Option>;
                  })}
              </Select>
            </div>

            <div className="form-group mb-3 mt-3">
              <label>
                จำนวนที่นั่งสูงสุดในการจองคอร์สต่อครั้ง
                <span className="text-danger">*</span>
              </label>
              <NumberFormat
                customInput={Input}
                thousandSeparator={true}
                className="some"
                inputMode="numeric"
                name="max_reserve"
                id="max_reserve"
                placeholder=""
                onChange={handleInputChange}
                value={course.max_reserve}
              />
            </div>

            <div className="form-group mb-3 mt-3">
              <label>
                External URL &nbsp;
                <span className="text-danger">
                  (ถ้าใส่ url จะเป็นการสมัครจากลิ้งค์ภายนอก กรุณาใส่ url ให้ถูก
                  format โดยมี http://,https://)
                </span>
              </label>
              <Input
                id="external_url"
                required
                value={course.external_url}
                onChange={handleInputChange}
                name="external_url"
              />
            </div>

            {/* {nft_only && (
              <> */}
            {/* <div className="form-group mb-2 mt-3">
              <label>
                สิทธิ์ของการจองคอร์ส (NFT){" "}
                <span className="text-danger">*</span>
              </label>
            </div>
            <div className="form-group mb-3 mt-2">
              <Checkbox onChange={onChangeCommon} checked={isCommon}>
                Common
              </Checkbox>
              <Checkbox onChange={onChangeRare} checked={isRare}>
                Rare
              </Checkbox>
              <Checkbox onChange={onChangeSuperRare} checked={isSuperRare}>
                Super Rare
              </Checkbox>
            </div> */}
            {/* </>
            )} */}

            <div className="header-bar mt-4">รายละเอียดคอร์สออนไลน์</div>

            <div className="form-group mb-3 mt-3">
              <label>VDO Link (youtube)</label>
              <Input
                id="vdo_link"
                value={course.vdo_link}
                onChange={handleInputChange}
                name="vdo_link"
              />
            </div>

            {course.vdo_link && (
              <div className="form-group mb-3 mt-3">
                <iframe
                  width="520"
                  height="315"
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={getYoutubeUrlEmbeded(course.vdo_link)}
                ></iframe>
              </div>
            )}

            <div className="form-group mb-3 mt-4">
              <label>รูปรายละเอียดคอร์ส</label>
              {/* <ImgCrop rotate zoom> */}
              <Upload
                action=""
                listType="picture-card"
                fileList={fileList}
                onChange={onChangeImgList}
                onPreview={onPreview}
                beforeUpload={() => false}
              >
                {fileList.length < 20 && "+ Upload"}
              </Upload>
              {/* </ImgCrop> */}
            </div>

            <div className="form-group pb-3">
              <label>รายละเอียดคอร์ส</label>
              <div className="content-wrapper">
                <CKEditor
                  editor={ClassicEditor}
                  data={course.course_detail}
                  config={{
                    extraPlugins: [customUploadAdapterPlugin],
                    indentBlock: {
                      offset: 1,
                      unit: "em",
                    },
                    image: {
                      resize: true,
                      toolbar: [
                        "linkImage",
                        "|",
                        "imageTextAlternative",
                        "|",
                        "imageStyle:full",
                        "imageStyle:alignLeft",
                        "imageStyle:alignCenter",
                        "imageStyle:alignRight",
                      ],
                      styles: [
                        "full",
                        "alignCenter",
                        "alignLeft",
                        "alignRight",
                      ],
                    },
                  }}
                  onInit={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    course.course_detail = data;
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
              </div>
            </div>

            <div className="form-group pb-3">
              <label>สิ่งที่จะได้เรียนรู้จากหลักสูตรนี้</label>
              <div className="content-wrapper">
                <CKEditor
                  editor={ClassicEditor}
                  data={course.expected_result}
                  config={{
                    extraPlugins: [customUploadAdapterPlugin],
                    indentBlock: {
                      offset: 1,
                      unit: "em",
                    },
                    image: {
                      resize: true,
                      toolbar: [
                        "linkImage",
                        "|",
                        "imageTextAlternative",
                        "|",
                        "imageStyle:full",
                        "imageStyle:alignLeft",
                        "imageStyle:alignCenter",
                        "imageStyle:alignRight",
                      ],
                      styles: [
                        "full",
                        "alignCenter",
                        "alignLeft",
                        "alignRight",
                      ],
                    },
                  }}
                  onInit={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    course.expected_result = data;
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
              </div>
            </div>

            <div className="form-group pb-3">
              <label>หลักสูตรนี้ เรียนอย่างไร</label>
              <div className="content-wrapper">
                <CKEditor
                  editor={ClassicEditor}
                  data={course.how_to_study}
                  config={{
                    extraPlugins: [customUploadAdapterPlugin],
                    indentBlock: {
                      offset: 1,
                      unit: "em",
                    },
                    image: {
                      resize: true,
                      toolbar: [
                        "linkImage",
                        "|",
                        "imageTextAlternative",
                        "|",
                        "imageStyle:full",
                        "imageStyle:alignLeft",
                        "imageStyle:alignCenter",
                        "imageStyle:alignRight",
                      ],
                      styles: [
                        "full",
                        "alignCenter",
                        "alignLeft",
                        "alignRight",
                      ],
                    },
                  }}
                  onInit={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    course.how_to_study = data;
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
              </div>
            </div>

            <div className="form-group pb-3">
              <label>ปิดการขาย</label>
              <div className="content-wrapper">
                <CKEditor
                  editor={ClassicEditor}
                  data={course.close_sale}
                  config={{
                    extraPlugins: [customUploadAdapterPlugin],
                    indentBlock: {
                      offset: 1,
                      unit: "em",
                    },
                    image: {
                      resize: true,
                      toolbar: [
                        "linkImage",
                        "|",
                        "imageTextAlternative",
                        "|",
                        "imageStyle:full",
                        "imageStyle:alignLeft",
                        "imageStyle:alignCenter",
                        "imageStyle:alignRight",
                      ],
                      styles: [
                        "full",
                        "alignCenter",
                        "alignLeft",
                        "alignRight",
                      ],
                    },
                  }}
                  onInit={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    course.close_sale = data;
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
              </div>
            </div>

            <div className="form-group pb-3">
              <table className="table table-bordered mt-3">
                <thead>
                  <tr>
                    <th>วิทยากร</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {lecturers && (
                        <Select
                          defaultValue={lecturers}
                          className="w-100"
                          mode="multiple"
                          allowClear
                          onChange={onChangeLecturer}
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder="เลือกวิทยากร"
                        >
                          {lecturerSelect &&
                            lecturerSelect.map((item, index) => {
                              return (
                                <Option value={item.lecturer_id}>
                                  {item.name}
                                </Option>
                              );
                            })}
                        </Select>
                      )}

                      {!lecturers && (
                        <Select
                          className="w-100"
                          mode="multiple"
                          allowClear
                          onChange={onChangeLecturer}
                          placeholder="เลือกวิทยากร"
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {lecturerSelect &&
                            lecturerSelect.map((item, index) => {
                              return (
                                <Option value={item.lecturer_id}>
                                  {item.name}
                                </Option>
                              );
                            })}
                        </Select>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-2">
          <div>
            {action === "update" && (
              <Popconfirm
                title="ต้องการลบเนื้อหานี้ออกจากเว็บไซต์?"
                onConfirm={confirmDialog}
                onCancel={cancelDialog}
                okText="ยืนยันการลบ"
                cancelText="ยกเลิก"
              >
                <Button className="btn-danger px-3">ลบข้อมูล</Button>
                <Spin spinning={loadingDelete} />
              </Popconfirm>
            )}
          </div>
        </div>

        <div className="col-10 text-right">
          <Button className="btn-secondary mr-2" onClick={cancel}>
            ยกเลิก
          </Button>
          <Button className="btn-primary" onClick={AddUpdateCourse}>
            บันทึกข้อมูล
          </Button>
          <Spin style={{ marginLeft: 10 }} spinning={loading} />
        </div>
      </div>

      <Modal
        title={null}
        footer={null}
        width={800}
        maskClosable={false}
        visible={cropModalOpen}
        onCancel={() => setCropModalOpen(false)}
      >
        <div className="d-flex">
          <div className="flex-fill thumbnail-crop-area">
            <Cropper
              src={imageUrl}
              aspectRatio={726 / 409}
              autoCropArea={1}
              preview=".book-preview"
              viewMode={1}
              ref={cropperRef}
            />
          </div>

          <div className="mt-4 book-preview-area ml-4">
            <div className="font-weight-bold text-center mb-2">ตัวอย่างรูป</div>

            <div className="book-preview" />
          </div>
        </div>
        <div className="mt-5 text-center">
          <button
            type="button"
            onClick={() => {
              cropImage();
            }}
            className="btn btn-primary mr-2"
          >
            ตกลง
          </button>
          <button
            type="button"
            className="btn btn-secondary mr-2"
            onClick={() => {
              setCropModalOpen(false);
            }}
          >
            ยกเลิก
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ManageCourse;
