import { notification } from "antd";

const Show = (type,header,message) =>
notification[type]({
  message: header,
  description: message,
});


  export default {
    Show
  };
  