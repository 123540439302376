import { httpClient } from "../utils/HttpClient";

const getAll = (page_no, filter_name, filter_category, filter_writer_id) => {
  return httpClient.get("/admin/article", {
    params: {
      page: page_no,
      name: filter_name,
      category_id: filter_category,
      writer_id: filter_writer_id,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getArticleSelect = () => {
  return httpClient.get("/admin/article/select", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getArticleCategory = () => {
  return httpClient.get("/admin/category/article", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const get = (article_id) => {
  return httpClient.get(`/admin/article/${article_id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getArticleName = (article_id) => {
  return httpClient.get(`/admin/article/name/${article_id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const create = (data) => {
  return httpClient.post("/admin/article", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const update = (article_id, data) => {
  return httpClient.patch(`/admin/article/${article_id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const remove = (article_id) => {
  return httpClient.delete(`/admin/article/${article_id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,    
    },
  });
};

const updateStatus = (article_id, data) => {
  return httpClient.patch(`/admin/article/status/${article_id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export default {
  getAll,
  get,
  create,
  update,
  remove,
  updateStatus,
  getArticleCategory,
  getArticleSelect,
  getArticleName,
};
