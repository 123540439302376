import { httpClient } from "../utils/HttpClient";

const getAll = (page_no, filter_name, filter_category, filter_writer,filter_nft_only) => {
  return httpClient.get("/admin/audio_book", {
    params: {
      page: page_no,
      book_name: filter_name,
      category_id: filter_category,
      writer_name: filter_writer,
      nft_only: filter_nft_only
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const get = (book_id) => {
  return httpClient.get(`/admin/audio_book/${book_id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getAudioBookSelect = () => {
  return httpClient.get("/admin/audio_book/select", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getAudioBookCategory = () => {
  return httpClient.get(`/admin/category/audio_book`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const create = (data) => {
  return httpClient.post("/admin/audio_book", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const update = (book_id, data) => {
  return httpClient.patch(`/admin/audio_book/${book_id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const remove = (book_id) => {
  return httpClient.delete(`/admin/audio_book/${book_id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const updateStatus = (book_id, data) => {
  return httpClient.patch(`/admin/audio_book/status/${book_id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export default {
  getAll,
  get,
  create,
  update,
  remove,
  updateStatus,
  getAudioBookCategory,
  getAudioBookSelect,
};
