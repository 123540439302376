import { httpClient } from "../utils/HttpClient";

const getAll = (page_no, filter_name) => {
  return httpClient.get("/admin/influencer", {
    params: {
      page: page_no,
      name: filter_name,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const get = (influencer_id) => {
  return httpClient.get(`/admin/influencer/${influencer_id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getInfluencerSelect = () => {
  return httpClient.get(`/admin/influencer_select`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getAddInfluencerSelect = () => {
  return httpClient.get(`/admin/influencer_add`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const create = (data) => {
  return httpClient.post("/admin/influencer", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const update = (influencer_id, data) => {
  return httpClient.patch(`/admin/influencer/${influencer_id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const remove = (influencer_id) => {
  return httpClient.delete(`/admin/influencer/${influencer_id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const updateStatus = (influencer_id, data) => {
  return httpClient.patch(`/admin/influencer/status/${influencer_id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export default {
  getAll,
  get,
  create,
  update,
  remove,
  updateStatus,
  getInfluencerSelect,
  getAddInfluencerSelect
};
