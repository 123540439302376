import { httpClient } from "../utils/HttpClient";

const getAll = (page_no, filter_name, filter_category, filter_level,filter_nft_only) => {
  return httpClient.get("/admin/course", {
    params: {
      page: page_no,
      course_name: filter_name,
      category_id: filter_category,
      level_id: filter_level,
      nft_only: filter_nft_only
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getCourseSummaryRegister = () => {
  return httpClient.get("/admin/course/total", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getCourseSelect = () => {
  return httpClient.get("/admin/course/select", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getCourseSelectReport = () => {
  return httpClient.get("/admin/course/select_report", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const get = (course_id) => {
  return httpClient.get(`/admin/course/${course_id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getCountOrderCourse = (course_id) => {
  return httpClient.get(`/admin/course/order/${course_id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getCourseLevel = () => {
  return httpClient.get(`/admin/course_level`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getCourseCategory = () => {
  return httpClient.get(`/admin/category/course`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const getNftClass = () => {
  return httpClient.get(`/admin/nft_class`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const create = (data) => {
  return httpClient.post("/admin/course", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const update = (course_id, data) => {
  return httpClient.patch(`/admin/course/${course_id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const remove = (course_id) => {
  return httpClient.delete(`/admin/course/${course_id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const updateStatus = (course_id, data) => {
  return httpClient.patch(`/admin/course/status/${course_id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

const updateAttendee = (data) => {
  return httpClient.patch(`/admin/attendee`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};


export default {
  getAll,
  get,
  create,
  update,
  remove,
  updateStatus,
  getCourseLevel,
  getCourseCategory,
  getCourseSelect,
  getCountOrderCourse,
  getCourseSummaryRegister,
  getCourseSelectReport,
  getNftClass,
  updateAttendee
};